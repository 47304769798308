import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, matchPath, useSearchParams } from "react-router-dom";
import { MAIN_LAYOUT_MENU, ROUTES } from "../../../utils/configs/router";

export default function Breadcrumb() {
  const location = useLocation();
  const params = useParams();
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const { listFolderAndFile, roomDetail, examDetail, groupDetail, memberResult } = useSelector(
    (state: { document; room; common; manageStudent }) => ({
      listFolderAndFile: state.document.listFolderAndFile,
      roomDetail: state.room.roomDetail,
      examDetail: state.common.examDetail,
      groupDetail: state.manageStudent.groupDetail,
      memberResult: state.manageStudent.memberResult,
    })
  );
  const availablePath = MAIN_LAYOUT_MENU?.map((e: any) => e?.path || e?.children?.map((c) => c?.path)).flat();

  const findValueRouter = (val) => {
    const key = Object.keys(params).find((e: string) => {
      if (params[e] === val) {
        return e;
      }
    });
    switch (key) {
      case "folderId":
        return listFolderAndFile?.folders?.find((e) => e?.id === Number(params?.folderId))?.title;
      case "roomId":
        return roomDetail?.room_info?.title;
      case "topicSlug":
        return examDetail?.title;
      case "groupHid":
        return groupDetail?.title;
      case "memberHid":
        return params?.groupHid
          ? groupDetail?.members?.find((mem) => mem?.member_hid === val)?.name
          : memberResult.name_member;
      case "examCode":
        return params?.examCode;
      default:
        return null;
    }
  };

  const breadcrumb = decodeURI(location.pathname)
    .split("/")
    ?.filter((e) => !!e && e);

  return (
    <div className="flex justify-start items-center py-6 text-gray-600">
      <button onClick={() => navigate("/")}>
        {" "}
        <i className="pi pi-home mr-1" />
      </button>
      {breadcrumb?.length > 0 && <i className="pi pi-chevron-right text-[0.8rem] mr-1" />}
      {breadcrumb.map((br, idx) => (
        <div key={br} className="gap-2 ">
          <button
            className="inline-block max-w-[400px] truncate"
            onClick={() => {
              if (idx !== breadcrumb.length - 1 || breadcrumb?.includes("xu-ly-khieu-nai")) {
                if (availablePath.some((e) => matchPath(e, location.pathname.split(br)[0] + br))) {
                  navigate(location.pathname.split(br)[0] + br);
                } else if (ROUTES.manageDocument.path.includes(br)) {
                  navigate(ROUTES.manageDocument.mine.path);
                }
              }
            }}
          >
            {findValueRouter(br) || <FormattedMessage id={br} />}
          </button>{" "}
          {idx !== breadcrumb.length - 1 && <i className="pi pi-chevron-right text-[0.8rem] mr-1" />}
        </div>
      ))}
      {!!searchParams?.get("qs") && (
        <span className="ml-1">
          <i className="pi pi-chevron-right text-[0.8rem]" /> <FormattedMessage id="cap-nhat-cau-hoi" />
        </span>
      )}
    </div>
  );
}
