/* eslint-disable no-console */
import { MathJaxContext } from "better-react-mathjax";
// import CircleLoading from 'features/common/components/CircleLoadding';
import { configMathJax } from "./utils/constant/constant";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { PrimeReactProvider } from "primereact/api";
import { persistStore } from "redux-persist";
import LocaleProvider from "./utils/localeProvider/LocaleProvider";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"; // icons
import "./App.css";
// import Firebase from './firebase/Firebase';
import "./index.css";
import "./index.scss";
import App from "./App";
import { store } from "./app/store";
import { AuthProvider } from "./components/auth/components/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-KKQHZ8MM",
};
TagManager.initialize(tagManagerArgs);

let persistor = persistStore(store);

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <>
    <PrimeReactProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <Firebase> */}
          <LocaleProvider>
            <HashRouter>
              <MathJaxContext config={configMathJax}>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </MathJaxContext>
            </HashRouter>
          </LocaleProvider>
          {/* </Firebase> */}
        </PersistGate>
      </Provider>
    </PrimeReactProvider>
    <ToastContainer
      position="top-right"
      hideProgressBar
      newestOnTop
      autoClose={3000}
      closeOnClick
      theme="colored"
      className="z-[9999]"
    />
    <script
      dangerouslySetInnerHTML={{
        __html: `<!-- Google tag (gtag.js) -->
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments)};
                    gtag('js', new Date());
                    gtag('config', ${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID});`,
      }}
    ></script>
  </>
);
