import api from "../../../utils/configs/api";

const createRoom = (data) => {
  const option = {
    method: "post",
    url: "teacher/room",
    data,
  };
  return api(option);
};

const updateRoom = (data) => {
  const option = {
    method: "patch",
    url: `teacher/room/${data.room_hid}`,
    data,
  };
  return api(option);
};

const getRoomDetail = (roomId) => {
  const option = {
    method: "get",
    url: `arena/rooms/${roomId}`,
  };
  return api(option);
};

const deleteRoom = (data) => {
  return api({
    method: "delete",
    url: `arena/rooms/${data.room_hid}`,
    data,
  });
};

const getMembersOfRoom = (params) => {
  return api({
    method: "get",
    url: `arena/rooms/${params?.roomHid}/members`,
    params,
  });
};

const deleteMemberOfRoom = (data) => {
  return api({
    method: "post",
    url: `arena/rooms/${data.room_hid}/remove`,
    data,
  });
};

const addMemberOfRoom = (data) => {
  return api({
    method: "post",
    url: `arena/rooms/${data.room_hid}/add`,
    data,
  });
};

const getExamDetailOfRoom = (params) => {
  return api({
    method: "get",
    url: `arena/rooms/${params.room_hid}/questions`,
    params,
  });
};

export const roomServices = {
  createRoom,
  updateRoom,
  getRoomDetail,
  deleteRoom,
  getMembersOfRoom,
  deleteMemberOfRoom,
  addMemberOfRoom,
  getExamDetailOfRoom,
};
