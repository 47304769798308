import moment from "moment";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "../../common/components/dialog/Dialog";
import { manageStudentActions } from "../redux/action";

type TActionHistoryDialogProps = {
  groupHid: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function ActionHistoryDialog({ groupHid, isOpen, onClose }: TActionHistoryDialogProps) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const actionHistory = useSelector((state: { manageStudent }) => state.manageStudent.actionHistory);

  useEffect(() => {
    dispatch(manageStudentActions.getGroupActionHistory({ groupHid }));
  }, []);

  return (
    <Dialog
      title={intl.formatMessage({ id: "actionHistoryDialog.title" })}
      isOpen={isOpen}
      onClose={onClose}
      actions={null}
      closable
    >
      <div className="mb-6 flex flex-col gap-1">
        {actionHistory.map((item) => (
          <div className="flex justify-between gap-6 text-gray-900 py-3">
            <div className="flex-1 line-clamp-2">{item.content}</div>
            <div>{moment(item.created_at * 1000).format("hh:mm DD/MM/yyyy")}</div>
          </div>
        ))}
      </div>
    </Dialog>
  );
}
