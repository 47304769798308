import { InputTextarea } from "primereact/inputtextarea";
import React, { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import ButtonGhost from "../button/ButtonGhost";
import ButtonPrimary from "../button/ButtonPrimary";
import Dialog from "./Dialog";
import { CloseIcon, IconUploadImage } from "../../../../assets";
import { Image } from "primereact/image";

const MAX_LENGTH_REPORT_TEXT_CONTENT = 256;
const MAX_IMAGE = 3;
const ACCEPT_IMAGE_TYPE = ["image/png", "image/jpg", "image/jpeg"];

type TReportDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formValue) => void;
};

export default function ReportDialog({ isOpen, onClose, onSubmit }: TReportDialogProps) {
  const intl = useIntl();
  const hiddenFileInput: any = useRef(null);
  const [errorImageType, setErrorImageType] = useState<String>("");
  const [formValue, setFormValue] = useState<{
    id?: number;
    textContent?: string;
    images?: { file: any; previewUrl: string }[];
  }>({
    textContent: "",
  });

  const handleUploadImage = (event) => {
    const filesUploaded = event?.target?.files;
    if (!filesUploaded) return;
    if (Object.values(filesUploaded)?.every((e: any) => ACCEPT_IMAGE_TYPE?.includes(e?.type))) {
      if ((formValue?.images?.length || 0) + filesUploaded.length > MAX_IMAGE) {
        setErrorImageType(intl.formatMessage({ id: "inputImage.maxFileError" }));
      } else {
        setErrorImageType("");
        const newImages = Object.values(filesUploaded)?.map((e: any) => ({
          file: e,
          previewUrl: URL?.createObjectURL(e),
        }));
        setFormValue({
          ...formValue,
          images: !!formValue?.images ? formValue?.images?.concat(newImages) : newImages,
        });
      }
    } else {
      setErrorImageType(intl.formatMessage({ id: "inputImage.fileTypeError" }));
    }
  };

  const handleDeleteImage = (imgUrl) => {
    if (!!errorImageType) {
      setErrorImageType("");
    }
    setFormValue({
      ...formValue,
      images: formValue?.images?.filter((img) => img?.previewUrl !== imgUrl),
    });
    if (hiddenFileInput?.current?.value) {
      hiddenFileInput.current.value = "";
    }
  };

  const validateTextContent = (textContent?: string) => {
    if (!textContent || textContent?.trim().length === 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    onSubmit(formValue);
  };

  const isDisableButtonSubmit = useMemo(() => !validateTextContent(formValue?.textContent), [formValue.textContent]);

  const actions = (
    <div className="flex justify-end gap-4">
      <ButtonGhost label="Huỷ" onClick={onClose} />
      <ButtonPrimary label="Gửi" onClick={handleSubmit} disabled={isDisableButtonSubmit} />
    </div>
  );

  return (
    <Dialog title="Báo cáo" isOpen={isOpen} onClose={onClose} actions={actions}>
      <div className="relative mb-2 border focus:border-secondary hover:border-secondary  rounded-[8px]">
        <InputTextarea
          className="w-full shadow-none border-none resize-none hide-custom-style"
          placeholder="Nhập nội dung báo cáo"
          value={formValue.textContent}
          onChange={(e) => {
            validateTextContent(e.target.value);
            setFormValue((prev) => ({ ...prev, textContent: e.target.value }));
          }}
          rows={5}
          cols={30}
          maxLength={MAX_LENGTH_REPORT_TEXT_CONTENT}
        />
        <input
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          ref={hiddenFileInput}
          onChange={handleUploadImage}
          style={{ display: "none" }}
          multiple
        />

        <div className="w-full flex justify-between px-3 pb-2 text-[12px] items-center text-gray-400">
          <button
            type="button"
            className={`${(formValue?.images?.length || 0) < MAX_IMAGE ? "visible" : "invisible"}`}
            onClick={() => hiddenFileInput.current.click()}
          >
            <IconUploadImage />
          </button>
          {formValue?.textContent?.length || 0}/{MAX_LENGTH_REPORT_TEXT_CONTENT}
        </div>
      </div>
      <span className="flex text-[12px] text-red-500 justify-end pb-2">{errorImageType}</span>
      <div className="flex gap-2 w-full justify-center">
        {!!formValue?.images &&
          formValue?.images?.map((image) => (
            <div className={`relative w-1/${formValue?.images?.length}`}>
              <Image alt="" src={image?.previewUrl} preview pt={{ image: { className: "object-fill" } }} />
              <button
                type="button"
                className="absolute right-0 top-0"
                onClick={() => handleDeleteImage(image?.previewUrl)}
              >
                <CloseIcon />
              </button>
            </div>
          ))}
      </div>
    </Dialog>
  );
}
