import React from "react";
import ButtonGhost from "../button/ButtonGhost";
import ButtonPrimary from "../button/ButtonPrimary";
import Dialog from "./Dialog";

type TAlertDialog = {
  open: boolean;
  handleClose: () => void;
  triggerFunc?: () => void;
  notify: {
    code?: string;
    message: string;
  };
  isHideCancel?: boolean;
};

export default function AlertDialog({
  open,
  handleClose,
  triggerFunc = () => {},
  notify = {} as any,
  isHideCancel = false,
}: TAlertDialog) {
  const actions = (
    <div>
      {!isHideCancel && <ButtonGhost label="Huỷ" onClick={() => handleClose()} />}
      <ButtonPrimary
        label="Đồng ý"
        onClick={() => {
          handleClose();
          triggerFunc();
        }}
        autoFocus
      />
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Dialog title="Thông báo" isOpen={open} closable={false} onClose={() => handleClose()} actions={actions}>
        <p className="m-0">{notify?.message}</p>
      </Dialog>
    </div>
  );
}
