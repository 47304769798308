import { convertAnswerKey, quizAnswerType } from "../../common/utils/constant";
import { arrayMove } from "../../common/utils/helper";
import { ANSWER_TYPE_OPTIONS } from "./constant";

export const getAnswerTypeOfQuestion = (options) => {
  if (!options) return null;
  const correctOptionsCount = options.reduce((accumulate, currentValue) => {
    if (Boolean(currentValue.correct)) {
      return accumulate + 1;
    }
    return accumulate;
  }, 0);

  const answerType = correctOptionsCount > 1 ? quizAnswerType.MANY_ANSWER : quizAnswerType.ONE_ANSWER;

  return answerType;
};

export const getBaseOption = (op) => ({
  id: op?.id,
  correct: op?.correct,
  ordinal: op?.ordinal,
  contents: op?.contents,
  answer_key: op?.answer_key,
  items: op?.items,
});

export const getCorrectOrdinalOfListQuestion = (listQuestion) => {
  const newListQuestion: any = [];
  let ordinalQs = 1;
  listQuestion?.forEach((question) => {
    if (question?.deleted) {
      newListQuestion.push({
        ...question,
      });
    } else {
      newListQuestion.push({
        ...question,
        ordinal: ordinalQs,
      });
      ordinalQs += 1;
    }
  });
  return newListQuestion;
};

export const isParentQuestion = (question) => {
  return question?.answer_type === quizAnswerType.NONE;
};

export const getNewOptions = (answerTypeChosen, data) => {
  let newOptions;
  if (answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER) {
    newOptions = data
      ?.filter((d) => d?.ordinal >= 0)
      ?.map((op) => ({
        ...op,
        ordinal: op?.ordinal,
        answer_key: convertAnswerKey(op?.ordinal),
        correct: op?.correct || 0,
        contents: [
          {
            id: op?.items ? op?.items[0]?.id : null,
            ordinal: 1,
            content_type: "html",
            edited: true,
            content: op?.content,
          },
        ],
      }));
  } else {
    const correctOption = data?.find((e) => e.ordinal === 1);
    newOptions = [
      {
        ...correctOption,
        contents: [
          {
            id: correctOption?.items ? correctOption?.items[0]?.id : null,
            content: correctOption.content,
            content_type: "html",
            ordinal: 1,
            edited: true,
          },
        ],
        correct: correctOption.correct,
        ordinal: correctOption.ordinal,
      },
    ];
  }

  return newOptions;
};

export const removeChildsOfParent = (listQuestion: any[], parentId: number) => {
  return listQuestion.map((question) => {
    if (question.parent === parentId) {
      return {
        ...question,
        parent: undefined,
      };
    } else {
      return question;
    }
  });
};

export const moveQuestionToBottomOfParentBlock = (listQuestion, originQsIndex, parentQuestionId) => {
  let lastQuestionIndexOfParentBlock = listQuestion?.reduce((accumulate, question, index) => {
    if (index !== originQsIndex && (question.parent === parentQuestionId || question.id === parentQuestionId)) {
      return index;
    } else {
      return accumulate;
    }
  }, -1);

  const newQsIndex =
    originQsIndex > lastQuestionIndexOfParentBlock
      ? lastQuestionIndexOfParentBlock + 1
      : lastQuestionIndexOfParentBlock;

  return {
    updatedListQuestion: arrayMove(listQuestion, originQsIndex, newQsIndex),
    newQuestionIndex: newQsIndex,
  };
};

export const addQuestionToBottomOfParentBlock = (listQuestion, question, parentQuestionId) => {
  const lastQuestionIndexOfParentBlock = listQuestion
    .filter((question) => !question.deleted)
    ?.reduce((accumulate, question, index) => {
      if (question.parent === parentQuestionId || question.id === parentQuestionId) {
        return index;
      } else {
        return accumulate;
      }
    }, -1);
  const clonedListQuestion = [...listQuestion];
  clonedListQuestion.splice(lastQuestionIndexOfParentBlock + 1, 0, question);
  return clonedListQuestion;
};

export const getOrdinalQuestionForUI = (listQuestion, question) => {
  return question
    ? listQuestion
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
        ?.findIndex((qs) => qs.ordinal === question.ordinal) + 1 || 1
    : listQuestion
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE)?.length + 1;
};

export const getSortedListQuestion = (listQuestion) => {
  const deletedQuestion = listQuestion?.filter((question) => question.deleted);
  const sortedList: any[] = [];
  const loopingList = listQuestion?.filter((question) => !question.deleted)?.filter((question) => !question.parent);
  loopingList.forEach((question) => {
    sortedList.push(question);
    if (question?.answer_type === quizAnswerType.NONE) {
      const listChildQuestion = listQuestion
        ?.filter((question) => !question.deleted)
        .filter((questionItem) => questionItem.parent === question.id);
      sortedList.push(listChildQuestion);
    }
  });
  return getCorrectOrdinalOfListQuestion([...sortedList.flat(), ...deletedQuestion]);
};

export const getUpdatedListQuestion = (
  listQuestion,
  oldQuestion,
  data,
  isCheckedParentQuestion,
  answerTypeChosen,
  parentQuestionId
) => {
  let updatedListQuestion = [...listQuestion];
  let targetOrdinal;
  const qsIndex = updatedListQuestion.findIndex((question) => question.ordinal === oldQuestion.ordinal);
  if (isParentQuestion(oldQuestion)) {
    // parent => parent
    if (isCheckedParentQuestion) {
      updatedListQuestion[qsIndex] = {
        ordinal: updatedListQuestion[qsIndex].ordinal,
        id: updatedListQuestion[qsIndex].id,
        contents: [
          {
            ...updatedListQuestion[qsIndex]?.contents[0],
            content: data?.find((e) => e?.ordinal === -1)?.content,
            edited: true,
          },
        ],
        answer_type: quizAnswerType.NONE,
        isSelfInput: true,
      };
    }
    // parent => normal
    else if (!Boolean(parentQuestionId)) {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;
      let newOptions = getNewOptions(answerTypeChosen, data);

      updatedListQuestion[qsIndex] = {
        ...updatedListQuestion[qsIndex],
        contents: [
          {
            ...updatedListQuestion[qsIndex]?.contents[0],
            content: data?.find((e) => e?.ordinal === -1)?.content,
            edited: true,
          },
        ],
        options: newOptions,
        answer_type: answerType,
        ...(answerType === quizAnswerType.CONSTRUCTED_RESPONSE && {
          description_answer_before: data?.find((e) => e.ordinal === 0).content,
          description_answer_after: data?.find((e) => e.ordinal === 2).content,
        }),
        solutions: [],
      };
      if (updatedListQuestion[qsIndex].id < 0) delete updatedListQuestion[qsIndex].id;
      updatedListQuestion = removeChildsOfParent(updatedListQuestion, oldQuestion.id);
      targetOrdinal = updatedListQuestion[qsIndex].ordinal;
    }
    // parent => child
    else {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;
      let newOptions = getNewOptions(answerTypeChosen, data);

      updatedListQuestion[qsIndex] = {
        ...updatedListQuestion[qsIndex],
        contents: [
          {
            ...updatedListQuestion[qsIndex]?.contents[0],
            content: data?.find((e) => e?.ordinal === -1)?.content,
            edited: true,
          },
        ],
        options: newOptions,
        answer_type: answerType,
        ...(answerType === quizAnswerType.CONSTRUCTED_RESPONSE && {
          description_answer_before: data?.find((e) => e.ordinal === 0).content,
          description_answer_after: data?.find((e) => e.ordinal === 2).content,
        }),
        parent: parentQuestionId,
        solutions: [],
      };
      if (updatedListQuestion[qsIndex].id < 0) delete updatedListQuestion[qsIndex].id;

      updatedListQuestion = removeChildsOfParent(updatedListQuestion, oldQuestion.id);
      const { updatedListQuestion: newUpdatedListQuestion, newQuestionIndex } = moveQuestionToBottomOfParentBlock(
        updatedListQuestion,
        qsIndex,
        parentQuestionId
      );
      updatedListQuestion = newUpdatedListQuestion;
      updatedListQuestion = getCorrectOrdinalOfListQuestion(updatedListQuestion);
      targetOrdinal = updatedListQuestion[newQuestionIndex].ordinal;
    }
  } else if (!oldQuestion.parent) {
    // normal => parent
    if (isCheckedParentQuestion) {
      updatedListQuestion[qsIndex] = {
        ordinal: updatedListQuestion[qsIndex].ordinal,
        id: updatedListQuestion[qsIndex].id || -updatedListQuestion[qsIndex].ordinal,
        contents: [
          {
            ...updatedListQuestion[qsIndex]?.contents[0],
            content: data?.find((e) => e?.ordinal === -1)?.content,
            edited: true,
          },
        ],
        answer_type: quizAnswerType.NONE,
        isSelfInput: true,
      };

      targetOrdinal = -1;
    }
    // normal => normal
    else if (!Boolean(parentQuestionId)) {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;
      let newOptions = getNewOptions(answerTypeChosen, data);

      updatedListQuestion[qsIndex] = {
        ...updatedListQuestion[qsIndex],
        contents: [
          {
            ...updatedListQuestion[qsIndex]?.contents[0],
            content: data?.find((e) => e?.ordinal === -1)?.content,
            edited: true,
          },
        ],
        options: newOptions,
        answer_type: answerType,
        ...(answerType === quizAnswerType.CONSTRUCTED_RESPONSE && {
          description_answer_before: data?.find((e) => e.ordinal === 0).content,
          description_answer_after: data?.find((e) => e.ordinal === 2).content,
        }),
      };
    }
    // normal => child
    else {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;
      let newOptions = getNewOptions(answerTypeChosen, data);

      updatedListQuestion[qsIndex] = {
        ...updatedListQuestion[qsIndex],
        contents: [
          {
            ...updatedListQuestion[qsIndex]?.contents[0],
            content: data?.find((e) => e?.ordinal === -1)?.content,
            edited: true,
          },
        ],
        options: newOptions,
        answer_type: answerType,
        ...(answerType === quizAnswerType.CONSTRUCTED_RESPONSE && {
          description_answer_before: data?.find((e) => e.ordinal === 0).content,
          description_answer_after: data?.find((e) => e.ordinal === 2).content,
        }),
        parent: parentQuestionId,
      };

      const { updatedListQuestion: newUpdatedListQuestion, newQuestionIndex } = moveQuestionToBottomOfParentBlock(
        updatedListQuestion,
        qsIndex,
        parentQuestionId
      );
      updatedListQuestion = newUpdatedListQuestion;
      updatedListQuestion = getCorrectOrdinalOfListQuestion(updatedListQuestion);
      targetOrdinal = updatedListQuestion[newQuestionIndex].ordinal;
    }
  } else {
    // child => parent
    if (isCheckedParentQuestion) {
      updatedListQuestion[qsIndex] = {
        ordinal: updatedListQuestion[qsIndex].ordinal,
        id: updatedListQuestion[qsIndex].id || -updatedListQuestion[qsIndex].ordinal,
        contents: [
          {
            ...updatedListQuestion[qsIndex]?.contents[0],
            content: data?.find((e) => e?.ordinal === -1)?.content,
            edited: true,
          },
        ],
        answer_type: quizAnswerType.NONE,
        isSelfInput: true,
      };
      updatedListQuestion = arrayMove(updatedListQuestion, qsIndex, updatedListQuestion.length - 1);
      updatedListQuestion = getCorrectOrdinalOfListQuestion(updatedListQuestion);

      targetOrdinal = -1;
    }
    // child => normal
    else if (!Boolean(parentQuestionId)) {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;
      let newOptions = getNewOptions(answerTypeChosen, data);

      updatedListQuestion[qsIndex] = {
        ...updatedListQuestion[qsIndex],
        contents: [
          {
            ...updatedListQuestion[qsIndex]?.contents[0],
            content: data?.find((e) => e?.ordinal === -1)?.content,
            edited: true,
          },
        ],
        options: newOptions,
        answer_type: answerType,
        ...(answerType === quizAnswerType.CONSTRUCTED_RESPONSE && {
          description_answer_before: data?.find((e) => e.ordinal === 0).content,
          description_answer_after: data?.find((e) => e.ordinal === 2).content,
        }),
      };
      const { updatedListQuestion: newUpdatedListQuestion, newQuestionIndex } = moveQuestionToBottomOfParentBlock(
        updatedListQuestion,
        qsIndex,
        oldQuestion.parent
      );
      updatedListQuestion = newUpdatedListQuestion;
      delete updatedListQuestion[newQuestionIndex].parent;
      updatedListQuestion = getCorrectOrdinalOfListQuestion(updatedListQuestion);
      targetOrdinal = updatedListQuestion[newQuestionIndex].ordinal;
    }
    // child to child
    else {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;
      let newOptions = getNewOptions(answerTypeChosen, data);

      updatedListQuestion[qsIndex] = {
        ...updatedListQuestion[qsIndex],
        contents: [
          {
            ...updatedListQuestion[qsIndex]?.contents[0],
            content: data?.find((e) => e?.ordinal === -1)?.content,
            edited: true,
          },
        ],
        options: newOptions,
        answer_type: answerType,
        ...(answerType === quizAnswerType.CONSTRUCTED_RESPONSE && {
          description_answer_before: data?.find((e) => e.ordinal === 0).content,
          description_answer_after: data?.find((e) => e.ordinal === 2).content,
        }),
        parent: parentQuestionId,
      };

      const isChangeParent = oldQuestion.parent !== parentQuestionId;
      if (isChangeParent) {
        const { updatedListQuestion: newUpdatedListQuestion, newQuestionIndex } = moveQuestionToBottomOfParentBlock(
          updatedListQuestion,
          qsIndex,
          parentQuestionId
        );
        updatedListQuestion = newUpdatedListQuestion;
        updatedListQuestion = getCorrectOrdinalOfListQuestion(updatedListQuestion);
        targetOrdinal = updatedListQuestion[newQuestionIndex].ordinal;
      }
    }
  }
  return { updatedListQuestion, targetOrdinal };
};

export const getFirstOrdinalQuestionWithoutParent = (listQuestion) => {
  const firstOrdinalQuestionWithoutParent = listQuestion
    ?.filter((question) => !question.deleted)
    ?.find((question) => question.answer_type !== quizAnswerType.NONE)?.ordinal;

  return firstOrdinalQuestionWithoutParent || -1;
};
