import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES } from "./actionTypes";
import { documentServices } from "./services";

function* createFolder(action) {
  try {
    const response = yield call(documentServices.createFolder, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error);
  }
  action.finallyCallback();
}

function* getListFolderAndFile(action) {
  try {
    const response = yield call(documentServices.getListFolderAndFile, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_LIST_FOLDER_FILE_SUCCESS,
        params: response?.data?.data,
      });
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback();
  }
  action.finallyCallback();
}

function* updateFolder(action) {
  try {
    const response = yield call(documentServices.updateFolder, action.params.folderId, action.params.data);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error);
  }
  action.finallyCallback();
}

function* deleteFolder(action) {
  try {
    const response = yield call(documentServices.deleteFolder, action.params.folderId, action.params.data);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* shareDocument(action) {
  try {
    const response = yield call(documentServices.shareDocument, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* deleteExams(action) {
  try {
    const response = yield call(documentServices.deleteExams, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* previewExam(action) {
  try {
    const response = yield call(documentServices.previewExam, action.params);
    const params = {
      ...response?.data?.data,
      questions: response?.data?.data?.questions?.map((e, idx) => ({
        ...e,
        ordinal: idx + 1,
        grade_id: action.params?.gradeId,
        subject_type_id: action.params?.subjectTypeId,
        learning_program_id: action.params?.learningProgramId,
        semester_id: action.params?.semesterId,
      })),
    };
    yield put({
      type: ACTION_TYPES.PREVIEW_EXAM_SUCCESS,
      params,
    });
    action.successCallback(params);
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* createGroupExam(action) {
  try {
    const response = yield call(documentServices.createGroupExam, action.params);
    yield put({
      type: ACTION_TYPES.CREATE_GROUP_EXAM_SUCCESS,
      params: response?.data?.data,
    });
    toast.success(response?.data?.data?.success_message);
    action.successCallback(response?.data?.data?.topic_slug);
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* getExamDetail(action) {
  try {
    const response = yield call(documentServices.getExamDetail, action.params);
    yield put({
      type: ACTION_TYPES.GET_EXAM_DETAIL_SUCCESS,
      params: response?.data?.data,
    });
    action.successCallback();
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* documentSaga() {
  yield takeLatest(ACTION_TYPES.CREATE_FOLDER, createFolder);
  yield takeLatest(ACTION_TYPES.GET_LIST_FOLDER_FILE, getListFolderAndFile);
  yield takeLatest(ACTION_TYPES.UPDATE_FOLDER, updateFolder);
  yield takeLatest(ACTION_TYPES.DELETE_FOLDER, deleteFolder);
  yield takeLatest(ACTION_TYPES.SHARE_DOCUMENT, shareDocument);
  yield takeLatest(ACTION_TYPES.DELETE_EXAMS, deleteExams);
  yield takeLatest(ACTION_TYPES.PREVIEW_EXAM, previewExam);
  yield takeLatest(ACTION_TYPES.CREATE_GROUP_EXAM, createGroupExam);
  yield takeLatest(ACTION_TYPES.GET_EXAM_DETAIL, getExamDetail);
}
export default documentSaga;
