import { InputNumber } from "primereact/inputnumber";
import React, { useState } from "react";
import ButtonGhost from "../../common/components/button/ButtonGhost";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import Dialog from "../../common/components/dialog/Dialog";

export default function DialogInputNumber({ open, handleClose, cancelFunction, title }) {
  const [value, setValue] = useState<number>();
  const actions = (
    <div className="flex justify-end gap-2">
      <ButtonGhost label="Hủy" onClick={() => cancelFunction()} />
      <ButtonPrimary label="OK" onClick={() => handleClose(value)} />
    </div>
  );

  return (
    <Dialog title={title} isOpen={open} onClose={cancelFunction} actions={actions}>
      <InputNumber
        className="w-full my-1"
        useGrouping={false}
        onValueChange={(e) => setValue(Number(e.value))}
        placeholder={title}
        min={1}
        // max={200}
      />
    </Dialog>
  );
}
