import { ACTION_TYPES } from "./actionTypes";

const createRoom = (params, successCallback = (roomId) => {}, errorCallback = (errorCode) => {}) => {
  return {
    type: ACTION_TYPES.CREATE_ROOM,
    params,
    successCallback,
    errorCallback,
  };
};

const updateRoom = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.UPDATE_ROOM,
    params,
    successCallback,
  };
};

const getRoomDetail = (params, successCallback = (roomDetail) => {}, errorCallback = (errorCode) => {}) => {
  return {
    type: ACTION_TYPES.GET_ROOM_DETAIL,
    params,
    successCallback,
    errorCallback,
  };
};

const deleteRoom = (params, successCallback = () => {}, failedCallBack = () => {}) => {
  return {
    type: ACTION_TYPES.DELETE_ROOM,
    params,
    successCallback,
    failedCallBack,
  };
};

const getMemberOfRoom = (params) => {
  return {
    type: ACTION_TYPES.GET_MEMBERS_OF_ROOM,
    params,
  };
};

const deleteMemberOfRoom = (params, successCallback = () => {}, failedCallback = () => {}) => {
  return {
    type: ACTION_TYPES.DELETE_MEMBER_OF_ROOM,
    params,
    successCallback,
    failedCallback,
  };
};

const addMemberOfRoom = (params, successCallback = () => {}, failedCallback = () => {}) => {
  return {
    type: ACTION_TYPES.ADD_MEMBER_OF_ROOM,
    params,
    successCallback,
    failedCallback,
  };
};

const handleReloadDetailRoom = () => {
  return {
    type: ACTION_TYPES.RELOAD_DETAIL_ROOM,
  };
};

const getExamDetailOfRoom = (
  params,
  successCallback = () => {},
  failedCallback = (errorCode) => {},
  finallyCallback = () => {}
) => {
  return {
    type: ACTION_TYPES.GET_EXAM_DETAIL_OF_ROOM,
    params,
    successCallback,
    failedCallback,
    finallyCallback,
  };
};

export const roomActions = {
  createRoom,
  updateRoom,
  getRoomDetail,
  deleteRoom,
  getMemberOfRoom,
  deleteMemberOfRoom,
  addMemberOfRoom,
  handleReloadDetailRoom,
  getExamDetailOfRoom,
};
