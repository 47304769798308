import React, { useState } from "react";
import { Editor, EditorTextChangeEvent } from "primereact/editor";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export default function DialogTextEditor({ open, onClose, onSubmit = (v: any) => {}, content = "", title = "" }) {
  const [text, setText] = useState<any>(content);

  return (
    <Dialog
      header={<span className="flex justify-center">{title}</span>}
      visible={open}
      style={{ width: "50vw" }}
      onHide={() => onClose()}
      closable={false}
      draggable={false}
      footer={
        <div className="flex justify-end">
          <Button
            severity="warning"
            rounded
            text
            className="w-[120px] h-[40px] flex justify-center font-[600]"
            onClick={() => {
              onClose();
            }}
          >
            Hủy
          </Button>
          <Button
            severity="warning"
            rounded
            className="w-[120px] h-[40px] flex justify-center font-[600]"
            onClick={() => {
              if (
                text
                  ?.replaceAll(/<\/?p[^>]*>/g, "")
                  .replace("<br>", "")
                  .trim()?.length === 0
              ) {
                onSubmit("");
              } else {
                onSubmit(text);
              }
              onClose();
            }}
          >
            Lưu
          </Button>
        </div>
      }
    >
      <div className="card">
        <Editor
          theme="snow"
          value={text}
          onTextChange={(e: EditorTextChangeEvent) => {
            setText(e.htmlValue);
          }}
          style={{ height: "320px" }}
          pt={{
            root: {
              className: `
                [&_.ql-active]:text-primary
                [&_.ql-active_.ql-stroke:not(.ql-color-label)]:stroke-primary
                [&_.ql-active_.ql-fill:not(.ql-color-label)]:stroke-primary
              `,
            },
          }}
        />
      </div>
    </Dialog>
  );
}
