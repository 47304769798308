import React, { useEffect, useRef, useState } from "react";

const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = useState([]);

  const getNestedHeadings = (headingElements) => {
    const nestedHeadings: any = [];
    headingElements.forEach((heading, index) => {
      const { innerText: title, id } = heading;
      if (heading.nodeName === "H2") {
        nestedHeadings.push({ id, title, items: [] });
      } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
        nestedHeadings[nestedHeadings.length - 1].items.push({
          id,
          title,
        });
      }
    });
    return nestedHeadings;
  };

  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll("h2, h3"));

    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const useIntersectionObserver = (setActiveId) => {
  // Tìm tiêu đề hiện đang active

  const headingElementsRef = useRef({});
  useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      const visibleHeadings: any = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) => headingElements.findIndex((heading) => heading.id === id);

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );
        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    // rootMargin: Xác định vùng của phần tử
    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px 0px -40% 0px",
    });

    const headingElements = Array.from(document.querySelectorAll("h2, h3"));
    headingElements.forEach((element) => observer.observe(element));
    return () => observer.disconnect();
  }, [setActiveId]);
};

export default function UserManual() {
  const { nestedHeadings } = useHeadingsData();
  const [activeId, setActiveId] = useState();
  useIntersectionObserver(setActiveId);

  const scrollIntoView = (id) => {
    document.getElementById(id)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className="flex flex-row">
      <div className="w-[312px] h-full px-10 py-6 font-bold">
        <ul>
          {nestedHeadings.map((heading: any) => (
            <li key={heading.id} className="py-2">
              <a
                href={`#${heading.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  scrollIntoView(`${heading.id}`);
                }}
                // className="hover:underline"
                className={`hover:underline ${heading.id === activeId ? "underline" : ""}`}
              >
                {heading.title}
              </a>
              {heading.items.length > 0 && (
                <ul className="ml-4">
                  {heading.items.map((child) => (
                    <li key={child.id} className={`py-2 ${child.id === activeId ? "underline" : ""}`}>
                      <a
                        href={`#${child.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          scrollIntoView(`${child.id}`);
                        }}
                        className="hover:underline"
                      >
                        {child.title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="main-content flex flex-col py-8 px-10 w-[calc(100%-312px)] gap-2 border-l">
        <h1 className="font-bold text-[24px] leading-[36px]">
          HƯỚNG DẪN GIÁO VIÊN SỬ DỤNG WEBSITE THI TỐT - GIÁO VIÊN
        </h1>
        <span>
          Trang web Thi Tốt - Giáo viên là website dành riêng cho giáo viên nhằm quản lý phòng thi online, tài liệu
          giảng dạy và quản lý học sinh. Dưới đây là hướng dẫn thầy cô sử dụng website để tải đề thi và tạo phòng thi
          online.
        </span>
        <span className="text-[14px] italic">
          * Lưu ý: Thầy cô cần dùng tài khoản Giáo viên đã được phê duyệt để đăng nhập hệ thống để có thể sử dụng các
          tính năng.
        </span>
        <h2 id="part_1" className="mt-5 text-[20px]">
          PHẦN 1: HƯỚNG DẪN TẠO FILE ĐỀ THI TRÊN HỆ THỐNG.
        </h2>
        <h3 id="part_1_pdf" className="text-[18px]">
          1. Nhập đề từ file PDF
        </h3>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/1.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 1:</b> Thêm thông tin và tải đề thi lên hệ thống.{" "}
        </span>
        <span>Trước khi tải đề, thầy cô cần điền thông tin đầy đủ trên hệ thống.</span>
        <span>
          Thông tin gồm: Lớp, Môn học, Chương trình học, Thời gian, Số câu.{" "}
          <b>Riêng Học kỳ, Chuyên đề (Có thể chọn hoặc không).</b>
        </span>

        <div className="w-full flex justify-center">
          {" "}
          <img src={require("../assets/images/user_manual/3.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Sau khi điền đủ thông tin, thầy cô tiến hành tải đề PDF lên hệ thống.</span>
        <span>Chọn ô "Click vào đây để tải file hoặc kéo và thả (PDF)" để tải đề thi PDF đã chuẩn bị.</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/1.2.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 2:</b> Nhập đáp án và lời giải cho đề thi.
        </span>
        <span> Bên trái màn hình, thầy cô nhập đáp án theo số câu. </span>
        <span>Click vào "Thêm hướng dẫn giải" để thêm lời giải.</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/4.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Thầy cô có thể nhập lời giải bằng nhiều cách:</span>
        <span>Cách 1: Nhập lời giải bằng văn bản.</span>
        <span>Nếu muốn nhập công thức Toán, Lý, Hóa thầy cô vui lòng nhập dưới dạng MathJax</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/5.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Cách 2: Sử dụng hình ảnh.</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/6.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 4:</b> Lưu đề thi.
        </span>
        <span>Sau khi nhập xong đáp án cho đề, thầy cô bấm "Tạo mới" để lưu đề và tạo đề trên hệ thống.</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/7.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Sau đó, thầy cô nhập tên file và bấm "Tạo"</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/8.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Vậy là thầy cô đã up file đề thi PDF thành công lên hệ thống!</span>
        <h3 id="part_1_docx" className="text-[18px] mt-5">
          2. Nhập đề thi bằng file DOC
        </h3>
        <span>
          Để nhập được đề thi bằng file DOC, thầy cô cần nhập đề theo format thiết kế sẵn để có thể tải lên hệ thống.
        </span>
        <span>
          Thầy cô lấy MẪU nhập đề tại{" "}
          <a
            className="text-[#18a0fb]"
            href="https://docs.google.com/document/d/1aDGfGCWfWcwjdVPLdoNiGxkotwgCaUBK/edit"
          >
            ĐÂY
          </a>
        </span>
        <span className="text-[14px] italic">
          * Lưu ý: Thầy cô cần điền đúng theo FORMAT mới có thể up đề lên hệ thống. Phần lời giải chỉ có thể up 1 hình
          và chữ.
        </span>

        <div className="w-full flex justify-center">
          {" "}
          <img src={require("../assets/images/user_manual/11.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 1:</b> Thêm thông tin và tải đề thi lên hệ thống.
        </span>
        <span>
          Thông tin gồm: Lớp, Môn học, Chương trình học, Thời gian, Số câu.{" "}
          <b>Riêng Học kỳ, Chuyên đề (Có thể chọn hoặc không).</b>
        </span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/11.1.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          Sau khi điền đủ thông tin, thầy cô tiến hành tải đề DOC lên hệ thống. Chọn ô "Click vào đây để tải file hoặc
          kéo và thả (DOC)" để tải đề thi DOC đã chuẩn bị.
        </span>
        <span className="text-[14px] italic">
          * Lưu ý: File DOC cần điền theo đúng FORMAT mẫu được cung cấp ở trên.
        </span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/9.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 2:</b> Lưu và tạo đề mới.
        </span>
        <span>Sau khi kiểm tra lại câu hỏi và đáp án, thầy cô bấm "Tạo mới" để tạo đề trên hệ thống.</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/12.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Sau đó, thầy cô lưu tên file cần thiết và bấm "Tạo"</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/14.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Vậy là thầy cô đã tạo đề bằng file DOC thành công!</span>
        <h3 id="part_1_nhbd" className="text-[18px]">
          3. Tạo đề từ ngân hàng bộ đề trên hệ thống.
        </h3>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/22.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 1:</b> Điền thông tin cần thiết
        </span>
        <span>
          Thông tin gồm: Lớp, Môn học, Chương trình học, Học kỳ, Chuyên đề (Có thể có hoặc không), Thời gian, Số câu.
        </span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/23.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          Vì đây là ngân hàng bộ đề có sẵn trên hệ thống, nên thầy cô có thể chọn nội dung kiểm tra mong muốn. Chọn
          chương cần tạo đề, bài cần tạo đề bên trong.
        </span>
        <span>
          Nếu muốn tạo đề kiểm tra cho cả chương, thầy cô chỉ cần chọn chương mong muốn. Ngoài ra, thầy cô có thể sửa số
          câu trong mức độ nhận thức bao gồm câu Nhận biết, Thông hiểu, Vận dụng, Nâng cao,...
        </span>
        <span className="text-[14px] italic">* Lưu ý: Cần bấm "Enter" để thay đổi số câu thành công.</span>

        <div className="w-full flex justify-center">
          {" "}
          <img src={require("../assets/images/user_manual/24.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 2:</b> Xem trước đề
        </span>
        <span>Sau khi chọn đủ số câu cần thiết, thầy cô bấm vào "Xem trước" để đánh giá đề tổng quát.</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/25.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          Tại màn hình "Xem trước", thầy cô đối chiếu kết quả, hướng dẫn giải của hệ thống, nếu phát hiện lỗi, hãy bấm
          "Báo lỗi" để đội kỹ thuật sửa lỗi.
        </span>
        <span>
          Nếu thầy cô muốn thay đổi câu hỏi, thầy cô chọn "Gen lại" để lấy một số câu hỏi khác từ ngân hàng bộ đề.
        </span>
        <span>
          Sau khi chọn được bộ đề như mong muốn, thầy cô chọn "Thời gian" phù hợp với đề thi để có thể tạo đề mới.
        </span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/26.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Sau khi bấm "Tạo mới", thầy cô đặt tên cho file đề và bấm "Tạo".</span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/27.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Vậy là thầy cô đã tạo đề thành công từ ngân hàng bộ đề trên hệ thống!</span>
        <h3 id="part_1_nhch" className="text-[18px]">
          4. Tạo đề từ Ngân hàng câu hỏi trên hệ thống
        </h3>

        <div className="w-full flex justify-center">
          {" "}
          <img src={require("../assets/images/user_manual/image11.jpg")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 1:</b> Điền thông tin cần thiết.
        </span>
        <span>Thầy cô cần điền thông tin bao gồm: Lớp, Môn học, Chương trình học,... để có thể tiến hành tạo đề.</span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/image29.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 2:</b> Lựa chọn câu hỏi từ các chuyên đề.
        </span>
        <span>
          Sau khi điền thông tin, thầy cô sẽ lựa chọn chuyên đề cần kiểm tra tại thanh bên trái màn hình. Sau khi chọn,
          các câu hỏi thuộc chuyên đề sẽ xuất hiện trên màn hình. Thầy cô kéo xuống để xem và lựa chọn câu hỏi cần có
          trong đề thi bằng cách bấm nút "Thêm".
        </span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/image26.png")} alt="" className="py-4 w-[70%]" />
        </div>

        <span>
          <b>Bước 3:</b> Xem trước và kiểm tra đề thi.
        </span>
        <span>Sau khi chọn đủ số câu hỏi cho đề thi, thầy cô bấm “Xem trước” để kiểm tra đề.</span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/image24.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          Thầy cô kiểm tra lại Đáp án, có thể chỉnh sửa số lượng và thay đổi câu hỏi tùy thích bằng cách bấm “Thêm câu
          hỏi” hoặc “Hủy”. Sau khi kiểm tra hoàn tất, thầy cô chọn “Thời gian” để tạo đề.
        </span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/image23.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Bước 4:</b> Lưu và tạo đề thành công.
        </span>
        <span>Thầy cô tiến hành đặt tên file và bấm “Tạo” để lưu đề trên hệ thống.</span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/27.png")} alt="" className="py-4 w-[70%]" />
        </div>

        <span>Vậy là thầy cô đã tạo đề thành công từ ngân hàng bộ đề trên hệ thống!</span>

        <h2 id="part_2" className="mt-5 text-[20px]">
          PHẦN 2: HƯỚNG DẪN TẠO PHÒNG THI ONLINE TRÊN HỆ THỐNG.
        </h2>
        <span>Cách 1: Tạo phòng thi qua tính năng "Quản lý phòng thi".</span>
        <span>
          <b>Bước 1:</b> Tại mục "Quản lý phòng thi", thầy cô bấm "Tạo phòng thi mới".
        </span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/15.png")} alt="" className="py-4 w-[70%]" />
        </div>

        <span>
          <b>Bước 2: </b>Điền thông tin về phòng thi.
        </span>

        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/image17.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <div className="w-full flex justify-center">
          {" "}
          <img src={require("../assets/images/user_manual/16.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Khi chọn loại phòng, thầy cô lưu ý khi chọn phòng thi đấu Trực tiếp hoặc Tự do.</span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/17.png")} alt="" className="py-4 w-[70%]" />
        </div>

        <span>Tại mục chọn Loại đề, thầy cô cần lưu ý loại đề cần dùng.</span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/19.png")} alt="" className="py-4 w-[70%]" />
        </div>

        <span>Sau đó, thầy cô tiếp tục điền những thông tin cần thiết để tạo phòng thi.</span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/20.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Sau khi Tạo phòng thành công, thầy cô bấm "Chia sẻ" để sao chép liên kết phòng gửi cho học sinh.</span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/21.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>
          <b>Cách 2:</b> Tạo phòng thi trực tiếp từ file đề.
        </span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/28.png")} alt="" className="py-4 w-[70%]" />
        </div>

        <span>Tại màn hình "Quản lý dữ liệu" thầy cô bấm vào dấu cộng "Tạo phòng" trực tiếp từ file đề mong muốn.</span>
        <div className="w-full flex justify-center">
          <img src={require("../assets/images/user_manual/image17.png")} alt="" className="py-4 w-[70%]" />
        </div>
        <span>Sau đó, thầy cô điền đầy đủ thông tin như hướng dẫn ở trên để Tạo phòng.</span>
        <span>Chúc thầy cô thực hiện thành công!</span>
      </div>
    </div>
  );
}
