import isEqual from "lodash/isEqual";
import React from "react";
import ButtonSecondary from "../../common/components/button/ButtonSecondary";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";

type TCreationInfoExamViewOnlyProps = {
  examInfo: any;
  numOfQuestions?: number | null;
};

function CreationInfoExamViewOnly({ examInfo, numOfQuestions = null }: TCreationInfoExamViewOnlyProps) {
  const LIST_ITEM = [
    examInfo?.grade?.title,
    examInfo?.subject_types?.title,
    examInfo?.learningProgram?.title,
    examInfo?.semesters?.title,
    examInfo?.thematics,
    `${numOfQuestions || examInfo?.numOfQuestions} câu`,
    `${examInfo?.time} phút`,
  ];

  return (
    <div className="flex justify-between w-full border-b items-center px-10 border">
      <div className="h-[104px] w-full items-center flex flex-wrap gap-x-6">
        {LIST_ITEM.filter((e) => !!e)?.map((item) => (
          <span key={item} className="font-[600] text-gray-900 leading-[24px]">
            {item}
          </span>
        ))}
      </div>
    </div>
  );
}

const checkEqualProps = (preProps, nextProps) => {
  return (
    isEqual(preProps.examInfo, nextProps?.examInfo) &&
    isEqual(preProps.disableSaveExamButton, nextProps?.disableSaveExamButton) &&
    isEqual(preProps.numOfQuestions, nextProps?.numOfQuestions)
  );
};

export default React.memo(CreationInfoExamViewOnly, checkEqualProps);
