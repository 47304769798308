import { loginWithEmailAndPassword } from "./../../auth/utils/authAPI";
import { isEmpty } from "lodash";
import { TCommonState } from "../utils/type";
import { ACTION_TYPES } from "./actionTypes";

export const initialStateCommon: TCommonState = {
  creationInfoExam: {},
  roomCreationInfo: {},
  allFolderAndFile: {},
  fireBaseInfos: {},
  loginWithEmailAndPasswordInfo: undefined,
  userInfos: {
    device: {},
    token: {},
  },
  providerId: "",
  idToken: "",
  authCode: "",
  accessToken: "",
  refreshToken: "",
  isNewMember: false,
  notify: {
    isOpenDialog: false,
    isOpenNotify: false,
    message: "",
    errorCode: "",
    description: "",
    type: "",
  },
  convertDocx: {
    json: null,
    media: null,
  },
  createExamFromFolderId: null,
  openDialogDeveloping: false,
  examDetail: null,
  hideLeftSide: false,
  dashboardInfo: {},
  teacherInfo: {
    phone: "",
    is_vip: false,
    status: "",
  },
  gradeSubjects: [],
  openDialogRegisterTeacher: false,
  openDialogNotifyRegister: "",
  openDialogUpdateUsername: false,
  reportReview: {},
  errorMessageFormLoginFromServer: "",
};

const commonReducer = (state = initialStateCommon, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.GET_CREATION_INFO_CREATE_EXAM_SUCCESS: {
      return {
        ...state,
        creationInfoExam: action.params,
      };
    }
    case ACTION_TYPES.ADD_USER_INFOS: {
      return {
        ...state,
        userInfos: action?.params,
        accessToken: action?.params?.token?.access_token,
        refreshToken: action?.params?.token?.ref_token,
      };
    }
    case ACTION_TYPES.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        userInfos: {
          ...state?.userInfos,
          member: action?.params,
        },
      };
    }
    case ACTION_TYPES.ADD_FIRE_BASE_INFOS: {
      return {
        ...state,
        fireBaseInfos: action?.params,
      };
    }
    case ACTION_TYPES.HANDLE_LOGOUT: {
      return {
        ...state,
        ...initialStateCommon,
        errorMessageFormLoginFromServer: state.errorMessageFormLoginFromServer,
        userInfos: {
          ...state?.userInfos,
          token: action.params,
          member: null,
        },
        accessToken: action?.param?.access_token,
        refreshToken: action?.param?.ref_token,
        modalLogin: {
          isOpen: false,
          isRequired: false,
        },
        loginWithEmailAndPasswordInfo: undefined,
        notify: {
          type: "LOGOUT_SUCCESS",
        },
      };
    }
    case ACTION_TYPES.SAVE_NOTIFY: {
      return {
        ...state,
        notify: action?.params,
      };
    }
    case ACTION_TYPES.AUTHORIZE_SUCCESS: {
      return {
        ...state,
        ...action?.params,
      };
    }
    case ACTION_TYPES.DELETE_NOTIFY: {
      return {
        ...state,
        notify: {
          type: "",
          errorCode: "",
          message: "",
          isOpenDialog: false,
          isOpenNotify: false,
        },
      };
    }
    case ACTION_TYPES.GET_LIST_ROOM_SUCCESS: {
      return {
        ...state,
        listRoom: action?.params,
      };
    }
    case ACTION_TYPES.HANDLE_CONVERT_DOCX_SUCCESS: {
      return {
        ...state,
        convertDocx: action?.params,
      };
    }
    case ACTION_TYPES.GET_ROOM_CREATION_INFO_SUCCESS: {
      return {
        ...state,
        roomCreationInfo: action.params,
      };
    }
    case ACTION_TYPES.GET_ALL_FOLDER_AND_FILE_SUCCESS: {
      return {
        ...state,
        allFolderAndFile: action.params,
      };
    }
    case ACTION_TYPES.CLEAN_QUIZ_FILE: {
      return {
        ...state,
        convertDocx: {},
        examDetail: null,
      };
    }
    case ACTION_TYPES.HANDLE_CREATE_QUIZ_PDF_FAILED: {
      return {
        ...state,
        notify: {
          type: "HANDLE_CREATE_QUIZ_PDF_FAILED",
          errorCode: action?.params?.code,
          message: action?.params?.message,
          isOpenDialog: true,
          isOpenNotify: false,
        },
      };
    }
    case ACTION_TYPES.ADD_FOLDER_CREATE_EXAM: {
      return {
        ...state,
        createExamFromFolderId: action?.params,
      };
    }
    case ACTION_TYPES.FEATURE_DEVELOPING: {
      return {
        ...state,
        openDialogDeveloping: action.params,
      };
    }
    case ACTION_TYPES.GET_EXAM_DETAIL_SUCCESS: {
      return {
        ...state,
        examDetail: action.params,
      };
    }
    case ACTION_TYPES.HIDE_LEFT_SIDE: {
      return {
        ...state,
        hideLeftSide: action?.params,
      };
    }
    case ACTION_TYPES.GET_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashboardInfo: action?.params,
      };
    }
    case ACTION_TYPES.GET_TEACHER_INFO_SUCCESS: {
      return {
        ...state,
        teacherInfo: action?.params,
      };
    }
    case ACTION_TYPES.GET_GRADE_AND_SUBJECT_SUCCESS: {
      return {
        ...state,
        gradeSubjects: action?.params,
      };
    }
    case ACTION_TYPES.SET_OPEN_DIALOG_REGISTER_TEACHER: {
      return {
        ...state,
        openDialogRegisterTeacher: action?.params,
      };
    }
    case ACTION_TYPES.SET_OPEN_DIALOG_NOTIFY_REGISTER: {
      return {
        ...state,
        openDialogNotifyRegister: action?.params,
      };
    }
    case ACTION_TYPES.SET_OPEN_DIALOG_UPDATE_PROFILE: {
      return {
        ...state,
        openDialogUpdateUsername: action?.params,
      };
    }
    case ACTION_TYPES.GET_REPORT_REVIEW_SUCCESS: {
      return {
        ...state,
        reportReview: action?.params,
      };
    }
    case ACTION_TYPES.LOGIN_WITH_EMAIL_AND_PASSWORD: {
      return {
        ...state,
        loginWithEmailAndPasswordInfo: action?.params,
      };
    }
    case ACTION_TYPES.SET_ERROR_MESSAGE_FORM_LOGIN_FROM_SERVER: {
      return {
        ...state,
        errorMessageFormLoginFromServer: action.params,
      };
    }
    default:
      return state;
  }
};

export default commonReducer;
