import React from "react";

type TSelectionsCardProps = {
  options: any;
  solution: any;
};

function SelectionsCard({ options, solution }: TSelectionsCardProps) {
  const items = JSON.parse(options[0]?.items[0]?.content || "{}")?.items;  

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="center-col min-h-[78px] mb-6">
        <div className="flex mt-10 h-full flex-wrap justify-center">
          {items?.map((v: any, index: number) => (
            <div
              key={index}
              className="flex justify-center items-center bg-gradient-to-b from-[#9ef01a] to-[#007200] text-white cursor-pointer px-2 mt-4 min-w-[48px] h-[48px] px-1 mt-2 mr-5 rounded-[16px] uppercase"
            >
              {v}
            </div>
          ))}
        </div>
      </div>
      {solution?.correct_options && (
        <div>
          <span className="font-semibold">Đáp án: </span>
          {solution?.correct_options?.join(" ")}
        </div>
      )}
    </div>
  );
}

export default React.memo(SelectionsCard);
