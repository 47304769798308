/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import { OAuthProvider } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { FIREBASE_INFO } from "./../utils/constant/env";

type TParamsEvent = {
  [key: string]: any;
};
export const app = firebase.initializeApp(FIREBASE_INFO);
export const analytics: Analytics = (typeof window !== "undefined" && getAnalytics(app)) as Analytics;
export const auth = firebase.auth();
export const providerGoogle = new firebase.auth.GoogleAuthProvider();
export const eventTracking = (nameEvent: string, params?: TParamsEvent, fn?: Function) => {
  if (fn) {
    fn();
  }
  typeof window !== "undefined" && logEvent(analytics, `webGV_${nameEvent}`, params);
};
providerGoogle.setCustomParameters({ prompt: "select_account" });

export const providerFacebook = new firebase.auth.FacebookAuthProvider();
providerFacebook.setCustomParameters({ prompt: "select_account" });

export const providerApple = new OAuthProvider("apple.com");
providerApple.addScope("email");
providerApple.addScope("name");

export default firebase;
