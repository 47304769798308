import { Divider } from "primereact/divider";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../components/common/redux/action";
import RoomCard from "../../components/manage_room/components/RoomCard";
// import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AddRoomIcon, ArrowDownIcon, NoDataIcon } from "../../assets";
import Paging from "../../components/common/components/Paging";
import SearchComponent from "../../components/common/components/SearchComponent";
import ButtonPrimary from "../../components/common/components/button/ButtonPrimary";
import AddOrEditRoomDialog from "../../components/common/components/dialog/AddOrEditRoomDialog";
import { ROOM_STATUS } from "../../components/common/utils/constant";
import { stringToSlug } from "../../components/common/utils/helper";
import { getRoomStatus } from "../../components/manage_room/utils/helper";
import { eventTracking } from "../../firebase/firebaseConfig";

const ITEMS_PER_PAGE = 15;

export default function ListRoom() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const { listRoom, userInfos } = useSelector((state: { common }) => state.common);

  const [page, setPage] = useState<number>(1);
  const [filterRoom, setFilterRoom] = useState<any>({
    grade: null,
    status: "",
    search: "",
  });
  const [isLoadingGetListRoom, setIsLoadingGetListRoom] = useState<boolean>(false);
  const [isOpenAddOrEditRoomDialog, setIsOpenAddOrEditRoomDialog] = useState<boolean>(false);

  const dropdownPassThrough = {
    root: {
      className: "bg-gray-200 border-none shadow-none text-gray-900 font-bold items-center",
    },
    trigger: {
      className: "text-gray-900",
    },
    input: {
      className: "text-gray-900 text-[14px] font-semibold font-[Inter]",
    },
  };

  const listRoomFilter = useMemo(() => {
    let newRooms = listRoom?.rooms;

    if (filterRoom?.grade && filterRoom?.grade > 0) {
      newRooms = newRooms.filter((e) => e?.subject_id === filterRoom?.grade);
    }
    if (!!filterRoom?.status && filterRoom?.status !== "common.unChoose") {
      newRooms = newRooms?.filter((e) => getRoomStatus(e) === filterRoom?.status);
    }
    if (filterRoom?.search) {
      newRooms = newRooms.filter((e) => stringToSlug(e?.title)?.includes(stringToSlug(filterRoom?.search)));
    }
    return newRooms;
  }, [filterRoom, listRoom]);

  const maxPage = Math.floor((listRoomFilter?.length - 1) / ITEMS_PER_PAGE) + 1;

  useLayoutEffect(() => {
    if (userInfos.member?.pub_id) {
      setIsLoadingGetListRoom(true);
      dispatch(commonActions.handleGetListRoom(() => setIsLoadingGetListRoom(false)));
      dispatch(commonActions.getRoomCreationInfo());
      dispatch(commonActions.getAllFolderAndFile());
    }
  }, []);

  useEffect(() => {
    setPage(1);
  }, [filterRoom]);

  useEffect(() => {
    document.querySelector(".main-content")?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    eventTracking("screen_list_room_view");
  }, []);

  return (
    <div className="main-content">
      <div className="sub-header px-8 flex justify-between w-full">
        <ButtonPrimary
          label={intl.formatMessage({ id: "ArenaRoom.createNew" })}
          icon={<AddRoomIcon />}
          onClick={() => {
            eventTracking("select_lr_create_room_button");
            setIsOpenAddOrEditRoomDialog(true);
          }}
        />
        <SearchComponent
          handleSearch={(e) => {
            eventTracking("select_lr_search");
            setFilterRoom({ ...filterRoom, search: e });
          }}
          placeholder="Tìm kiếm phòng thi"
          trackingFocusInput={() => eventTracking("select_lr_click_search_bar")}
        />
      </div>
      <Divider className="m-0" />
      <div className="flex px-8 pb-0 pt-8 gap-4">
        <Dropdown
          value={filterRoom.grade}
          valueTemplate={(option, props) => {
            if (option?.value > 0) return <div>{option.label}</div>;
            return <div>{props.placeholder}</div>;
          }}
          onShow={() => eventTracking("select_lr_click_filter_grade")}
          onChange={(e) => {
            eventTracking("select_lr_choose_filter_grade");
            setFilterRoom({ ...filterRoom, grade: e?.value });
          }}
          options={listRoom?.grades
            ?.map((e) => ({ value: e?.id, label: e?.title }))
            ?.concat([{ value: -1, label: intl.formatMessage({ id: "common.unChoose" }) }])}
          // optionLabel="title"
          className="w-[120px] text-[14px] h-[40px]"
          placeholder={intl.formatMessage({ id: "common.grade" })}
          dropdownIcon={<ArrowDownIcon stroke="#475467" />}
          pt={dropdownPassThrough}
        />
        <Dropdown
          value={filterRoom.status}
          onShow={() => eventTracking("select_lr_click_filter_status")}
          onChange={(e) => {
            eventTracking("select_lr_choose_filter_status");
            setFilterRoom({ ...filterRoom, status: e?.value });
          }}
          options={ROOM_STATUS.concat("common.unChoose")}
          className="min-w-[150px] h-[40px]"
          valueTemplate={(option, props) => {
            if (option && option !== "common.unChoose") {
              return <span>{intl.formatMessage({ id: option })}</span>;
            }
            return <span>{props.placeholder}</span>;
          }}
          itemTemplate={(option) => <span>{intl.formatMessage({ id: option })}</span>}
          placeholder={intl.formatMessage({ id: "common.status" })}
          dropdownIcon={<ArrowDownIcon stroke="#475467" />}
          pt={dropdownPassThrough}
        />
      </div>
      {isLoadingGetListRoom ? (
        <div className="h-[calc(100vh-350px)] w-full pt-[200px] flex justify-center">
          <ProgressSpinner
            strokeWidth="2"
            animationDuration="2s"
            pt={{
              circle: {
                className: "stroke-primary",
              },
            }}
          />
        </div>
      ) : listRoomFilter?.length === 0 ? (
        <div className="h-[calc(100vh-350px)] justify-center items-center flex w-full">
          <div className="flex flex-col text-center">
            <NoDataIcon />
            <span className="text-gray-700 font-[400] text-[16px]">Hiện chưa có phòng thi nào.</span>
          </div>
        </div>
      ) : (
        <div className="px-8 py-6 grid md:grid-cols-2 xl2:grid-cols-3 gap-8">
          {listRoomFilter?.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)?.map((room, index) => (
            <RoomCard
              room={room}
              grades={listRoom?.grades}
              subject={listRoom?.subject_types.find((e) => e?.id === room?.subject_type_id)}
              handleDirectDetail={() => {
                eventTracking("select_list_room_item");
                navigate(`/quan-ly-phong-thi/${room?.room_hid}`);
              }}
              index={index}
            />
          ))}
        </div>
      )}
      {!isLoadingGetListRoom && listRoomFilter?.length > 0 && maxPage > 1 && (
        <>
          <Divider className="m-0" />
          <div className="flex justify-end h-[76px] items-center px-8">
            <div className="w-[282px]">
              <Paging page={page} setPage={setPage} maxPage={maxPage} />
            </div>
          </div>
        </>
      )}
      {isOpenAddOrEditRoomDialog && (
        <AddOrEditRoomDialog isOpen={isOpenAddOrEditRoomDialog} onClose={() => setIsOpenAddOrEditRoomDialog(false)} />
      )}
    </div>
  );
}
