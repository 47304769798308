import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

type KnobProgressProps = {
  value: number;
  circleRatio?: number;
  text?: string;
  pathColor?: string;
  trailColor?: string;
  className?: string;
  strokeWidth?: number;
  rotation?: number;
  strokeLinecap?: "butt" | "round";
  shadowColor?: string;
  isTrailShadow?: boolean;
};

function KnobProgress({
  value,
  circleRatio = 0.6,
  text = `${value}%`,
  pathColor = "",
  trailColor = "#d6d6d6",
  className = "",
  strokeWidth = 8,
  rotation,
  strokeLinecap,
  shadowColor,
}: KnobProgressProps) {
  return (
    <CircularProgressbar
      value={value}
      text={text}
      circleRatio={circleRatio}
      className={className}
      strokeWidth={strokeWidth}
      styles={{
        // Customize the root svg element
        root: {
          overflow: "visible",
        },
        // Customize the path, i.e. the "completed progress"
        path: {
          // Path color
          stroke: pathColor || `rgba(62, 152, 199, ${value / 100})`,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: strokeLinecap || "round",
          // Customize transition animation
          transition: "stroke-dashoffset 0.5s ease 0s",
          // Rotate the path
          transform: `rotate(${rotation}turn)`,
          transformOrigin: "center center",
          filter: shadowColor ? `drop-shadow(1px 1px 3px ${shadowColor})` : "",
        },

        // Customize the circle behind the path, i.e. the "total progress"
        trail: {
          // Trail color
          stroke: trailColor,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: strokeLinecap || "round",
          // Rotate the trail
          transform: `rotate(${rotation}turn)`,
          transformOrigin: "center center",
        },
        // Customize the text
        text: {
          // Text color
          fill: "#f88",
          // Text size
          fontSize: "16px",
        },
        // Customize background - only used when the `background` prop is true
        background: {
          fill: "#3e98c7",
        },
      }}
    />
  );
}

export default React.memo(KnobProgress);
