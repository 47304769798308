import { isEmpty, isEqual, isNumber } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddQuestionSelfInput } from "../../assets";
import AlertDialog from "../../components/common/components/dialog/AlertDialog";
import DialogTextEditor from "../../components/common/components/dialog/DialogTextEditor";
import { commonActions } from "../../components/common/redux/action";
import { TYPE_GENERATE_EXAM, convertAnswerKey, quizAnswerType } from "../../components/common/utils/constant";
import { genSignature, getNonce, removeNullAttributeFromObject } from "../../components/common/utils/helper";
import { documentActions } from "../../components/manage_document/redux/action";
import CreateQuizLeftSide from "../../components/manage_exam/components/CreateQuizLeftSide";
import CreateQuizRightSide from "../../components/manage_exam/components/CreateQuizRightSide";
import CreationInfoExam from "../../components/manage_exam/components/CreationInfoExam";
import CreationInfoExamViewOnly from "../../components/manage_exam/components/CreationInfoExamViewOnly ";
import DialogEditExam from "../../components/manage_exam/components/DialogEditExam";
import QuestionsDocx from "../../components/manage_exam/components/QuestionsDocx";
import {
  ANSWER_TYPE_OPTIONS,
  DIALOG_TYPE,
  MAX_LENGTH_CORRECT_OPTION,
} from "../../components/manage_exam/utils/constant";
import {
  getAnswerTypeOfQuestion,
  getBaseOption,
  getCorrectOrdinalOfListQuestion,
  getFirstOrdinalQuestionWithoutParent,
  getOrdinalQuestionForUI,
  getUpdatedListQuestion,
} from "../../components/manage_exam/utils/helpers";

export default function SelfInputQuestion({ isEdited = false }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const [ordinalQuestion, setOrdinalQuestion] = useState(1);
  const [openDialogAddOrEditExam, setOpenDialogAddOrEditExam] = useState<{ type: number | null; question?: any }>({
    type: null,
  });
  const mainRef: any = useRef(null);
  const { examDetail, createExamFromFolderId } = useSelector((state: { common }) => state.common);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [openDialogConfirmDeleteFile, setOpenDialogConfirmDeleteFile] = useState(false);

  const [creationInfo, setCreationInfo] = useState<any>({
    thematics: [],
    title: "",
  });
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
  }>({
    title: "",
  });

  const isViewOnly = isEdited && examDetail?.permission !== "mine";

  const { listQuestion, listQuestionWithoutParent } = useMemo<{
    listQuestion: any[];
    listQuestionWithoutParent: any[];
  }>(() => {
    return {
      listQuestion: creationInfo?.listQuestion?.filter((e) => !e?.deleted) || [],
      listQuestionWithoutParent:
        creationInfo?.listQuestion
          ?.filter((question) => !question.deleted)
          ?.filter((question) => question.answer_type !== quizAnswerType.NONE) || [],
    };
  }, [creationInfo?.listQuestion]);

  const currentQuestion = useMemo(() => {
    const cq = listQuestion
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
      ?.find((e) => e?.ordinal === ordinalQuestion);
    if (cq) {
      return cq;
    }
    // nếu câu hỏi có ordinalQuestion đã bị xoá thì setOrdinalQuestion là câu trước nó, nếu không có câu trước đó thì tìm câu đầu tiên.
    setOrdinalQuestion(
      ordinalQuestion - 1 >= 1 ? ordinalQuestion - 1 : getFirstOrdinalQuestionWithoutParent(listQuestion)
    );
  }, [listQuestion, ordinalQuestion, quizAnswerType]);

  const handleFillSolution = (solution: any) => {
    const oldContent = currentQuestion?.solutions.find((e: any) => e.content_type === "html")?.content;
    if ((!oldContent || oldContent?.length === 0) && solution?.length === 0) return;
    const newListQuestion = listQuestion.map((e: any) => {
      const newSolution = {
        ordinal: 1,
        content: solution,
        content_type: "html",
      };
      return e?.ordinal === ordinalQuestion
        ? {
            ...e,
            solutions: [
              !!e?.id
                ? !solution || solution?.length === 0
                  ? { ...newSolution, id: e?.solutions[0]?.id, deleted: true }
                  : !oldContent || oldContent?.length === 0
                  ? {
                      ...newSolution,
                      id: e?.solutions[0]?.id,
                      added: true,
                    }
                  : {
                      ...newSolution,
                      id: e?.solutions[0]?.id,
                      edited: true,
                    }
                : newSolution,
            ],
          }
        : e;
    });
    setCreationInfo({ ...creationInfo, listQuestion: newListQuestion });
  };

  const handleChooseAnswerKey = (key: any, isChecked: boolean) => {
    const newListQuestion = listQuestion.map((e: any) => {
      if (e?.ordinal === ordinalQuestion) {
        const newOptions = e?.options?.map((op) =>
          key === op?.answer_key
            ? !!op?.id
              ? { ...getBaseOption(op), correct: Number(isChecked), edited: true }
              : { ...getBaseOption(op), correct: Number(isChecked) }
            : getBaseOption(op)
        );
        const answerType = getAnswerTypeOfQuestion(newOptions);
        return {
          ...e,
          options: newOptions,
          answer_type: answerType,
        };
      } else {
        return e;
      }
    });
    setCreationInfo({ ...creationInfo, listQuestion: newListQuestion });
  };

  const handleChangeCorrectAnswer = (content: string) => {
    const newListQuestion = listQuestion.map((e: any) => {
      if (e?.ordinal === ordinalQuestion) {
        const newOptions = e?.options?.map((op) =>
          !!op?.id
            ? {
                ...getBaseOption(op),
                contents: [
                  {
                    id: op?.items ? op?.items[0]?.id : null,
                    content: content,
                    content_type: "html",
                    ordinal: 1,
                    edited: true,
                  },
                ],
                edited: true,
              }
            : {
                ...getBaseOption(op),
                contents: [
                  {
                    content: content,
                    content_type: "html",
                    ordinal: 1,
                  },
                ],
              }
        );
        return {
          ...e,
          options: newOptions,
          answer_type: quizAnswerType.CONSTRUCTED_RESPONSE,
        };
      } else {
        return e;
      }
    });
    setCreationInfo({ ...creationInfo, listQuestion: newListQuestion });
  };

  const checkEditCreationInfo = useMemo(() => {
    return !isEqual(
      [
        examDetail?.title,
        examDetail?.time,
        examDetail?.grade?.id,
        examDetail?.subject_type?.id,
        examDetail?.learning_program?.id,
        examDetail?.semester?.id,
      ],
      [
        creationInfo?.title?.trim(),
        creationInfo?.time,
        creationInfo?.grade?.id,
        creationInfo?.subject_types?.id,
        creationInfo?.learningProgram?.id,
        creationInfo?.semesters?.id,
      ]
    );
  }, [examDetail, creationInfo]);

  const checkIsEditSolution = useMemo(() => {
    return !isEqual(
      examDetail?.solutions?.map((e) => e?.items?.detail?.map((e) => e?.content)).flat(),
      listQuestion
        ?.filter((e) => !isEmpty(e?.solutions))
        ?.map((e) => e.solutions?.map((e) => e?.content))
        .flat()
    );
  }, [examDetail, listQuestion]);

  const checkIsEditQuestion = useMemo(() => {
    return (
      !isEqual(examDetail?.questions?.length, listQuestion?.filter((e) => !e?.deleted)?.length) ||
      !isEqual(
        examDetail?.questions?.map((e) => e?.options?.map((op) => [op?.correct, op?.items[0]?.content])).flat(),
        listQuestion?.map((e) => e?.options?.map((op) => [op?.correct, op?.contents?.[0]?.content])).flat()
      ) ||
      !isEqual(
        examDetail?.questions?.map((e) => e?.questions?.map((c) => c?.content)).flat(),
        listQuestion?.map((e) => e?.contents?.map((c) => c?.content)).flat()
      ) ||
      !isEqual(
        examDetail?.questions?.map((e) => ({
          description_answer_before: e?.description_answer_before,
          description_answer_after: e?.description_answer_after,
        })),
        listQuestion?.map((e) => ({
          description_answer_before: e?.description_answer_before,
          description_answer_after: e?.description_answer_after,
        }))
      )
    );
  }, [examDetail, listQuestion]);

  const checkEnableButtonCreate = useMemo(() => {
    const listParentQuestion = listQuestion
      ?.filter((question) => !question.deleted)
      ?.filter((question) => question.answer_type === quizAnswerType.NONE);
    const listQuestionWithoutParent = listQuestion
      ?.filter((question) => !question.deleted)
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE);

    const isAllParentHasChild = listParentQuestion?.every((parentQuestionItem) => {
      return listQuestionWithoutParent?.some((question) => question.parent === parentQuestionItem.id);
    });

    const checkBtnCreate =
      !!creationInfo.title &&
      !errorMessage.title &&
      !!creationInfo?.grade?.id &&
      !!creationInfo?.subject_types?.id &&
      !!creationInfo?.learningProgram?.id &&
      !!creationInfo?.time &&
      listQuestionWithoutParent?.length > 0 &&
      listQuestionWithoutParent?.every(
        (e: any) =>
          !!e?.options?.some(
            (op) =>
              op.correct === 1 &&
              op.contents?.[0]?.content?.trim() !== "" &&
              (e.answer_type === quizAnswerType.CONSTRUCTED_RESPONSE
                ? op.contents?.[0]?.content.length <= MAX_LENGTH_CORRECT_OPTION
                : true)
          )
      ) &&
      isAllParentHasChild;

    if (isEdited) {
      return checkBtnCreate && (checkIsEditQuestion || checkIsEditSolution || checkEditCreationInfo);
    }
    return checkBtnCreate;
  }, [creationInfo, listQuestion]);

  const handleUpdateListQuestion = (
    oldQuestion: any,
    data: any,
    isCheckedParentQuestion: boolean,
    answerTypeChosen: keyof typeof ANSWER_TYPE_OPTIONS,
    parentQuestionId: number
  ) => {
    let { updatedListQuestion, targetOrdinal } = getUpdatedListQuestion(
      listQuestion,
      oldQuestion,
      data,
      isCheckedParentQuestion,
      answerTypeChosen,
      parentQuestionId
    );
    if (targetOrdinal) setOrdinalQuestion(targetOrdinal);
    setCreationInfo({ ...creationInfo, listQuestion: updatedListQuestion });
  };
  const handleCreateNewQuestion = (
    data: any,
    isParentQuestion: boolean,
    answerTypeChosen: keyof typeof ANSWER_TYPE_OPTIONS,
    parentQuestionId: number
  ) => {
    let qs: any;
    if (isParentQuestion) {
      qs = {
        id: -((listQuestion?.length + 1) as number),
        ordinal: listQuestion?.filter((question) => !question.deleted)?.length + 1,
        contents: [{ content: data?.find((e) => e?.ordinal === -1)?.content, content_type: "html", ordinal: 1 }],
        answer_type: quizAnswerType.NONE,
        isSelfInput: true,
      };
    } else {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;
      let options;
      if (answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER) {
        options = data
          ?.filter((e) => e?.ordinal >= 0)
          ?.map((op) => ({
            contents: [{ content: op?.content, content_type: "html", ordinal: 1 }],
            answer_key: op?.answer_key,
            correct: op?.correct || 0,
            ordinal: op?.ordinal,
          }));
      } else {
        const correctOption = data?.find((e) => e.ordinal === 1);
        options = [
          {
            contents: [{ content: correctOption.content, content_type: "html", ordinal: 1 }],
            correct: correctOption.correct,
            ordinal: correctOption.ordinal,
          },
        ];
      }

      let ordinal: number;
      if (!Boolean(parentQuestionId)) {
        ordinal = listQuestion?.filter((question) => !question.deleted)?.length + 1;
      } else {
        const previousOrdinal = listQuestion
          ?.filter((question) => !question.deleted)
          ?.reduce((accumulate, question) => {
            if (question.parent === parentQuestionId || question.id === parentQuestionId) {
              return question.ordinal;
            } else {
              return accumulate;
            }
          }, -1);
        ordinal = previousOrdinal + 1;
      }
      qs = {
        ordinal,
        contents: [{ content: data?.find((e) => e?.ordinal === -1)?.content, content_type: "html", ordinal: 1 }],
        ...(answerTypeChosen === ANSWER_TYPE_OPTIONS.ENTER_ANSWER && {
          description_answer_before: data?.find((e) => e?.ordinal === 0).content,
          description_answer_after: data?.find((e) => e?.ordinal === 2).content,
        }),
        options,
        solutions: [],
        answer_type: answerType,
        isSelfInput: true,
        parent: parentQuestionId,
      };
      setOrdinalQuestion(qs.ordinal);
    }
    if (parentQuestionId) {
      const previousQuestionIndex = listQuestion.findIndex((question) => question.ordinal === qs.ordinal - 1);
      const clonedListQuestion = [...listQuestion];
      clonedListQuestion.splice(previousQuestionIndex + 1, 0, qs);
      setCreationInfo({ ...creationInfo, listQuestion: getCorrectOrdinalOfListQuestion(clonedListQuestion) });
    } else {
      setCreationInfo({ ...creationInfo, listQuestion: [...listQuestion].concat([qs]) });
    }
  };

  const handleCreateOrEditExam = () => {
    const folderId = createExamFromFolderId;

    let dataPost: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade_id: creationInfo?.grade?.id,
      subject_type_id: creationInfo?.subject_types?.id,
      learning_program_id: creationInfo?.learningProgram?.id,
      folder_id: folderId,
      semester_ids: creationInfo?.semesters?.id ? [creationInfo?.semesters?.id] : undefined,
      thematic_ids: creationInfo?.thematics,
    };
    if (!isEdited || (isEdited && creationInfo?.title?.trim() !== examDetail.title)) {
      dataPost.title = creationInfo?.title?.trim();
    }
    if (isEdited) {
      const removeQuestions =
        examDetail?.questions
          ?.filter((e) => listQuestion?.findIndex((qs) => qs?.id === e?.id) < 0)
          ?.map((e) => ({ ...e, deleted: true })) || [];

      const getRemoveOptions = (qs) => {
        const oldQuestion = examDetail?.questions?.find((e) => e?.id === qs.id);
        if (!oldQuestion) return [];
        return (
          oldQuestion.options
            ?.filter((e) => {
              return qs?.options?.findIndex((o) => o?.id === e?.id) < 0;
            })
            ?.map((e) => ({ ...e, deleted: true })) || []
        );
      };

      const listQuestionEdit = listQuestion
        ?.map((e, ix) => {
          if (!e?.id) {
            return {
              ...e,
              added: true,
              ordinal: ix + 1,
            };
          } else {
            const oldAnswerType = examDetail?.questions?.find((question) => question.id === e.id)?.answer_type;
            const isEditQuestion =
              e?.answer_type !== oldAnswerType ||
              !isEqual(
                [
                  examDetail?.questions?.find((question) => question.id === e.id)?.description_answer_before,
                  examDetail?.questions?.find((question) => question.id === e.id)?.description_answer_after,
                ],
                [e.description_answer_before, e.description_answer_after]
              ) ||
              e?.parent !== examDetail?.questions?.find((question) => question.id === e.id)?.parent;

            return {
              ...e,
              ordinal: ix + 1,
              options: e?.options
                ?.map((op, i) =>
                  !op?.id
                    ? {
                        ...op,
                        added: true,
                        ordinal: i,
                      }
                    : { ...op, ordinal: i, edited: true }
                )
                .concat(getRemoveOptions(e)),
              edited: isEditQuestion,
            };
          }
        })
        .concat(removeQuestions);

      dataPost = {
        ...dataPost,
        topic_slug: examDetail?.slug,
        json_data: {
          time: creationInfo?.time,
          num_of_questions: listQuestion.length,
          data: JSON.stringify(listQuestionEdit),
        },
      };
    } else {
      dataPost = {
        ...dataPost,
        type_generate: TYPE_GENERATE_EXAM.SELF_INPUT,
        json_data: {
          time: creationInfo?.time,
          num_of_questions: listQuestion.length,
          data: JSON.stringify(listQuestion),
        },
      };
    }
    dataPost = {
      ...removeNullAttributeFromObject(dataPost),
      signature: genSignature(removeNullAttributeFromObject(dataPost), undefined, undefined, [
        "semester_ids",
        "thematic_ids",
        "json_data",
      ]),
    };
    if (isEdited) {
      dispatch(
        commonActions.handleEditExam({ data: dataPost, topicSlug: examDetail?.slug }, (tpSlug) => {
          if (tpSlug === examDetail?.slug) {
            navigate(0);
          } else {
            navigate(`/quan-ly-tai-lieu/${tpSlug}?mode=edit`, { replace: true });
            window.location.reload();
          }
        })
      );
    } else {
      dispatch(
        commonActions.handleCreateExam(dataPost, () =>
          navigate(folderId ? `/quan-ly-tai-lieu/cua-toi/${folderId}` : "/quan-ly-tai-lieu/cua-toi")
        )
      );
    }
  };

  const handleDeleteExam = () => {
    const dataPostPre = {
      nonce: getNonce(),
      timestamp: moment().unix(),
      exam_ids: [examDetail?.id],
    };
    const dataPost = {
      ...dataPostPre,
      signature: genSignature(dataPostPre),
    };
    dispatch(documentActions.deleteExams(dataPost, () => navigate("/quan-ly-tai-lieu/cua-toi")));
  };

  useEffect(() => {
    document.getElementById(`question_${ordinalQuestion}`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [ordinalQuestion]);

  useEffect(() => {
    if (isEdited && examDetail) {
      // set default info
      const getSolution = (qsId) => examDetail?.solutions?.find((so) => so?.quiz_question_id === qsId);
      const qsSelected = examDetail?.questions?.map((qs) => ({
        ...qs,
        options: qs.options.map((op) => ({
          ...op,
          contents: op?.items,
          ...(qs.answer_type !== quizAnswerType.CONSTRUCTED_RESPONSE && {
            answer_key: convertAnswerKey(op?.ordinal),
          }),
        })),
        contents: qs?.questions,
        solutions:
          getSolution(qs?.id)?.items?.detail?.map((s, ix) => ({
            id: getSolution(qs?.id)?.id,
            ordinal: ix + 1,
            content_type: s?.content_type,
            content: s?.content,
          })) || [],
        isSelfInput: true,
      }));
      setCreationInfo((prev) => ({
        ...prev,
        title: examDetail.title,
        grade: examDetail.grade,
        subject_types: examDetail.subject_type,
        learningProgram: examDetail.learning_program,
        semesters: examDetail.semester,
        thematics: examDetail.thematics,
        time: examDetail.time,
        numOfQuestions: examDetail?.questions?.length,
        listQuestion: qsSelected,
      }));

      const firstOrdinalQuestionWithoutParent = qsSelected
        ?.filter((question) => !question.deleted)
        ?.find((question) => question.answer_type !== quizAnswerType.NONE).ordinal;

      setOrdinalQuestion(firstOrdinalQuestionWithoutParent);
    }
  }, [isEdited, examDetail]);

  useEffect(() => {
    if (!isEdited) {
      dispatch(
        commonActions.handleGetTeacherInfo((teacherInfoResponse) => {
          const formattedTime = moment().format("DDMMyyyy hhmm");
          const subjectTitle = teacherInfoResponse?.subject_register?.title;
          const initialTitle = `Đề ${subjectTitle} ${formattedTime}`;
          setCreationInfo((prev) => ({ ...prev, title: initialTitle }));
        })
      );
    }
  }, [isEdited]);

  return (
    <div className="main-content overflow-hidden">
      <div>
        {isViewOnly ? (
          <CreationInfoExamViewOnly examInfo={creationInfo} numOfQuestions={listQuestionWithoutParent?.length as any} />
        ) : (
          <CreationInfoExam
            examInfo={creationInfo}
            setExamInfo={setCreationInfo}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            checkEnableButtonCreate={checkEnableButtonCreate}
            isSelfInput
            isEdited={isEdited}
            handleSubmit={handleCreateOrEditExam}
          />
        )}
      </div>
      <div className="flex flex-row w-full">
        <div className="w-[312px] h-[calc(100vh-232px)] overflow-x-hidden overflow-y-auto scrollbar-thin border-r border-gray-200">
          <CreateQuizLeftSide
            listQuestion={listQuestion}
            checkEnableEditExam={true}
            setOrdinalQuestion={setOrdinalQuestion}
          />
        </div>
        <div className={`w-[calc(100%-624px)] h-[calc(100vh-232px)] overflow-auto mt-[-1px] border-r scrollbar-thin`}>
          {listQuestion?.length > 0 && (
            <QuestionsDocx
              listQuestion={listQuestion}
              setOrdinalQuestion={setOrdinalQuestion}
              setOpenDialogSolution={(ordinal) => {
                setOrdinalQuestion(ordinal);
                setOpenEditor(true);
              }}
              isViewOnly={isViewOnly}
              isEdited={isEdited}
              updateListQuestion={(newList) => setCreationInfo({ ...creationInfo, listQuestion: newList })}
              isSelfInput
              handleOpenDialogEditQuestion={(qs) => {
                setOpenDialogAddOrEditExam({ type: DIALOG_TYPE.EDIT, question: qs });
                if (qs.answer_type !== quizAnswerType.NONE) {
                  setOrdinalQuestion(qs.ordinal);
                }
              }}
              setOpenDialogConfirmDeleteFile={() => setOpenDialogConfirmDeleteFile(true)}
            />
          )}
          {!isViewOnly && (
            <div
              className={`w-full flex justify-center items-center ${listQuestion?.length === 0 ? "h-full" : "my-10"}`}
            >
              <button
                id="add-question"
                className="w-[512px] h-[108px] rounded-[12px] border flex justify-center items-center flex-col"
                onClick={() => setOpenDialogAddOrEditExam({ type: DIALOG_TYPE.CREATE })}
              >
                <div>
                  <AddQuestionSelfInput />
                </div>
                <span className="text-secondary pt-3 font-bold">
                  <FormattedMessage id="selfInput.add" />
                </span>
              </button>
            </div>
          )}
        </div>
        <CreateQuizRightSide
          disabled={false}
          isViewOnly={isViewOnly}
          currentQuestion={currentQuestion}
          ordinalQuestionWithoutParent={
            listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) + 1
          }
          handleChooseAnswerKey={handleChooseAnswerKey}
          onChangeCorrectAnswer={handleChangeCorrectAnswer}
          setOpenEditor={setOpenEditor}
          mainRef={mainRef}
          disablePreviousQuestionButton={
            listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) <= 0
          }
          onClickPreviousQuestionButton={() => {
            const currentChildQuestionIndex = listQuestionWithoutParent.findIndex(
              (question) => question.ordinal === ordinalQuestion
            );
            const previousChildQuestion = listQuestionWithoutParent[currentChildQuestionIndex - 1];
            setOrdinalQuestion(previousChildQuestion.ordinal);
          }}
          disableNextQuestionButton={
            listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) >=
            listQuestionWithoutParent.length - 1
          }
          onClickNextQuestionButton={() => {
            const currentChildQuestionIndex = listQuestionWithoutParent.findIndex(
              (question) => question.ordinal === ordinalQuestion
            );
            const nextChildQuestion = listQuestionWithoutParent[currentChildQuestionIndex + 1];
            setOrdinalQuestion(nextChildQuestion.ordinal);
          }}
        />
      </div>
      {isNumber(openDialogAddOrEditExam.type) && (
        <DialogEditExam
          question={openDialogAddOrEditExam.question}
          listParentQuestion={listQuestion
            ?.filter((e) => !e?.deleted)
            ?.filter((question) => question.answer_type === quizAnswerType.NONE)
            ?.filter((question) => question.id !== openDialogAddOrEditExam.question?.id)}
          open={isNumber(openDialogAddOrEditExam.type)}
          onClose={() => {
            setOpenDialogAddOrEditExam({ type: null });
          }}
          onSubmit={(val, isParentQuestion, answerType, parentQuestionId) => {
            if (openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE) {
              handleCreateNewQuestion(val, isParentQuestion, answerType, parentQuestionId);
            } else {
              handleUpdateListQuestion(
                openDialogAddOrEditExam.question,
                val,
                isParentQuestion,
                answerType,
                parentQuestionId
              );
            }
          }}
          title={
            openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE
              ? intl.formatMessage({ id: "selfInput.addQuestion.title" })
              : intl.formatMessage({ id: "selfInput.editQuestion.title" })
          }
          isAdded={openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE}
          isSelfInput
          ordinalQuestion={getOrdinalQuestionForUI(listQuestion, openDialogAddOrEditExam.question)}
        />
      )}
      {openEditor && (
        <DialogTextEditor
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          onSubmit={(val: any) => {
            handleFillSolution(val);
          }}
          content={currentQuestion?.solutions.find((e: any) => e.content_type === "html")?.content}
          title={
            isEmpty(currentQuestion?.solutions.find((e: any) => e.content_type === "html")?.content)
              ? "Nhập hướng dẫn giải"
              : "Sửa hướng dẫn giải"
          }
        />
      )}
      {openDialogConfirmDeleteFile && (
        <AlertDialog
          open={openDialogConfirmDeleteFile}
          handleClose={() => setOpenDialogConfirmDeleteFile(false)}
          triggerFunc={() => handleDeleteExam()}
          notify={{ message: intl.formatMessage({ id: "manageExam.deleteFile" }) }}
        />
      )}
    </div>
  );
}
