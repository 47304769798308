import isEqual from "lodash/isEqual";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDownIcon } from "../../../assets";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import { commonActions } from "../../common/redux/action";
import { containsSpecialChars } from "../../common/utils/helper";
import "../style.css";
import DialogInputNumber from "./DialogInputNumber";
import { MAX_LENGTH_TITLE } from "../utils/constant";
import { quizAnswerType } from "../../common/utils/constant";

const INPUT_TIME = "time";
const INPUT_NUM_OF_QUESTIONS = "question";

function CreationInfoExam({
  examInfo,
  isInputDocx = false,
  setExamInfo,
  isSelfInput = false,
  checkEnableButtonCreate,
  handleSubmit,
  isEdited = false,
  errorMessage,
  setErrorMessage,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { creationInfoExam } = useSelector((state: { common }) => state.common);
  const [openDialogInput, setOpenDialogInput] = useState<string>("");
  const listQuestionAvailable = examInfo?.listQuestion
    ?.filter((e) => !e?.deleted)
    ?.filter((question) => question.answer_type !== quizAnswerType.NONE);
  const isFocusedTitleInput = useRef(false);

  const handleGetCreationInfo = (params) => {
    dispatch(commonActions.handleGetCreationInfoCreateExam(params));
  };

  const timeOptions = [...new Set(creationInfoExam?.quiz_creation_info?.map((e) => e.time))]
    ?.map((el) => ({
      label: el,
      value: el,
    }))
    .concat([{ label: "Tự nhập", value: 0 }]);

  const numOfQuestionsOptions = creationInfoExam?.quiz_creation_info
    ?.filter((e) => e.time === examInfo?.time)
    ?.map((e) => ({ label: e?.num_of_questions, value: e?.num_of_questions }))
    ?.concat([{ label: "Tự nhập", value: 0 }]);

  useEffect(() => {
    handleGetCreationInfo({ exam_type: "document" });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-4 justify-between w-full items-start px-10 pt-4 pb-4">
        <div className="flex items-center gap-x-5">
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.grade?.id}
            onChange={(e) =>
              setExamInfo({
                ...examInfo,
                grade: creationInfoExam?.grades.find((el) => el.id === e.value),
                subject_types: null,
                learningProgram: null,
              })
            }
            options={creationInfoExam?.grades?.map((e) => ({
              label: e?.title,
              value: e?.id,
            }))}
            // valueTemplate={valueTemplate}
            placeholder="Lớp"
            className="border-none font-[600]"
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.subject_types?.id}
            onChange={(e) =>
              setExamInfo({
                ...examInfo,
                subject_types: examInfo.grade?.subject_types?.find((el) => el.id === e.value),
                learningProgram: null,
              })
            }
            options={creationInfoExam?.grades
              ?.find((e) => e?.id === examInfo?.grade?.id)
              ?.subject_types?.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            placeholder="Môn học"
            className="border-none font-[600]"
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.learningProgram?.id}
            onChange={(e) => {
              setExamInfo({
                ...examInfo,
                learningProgram: creationInfoExam?.grades
                  ?.find((e) => e?.id === examInfo?.grade?.id)
                  ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
                  ?.learning_programs?.find((el) => el.id === e.value),
                semesters: undefined,
                thematics: undefined,
              });
            }}
            options={creationInfoExam?.grades
              ?.find((e) => e?.id === examInfo?.grade?.id)
              ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
              ?.learning_programs.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            placeholder="Chương trình học"
            className="border-none font-[600] max-w-[200px] text-ellipsis"
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.semesters?.id}
            onChange={(e) =>
              setExamInfo({
                ...examInfo,
                semesters: creationInfoExam?.grades
                  ?.find((e) => e?.id === examInfo?.grade?.id)
                  ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
                  ?.learning_programs?.find((e) => e?.id === examInfo?.learningProgram?.id)
                  ?.semesters?.find((el) => el.id === e.value),
                thematics: undefined,
              })
            }
            options={creationInfoExam?.grades
              ?.find((e) => e?.id === examInfo?.grade?.id)
              ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
              ?.learning_programs?.find((e) => e?.id === examInfo?.learningProgram?.id)
              ?.semesters?.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            placeholder="Học kỳ"
            className="border-none font-[600]"
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
          <MultiSelect
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.thematics}
            onChange={(e) => {
              setExamInfo({
                ...examInfo,
                thematics: e.value,
              });
            }}
            showSelectAll={false}
            panelHeaderTemplate={null}
            options={creationInfoExam?.grades
              ?.find((e) => e?.id === examInfo?.grade?.id)
              ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
              ?.learning_programs?.find((e) => e?.id === examInfo?.learningProgram?.id)
              ?.semesters?.find((e) => e?.id === examInfo?.semesters?.id)
              ?.thematics.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            placeholder="Chuyên đề"
            className="border-none font-[600] max-w-[200px] text-ellipsis"
            pt={{
              input: {
                className: "pr-0",
              },
              header: {
                className: "hidden",
              },
            }}
          />
          {isSelfInput && <span className="text-[#495057] font-bold">{listQuestionAvailable?.length || 0} câu</span>}
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.time}
            valueTemplate={examInfo?.time && <span className="text-[#495057]">{examInfo?.time} phút</span>}
            onChange={(e) => {
              if (Number(e.value) === 0) {
                setOpenDialogInput(INPUT_TIME);
              } else {
                setExamInfo({
                  ...examInfo,
                  time: Number(e.value),
                });
              }
            }}
            options={timeOptions}
            placeholder="Thời gian"
            className="border-none font-[600]"
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
          {!isSelfInput && (
            <Dropdown
              dropdownIcon={<ArrowDownIcon stroke="#475467" />}
              value={listQuestionAvailable?.length > 0 ? listQuestionAvailable?.length : -1}
              valueTemplate={
                !!listQuestionAvailable &&
                listQuestionAvailable?.length > 0 && (
                  <span className="text-[#495057]">{listQuestionAvailable?.length} câu</span>
                )
              }
              onChange={(e) => {
                if (Number(e.value) === 0) {
                  setOpenDialogInput(INPUT_NUM_OF_QUESTIONS);
                } else {
                  setExamInfo({
                    ...examInfo,
                    numOfQuestions: Number(e.value),
                  });
                }
              }}
              disabled={isInputDocx}
              options={numOfQuestionsOptions}
              placeholder="Số câu"
              className="border-none font-[600]"
              pt={{
                input: {
                  className: "pr-0",
                },
              }}
            />
          )}
        </div>
        <div className="flex gap-x-8 w-full">
          <div className="flex gap-x-4">
            <ButtonPrimary
              label={
                isEdited
                  ? intl.formatMessage({ id: "manageExam.edit" })
                  : intl.formatMessage({ id: "manageExam.create" })
              }
              onClick={() => handleSubmit(true)}
              disabled={!checkEnableButtonCreate}
              className="min-w-min w-[108px]"
            />
          </div>
          <div className="flex-1">
            <InputText
              id="title"
              placeholder={intl.formatMessage({
                id: "dialogInputTitle.inputPlaceholder",
              })}
              className={classNames("w-full h-[44px] rounded-full placeholder-gray-400 text-gray-900 px-4", {
                "p-invalid": isFocusedTitleInput.current && (!examInfo.title || errorMessage.title),
              })}
              onFocus={() => {
                isFocusedTitleInput.current = true;
              }}
              value={examInfo.title}
              onChange={(event) => {
                if (!containsSpecialChars(event.target.value)) {
                  setExamInfo((prev) => ({ ...prev, title: event.target.value }));
                  if (event.target.value.length > MAX_LENGTH_TITLE) {
                    setErrorMessage({
                      title: intl.formatMessage(
                        { id: "createExam.titleField.errorMessage" },
                        { max: MAX_LENGTH_TITLE }
                      ),
                    });
                  } else {
                    setErrorMessage({
                      title: "",
                    });
                  }
                }
              }}
            />
            {errorMessage.title && <span className="text-error ml-4 text-xs">{errorMessage.title}</span>}
          </div>
        </div>
      </div>
      {openDialogInput && (
        <DialogInputNumber
          open={openDialogInput}
          handleClose={(val) => {
            setOpenDialogInput("");
            if (openDialogInput === INPUT_TIME) {
              setExamInfo({ ...examInfo, time: val });
            } else {
              setExamInfo({
                ...examInfo,
                numOfQuestions: val,
              });
            }
          }}
          cancelFunction={() => setOpenDialogInput("")}
          title={openDialogInput === INPUT_TIME ? "Nhập thời gian" : "Nhập số câu"}
        />
      )}
      <div className="border-b border-gray-200" />
    </>
  );
}

const checkEqualProps = (preProps, nextProps) => {
  return (
    isEqual(preProps?.examInfo, nextProps?.examInfo) &&
    isEqual(preProps?.checkEnableButtonCreate, nextProps?.checkEnableButtonCreate)
  );
};

export default React.memo(CreationInfoExam, checkEqualProps);
