import { ACTION_TYPES } from "./actionTypes";
import { ACTION_TYPES as COMMON_ACTION_TYPES } from "../../common/redux/actionTypes";

export const initialStateRoom = {
  roomDetail: {},
  members: [],
  isReloadDetail: false,
  examDetail: {},
};

const roomReducer = (state = initialStateRoom, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ROOM_DETAIL_SUCCESS: {
      return {
        ...state,
        roomDetail: action.params,
        isReloadDetail: false,
      };
    }
    case ACTION_TYPES.GET_MEMBERS_OF_ROOM_SUCCESS: {
      return {
        ...state,
        members: action.params,
      };
    }
    case ACTION_TYPES.ADD_MEMBER_OF_ROOM_SUCCESS: {
      return {
        ...state,
        isReloadDetail: true,
      };
    }
    case ACTION_TYPES.RELOAD_DETAIL_ROOM: {
      return {
        ...state,
        isReloadDetail: true,
      };
    }
    case ACTION_TYPES.DELETE_MEMBER_OF_ROOM_SUCCESS: {
      return {
        ...state,
        isReloadDetail: true,
      };
    }
    case ACTION_TYPES.DELETE_MEMBER_OF_ROOM_FAILED: {
      return {
        ...state,
        isReloadDetail: true,
      };
    }
    case COMMON_ACTION_TYPES.HANDLE_LOGOUT: {
      return {
        ...state,
        roomDetail: {},
      };
    }
    case ACTION_TYPES.GET_EXAM_DETAIL_OF_ROOM_SUCCESS: {
      return {
        ...state,
        examDetail: action.params,
      };
    }
    default:
      return state;
  }
};

export default roomReducer;
