import { toast } from "react-toastify";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES } from "./actionTypes";
import { manageStudentServices } from "./services";

function* createGroup(action) {
  try {
    const response = yield call(manageStudentServices.createGroup, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback(response?.data?.data?.group_hid);
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* getListGroup(action) {
  try {
    const response = yield call(manageStudentServices.getListGroup);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_LIST_GROUP_SUCCESS,
        params: response?.data?.data,
      });
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
  action.callback();
}

function* deleteGroup(action) {
  try {
    const response = yield call(manageStudentServices.deleteGroup, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error);
  }
}

function* updateGroup(action) {
  try {
    const response = yield call(manageStudentServices.updateGroup, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error);
  }
}

function* getGroupDetail(action) {
  try {
    const response = yield call(manageStudentServices.getGroupDetail, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_GROUP_DETAIL_SUCCESS,
        params: response?.data?.data,
      });
      action.successCallback(response?.data?.data);
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error);
  }
  action.finallyCallback();
}

function* addMembersToGroup(action) {
  try {
    const response = yield call(manageStudentServices.addMembersToGroup, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error);
  }
}

function* removeMemberOfGroup(action) {
  try {
    const response = yield call(manageStudentServices.removeMemberOfGroup, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error);
  }
}

function* getGroupActionHistory(action) {
  try {
    const response = yield call(manageStudentServices.getGroupActionHistory, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_GROUP_ACTION_HISTORY_SUCCESS,
        params: response?.data?.data,
      });
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error);
  }
}

function* addMembersOfGroupToRoom(action) {
  try {
    const response = yield call(manageStudentServices.addMembersOfGroupToRoom, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
  action.finallyCallback();
}

function* getMemberResultInGroup(action) {
  try {
    const response = yield call(manageStudentServices.getMemberResultInGroup, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_MEMBER_RESULT_IN_GROUP_SUCCESS,
        params: response?.data?.data,
      });
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
  action.finallyCallback();
}

function* getAllMembers(action) {
  try {
    const response = yield call(manageStudentServices.getAllMembers);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_ALL_MEMBERS_SUCCESS,
        params: response?.data?.data,
      });
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
  action.finallyCallback();
}

function* getMemberResult(action) {
  try {
    const response = yield call(manageStudentServices.getMemberResult, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_MEMBER_RESULT_SUCCESS,
        params: response?.data?.data,
      });
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
  action.finallyCallback();
}

function* manageStudentSaga() {
  yield takeLatest(ACTION_TYPES.CREATE_GROUP, createGroup);
  yield takeLatest(ACTION_TYPES.GET_LIST_GROUP, getListGroup);
  yield takeLatest(ACTION_TYPES.DELETE_GROUP, deleteGroup);
  yield takeEvery(ACTION_TYPES.UPDATE_GROUP, updateGroup);
  yield takeEvery(ACTION_TYPES.GET_GROUP_DETAIL, getGroupDetail);
  yield takeLatest(ACTION_TYPES.ADD_MEMBERS_TO_GROUP, addMembersToGroup);
  yield takeLatest(ACTION_TYPES.REMOVE_MEMBER_OF_GROUP, removeMemberOfGroup);
  yield takeLatest(ACTION_TYPES.GET_GROUP_ACTION_HISTORY, getGroupActionHistory);
  yield takeLatest(ACTION_TYPES.ADD_MEMBERS_OF_GROUP_TO_ROOM, addMembersOfGroupToRoom);
  yield takeLatest(ACTION_TYPES.GET_MEMBER_RESULT_IN_GROUP, getMemberResultInGroup);
  yield takeLatest(ACTION_TYPES.GET_ALL_MEMBERS, getAllMembers);
  yield takeLatest(ACTION_TYPES.GET_MEMBER_RESULT, getMemberResult);
}
export default manageStudentSaga;
