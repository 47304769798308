import moment from "moment";

export const getRoomStatus = (room) => {
    let messageId: string;
    if (room?.is_locked) {
      messageId = "Arena.room_status.finished";
    } else if (room?.start_time > moment().unix()) {
      messageId = "Arena.room_status.waiting";
    } else if (room?.start_time <= moment().unix() && moment().unix() <= room?.end_time) {
      messageId = "Arena.room_status.playing";
    } else {
      messageId = "Arena.room_status.finished";
    }
    return messageId;
  };