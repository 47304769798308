import React, { useState } from "react";
import QuestionRow from "./QuestionRow";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { genSignature, getFormData, getNonce, removeNullAttributeFromObject } from "../../common/utils/helper";
import { REPORT_SCREEN_TYPE } from "../utils/constant";
import { commonActions } from "../../common/redux/action";
import { MathJax } from "better-react-mathjax";
import { quizAnswerType } from "../../common/utils/constant";
import ReportDialog from "../../common/components/dialog/ReportDialog";
import SolutionDialog from "../../common/components/dialog/SolutionDialog";

type SelectQuestionThousandQuestionProps = {
  questions: any[];
  solutions: any[];
  questionsSelected: any[];
  isShowRightSide: boolean;
  isShowBorder: boolean;
  onChooseQuestion?: (question: any, actionType: "add" | "remove") => void;
  onClickQuestionContent?: (ordinal) => void;
};

const SelectQuestionThousandQuestion = ({
  questions,
  solutions,
  questionsSelected,
  isShowRightSide,
  isShowBorder,
  onChooseQuestion,
  onClickQuestionContent,
}: SelectQuestionThousandQuestionProps) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [reportDialogState, setReportDialogState] = useState<{ isOpen: boolean; id?: number }>({
    isOpen: false,
  });
  const [showSolution, setShowSolution] = useState<{ questionId: number; isSelfInput: boolean } | null>(null);

  const handleShowSolutions = (questionId) => {
    setShowSolution(questionId);
  };

  const handleSubmitReport = (formValues) => {
    let dataPost: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      quiz_question_id: reportDialogState.id,
    };

    const isPreviewScreen = true;

    dataPost = {
      ...dataPost,
      signature: genSignature(dataPost),
      reason: formValues?.textContent.trim(),
      images: formValues?.images ? formValues?.images?.map((e) => e?.file) : undefined,
      screen: isPreviewScreen ? REPORT_SCREEN_TYPE.PREVIEW : REPORT_SCREEN_TYPE.DETAIL,
    };

    if (params.roomId) {
      delete dataPost.screen;
      dataPost.room_hid = params.roomId;
    }

    dispatch(
      commonActions.reportQuiz(getFormData(removeNullAttributeFromObject(dataPost)), () => {
        setReportDialogState({ isOpen: false });
      })
    );
  };

  return (
    <div className={`h-full w-full ${!isShowRightSide && "pr-[50px]"} ${isShowBorder && "border"}`}>
      <div className="flex flex-col gap-6 pb-6 p-6">
        {questions
          ?.filter((question) => question?.answer_type !== quizAnswerType.NONE)
          ?.map((qs) => (
            <MathJax key={qs.ordinal} dynamic={false} inline>
              <QuestionRow
                qs={qs}
                isShowSolution={true}
                solution={solutions?.find((solution) => solution?.quiz_question_id === qs?.id)}
                questionNumber={qs?.ordinal}
                parentQs={questions?.find((e) => e.id === qs.parent)}
                isThousandQuestions={true}
                questionsSelected={questionsSelected}
                isShowSmallCancelButton={isShowRightSide}
                onOpenReportDialog={(questionId: number) => setReportDialogState({ isOpen: true, id: questionId })}
                onClickShowSolution={(questionId) => handleShowSolutions(questionId)}
                onChooseQuestion={onChooseQuestion}
                onClickQuestionContent={() => onClickQuestionContent?.(qs.ordinal)}
              />
            </MathJax>
          ))}
      </div>
      {reportDialogState.isOpen && (
        <ReportDialog
          isOpen={reportDialogState.isOpen}
          onClose={() => setReportDialogState({ isOpen: false })}
          onSubmit={handleSubmitReport}
        />
      )}
      {Boolean(showSolution) && (
        <SolutionDialog
          solution={solutions?.find((solution) => solution?.quiz_question_id === showSolution)}
          isOpen={Boolean(showSolution)}
          onClose={() => {
            setShowSolution(null);
          }}
        />
      )}
    </div>
  );
};

export default SelectQuestionThousandQuestion;
