import { ACTION_TYPES } from "./actionTypes";

const handleGetCreationInfoCreateExam = (params) => {
  return {
    type: ACTION_TYPES.GET_CREATION_INFO_CREATE_EXAM,
    params,
  };
};
const handleLogOut = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_LOGOUT,
    params,
  };
};
const addUserInfos = (params) => {
  return {
    type: ACTION_TYPES.ADD_USER_INFOS,
    params,
  };
};
const addFireBaseInfos = (params) => {
  return {
    type: ACTION_TYPES.ADD_FIRE_BASE_INFOS,
    params,
  };
};
const handleSaveNotify = (params) => {
  return {
    type: ACTION_TYPES.SAVE_NOTIFY,
    params,
  };
};
const handleAuthorizeSuccess = (params) => {
  return {
    type: ACTION_TYPES.AUTHORIZE_SUCCESS,
    params,
  };
};
const handleDeleteNotify = () => {
  return {
    type: ACTION_TYPES.DELETE_NOTIFY,
  };
};
const handleCreateQuizPDF = (params, callback) => {
  return {
    type: ACTION_TYPES.HANDLE_CREATE_QUIZ_PDF,
    params,
    callback,
  };
};
const handleGetListRoom = (finallyCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_LIST_ROOM,
    finallyCallback,
  };
};

const handleConvertDocx = (params, callback) => {
  return {
    type: ACTION_TYPES.HANDLE_CONVERT_DOCX,
    params,
    callback,
  };
};

const getRoomCreationInfo = () => {
  return {
    type: ACTION_TYPES.GET_ROOM_CREATION_INFO,
  };
};

const getAllFolderAndFile = (params = { folder_type: "all" }) => {
  return {
    type: ACTION_TYPES.GET_ALL_FOLDER_AND_FILE,
    params: params || { folder_type: "all" },
  };
};

const handleCleanQuizFile = () => {
  return {
    type: ACTION_TYPES.CLEAN_QUIZ_FILE,
  };
};

const handleSaveFolderCreateExam = (params) => {
  return {
    type: ACTION_TYPES.ADD_FOLDER_CREATE_EXAM,
    params,
  };
};

const handleOpenDialogFeatureDeveloping = (params) => {
  return {
    type: ACTION_TYPES.FEATURE_DEVELOPING,
    params,
  };
};
const handleGetExamDetail = (params, successCallback = () => {}, errorCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_EXAM_DETAIL,
    params,
    successCallback,
    errorCallback,
  };
};
const handleEditExam = (params, successCallback = (topicSlug) => {}, errorCallback = (error) => {}) => {
  return {
    type: ACTION_TYPES.EDIT_EXAM,
    params,
    successCallback,
    errorCallback,
  };
};
const handleSetHideLeftSide = (params) => {
  return {
    type: ACTION_TYPES.HIDE_LEFT_SIDE,
    params,
  };
};

const reportQuiz = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.REPORT_QUIZ,
    params,
    successCallback,
  };
};
const handleGetDashboardInfo = (successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_DASHBOARD,
    successCallback,
  };
};
const handleGetTeacherInfo = (successCallback = (teacherInfo) => {}) => {
  return {
    type: ACTION_TYPES.GET_TEACHER_INFO,
    successCallback,
  };
};
const handleGetGradeAndSubjects = () => {
  return {
    type: ACTION_TYPES.GET_GRADE_AND_SUBJECT,
  };
};
const handleUpdateAccountInfo = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.UPDATE_ACCOUNT_INFO,
    params,
    successCallback,
  };
};
const handleSetOpenDialogRegisterTeacher = (params) => {
  return {
    type: ACTION_TYPES.SET_OPEN_DIALOG_REGISTER_TEACHER,
    params,
  };
};
const handleSetOpenDialogNotifyRegisterTeacher = (params) => {
  return {
    type: ACTION_TYPES.SET_OPEN_DIALOG_NOTIFY_REGISTER,
    params,
  };
};
const handleRegisterTeacher = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.HANDLE_REGISTER_TEACHER,
    params,
    successCallback,
  };
};
const handleSetOpenDialogUpdateProfile = (params) => {
  return {
    type: ACTION_TYPES.SET_OPEN_DIALOG_UPDATE_PROFILE,
    params,
  };
};
const handleUpdateProfile = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.HANDLE_UPDATE_PROFILE,
    params,
    successCallback,
  };
};

const handleGetReportReview = (finallyCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_REPORT_REVIEW,
    finallyCallback,
  };
};
const handleChangeStatusReport = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.HANDLE_CHANGE_STATUS_REPORT,
    params,
    successCallback,
  };
};
const handleGetUserInfo = (params) => {
  return {
    type: ACTION_TYPES.GET_USER_INFO,
    params,
  };
};
const handleCreateExam = (params, successCallback = (v) => {}) => {
  return {
    type: ACTION_TYPES.CREATE_EXAM,
    params,
    successCallback,
  };
};
const handleEditQuestionReport = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.HANDLE_EDIT_QUESTION,
    params,
    successCallback,
  };
};
const loginWithEmailAndPassword = (params) => {
  return {
    type: ACTION_TYPES.LOGIN_WITH_EMAIL_AND_PASSWORD,
    params,
  };
};

const setErrorMessageFormLoginFromServer = (params) => {
  return {
    type: ACTION_TYPES.SET_ERROR_MESSAGE_FORM_LOGIN_FROM_SERVER,
    params,
  };
};

export const commonActions = {
  handleGetCreationInfoCreateExam,
  handleLogOut,
  addFireBaseInfos,
  addUserInfos,
  handleSaveNotify,
  handleAuthorizeSuccess,
  handleDeleteNotify,
  handleCreateQuizPDF,
  handleGetListRoom,
  handleConvertDocx,
  getRoomCreationInfo,
  getAllFolderAndFile,
  handleCleanQuizFile,
  handleSaveFolderCreateExam,
  handleOpenDialogFeatureDeveloping,
  handleGetExamDetail,
  handleEditExam,
  handleSetHideLeftSide,
  reportQuiz,
  handleGetDashboardInfo,
  handleGetTeacherInfo,
  handleGetGradeAndSubjects,
  handleUpdateAccountInfo,
  handleSetOpenDialogRegisterTeacher,
  handleRegisterTeacher,
  handleSetOpenDialogNotifyRegisterTeacher,
  handleSetOpenDialogUpdateProfile,
  handleUpdateProfile,
  handleGetReportReview,
  handleChangeStatusReport,
  handleGetUserInfo,
  handleCreateExam,
  handleEditQuestionReport,
  loginWithEmailAndPassword,
  setErrorMessageFormLoginFromServer,
};
