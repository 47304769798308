import React from "react";
import ReactAudioPlayer from "react-audio-player";
import { isEmptyCustom } from "../../../common/utils/helper";

function SortPositionCard({ options, solution }: any) {
  const checkContentImage = options?.some((e) => e?.items[0]?.content?.includes("<img"));
  const correctAnswer = solution?.correct_options?.map((ele) => options?.findIndex((e) => e.id === ele) + 1);
  
  return (
    <div style={{ width: "100%" }}>
      <div className="grid grid-cols-2 justify-items-center">
        {options?.map((ele: { items: any[] }, idx: number) => (
          <div key={idx} className="flex items-start flex-col ml-4 p-2">
            {ele && <ShowOptionsSortPosition ele={ele?.items[0]} idx={idx + 1} />}
          </div>
        ))}
      </div>
      <div>
        <span className="font-semibold">Đáp án: </span>
        {checkContentImage ? correctAnswer?.join(" - ") : solution?.correct_options
          ?.map((correctId) => {
            return options.find((option) => option.id === correctId).items[0].content;
          })
          .join(" ")}
      </div>
    </div>
  );
}

export default React.memo(SortPositionCard);

function ShowOptionsSortPosition({ ele, idx }: any) {
  if (isEmptyCustom(ele)) return null;
  switch (ele?.content_type) {
    case "image":
      return (
        <div className="flex justify-center">
          {idx}.
          <img
            src={ele?.content}
            alt=""
            style={{
              maxWidth: 496,
              maxHeight: 200,
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      );
    case "audio":
      return (
        <div className="flex justify-center my-2">
          {idx}.
          <ReactAudioPlayer src={ele?.content} controls />
        </div>
      );
    default:
      return (
        <div className="flex items-baseline">
          {idx}.
          {ele?.content && (
            // eslint-disable-next-line
            <div dangerouslySetInnerHTML={{ __html: ele?.content }} />
          )}
        </div>
      );
  }
}
