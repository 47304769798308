import React from "react";
import Dialog from "./Dialog";
import ShowBasicDataContent from "../../../manage_exam/components/ShowBasicDataContent";
import { quizAnswerType } from "../../utils/constant";
import { MathJax } from "better-react-mathjax";
import { useIntl } from "react-intl";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

type TSolutionDialogProps = {
  solution: any;
  isOpen: boolean;
  onClose: () => void;
};

const listQuizAnswerTypeShowAnswer = [
  quizAnswerType.CONCAT_WORD,
  quizAnswerType.CONCAT_LETTER_EN,
  quizAnswerType.CONCAT_LETTER_VI,
  quizAnswerType.CONCAT_NUMBER,
  quizAnswerType.CONCAT_LETTER_MIX,
];

function SolutionDialog({ solution, isOpen, onClose }: TSolutionDialogProps) {
  const intl = useIntl();
  const solutionItems = solution?.items;
  return (
    <Dialog
      title={intl.formatMessage({ id: "manageExam.solution" })}
      isOpen={isOpen}
      onClose={onClose}
      actions={<></>}
      closable
      className="min-h-[250px] max-h-[400px] overflow-y-auto overflow-x-hidden"
    >
      <MathJax>
        <div className="break-words">
          {isEmpty(solutionItems?.suggestion) && isEmpty(solutionItems?.detail) ? (
            <span>{intl.formatMessage({ id: "manageExam.noSolution" })}</span>
          ) : (
            <>
              {solutionItems?.suggestion?.map((solution, idx) => (
                <div key={idx} className="flex flex-col">
                  <ShowBasicDataContent ele={solution} />
                </div>
              ))}
              {solutionItems?.detail?.map((solution, idx) => (
                <div key={idx} className="flex flex-col">
                  <ShowBasicDataContent ele={solution} />
                </div>
              ))}
            </>
          )}
        </div>
      </MathJax>
    </Dialog>
  );
}

const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.solution, nextProps?.solution) && isEqual(prevProps?.isOpen, nextProps?.isOpen);
};

export default React.memo(SolutionDialog, checkEqualProps);
