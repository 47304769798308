import moment from "moment";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import React, { ReactNode, useState } from "react";
import { CreateRoomIcon, DownloadIcon, Edit2Icon, MoveExamIcon, ShareIcon, TrashIcon } from "../../../assets";
import { EExamActions, TExam } from "../../../types/exam";
import { useDispatch } from "react-redux";
import { commonActions } from "../../common/redux/action";
import { Link } from "react-router-dom";
import DialogInputTitleExam from "../../manage_exam/components/DialogInputTitleExam";
import { genSignature, getNonce } from "../../common/utils/helper";
import { documentActions } from "../redux/action";
import { eventTracking } from "../../../firebase/firebaseConfig";

type TExamItemProps = {
  exam: TExam;
  isChecked: boolean;
  hiddenActionKeys: EExamActions[];
  onClickDelete: (id: number) => void;
  onClickShare: (id: number) => void;
  onClickCheckbox: (isChecked: boolean, id: number) => void;
  onClickCreateRoom: (examId: number) => void;
};

export default function ExamItem({
  exam,
  isChecked,
  hiddenActionKeys,
  onClickDelete,
  onClickShare,
  onClickCheckbox,
  onClickCreateRoom,
}: TExamItemProps) {
  const dispatch = useDispatch();
  const [openDialogChangeTitle, setOpenDialogChangeTitle] = useState(false);

  const handleChangeChecked = (value: boolean) => {
    onClickCheckbox(value, exam.id);
  };

  const handleOpenDialogFeatureDeveloping = () => {
    dispatch(commonActions.handleOpenDialogFeatureDeveloping(true));
  };

  const handleEditTitleExam = ({ title }) => {
    const dataEditPre = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      title: title,
      topic_slug: exam?.slug,
    };
    const dataEdit = {
      ...dataEditPre,
      signature: genSignature(dataEditPre),
    };
    dispatch(
      commonActions.handleEditExam({ topicSlug: exam?.slug, data: dataEdit }, () =>
        dispatch(
          documentActions.getListFolderAndFile({
            folder_type: "mine",
          })
        )
      )
    );
  };

  const examActions: {
    key: EExamActions;
    icon: ReactNode;
    onClick?: () => void;
  }[] = [
    {
      key: EExamActions.SHARE,
      icon: <ShareIcon />,
      onClick: () => onClickShare(exam.id),
    },
    {
      key: EExamActions.MOVE,
      icon: <MoveExamIcon />,
      onClick: () => handleOpenDialogFeatureDeveloping(),
    },
    {
      key: EExamActions.EDIT,
      icon: <Edit2Icon />,
      onClick: () => setOpenDialogChangeTitle(true),
    },
    {
      key: EExamActions.DOWNLOAD,
      icon: <DownloadIcon />,
      onClick: () => handleOpenDialogFeatureDeveloping(),
    },
    {
      key: EExamActions.DELETE,
      icon: <TrashIcon />,
      onClick: () => onClickDelete(exam.id),
    },
  ];

  return (
    <>
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
        <td colSpan={2} className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          <div className="flex justify-center">
            <Checkbox
              checked={isChecked}
              onChange={(e) => handleChangeChecked(e.checked as boolean)}
              pt={{
                input: {
                  className: "[&]:shadow-none",
                },
              }}
            />
          </div>
        </td>
        <td colSpan={15} className="px-4 py-3 text-gray-900 dark:text-white hover:bg-gray-100">
          <Link
            to={`/quan-ly-tai-lieu/${exam?.slug}`}
            onClick={() => eventTracking("select_a_file_item")}
            className="line-clamp-2"
          >
            {exam.title}
          </Link>
        </td>
        <td colSpan={5} className="px-4 py-3 text-gray-900">
          {exam.status}
        </td>
        <td colSpan={6} className="px-4 py-3 text-gray-900">
          {moment((exam.updated_at as number) * 1000).format("DD-MM-yyyy")}
        </td>
        <td colSpan={10} className="px-4 py-3">
          <div className="flex gap-4 items-center justify-center">
            {examActions.map((action) => {
              if (hiddenActionKeys.includes(action.key)) return null;
              return (
                <Button
                  key={action.key}
                  icon={action.icon}
                  className="flex items-center w-[24px] h-[24px] bg-transparent border-none focus:shadow-none"
                  onClick={action.onClick}
                />
              );
            })}
          </div>
        </td>
        <td colSpan={5} className="px-4 py-3">
          <div className="flex justify-center">
            <Button
              icon={<CreateRoomIcon />}
              className="flex items-center w-[24px] h-[24px] bg-transparent border-none focus:shadow-none"
              onClick={() => onClickCreateRoom(exam.id)}
            />
          </div>
        </td>
      </tr>
      {openDialogChangeTitle && (
        <DialogInputTitleExam
          open={openDialogChangeTitle}
          setOpen={setOpenDialogChangeTitle}
          initTitle={exam?.title}
          onSubmit={(title) => handleEditTitleExam(title)}
          isEditTitle
        />
      )}
    </>
  );
}
