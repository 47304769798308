import React, { useRef } from "react";
import isEqual from "lodash/isEqual";
import { InputNumber } from "primereact/inputnumber";

function Paging({ page, setPage, maxPage }) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="flex justify-between items-center w-full">
      <button
        className={`${page > 1 ? "bg-primary" : ""} border rounded-[8px] h-[44px] w-[44px]`}
        onClick={() => page > 1 && setPage(page - 1)}
      >
        <i className={`pi pi-arrow-left ${page > 1 && "text-white"}`} />
      </button>
      <span
        className="flex items-center justify-center border w-[82px] h-[32px] text-gray-700 rounded-[12px] pr-4"
        onClick={() => inputRef.current?.focus()}
      >
        <InputNumber
          inputRef={inputRef}
          value={page}
          disabled={maxPage === 1}
          onValueChange={(e) => {
            setPage(Number(e?.value));
            inputRef.current?.blur();
          }}
          pt={{
            input: {
              root: { className: "w-[32px] p-0 border-none text-right" },
            },
          }}
          defaultValue={page}
          min={1}
          max={maxPage}
        />
        /{maxPage}
      </span>
      <button
        className={`${page < maxPage ? "bg-primary" : ""} border rounded-[8px] h-[44px] w-[44px]`}
        onClick={() => page < maxPage && setPage(page + 1)}
      >
        <i className={`${page < maxPage && "text-white"} pi pi-arrow-right`} />
      </button>
    </div>
  );
}
const checkEqualProps = (preProps, nextProps) => {
  return isEqual(preProps?.page, nextProps?.page) && isEqual(preProps?.maxPage, nextProps?.maxPage);
};
export default React.memo(Paging, checkEqualProps);
