export const QUIZ_LEVEL = [
  {
    level: 0,
    title: "quiz.level.normal",
  },
  {
    level: 1,
    title: "quiz.level.premium",
  },
  {
    level: 2,
    title: "quiz.level.pdf",
  },
  {
    level: 3,
    title: "quiz.level.docx",
  },
  {
    level: 4,
    title: "quiz.level.selfInput",
  },
];

export const QUIZ_LEVEL_OBJECT = {
  NORMAL: 0,
  PREMIUM: 1,
  PDF: 2,
  DOC: 3,
  CREATED: 4,
};

export const ANSWER_KEY = ["A", "B", "C", "D"];

export const convertAnswerKey = (ix: number) => {
  switch (ix) {
    case 0:
      return "A";
    case 1:
      return "B";
    case 2:
      return "C";
    case 3:
      return "D";
    case 4:
      return "E";
    case 5:
      return "F";
    case 6:
      return "G";
    case 7:
      return "H";
    default:
      return "I";
  }
};

export const ROOM_STATUS = ["Arena.room_status.finished", "Arena.room_status.waiting", "Arena.room_status.playing"];

export const ERROR_CODE_NOT_FOUND_EXAM = "ENFTOP";

export const ADD_DAYS_MAX_END_TIME = 3;

export const ADD_DAYS_MAX_START_TIME = 1;

export const quizAnswerType = {
  NONE: -1,
  ONE_ANSWER: 0,
  MANY_ANSWER: 1,
  PAIRING: 2,
  CONCAT_WORD: 3,
  CONCAT_NUMBER: 4,
  CONCAT_LETTER_VI: 5,
  CONCAT_LETTER_EN: 6,
  CONCAT_LETTER_MIX: 7,
  SORT_POSITION: 8,
  CONSTRUCTED_RESPONSE: 9,
};

export const TYPE_GENERATE_EXAM = {
  DOCX: 0,
  PDF: 1,
  GROUP_EXAM: 2,
  THOUSAND_EXAM: 3,
  SELF_INPUT: 4,
};

export const ROOM_STATUS_EDIT_QUIZ = {
  NO_CREATE: 0,
  NO_START: 1,
  NO_SUBMIT: 2,
  QUIZ_DONE: 3,
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const phoneRegex = /^(([+]?84)|0[3|5|7|8|9])+([0-9]{8})$/;

export const ACCOUNT_TEACHER_STATUS = {
  NEW: "new_member",
  NOT_REGISTER: "has_not_register_teacher",
  REGISTER: "registered_teacher",
  ACCEPTED: "accepted_teacher",
  REJECTED: "rejected_teacher",
  BLOCKED: "blocked_teacher",
};

export const GENDER = {
  UNDISCLOSED: -1,
  FEMALE: 0,
  MALE: 1,
  OTHER: 2,
};

export const trackingNameOfProvider = {
  "google.com": "email",
  "apple.com": "Apple ID",
  "facebook.com": "facebook",
};

export const trackingEventNameOfMenuItem = {
  "navbar.manageStudent": "select_menu_group", //default children
  "navbar.manageStudent.groups": "select_menu_group",
  "navbar.manageStudent.members": "select_menu_student",
  "navbar.manageDocument": "select_menu_mine", //default children
  "navbar.manageDocument.mine": "select_menu_mine",
  "navbar.manageDocument.shared": "select_menu_share",
  "navbar.manageRoom": "select_menu_room",
};

export const trackingEventNameByExamType = {
  "dialogCreateExam.nhbd": "select_random_input_type_done",
  "dialogCreateExam.nhch": "select_question_input_type",
  "dialogCreateExam.pdf": "select_pdf_input_type",
  "dialogCreateExam.docx": "select_doc_input_type",
  "dialogCreateExam.selfInput": "select_text_input_type",
};

export const EXAM_DIFFICULT_DEGREE = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
};

export const SIGN_IN_METHOD = {
  FIREBASE: "FIREBASE",
  EMAIL_AND_PASSWORD: "EMAIL_AND_PASSWORD",
};
