import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SmallFolderIcon } from "../../../assets";
import ButtonGhost from "../../common/components/button/ButtonGhost";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import Dialog from "../../common/components/dialog/Dialog";
import { commonActions } from "../../common/redux/action";
import { containsSpecialChars, isEmptyCustom } from "../../common/utils/helper";

export default function DialogInputTitleExam({ open, setOpen, onSubmit, initTitle = "", isEditTitle = false }) {
  const intl = useIntl();
  const [title, setTitle] = useState("");
  const { allFolderAndFile, createExamFromFolderId } = useSelector((state: { common }) => state.common);
  const [folderId, setFolderId] = useState<any>();
  const dispatch = useDispatch();

  const actions = (
    <div className="flex items-center justify-end gap-2">
      <ButtonGhost label={intl.formatMessage({ id: "common.cancel" })} onClick={() => setOpen(false)} />
      <ButtonPrimary
        className="w-[100px]"
        label={
          isEditTitle
            ? intl.formatMessage({ id: "common.edit" })
            : intl.formatMessage({ id: "dialogInputTitle.create" })
        }
        onClick={() => {
          setOpen(false);
          onSubmit({ title: title.trim(), folderId: folderId && folderId > -1 ? folderId : null });
        }}
        autoFocus
        disabled={!title}
      />
    </div>
  );
  const selectedFolderTemplate = (option, props) => {
    if (option && option?.value > -1) {
      return (
        <div className="flex items-center gap-2">
          <SmallFolderIcon />
          <div className="truncate max-w-[400px]">{option?.label}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const folderOptionTemplate = (option) => {
    return (
      <div className="flex items-center gap-2">
        {option?.value > -1 && <SmallFolderIcon />}
        <div className="truncate max-w-[430px]">{option.label}</div>
      </div>
    );
  };

  useEffect(() => {
    if (createExamFromFolderId && !!allFolderAndFile) {
      // setFolder(allFolderAndFile?.folders?.find((e) => e?.id === createExamFromFolderId));
      setFolderId(createExamFromFolderId);
    }
  }, [allFolderAndFile]);

  useEffect(() => {
    if (isEditTitle && !isEmptyCustom(initTitle)) {
      setTitle(initTitle);
    } else {
      dispatch(
        commonActions.getAllFolderAndFile({
          folder_type: "mine",
        })
      );
    }
  }, []);
  return (
    <div className="card flex justify-content-center">
      <Dialog
        title={
          isEditTitle
            ? intl.formatMessage({ id: "dialogInputTitle.editTitle" })
            : intl.formatMessage({ id: "dialogInputTitle.title" })
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        actions={actions}
      >
        <InputText
          value={title}
          className={`w-full rounded-[12px] mt-1 ${!isEditTitle ? "mb-[24px]" : "mb-1"}`}
          onChange={(e) => {
            if (!containsSpecialChars(e.target.value)) {
              setTitle(e.target.value);
            }
          }}
          placeholder={intl.formatMessage({
            id: "dialogInputTitle.inputPlaceholder",
          })}
        />
        {!isEditTitle && (
          <div className="mb-1">
            <label htmlFor="folder" className="font-[600] text-gray-900">
              <FormattedMessage id="dialogInputTitle.savedFolder" />
            </label>
            <Dropdown
              value={folderId}
              onChange={(e) => setFolderId(e?.value)}
              options={allFolderAndFile?.folders
                ?.map((e) => ({ value: e?.id, label: e?.title }))
                .concat([{ value: -1, label: intl.formatMessage({ id: "common.unChoose" }) }])}
              // optionLabel="title"
              placeholder={intl.formatMessage({
                id: "dialogInputTitle.folderPlaceholder",
              })}
              filter
              className="w-full md:w-14rem rounded-[12px] mt-3"
              valueTemplate={selectedFolderTemplate}
              itemTemplate={folderOptionTemplate}
              virtualScrollerOptions={{ itemSize: 42 }}
              filterPlaceholder={intl.formatMessage({
                id: "dialogInputTitle.filterPlaceholder",
              })}
              pt={{
                filterInput: {
                  className: "h-[44px] pl-[30px]",
                },
                filterIcon: {
                  className: "absolute left-2 ",
                },
              }}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}
