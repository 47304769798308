import React from "react";
import { quizAnswerType } from "../../../common/utils/constant";
import { MathJax } from "better-react-mathjax";
import ShowBasicDataContent from "../ShowBasicDataContent";
import ConstructedResponseCard from "./ConstructedReponseCard";
import { PencilEdit, TrashIcon } from "../../../../assets";

type QuestionCardProps = {
  qs: any;
  isDynamicMathJax: boolean;
  setOrdinalQuestion;
  idx: number;
  breakShowOption: any;
  isViewOnly: boolean;
  onClickShowSolution: any;
  onClickEditButton: any;
  onClickDeleteQuestion: any;
};

const QuestionCard = ({
  qs,
  isDynamicMathJax,
  setOrdinalQuestion,
  idx,
  breakShowOption,
  isViewOnly,
  onClickShowSolution,
  onClickEditButton,
  onClickDeleteQuestion,
}: QuestionCardProps) => {
  return (
    <MathJax key={qs.ordinal} dynamic={isDynamicMathJax}>
      <div id={`question_${qs?.ordinal}`}>
        <div className="py-4 flex flex-col">
          {qs?.contents?.map((e, ix) => (
            <button
              key={e?.id}
              className="flex w-full"
              onClick={() => e?.content_type === "html" && setOrdinalQuestion(qs?.ordinal)}
            >
              {ix === 0 && <span className="font-[600] mr-2 w-max text-start">Câu {idx + 1}:</span>}{" "}
              <div className="w-[calc(100%-80px)] text-start">
                <ShowBasicDataContent ele={e} />
              </div>
            </button>
          ))}
        </div>
        {(() => {
          switch (qs?.answer_type) {
            case quizAnswerType.ONE_ANSWER:
            case quizAnswerType.MANY_ANSWER:
              return (
                <div className={`mt-[-12px] w-full grid ${breakShowOption ? "grid-cols-2 gap-x-6" : "grid-cols-1"}`}>
                  {qs?.options?.map((op) => (
                    <div key={op.ordinal}>
                      <div
                        className={`min-h-[48px] py-2 border rounded-[8px] my-3 items-center px-3 ${
                          Boolean(op?.correct) && "border-primary"
                        }`}
                      >
                        {op?.contents?.map((opc, i) => (
                          <span key={opc.id} className="flex items-baseline gap-1">
                            <span>{i === 0 && op?.answer_key}.</span> <ShowBasicDataContent ele={opc} />
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              );
            case quizAnswerType.CONSTRUCTED_RESPONSE: {
              return <ConstructedResponseCard qs={qs} />;
            }
          }
        })()}

        {!isViewOnly && (
          <div className="flex justify-between items-center w-full font-[600] mt-6">
            <button className="text-secondary" onClick={onClickShowSolution}>
              Hướng dẫn giải
            </button>
            <div className="flex gap-[64px]">
              <button className="flex gap-2 text-primary" onClick={onClickEditButton}>
                <PencilEdit stroke="#38B000" /> Sửa
              </button>
              <button className="flex gap-2 text-red-500" onClick={() => onClickDeleteQuestion(qs?.ordinal)}>
                <TrashIcon />
                Xoá câu hỏi
              </button>
            </div>
          </div>
        )}
      </div>
    </MathJax>
  );
};

export default QuestionCard;
