import { classNames } from "primereact/utils";
import React from "react";
import { convertAnswerKey, quizAnswerType } from "../../common/utils/constant";

type TAnswerBoardGroupExamProps = {
  questions: any;
  solutions: any[];
  setOrdinalQuestion: any;
};

export default function AnswerBoardGroupExam({ questions, solutions, setOrdinalQuestion }: TAnswerBoardGroupExamProps) {
  return (
    <div className="w-full h-full">
      <div className="pl-10 pr-4 pt-6 space-y-6">
        <span className="text-gray-900 font-[600]">Đáp án</span>
        <div className="space-y-1">
          {questions
            ?.filter((question) => question?.answer_type !== quizAnswerType.NONE)
            ?.filter((question) => !question.deleted)
            ?.map((question: any, index) => (
              <div
                key={question?.id}
                className="p-2"
                role="button"
                onClick={() => {
                  setOrdinalQuestion(question?.ordinal);
                }}
              >
                <div className="flex gap-x-4">
                  <p
                    className={classNames("text-gray-900 text-[12px]", {
                      "mt-3": [quizAnswerType.ONE_ANSWER, quizAnswerType.MANY_ANSWER, undefined].includes(
                        question.answer_type
                      ),
                    })}
                  >
                    {index + 1}
                  </p>
                  {[quizAnswerType.ONE_ANSWER, quizAnswerType.MANY_ANSWER, undefined].includes(question.answer_type) ? (
                    <div className="flex-1">
                      <div className="grid grid-cols-4 gap-y-2">
                        {question?.options?.map((option, index) => (
                          <p
                            className={classNames(
                              "flex justify-center items-center",
                              "w-[40px] h-[40px] rounded-full border-[1px] bg-gray-100 text-[12px] text-gray-900",
                              {
                                "bg-success text-white": option.correct,
                              }
                            )}
                          >
                            {(() => {
                              if (
                                [quizAnswerType.ONE_ANSWER, quizAnswerType.MANY_ANSWER].includes(question.answer_type)
                              ) {
                                if (question.isSelfInput) return convertAnswerKey(option.ordinal);
                                return convertAnswerKey(index);
                              } else if (question.answer_type === undefined) {
                                return option.answer_key;
                              }
                            })()}
                          </p>
                        ))}
                      </div>
                    </div>
                  ) : (
                    (() => {
                      const solution = solutions?.find((solution) => solution?.quiz_question_id === question?.id);
                      switch (question?.answer_type) {
                        case quizAnswerType.SORT_POSITION: {
                          const checkContentImage = question?.options?.some(
                            (e) => e?.items[0]?.content?.includes("<img")
                          );
                          const correctAnswer = solution?.correct_options?.map(
                            (ele) => question?.options?.findIndex((e) => e.id === ele) + 1
                          );

                          return (
                            <div className="font-normal text-gray-900 text-[12px]">
                              {checkContentImage
                                ? correctAnswer?.join(" - ")
                                : solution?.correct_options
                                    ?.map((correctId) => {
                                      return question?.options.find((option) => option.id === correctId).items[0]
                                        .content;
                                    })
                                    .join(" ")}
                            </div>
                          );
                        }
                        // case quizAnswerType.ONE_ANSWER: {
                        //   return (
                        //     <span className="w-[90px] h-[40px] border flex justify-center items-center rounded-[16px] font-normal text-[#495057]">
                        //       {convertAnswerKey(question?.options?.find((e) => e?.correct === 1)?.ordinal - 1)}
                        //     </span>
                        //   );
                        // }
                        case quizAnswerType.CONCAT_LETTER_EN:
                        case quizAnswerType.CONCAT_LETTER_VI:
                        case quizAnswerType.CONCAT_NUMBER:
                        case quizAnswerType.CONCAT_WORD:
                        case quizAnswerType.CONCAT_LETTER_MIX:
                          return (
                            solution?.correct_options && (
                              <span className="font-normal text-gray-900 text-[12px]">
                                {solution?.correct_options?.join(" ")}
                              </span>
                            )
                          );
                        case quizAnswerType.CONSTRUCTED_RESPONSE:
                          return (
                            question?.options[0].contents[0] && (
                              <span className="font-normal text-gray-900 text-[12px]">
                                {question?.options[0].contents[0].content}
                              </span>
                            )
                          );
                      }
                    })()
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
