import React from "react";
import { Image } from "primereact/image";
import ReactAudioPlayer from "react-audio-player";
import { classNames } from "primereact/utils";

type ShowBasicDataContentProps = {
  ele?: any;
  textClassName?: string;
};

const ShowBasicDataContent = ({ ele, textClassName }: ShowBasicDataContentProps) => {
  switch (ele?.content_type) {
    case "image":
      return (
        <div className="flex justify-center">
          <Image src={ele?.content} alt="Image" width="250" preview />
        </div>
      );
    case "audio":
      return (
        <div className="my-2 flex justify-center">
          <ReactAudioPlayer
            src={ele?.content}
            controls
            // onPlay={(event) => {
            //   event.preventDefault();
            // }}
          />
        </div>
      );
    default:
      return (
        <div className={classNames("flex items-baseline font-normal", textClassName)}>
          {ele?.content && (
            // eslint-disable-next-line
            <div
              dangerouslySetInnerHTML={{
                __html: ele?.content,
              }}
            />
          )}
        </div>
      );
  }
};
export default ShowBasicDataContent;
