import React from "react";

function ArrowCircleLeft({ stroke = "#101828" }) {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.9917 16.999L16.9998 25.0071M16.9998 25.0071L25.008 32.999M16.9998 25.0071L32.9998 24.9908M4.99985 25.0194C4.98857 13.9737 13.9337 5.01026 24.9794 4.99897C36.0251 4.98769 44.9885 13.9328 44.9998 24.9785C45.0111 36.0242 36.066 44.9877 25.0203 44.9989C13.9746 45.0102 5.01114 36.0651 4.99985 25.0194Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ArrowCircleRight({ stroke = "#101828" }) {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 32L32.5 24M32.5 24L24.5 16M32.5 24H16.5M44.5 24C44.5 35.0457 35.5457 44 24.5 44C13.4543 44 4.5 35.0457 4.5 24C4.5 12.9543 13.4543 4 24.5 4C35.5457 4 44.5 12.9543 44.5 24Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { ArrowCircleRight, ArrowCircleLeft };
