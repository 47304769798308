import React from "react";
import { FolderIcon } from "../../../assets";
import { TFolder } from "../../../types/folder";

type TFolderProps = {
  folder: TFolder;
  onClick: (id: number) => void;
  onRightClick: (event: any, folder: any) => void;
};

export default function Folder({ folder, onClick, onRightClick }: TFolderProps) {
  return (
    <div
      className="flex flex-col items-center gap-3 py-2.5 px-4 w-full min-w-[148px] hover:cursor-pointer hover:bg-gray-200 duration-200"
      onClick={() => onClick(folder.id)}
      onContextMenu={(event) => onRightClick(event, folder)}
    >
      <FolderIcon />
      <span className="text-gray-900 line-clamp-2 break-words">{folder.title}</span>
    </div>
  );
}
