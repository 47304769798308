import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from "primereact/tooltip";
import { Card } from "primereact/card";
import { commonActions } from "../components/common/redux/action";
import { TCommonState } from "../components/common/utils/type";
import { FormattedMessage } from "react-intl";
import { DashboardFileCheck, DashboardShared, FolderIcon2 } from "../assets";
import KnobProgress from "../components/common/components/KnobProgress";
import DashboardRoomProgress from "../components/common/components/DashboardRoomProgress";
import {
  RoomProgressNoGroupIcon,
  RoomProgressNoLockedIcon,
  RoomProgressTotalIcon,
} from "../assets/icons/dashboard_icon";
import { eventTracking } from "../firebase/firebaseConfig";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { dashboardInfo, userInfos } = useSelector((state: { common: TCommonState }) => state?.common);

  const activitiesDaily = [
    {
      id: "dashboard.class",
      additions: dashboardInfo.activities_daily?.groups?.add,
      deletions: dashboardInfo.activities_daily?.groups?.remove,
    },
    {
      id: "dashboard.student",
      additions: dashboardInfo.activities_daily?.students?.add,
      deletions: dashboardInfo.activities_daily?.students?.remove,
    },
    {
      id: "dashboard.daily.folder",
      additions: dashboardInfo.activities_daily?.folders?.add,
      deletions: dashboardInfo.activities_daily?.folders?.remove,
    },
    {
      id: "dashboard.daily.exam",
      additions: dashboardInfo.activities_daily?.files?.add,
      deletions: dashboardInfo.activities_daily?.files?.remove,
    },
    {
      id: "dashboard.daily.room",
      additions: dashboardInfo.activities_daily?.rooms?.add,
      deletions: dashboardInfo.activities_daily?.rooms?.remove,
    },
  ];

  useEffect(() => {
    if (userInfos?.member) {
      dispatch(commonActions.handleGetDashboardInfo());
    }
  }, []);

  useEffect(() => {
    eventTracking("screen_dashboard_view");
  }, []);

  return (
    <div className="main-content px-10 pb-8 text-gray-900">
      <div className="w-full h-[256px] p-8 rounded-[32px] bg-white">
        <span className="font-[600] text-[24px] leading-[36px]">
          <FormattedMessage id="dashboard.daily" />
        </span>
        <div className="flex justify-between pb-[32px] pt-[24px] h-full gap-x-[24px]">
          {activitiesDaily.map((ac) => (
            <div key={ac.id} className="border rounded-[32px] w-[calc(25%-19.2px)] px-6 py-4">
              <span className="flex justify-center font-[600] leading-[24px]">
                <FormattedMessage id={ac.id} />
              </span>
              <div className="flex flex-col gap-y-3 pt-2">
                <div className="flex justify-between">
                  <span className="text-[14px] text-gray-700">
                    <FormattedMessage id="dashboard.daily.additions" />
                  </span>
                  <span className="font-[600] text-primary-300">+{ac?.additions}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-[14px] text-gray-700">
                    <FormattedMessage id="dashboard.daily.deletions" />
                  </span>
                  <span className="font-[600] text-red-500">-{ac?.deletions}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex mt-[32px]">
        <div className="w-2/3 mr-[32px] ">
          <div className="flex justify-between">
            <div className="h-[216px] w-[calc(50%-16px)] rounded-[32px] bg-white p-8">
              <span className="font-[600] text-[24px] leading-[36px]">
                <FormattedMessage id="dashboard.class" />
              </span>
              <div className="flex flex-col gap-y-3 pt-6">
                <div>
                  <div className="flex justify-between mb-1">
                    <span className="font-[600] text-[14px]">
                      <FormattedMessage id="dashboard.class.total" />
                    </span>
                    <span className="">
                      {dashboardInfo?.group?.group_is_active}
                      {!!dashboardInfo?.group?.group_limit && `/${dashboardInfo?.group?.group_limit}`}
                    </span>
                  </div>
                  <ProgressBar
                    value={
                      dashboardInfo?.group?.group_is_active && dashboardInfo?.group?.group_is_active > 0
                        ? dashboardInfo?.group?.group_limit
                          ? ((dashboardInfo?.group?.group_is_active || 0) * 100) / dashboardInfo?.group?.group_limit
                          : 100
                        : 0
                    }
                    showValue={false}
                    pt={{ value: { className: "bg-primary-300" } }}
                    className="h-[12px]"
                  ></ProgressBar>
                </div>
                <div className="flex justify-between mt-[12px]">
                  <span className="font-[600] text-[14px]">
                    <FormattedMessage id="dashboard.class.available" />
                  </span>
                  <span>{dashboardInfo?.group?.group_available || 0}</span>
                </div>
              </div>
            </div>
            <div className="h-[216px] w-[calc(50%-16px)] rounded-[32px] bg-white p-8">
              <span className="font-[600] text-[24px] leading-[36px]">
                <FormattedMessage id="dashboard.student" />
              </span>
              <div className="flex flex-col gap-y-3 pt-6">
                <div>
                  <div className="flex justify-between mb-1">
                    <span className="font-[600] text-[14px]">
                      <FormattedMessage id="dashboard.student.total" />
                    </span>
                    <span className="">
                      {dashboardInfo?.student?.student_available}
                      {!!dashboardInfo?.student?.student_limit && `${dashboardInfo?.student?.student_limit}`}
                    </span>
                  </div>
                  <ProgressBar
                    value={
                      dashboardInfo?.student?.student_available && dashboardInfo?.student?.student_available > 0
                        ? dashboardInfo?.student?.student_limit
                          ? ((dashboardInfo?.student?.student_available || 0) * 100) /
                            dashboardInfo?.student?.student_limit
                          : 100
                        : 0
                    }
                    showValue={false}
                    pt={{ value: { className: "bg-primary-300" } }}
                    className="h-[12px]"
                  ></ProgressBar>
                </div>
                <div className="flex justify-between mt-[12px]">
                  <span className="font-[600] text-[14px]">
                    <FormattedMessage id="dashboard.student.noGroup" />
                  </span>
                  <span>{dashboardInfo?.student?.student_out_of_group || 0}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="h-[305px] w-full mt-[32px] flex justify-between">
            <div className="h-full w-[calc(33.3%-21.3px)] rounded-[32px] bg-white py-[51.5px] flex flex-col items-center">
              <span className="flex font-[600] mb-5">
                <FormattedMessage id="dashboard.folderOfMe" />
              </span>
              <div className="flex relative mt-6">
                <KnobProgress
                  value={
                    dashboardInfo?.document?.mine?.folders_available &&
                    dashboardInfo?.document?.mine?.folders_available > 0
                      ? dashboardInfo?.document?.mine?.folders_limit
                        ? ((dashboardInfo?.document?.mine?.folders_available || 0) /
                            dashboardInfo?.document?.mine?.folders_limit) *
                          100
                        : 100
                      : 0
                  }
                  text=""
                  pathColor="#63FF1A"
                  shadowColor="#63FF1A75"
                  className="w-[124px] h-[124px]"
                  rotation={-0.3}
                  trailColor="#F2F4F7"
                />
                <div className="absolute top-[30%] left-[33%]">
                  <FolderIcon2 />
                </div>
              </div>
              <span className="font-[600] mt-[-10px]">
                {dashboardInfo?.document?.mine?.folders_available || 0}
                {dashboardInfo?.document?.mine?.folders_limit && `/${dashboardInfo?.document?.mine?.folders_limit}`}
              </span>
            </div>
            <div className="h-full w-[calc(33.3%-21.3px)] rounded-[32px] bg-white py-[51.5px] flex flex-col items-center">
              <span className="flex font-[600] mb-5">
                <FormattedMessage id="dashboard.examOfMe" />
              </span>
              <div className="flex relative mt-6">
                <KnobProgress
                  value={
                    dashboardInfo?.document?.mine?.files_available && dashboardInfo?.document?.mine?.files_available > 0
                      ? dashboardInfo?.document?.mine?.files_limit
                        ? ((dashboardInfo?.document?.mine?.files_available || 0) /
                            dashboardInfo?.document?.mine?.files_limit) *
                          100
                        : 100
                      : 0
                  }
                  text=""
                  pathColor="#B455FF"
                  className="w-[124px] h-[124px]"
                  rotation={-0.3}
                  shadowColor="#B455FF75"
                  trailColor="#F2F4F7"
                />
                <div className="absolute top-[30%] left-[33%]">
                  <DashboardFileCheck />
                </div>
              </div>
              <span className="font-[600] mt-[-10px]">
                {dashboardInfo?.document?.mine?.files_available || 0}
                {dashboardInfo?.document?.mine?.files_limit && `/${dashboardInfo?.document?.mine?.files_limit}`}
              </span>
            </div>
            <div className="h-full w-[calc(33.3%-21.3px)] rounded-[32px] bg-white py-[51.5px] flex flex-col items-center">
              <span className="flex font-[600] mb-5">
                <FormattedMessage id="dashboard.documentShared" />
              </span>
              <div className="folder-tooltip">
                <div className="flex relative mt-6">
                  <KnobProgress
                    value={
                      dashboardInfo?.document?.shared?.files_available &&
                      dashboardInfo?.document?.shared?.files_available > 0
                        ? 100
                        : 0
                    }
                    text=""
                    pathColor="#0596FF"
                    className="w-[124px] h-[124px]"
                    rotation={-0.3}
                    shadowColor="#0596FF75"
                    trailColor="#F2F4F7"
                  />
                  <div className="absolute top-[30%] left-[33%]">
                    <DashboardShared />
                  </div>
                </div>
                <span className="font-[600] mt-[-10px] flex justify-center">
                  {dashboardInfo?.document?.shared?.files_available}
                </span>
              </div>
              <Tooltip target=".folder-tooltip">
                <div className="flex flex-col">
                  <span className="font-[400] text-[14px]">
                    <FormattedMessage id="dashboard.numOfFolder" /> {dashboardInfo?.document?.shared?.folders_available}
                  </span>
                  <span className="font-[400] text-[14px]">
                    <FormattedMessage id="dashboard.numOfFile" /> {dashboardInfo?.document?.shared?.files_available}
                  </span>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="w-[calc(33%-32px)] rounded-[32px] bg-white p-8">
          <span className="font-[600] text-[24px] leading-[36px]">
            <FormattedMessage id="dashboard.room" />
          </span>
          <div className="flex justify-center py-16">
            <DashboardRoomProgress
              value2={
                dashboardInfo?.room?.total
                  ? ((dashboardInfo?.room?.room_not_finish || 0) * 100) / dashboardInfo?.room?.total
                  : 0
              }
              value1={
                dashboardInfo?.room?.total
                  ? ((dashboardInfo?.room?.room_not_group || 0) * 100) / dashboardInfo?.room?.total
                  : 0
              }
            />
          </div>
          <div className="flex items-center gap-2 py-2">
            <RoomProgressTotalIcon />
            <span>
              <FormattedMessage id="dashboard.room.total" /> <b>{dashboardInfo?.room?.total}</b>
            </span>
          </div>
          <div className="flex items-center gap-2 py-2">
            <RoomProgressNoGroupIcon />
            <span>
              <FormattedMessage id="dashboard.room.noClass" /> <b>{dashboardInfo?.room?.room_not_group}</b>
            </span>
          </div>
          <div className="flex items-center gap-2 py-2">
            <RoomProgressNoLockedIcon />
            <span>
              <FormattedMessage id="dashboard.room.noLocked" /> <b>{dashboardInfo?.room?.room_not_finish}</b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
