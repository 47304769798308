import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Edit2Icon, ShareIcon, TrashIcon } from "../../assets";
import ButtonBase from "../../components/common/components/button/ButtonBase";
import ButtonGhost from "../../components/common/components/button/ButtonGhost";
import ButtonPrimary from "../../components/common/components/button/ButtonPrimary";
import AddOrEditRoomDialog from "../../components/common/components/dialog/AddOrEditRoomDialog";
import confirmDialog from "../../components/common/components/dialog/confirmDialog";
import { commonActions } from "../../components/common/redux/action";
import { genSignature, getNonce } from "../../components/common/utils/helper";
import AddMemberDialog from "../../components/manage_room/components/AddMemberDialog";
import MemberAndRanking from "../../components/manage_room/components/MemberAndRanking";
import RoomInfomation from "../../components/manage_room/components/RoomInfomation";
import { roomActions } from "../../components/manage_room/redux/action";
import { ERROR_CODE_NOT_FOUND_ROOM } from "../../components/manage_room/utils/constant";
import { eventTracking } from "../../firebase/firebaseConfig";

export default function RoomDetail() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const { roomId } = useParams();

  const { userInfos } = useSelector((state: { common: any }) => state.common);
  const { roomDetail, members, isReloadDetail } = useSelector((state: { room: any }) => state.room);
  const [isOpenAddOrEditRoomDialog, setIsOpenAddOrEditRoomDialog] = useState<boolean>(false);
  const [isOpenAddMemberDialog, setIsOpenAddMemberDialog] = useState<boolean>(false);

  const startTimeBeforeFiveMinute = moment.unix(roomDetail?.room_info?.start_time - 1).subtract(5, "minutes");
  const checkShowRemoveAndUpdateRoom = () => {
    return moment().unix() - startTimeBeforeFiveMinute.unix() <= 0;
  };
  const checkShowBtnAddMember = useMemo(() => {
    const isHideBtn =
      roomDetail?.room_info?.num_of_members >= roomDetail?.room_info?.max_slot || roomDetail?.room_info?.is_locked;

    if (isHideBtn) {
      return false;
    }
    if (roomDetail?.room_info?.room_type?.type === "simultaneously") {
      return moment().diff(moment.unix(roomDetail?.room_info?.start_time)) <= 0;
    }
    return (
      moment().diff(
        moment.unix(roomDetail?.room_info?.end_time).subtract((1 / 3) * roomDetail?.room_info?.time, "minutes")
      ) <= 0
    );
  }, [roomDetail]);

  const getMemberAndRankingMode = () => {
    return moment().unix() - startTimeBeforeFiveMinute.unix() <= 0 ? "member" : "ranking";
  };

  const handleClickEditRoom = () => {
    dispatch(
      roomActions.getRoomDetail(roomId, (roomDetail) => {
        if (roomDetail.room_info?.edited_times > 0) {
          toast.error(intl.formatMessage({ id: "errorMessage.thisRoomWasEdited" }));
        } else {
          setIsOpenAddOrEditRoomDialog(true);
        }
      })
    );
  };

  const handleClickDeleteRoom = () => {
    confirmDialog({
      header: intl.formatMessage({ id: "confirmDeleteRoomDialog.title" }),
      message: intl.formatMessage({
        id: "confirmDeleteRoomDialog.description",
      }),
      accept: () => {
        const params = {
          timestamp: moment().unix(),
          nonce: getNonce(),
          room_hid: roomDetail?.room_info?.room_hid,
        };
        const dataPost = {
          ...params,
          signature: genSignature(params),
        };
        eventTracking("select_delete_room");
        dispatch(
          roomActions.deleteRoom(
            dataPost,
            () => navigate("/quan-ly-phong-thi"),
            () => {
              dispatch(
                roomActions.getMemberOfRoom({
                  roomHid: roomId,
                })
              );
              dispatch(roomActions.getRoomDetail(roomId));
            }
          )
        );
      },
      reject: () => {},
      footer: (options) => {
        return (
          <div className="flex justify-end gap-4">
            <ButtonPrimary label={intl.formatMessage({ id: "common.cancel" })} onClick={options.reject} />
            <ButtonGhost label={intl.formatMessage({ id: "common.delete" })} onClick={options.accept} />
          </div>
        );
      },
    });
  };

  const handleSubmitAddMemberDialog = (formValues) => {
    eventTracking("select_add_member");
    const dataParams = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      member_hid: formValues.memberId.trim(),
    };
    const dataPost = {
      ...dataParams,
      signature: genSignature(dataParams),
      room_hid: roomId,
    };
    dispatch(
      roomActions.addMemberOfRoom(
        dataPost,
        () => {
          setIsOpenAddMemberDialog(false);
          dispatch(
            roomActions.getMemberOfRoom({
              roomHid: roomId,
            })
          );
        },
        () => {
          setIsOpenAddMemberDialog(false);
          dispatch(roomActions.getRoomDetail(roomId));
        }
      )
    );
  };

  const handleDeleteMember = (memberId) => {
    confirmDialog({
      header: intl.formatMessage({ id: "confirmDialog.title" }),
      message: intl.formatMessage({
        id: "confirmDialog.deleteMemberDescription",
      }),
      accept: () => {
        eventTracking("select_dr_remove_member");
        const dataPre = {
          timestamp: moment().unix(),
          nonce: getNonce(),
          member_hid: memberId,
        };
        const dataPost = {
          ...dataPre,
          signature: genSignature(dataPre),
          room_hid: roomId,
        };
        dispatch(
          roomActions.deleteMemberOfRoom(
            dataPost,
            () =>
              dispatch(
                roomActions.getMemberOfRoom({
                  roomHid: roomId,
                })
              ),
            () => {
              dispatch(
                roomActions.getMemberOfRoom({
                  roomHid: roomId,
                })
              );
            }
          )
        );
      },
      reject: () => {},
      footer: (options) => {
        return (
          <div className="flex justify-end gap-4">
            <ButtonPrimary label={intl.formatMessage({ id: "common.cancel" })} onClick={options.reject} />
            <ButtonGhost label={intl.formatMessage({ id: "common.delete" })} onClick={options.accept} />
          </div>
        );
      },
    });
  };

  useEffect(() => {
    if (!!userInfos?.member) {
      dispatch(
        roomActions.getRoomDetail(
          roomId,
          () => {
            dispatch(
              roomActions.getMemberOfRoom({
                roomHid: roomId,
              })
            );
          },
          (errorCode) => {
            switch (errorCode) {
              case ERROR_CODE_NOT_FOUND_ROOM: {
                navigate(-1);
                break;
              }
              default:
                break;
            }
          }
        )
      );

      dispatch(commonActions.getRoomCreationInfo());
      dispatch(commonActions.getAllFolderAndFile());
    }
  }, []);

  // set timeout start_time - 5p thi reload component de update button add, edit, delete,...
  useEffect(() => {
    const startTimeBefore5Minutes = moment
      .unix(roomDetail?.room_info?.start_time)
      .subtract(5, "minutes")
      .diff(moment(), "seconds");
    if (!startTimeBefore5Minutes || startTimeBefore5Minutes <= 0 || !roomDetail) return;
    const timer = setTimeout(() => {
      dispatch(roomActions.getRoomDetail(roomId));
    }, startTimeBefore5Minutes * 1000);
    return () => clearTimeout(timer);
  }, [roomDetail?.room_info?.start_time]);

  useEffect(() => {
    if (isReloadDetail) {
      dispatch(roomActions.getRoomDetail(roomId));
    }
  }, [isReloadDetail]);

  useEffect(() => {
    eventTracking("screen_detail_room_view");
  }, []);

  return (
    <div className="main-content">
      <div className="flex justify-between items-center px-10 py-6 border-b border-gray-200">
        <span className="text-[24px] font-semibold text-gray-900 ">{roomDetail?.room_info?.title}</span>
        <div className="flex gap-6">
          <CopyToClipboard
            text={roomDetail?.room_info?.direct_link}
            onCopy={() => {
              eventTracking("select_dr_click_share_button");
              toast.success(intl.formatMessage({ id: "common.copiedSuccessfully" }));
            }}
          >
            <ButtonBase
              label={intl.formatMessage({ id: "common.share" })}
              icon={<ShareIcon className="w-[20px] h-[20px]" />}
              className="bg-gray-100 text-gray-900 border-none"
            />
          </CopyToClipboard>
          {checkShowRemoveAndUpdateRoom() && (
            <>
              {roomDetail.room_info?.edited_times === 0 && (
                <ButtonBase
                  className="border-none bg-gray-100 text-gray-900"
                  icon={<Edit2Icon className="w-[20px] h-[20px]" />}
                  label={intl.formatMessage({ id: "roomDetail.editRoom" })}
                  onClick={() => {
                    eventTracking("select_dr_click_edit_button");
                    handleClickEditRoom();
                  }}
                />
              )}
              {roomDetail.room_info?.num_of_members === 1 && (
                <ButtonBase
                  className="border-none bg-gray-100 text-error"
                  icon={<TrashIcon />}
                  label={intl.formatMessage({ id: "roomDetail.deleteRoom" })}
                  onClick={() => {
                    eventTracking("select_dr_click_delete_button");
                    handleClickDeleteRoom();
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex">
        <RoomInfomation roomInfo={roomDetail?.room_info} />
        <MemberAndRanking
          mode={getMemberAndRankingMode()}
          highScores={roomDetail.high_scores}
          roomInfo={roomDetail?.room_info}
          members={members}
          isShowAddMemberButton={checkShowBtnAddMember}
          onClickAddMember={() => {
            eventTracking("select_dr_click_add_button");
            setIsOpenAddMemberDialog(true);
          }}
          onDeleteMember={handleDeleteMember}
        />
      </div>
      {isOpenAddMemberDialog && (
        <AddMemberDialog
          isOpen={isOpenAddMemberDialog}
          onClose={() => setIsOpenAddMemberDialog(false)}
          onSubmit={handleSubmitAddMemberDialog}
        />
      )}
      {isOpenAddOrEditRoomDialog && (
        <AddOrEditRoomDialog
          isOpen={isOpenAddOrEditRoomDialog}
          roomDetail={roomDetail}
          onClose={() => setIsOpenAddOrEditRoomDialog(false)}
        />
      )}
    </div>
  );
}
