import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import { TrashIcon } from "../../../assets";
import Paging from "../../common/components/Paging";
import { COLUMN_KEY_MEMBERS_TABLE } from "../utils/constant";

const ITEMS_PER_PAGE = 15;

type TMembersTableProps = {
  members: any[];
  columnKeysDisplay: string[];
  onClickViewMember: (memberHid: string) => void;
  onClickDeleteMember?: (memberHid: string) => void;
};

export default function MembersTable({
  members,
  columnKeysDisplay,
  onClickViewMember,
  onClickDeleteMember,
}: TMembersTableProps) {
  const intl = useIntl();

  const { hideLeftSide } = useSelector((state: { common }) => state?.common);

  const [page, setPage] = useState<number>(1);

  const maxPage = useMemo(() => {
    return Math.ceil(members.length / ITEMS_PER_PAGE);
  }, [members]);

  const isShowPaging = maxPage > 1;

  useUpdateEffect(() => {
    if (page > maxPage && maxPage > 0) {
      setPage(maxPage);
    }
  }, [maxPage]);

  useEffect(() => {
    document.querySelector(".main-content")?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  return (
    <div className={`px-10 py-8 ${isShowPaging && "pb-[108px]"}`}>
      <DataTable
        value={members.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)}
        showGridlines
        onRowClick={(e) => onClickViewMember(e.data.member_hid)}
      >
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.NO)}
          header={intl.formatMessage({ id: "memberTable.column.no" })}
          body={(_, options) => options.rowIndex + 1 + (page - 1) * ITEMS_PER_PAGE}
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          className="w-0"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.NAME)}
          header={intl.formatMessage({ id: "memberTable.column.name" })}
          field="name"
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-left p-2.5" },
          }}
          className="w-[40%]"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.MEMBER_HID)}
          header={intl.formatMessage({ id: "memberTable.column.memberHid" })}
          field="member_hid"
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          className="w-[20%]"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.AVG_SCORE)}
          header={intl.formatMessage({ id: "memberTable.column.scoreAvg" })}
          body={(data) => Number(data.avg_score).toFixed(2)}
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          className="w-[10%]"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.SCORE_AVG)}
          header={intl.formatMessage({ id: "memberTable.column.scoreAvg" })}
          body={(data) => Number(data.score_avg).toFixed(2)}
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          className="w-[10%]"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.NUM_OF_CLASS_JOIN)}
          header={intl.formatMessage({ id: "memberTable.column.numOfJoinedClass" })}
          field="num_of_class_join"
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          className="w-[10%]"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.RANKING)}
          header={intl.formatMessage({ id: "memberTable.column.ranking" })}
          field="ranking"
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          className="w-[10%]"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.NUM_OF_ROOM_JOIN)}
          header={intl.formatMessage({ id: "memberTable.column.numOfRoomSubmitted" })}
          field="num_of_room_join"
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          className="w-[10%]"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.NUM_OF_ROOM_SUBMITTED)}
          header={intl.formatMessage({ id: "memberTable.column.numOfRoomSubmitted" })}
          field="num_of_room_submitted"
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          className="w-[10%]"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBERS_TABLE.DELETE)}
          header={intl.formatMessage({ id: "memberTable.column.delete" })}
          body={(data, options) => (
            <Button
              className="focus:shadow-none"
              rounded
              text
              icon={<TrashIcon />}
              onClick={(e) => {
                e.stopPropagation();
                onClickDeleteMember?.(data.member_hid);
              }}
            />
          )}
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-center p-0" },
          }}
          className="w-0"
        />
      </DataTable>
      {isShowPaging && (
        <div
          className={`fixed bottom-0 right-0 flex justify-end ${
            hideLeftSide ? "w-[calc(100%-82px)]" : "w-[calc(100%-312px)]"
          } bg-white border border-t-gray-200 px-10 py-4`}
        >
          <div className="w-[312px]">
            <Paging page={page} setPage={setPage} maxPage={maxPage} />
          </div>
        </div>
      )}
    </div>
  );
}
