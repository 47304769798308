import React from "react";
import moment from "moment";
import { IntlProvider } from "react-intl";
// import { connect } from "react-redux";
import "moment/locale/vi";
import vi from "../intl/vi.json";
import en from "../intl/en.json";

moment.locale("vi");

function Locales({ children }) {
  const locale = "vi";
  const localeIntl = {
    locale,
    messages: locale === "en" ? en : vi,
  };
  moment.locale(locale);
  return <IntlProvider {...localeIntl}>{children}</IntlProvider>;
}
export default Locales;

// export default connect(
//   (state) => ({
//     locale: state.system.locale,
//   }),
//   null,
// )(Locales);
