import React from "react";

type ConstructedResponseCardProps = {
  qs: any;
};

const ConstructedResponseCard = ({ qs }: ConstructedResponseCardProps) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-gray-900 font-semibold">Trả lời</p>
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: qs.description_answer_before,
          }}
          className="[&>p]:inline"
        />
        &nbsp;
        <span className="inline-block rounded-[8px] p-2 my-1 border border-gray-200">
          &nbsp;
          {qs.options[0].contents[0].content}
          &nbsp;
        </span>
        &nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: qs.description_answer_after,
          }}
          className="[&>p]:inline"
        />
      </div>
    </div>
  );
};

export default ConstructedResponseCard;
