import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ButtonGhost from "../../common/components/button/ButtonGhost";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import Dialog from "../../common/components/dialog/Dialog";
import { commonActions } from "../../common/redux/action";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { trackingEventNameByExamType } from "../../common/utils/constant";

const CREATE_EXAM_CHOOSES = [
  {
    value: "dialogCreateExam.nhbd",
    href: "/tao-de/ngan-hang-bo-de",
  },
  {
    value: "dialogCreateExam.nhch",
    href: "/tao-de/ngan-hang-cau-hoi",
  },
  {
    value: "dialogCreateExam.pdf",
    href: "/tao-de/pdf",
  },
  {
    value: "dialogCreateExam.docx",
    href: "/tao-de/docx",
  },
  {
    value: "dialogCreateExam.selfInput",
    href: "/tao-de/tu-nhap",
  },
];

export default function DialogChooseTypeAddExam({ isOpen, onClose }) {
  const [choose, setChoose] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paramsRouter = useParams();

  const intl = useIntl();

  const actions = (
    <div className="flex justify-end gap-4">
      <ButtonGhost label={intl.formatMessage({ id: "common.cancel" })} onClick={onClose} />
      <ButtonPrimary
        label={intl.formatMessage({ id: "common.next" })}
        onClick={() => {
          eventTracking(trackingEventNameByExamType[choose]);
          navigate(CREATE_EXAM_CHOOSES.find((e) => e.value === choose)?.href as string);
          dispatch(
            commonActions.handleSaveFolderCreateExam(paramsRouter?.folderId ? Number(paramsRouter?.folderId) : null)
          );
          onClose();
        }}
        disabled={!choose}
      />
    </div>
  );

  useEffect(() => {
    eventTracking("dl_select_input_type_view");
  }, []);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({ id: "dialogCreateExam.title" })}
      actions={actions}
    >
      {CREATE_EXAM_CHOOSES.map((e) => (
        <div key={e.value} className="flex items-center py-4">
          <RadioButton
            inputId={e.value}
            value={e.value}
            onChange={(e) => setChoose(e.value)}
            checked={choose === e.value}
            disabled={!e.href}
          />
          <label htmlFor={e.value} className={`ml-2 ${!e.href ? "text-gray-400" : "text-gray-900"}`}>
            {intl.formatMessage({ id: e.value })} {!e.href && intl.formatMessage({ id: "dialogCreateExam.developing" })}
          </label>
        </div>
      ))}
    </Dialog>
  );
}
