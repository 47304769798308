import { ProgressSpinner } from "primereact/progressspinner";
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { roomActions } from "../../components/manage_room/redux/action";
import QuestionsGroupExam from "../../components/manage_exam/components/QuestionsGroupExam";
import { ERROR_CODE_NOT_FOUND_ROOM } from "../../components/manage_room/utils/constant";
import { commonActions } from "../../components/common/redux/action";

export default function ExamDetail() {
  const dispatch = useDispatch();
  const routerParams = useParams();
  const navigate = useNavigate();

  const { userInfos } = useSelector((state: { common }) => state.common);
  const { examDetail } = useSelector((state: { room }) => state.room);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!!routerParams?.roomId && routerParams?.examCode && !!userInfos?.member) {
      setIsLoading(true);

      dispatch(
        roomActions.getRoomDetail(
          routerParams?.roomId,
          () => {
            dispatch(
              roomActions.getExamDetailOfRoom(
                {
                  room_hid: routerParams?.roomId,
                  exam_code: routerParams?.examCode,
                },
                undefined,
                (errorCode) => {
                  switch (errorCode) {
                    default:
                      navigate(-1);
                      break;
                  }
                },
                () => {
                  setIsLoading(false);
                }
              )
            );
          },
          (errorCode) => {
            switch (errorCode) {
              case ERROR_CODE_NOT_FOUND_ROOM: {
                navigate(-1);
                break;
              }
              default:
                break;
            }
            setIsLoading(false);
          }
        )
      );

      dispatch(commonActions.getAllFolderAndFile());
    }
  }, []);

  return isLoading ? (
    <div className="flex justify-center items-center w-full h-full pt-[200px]">
      <ProgressSpinner
        style={{ width: "80px", height: "80px" }}
        pt={{
          circle: {
            className: "stroke-primary",
          },
        }}
      />
    </div>
  ) : (
    <div className="main-content">
      <QuestionsGroupExam
        questions={examDetail.exam_code_data}
        solutions={[]}
        isPreview={true}
        isShowSolution={false}
        isShowBorder={false}
      />
    </div>
  );
}
