import moment from "moment";
import { Image } from "primereact/image";
import React, { useEffect, useMemo, useState } from "react";
import { ArrowDownIcon, Edit2Icon } from "../../assets";
import { MathJax } from "better-react-mathjax";
import ShowBasicDataContent from "../manage_exam/components/ShowBasicDataContent";
import { convertAnswerKey } from "../common/utils/constant";
import { Dropdown } from "primereact/dropdown";
import DialogEditExam from "../manage_exam/components/DialogEditExam";
import DialogTextEditor from "../common/components/dialog/DialogTextEditor";
import ButtonPrimary from "../common/components/button/ButtonPrimary";
import ButtonSecondary from "../common/components/button/ButtonSecondary";
import { useNavigate } from "react-router-dom";
import { isEmpty, isEqual } from "lodash";
import { genSignature, getFormData, getNonce } from "../common/utils/helper";
import { useDispatch } from "react-redux";
import { commonActions } from "../common/redux/action";
import { FormattedMessage, useIntl } from "react-intl";

import OneAnswerCard from "../manage_exam/components/QuizComponents/OneAnswerCard";

export default function ReportQuestionDetail({
  question,
  handleCloseReport,
  isDone = false,
  handleGetReportReview = () => {},
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [questionInfo, setQuestionInfo] = useState<any>();
  const [openEditorSolution, setOpenEditorSolution] = useState<boolean>(false);
  const [openDialogEditExam, setOpenDialogEditExam] = useState({ open: false, tabIndex: 0 });

  const handleUpdateQuestionContent = (data) => {
    setQuestionInfo({
      ...questionInfo,
      contents: [
        { ...questionInfo?.questions[0], content: data?.find((e) => e?.ordinal === -1)?.content, edited: true },
      ],
      options: questionInfo?.options?.map((op) => ({
        ...op,
        contents: [
          { ...op?.contents[0], content: data?.find((e) => e?.ordinal === op?.ordinal)?.content, edited: true },
        ],
      })),
    });
  };

  const handleUpdateCorrectAnswer = (val) => {
    const defaultCorrectOptionId = questionInfo?.options?.find((op) => op?.correct === 1)?.id;
    const baseOption = (op) => ({
      id: op?.id,
      correct: op?.correct,
      ordinal: op?.ordinal,
      contents: op?.contents,
      answer_key: op?.answer_key,
    });
    setQuestionInfo({
      ...questionInfo,
      options: questionInfo?.options?.map((op) =>
        val === op?.answer_key
          ? !!op?.id
            ? { ...baseOption(op), correct: 1, edited: true }
            : { ...baseOption(op), correct: 1 }
          : !!op?.id && defaultCorrectOptionId === op?.id
          ? { ...baseOption(op), correct: 0, edited: true }
          : { ...baseOption(op), correct: 0 }
      ),
    });
  };
  const solutions =
    questionInfo?.editedSolutions ||
    questionInfo?.solutions?.items?.detail ||
    questionInfo?.solutions?.items?.suggestion;

  const handleEditSolution = (solution) => {
    const oldSolution = solutions?.find((e: any) => e.content_type === "html");
    if ((!oldSolution?.content || oldSolution?.content?.length === 0) && solution?.length === 0) return;
    const newSolution = {
      ordinal: 1,
      content: solution,
      content_type: "html",
    };
    setQuestionInfo({
      ...questionInfo,
      editedSolutions: [
        !solution || solution?.length === 0
          ? { ...newSolution, id: oldSolution?.id, deleted: true }
          : oldSolution?.id
          ? {
              ...newSolution,
              id: oldSolution?.id,
              edited: true,
            }
          : newSolution,
      ],
    });
  };

  const checkIsEditQuestion = useMemo(() => {
    const oldQuestion = question?.latestQuestion ? question?.latestQuestion : question;
    return (
      !isEqual(questionInfo?.contents, oldQuestion?.questions) ||
      !isEqual(
        questionInfo?.options?.map((op) => [op?.correct, op?.contents[0]?.content]),
        oldQuestion?.options?.map((op) => [op?.correct, op?.items[0]?.content])
      )
    );
  }, [questionInfo]);

  const checkIsEditSolution = useMemo(() => {
    if (!question?.solutions?.items?.detail || !solutions) return false;
    if (isEmpty(question?.solutions?.items?.detail[0]?.content) && isEmpty(solutions[0]?.content)) return false;
    return !isEqual(question?.solutions?.items?.detail[0]?.content, solutions[0]?.content);
  }, [solutions]);

  const handleSubmit = (callback = () => {}, isShowToast = true) => {
    let dataPost: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      question_id: questionInfo?.id,
    };
    dataPost = getFormData({
      ...dataPost,
      signature: genSignature(dataPost),
      json_data: JSON.stringify({
        ...questionInfo,
        options: questionInfo?.options,
        questions: [questionInfo?.contents],
        solutions: solutions,
      }),
    });
    dispatch(
      commonActions.handleEditQuestionReport(
        { data: dataPost, question_id: questionInfo?.id, isShowToast: isShowToast },
        () => {
          handleGetReportReview();
          callback();
          navigate("/xu-ly-khieu-nai/cho-xu-ly");
        }
      )
    );
  };

  useEffect(() => {
    if (question?.latestQuestion) {
      setQuestionInfo({
        ...question?.latestQuestion,
        questionReport: {
          contents: question?.contents,
          options: question?.options,
        },
      });
    } else {
      setQuestionInfo(question);
    }
  }, [question]);

  return (
    <div className="main-content flex flex-row">
      <div className="w-[calc(100%-312px)] pl-10  py-8 pr-6 flex flex-col gap-6 h-max border-r">
        {/* <div className="w-full relative">
          <small className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">Lời dẫn</small>
          <div className="w-full border border-gray-200 rounded-[8px] py-4 pl-4 pr-7 min-h-[90px]">
            <MathJax>{questionInfo?.parents?.map((e) => <ShowBasicDataContent ele={e} />)}</MathJax>
          </div>
          {!isDone && (
            <button>
              <Edit2Icon className="absolute right-2 top-3" />
            </button>
          )}
        </div> */}
        {questionInfo?.questionReport && (
          <MathJax dynamic>
            <div className="">
              <span className="font-bold">Nội dung bị báo cáo: </span>

              <span className="font-semibold inline-block">
                {questionInfo?.questionReport?.contents?.map((ele: any) => (
                  <ShowBasicDataContent key={ele.id} ele={ele} />
                ))}
              </span>
            </div>
            <OneAnswerCard options={questionInfo?.questionReport?.options} />
          </MathJax>
        )}
        {questionInfo?.questionReport && <span className="font-bold">Nội dung hiện tại: </span>}
        <div className="w-full relative">
          <small className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            Câu hỏi <span className="text-error">*</span>
          </small>
          <div className="w-full border border-gray-200 rounded-[8px] py-4 pl-4 pr-9 min-h-[90px]">
            <MathJax dynamic>{questionInfo?.contents?.map((e) => <ShowBasicDataContent ele={e} />)}</MathJax>
          </div>
          {!isDone && (
            <button
              onClick={() => {
                setOpenDialogEditExam({ open: true, tabIndex: 0 });
              }}
            >
              <Edit2Icon className="absolute right-2 top-3" />
            </button>
          )}
        </div>
        <div className="flex flex-col gap-3 w-full">
          {questionInfo?.options?.map((op, ix) => (
            <div key={op?.id} className="flex w-full font-[400] relative">
              <div className="w-[48px] h-[48px] rounded-[8px] mr-4 bg-gray-200 flex justify-center items-center">
                {convertAnswerKey(ix)}
              </div>
              <div className="w-full py-3 border rounded-[8px] flex items-center pl-3 pr-9">
                <MathJax dynamic>
                  {op?.contents?.map((opi) => <ShowBasicDataContent key={opi?.id} ele={opi} />)}
                </MathJax>
              </div>
              {!isDone && (
                <button
                  onClick={() => {
                    setOpenDialogEditExam({ open: true, tabIndex: ix + 1 });
                  }}
                >
                  <Edit2Icon className="absolute right-2 top-3" />
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="flex items-center gap-[49px]">
          <span className="font-bold pl-4">Đáp án đúng</span>
          {isDone ? (
            <div className="h-[40px] flex justify-center border items-center rounded-[16px] w-[90px] font-bold bg-primary-50 text-gray-700">
              {questionInfo?.options?.find((e) => e?.correct === 1)?.answer_key}
            </div>
          ) : (
            <Dropdown
              value={questionInfo?.options?.find((e) => e?.correct === 1)?.answer_key}
              options={questionInfo?.options?.map((e) => e?.answer_key)}
              className="h-[40px] pl-1 items-center rounded-[16px] w-[90px] font-bold bg-primary-50"
              onChange={(e) => handleUpdateCorrectAnswer(e?.value)}
              dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            />
          )}
        </div>
        <div className="w-full relative">
          <small className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            Hướng dẫn giải
          </small>
          <div className="w-full border border-gray-200 rounded-[8px] py-4 pl-4 pr-9 min-h-[90px]">
            <MathJax>
              <ShowBasicDataContent ele={solutions ? solutions[0] : {}} />
            </MathJax>
          </div>
          {!isDone && (
            <button onClick={() => setOpenEditorSolution(true)}>
              <Edit2Icon className="absolute right-2 top-3" />
            </button>
          )}
        </div>
        {!isDone && (
          <div className="gap-6 flex justify-end">
            <ButtonSecondary text className="font-bold w-[205px] border-none" onClick={() => setQuestionInfo(question)}>
              <FormattedMessage id="common.cancel" />
            </ButtonSecondary>

            <ButtonSecondary
              disabled={!checkIsEditQuestion && !checkIsEditSolution}
              className="font-bold w-[205px]"
              onClick={() => handleSubmit()}
            >
              <FormattedMessage id="common.update" />
            </ButtonSecondary>
            <ButtonPrimary
              disabled={!checkIsEditQuestion && !checkIsEditSolution}
              className="font-bold w-[205px]"
              onClick={() => handleSubmit(() => handleCloseReport(questionInfo?.quiz_report?.id), false)}
            >
              <FormattedMessage id="common.updateAndClose" />
            </ButtonPrimary>
          </div>
        )}
      </div>
      <div className="w-[312px] flex gap-8 py-8 flex-col pl-4 pr-10">
        <div className="font-bold flex justify-center">
          <FormattedMessage id="questionReview.detail.info" />
        </div>
        <div className="flex gap-4 flex-col">
          <span className="font-bold">
            <FormattedMessage id="questionReview.detail.reason" />
          </span>
          <div>{questionInfo?.quiz_report?.reason}</div>
          <div className="flex gap-2 w-full">
            {questionInfo?.quiz_report?.images?.map((e) => (
              <Image src={e} alt="" pt={{ image: { className: "h-[200px] w-[200px]" } }} preview key={e}></Image>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <span className="font-bold">
            <FormattedMessage id="questionReview.detail.account" />
          </span>
          <span>{questionInfo?.quiz_report?.report_user?.name}</span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="font-bold">
            <FormattedMessage id="questionReview.detail.grade" />
          </span>
          <span>{questionInfo?.quiz_report?.report_user?.group}</span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="font-bold">
            <FormattedMessage id="questionReview.detail.time" />
          </span>
          <span>{moment.unix(questionInfo?.quiz_report?.created_at).format("DD-MM-yyyy HH:mm")}</span>
        </div>
      </div>
      {openDialogEditExam?.open && (
        <DialogEditExam
          open={openDialogEditExam?.open}
          title={intl.formatMessage({ id: "questionReview.detail.questionTitle" })}
          onClose={() => setOpenDialogEditExam({ ...openDialogEditExam, open: false })}
          question={questionInfo}
          activeIndex={openDialogEditExam?.tabIndex}
          onSubmit={handleUpdateQuestionContent}
        />
      )}
      {openEditorSolution && (
        <DialogTextEditor
          open={openEditorSolution}
          onClose={() => setOpenEditorSolution(false)}
          onSubmit={(val: any) => {
            handleEditSolution(val);
          }}
          content={solutions[0]?.content}
          title={intl.formatMessage({ id: "questionReview.detail.solutionTitle" })}
        />
      )}
    </div>
  );
}
