export enum EExam {
  DOCX,
  PDF,
  NHBD,
  NHCH,
  SELFINPUT,
}

export const MODE: any = {
  PREVIEW: "preview",
  SELECT: "select",
  EDIT: "edit",
};

export const REPORT_SCREEN_TYPE = {
  PREVIEW: 1,
  DETAIL: 2,
};

export const DIALOG_TYPE = {
  CREATE: 0,
  EDIT: 2,
};

export const MAX_LENGTH_TITLE: number = 64;
export const MAX_LENGTH_DESCRIPTION_BEFORE_AFTER = 512;
export const MAX_LENGTH_CORRECT_OPTION = 251;

export const ANSWER_TYPE_OPTIONS = {
  SELECT_ANSWER: "SELECT_ANSWER" as const,
  ENTER_ANSWER: "ENTER_ANSWER" as const,
};
