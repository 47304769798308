import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import Paging from "../../common/components/Paging";
import { COLUMN_KEY_MEMBER_RESULT_TABLE } from "../utils/constant";

const ITEMS_PER_PAGE = 15;

type TMemberResultTableProps = {
  memberResult: any[];
  columnKeysDisplay: string[];
};

export default function MemberResultTable({ memberResult, columnKeysDisplay }: TMemberResultTableProps) {
  const intl = useIntl();

  const { hideLeftSide } = useSelector((state: { common }) => state?.common);

  const [page, setPage] = useState<number>(1);

  const maxPage = useMemo(() => {
    return Math.ceil(memberResult.length / ITEMS_PER_PAGE);
  }, [memberResult]);

  const isShowPaging = maxPage > 1;

  useUpdateEffect(() => {
    if (page > maxPage && maxPage > 0) {
      setPage(maxPage);
    }
  }, [maxPage]);

  useEffect(() => {
    document.querySelector(".main-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [page]);

  return (
    <div className={`px-10 py-8 ${isShowPaging && "pb-[108px]"}`}>
      <DataTable value={memberResult.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)} showGridlines>
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBER_RESULT_TABLE.NO)}
          header={intl.formatMessage({ id: "memberResultTable.column.no" })}
          body={(_, options) => options.rowIndex + 1 + (page - 1) * ITEMS_PER_PAGE}
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          className="w-0"
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBER_RESULT_TABLE.TITLE_ROOM)}
          header={intl.formatMessage({ id: "memberResultTable.column.title" })}
          field="title_room"
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-left p-2.5" },
          }}
          style={{
            width: `calc(100%/${columnKeysDisplay.length - 1})`,
          }}
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBER_RESULT_TABLE.TITLE)}
          header={intl.formatMessage({ id: "memberResultTable.column.title" })}
          field="title"
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-left p-2.5" },
          }}
          style={{
            width: `calc(100%/${columnKeysDisplay.length - 1})`,
          }}
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBER_RESULT_TABLE.TITLE_GROUP)}
          header={intl.formatMessage({ id: "memberResultTable.column.class" })}
          body={(data) => <span className="line-clamp-2">{data?.groups[0]?.title}</span>}
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          style={{
            width: `calc(100%/${columnKeysDisplay.length - 1})`,
          }}
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBER_RESULT_TABLE.SCORE)}
          header={intl.formatMessage({ id: "memberResultTable.column.score" })}
          body={(data) => Number(data.score).toFixed(2)}
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          style={{
            width: `calc(100%/${columnKeysDisplay.length - 1})`,
          }}
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBER_RESULT_TABLE.RANKING)}
          header={intl.formatMessage({ id: "memberResultTable.column.ranking" })}
          field="ranking"
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          style={{
            width: `calc(100%/${columnKeysDisplay.length - 1})`,
          }}
        />
        <Column
          hidden={!columnKeysDisplay.find((columnKey) => columnKey === COLUMN_KEY_MEMBER_RESULT_TABLE.SPENT_TIME)}
          header={intl.formatMessage(
            { id: "memberResultTable.column.spent_time" },
            {
              description: (
                <>
                  <br />
                  <span className="text-[14px] font-normal">(phút)</span>
                </>
              ),
            }
          )}
          body={(data) => Number(data.spent_time / 60).toFixed(2)}
          pt={{
            headerContent: {
              className: "flex justify-center text-center text-gray-900 font-semibold",
            },
            bodyCell: { className: "text-gray-900 text-[14px] text-center p-2.5" },
          }}
          style={{
            width: `calc(100%/${columnKeysDisplay.length - 1})`,
          }}
        />
      </DataTable>
      {isShowPaging && (
        <div
          className={`fixed bottom-0 right-0 flex justify-end ${
            hideLeftSide ? "w-[calc(100%-82px)]" : "w-[calc(100%-312px)]"
          } bg-white border border-t-gray-200 px-10 py-4`}
        >
          <div className="w-[312px]">
            <Paging page={page} setPage={setPage} maxPage={maxPage} />
          </div>
        </div>
      )}
    </div>
  );
}
