import { toast } from "react-toastify";
import { call, put, takeLatest, debounce } from "redux-saga/effects";
import { ACTION_TYPES } from "./actionTypes";
import { roomServices } from "./services";

function* createRoom(action) {
  try {
    const response = yield call(roomServices.createRoom, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback(response?.data?.data?.room_hid);
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error?.code);
  }
}

function* updateRoom(action) {
  try {
    const response = yield call(roomServices.updateRoom, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* getRoomDetail(action) {
  try {
    const response = yield call(roomServices.getRoomDetail, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_ROOM_DETAIL_SUCCESS,
        params: response?.data?.data,
      });
      action.successCallback(response?.data?.data);
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.errorCallback(error?.data?.error?.code);
  }
}

function* deleteRoom(action) {
  try {
    const response = yield call(roomServices.deleteRoom, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.failedCallBack();
  }
}

function* getMembersOfRoom(action) {
  try {
    const response = yield call(roomServices.getMembersOfRoom, action.params);
    if (response?.data) {
      yield put({
        type: ACTION_TYPES.GET_MEMBERS_OF_ROOM_SUCCESS,
        params: response?.data?.data?.members,
      });
    }
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* deleteMemberOfRoom(action) {
  try {
    const response = yield call(roomServices.deleteMemberOfRoom, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
      yield put({
        type: ACTION_TYPES.DELETE_MEMBER_OF_ROOM_SUCCESS,
        params: response?.data?.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.DELETE_MEMBER_OF_ROOM_FAILED,
      params: error?.data?.error?.message,
    });
    action.failedCallback();
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* addMemberOfRoom(action) {
  try {
    const response = yield call(roomServices.addMemberOfRoom, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      action.successCallback();
      yield put({
        type: ACTION_TYPES.ADD_MEMBER_OF_ROOM_SUCCESS,
        params: response?.data?.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.ADD_MEMBER_OF_ROOM_FAILED,
      params: error?.data?.error?.message,
    });
    action.failedCallback();
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* getExamDetailOfRoom(action) {
  try {
    const response = yield call(roomServices.getExamDetailOfRoom, action.params);
    if (response) {
      toast.success(response?.data?.data?.success_message);
      yield put({
        type: ACTION_TYPES.GET_EXAM_DETAIL_OF_ROOM_SUCCESS,
        params: response?.data?.data,
      });
    }
    action.successCallback();
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.GET_EXAM_DETAIL_OF_ROOM_FAILED,
      params: error?.data?.error,
    });
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.failedCallback(error?.data?.error?.code);
  }
  action.finallyCallback();
}

function* roomSaga() {
  yield debounce(500, ACTION_TYPES.CREATE_ROOM, createRoom);
  yield debounce(500, ACTION_TYPES.UPDATE_ROOM, updateRoom);
  yield takeLatest(ACTION_TYPES.GET_ROOM_DETAIL, getRoomDetail);
  yield takeLatest(ACTION_TYPES.DELETE_ROOM, deleteRoom);
  yield takeLatest(ACTION_TYPES.GET_MEMBERS_OF_ROOM, getMembersOfRoom);
  yield takeLatest(ACTION_TYPES.DELETE_MEMBER_OF_ROOM, deleteMemberOfRoom);
  yield debounce(500, ACTION_TYPES.ADD_MEMBER_OF_ROOM, addMemberOfRoom);
  yield takeLatest(ACTION_TYPES.GET_EXAM_DETAIL_OF_ROOM, getExamDetailOfRoom);
}
export default roomSaga;
