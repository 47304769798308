import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  ManageDocument,
  ManageStudent,
  ManageRoom,
  ArrowUpIcon,
  ArrowDownIcon,
  FAQIcon,
  BookIcon,
  LoginButton,
  LogoutButton,
  SideBarHoverIconLeft,
  SideBarHoverIconRight,
  IconMine,
  ShareIcon,
  ClassIcon,
  TeacherIcon,
  HandleReport,
  ReportWaiting,
  ReportDone,
} from "../assets";
import LogoApp from "../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../utils/configs/router";
import { Divider } from "primereact/divider";
import { useClickOutside } from "primereact/hooks";
import { Avatar } from "primereact/avatar";
import { useDispatch, useSelector, useStore } from "react-redux";
import { TCommonState } from "../components/common/utils/type";
import moment from "moment";
import { genSignature, getNonce } from "../components/common/utils/helper";
import { getAuth, signOut } from "firebase/auth";
import { signOutApi } from "../components/auth/utils/authAPI";
import { setCookie } from "cookies-next";
import { commonActions } from "../components/common/redux/action";
// import { MAIN_DOMAIN } from "../utils/constant/env";
// import AlertDialog from "../components/common/components/dialog/AlertDialog";
import { APP_NAME } from "../utils/constant/env";
import { useMouse } from "react-use";
import { eventTracking } from "../firebase/firebaseConfig";
import { trackingEventNameOfMenuItem } from "../components/common/utils/constant";

export const MENU_ITEMS = [
  {
    title: "navbar.manageStudent",
    icon: <ManageStudent />,
    href: ROUTES.manageStudent.groups.path,
    parentHref: ROUTES.manageStudent?.path,
    children: [
      {
        title: "navbar.manageStudent.groups",
        href: ROUTES.manageStudent.groups.path,
        icon: <ClassIcon />,
      },
      {
        title: "navbar.manageStudent.members",
        href: ROUTES.manageStudent.members.path,
        icon: <TeacherIcon />,
      },
    ],
    developing: false,
  },
  {
    title: "navbar.manageDocument",
    icon: <ManageDocument />,
    href: ROUTES.manageDocument.mine.path,
    parentHref: ROUTES.manageDocument?.path,
    children: [
      {
        title: "navbar.manageDocument.mine",
        href: ROUTES.manageDocument.mine.path,
        icon: <IconMine />,
        icon2: <IconMine />,
      },
      {
        title: "navbar.manageDocument.shared",
        href: ROUTES.manageDocument.shared.path,
        icon: <ShareIcon stroke="white" />,
      },
    ],
  },
  {
    title: "navbar.manageRoom",
    icon: <ManageRoom />,
    href: ROUTES.manageRoom.listRoom.path,
  },
  {
    title: "navbar.handleReport",
    icon: <HandleReport />,
    href: ROUTES.handleReport.waiting.path,
    parentHref: ROUTES.handleReport.path,
    children: [
      {
        title: "navbar.handleReport.waiting",
        href: ROUTES.handleReport.waiting.path,
        icon: <ReportWaiting />,
      },
      {
        title: "navbar.handleReport.done",
        href: ROUTES.handleReport.done.path,
        icon: <ReportDone />,
      },
    ],
  },
];

export default function DefaultLeftSide({ hideLeftSide, setHideLeftSide }) {
  const { userInfos } = useSelector((state: { common: TCommonState }) => state.common);
  const store = useStore();
  const overlayRef = useRef(null);
  const { posY, docX, docY } = useMouse(overlayRef);

  const [showChildren, setShowChildren] = useState<String>("");
  const [showChildrenIcon, setShowChildrenIcon] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useClickOutside(overlayRef, () => {
    setShowChildrenIcon("");
  });

  const onClickShowChildren = (it: String) => {
    if (it && it === showChildren) {
      setShowChildren("");
    } else {
      setShowChildren(it);
    }
  };

  const handleDirectAccountInfo = () => {
    if (!!userInfos?.member) {
      eventTracking("select_menu_account");
      navigate("/trang-ca-nhan");
    }
  };

  const handleSignOut = async () => {
    const paramsSignatureSignOut = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      uid: userInfos?.member?.pub_id,
    };
    const params = {
      ...paramsSignatureSignOut,
      signature: genSignature(paramsSignatureSignOut),
    };
    const auth = getAuth();
    try {
      const response = await signOutApi(store, params);
      if (response?.data) {
        signOut(auth)
          .then(() => {
            dispatch(commonActions.handleLogOut(response?.data?.data?.token));
            setCookie("accessToken", JSON.stringify(response?.data?.data?.token?.access_token));
            setCookie("userInfo", JSON.stringify(response?.data?.data));
            dispatch(commonActions.addUserInfos(response?.data?.data));
            dispatch(
              commonActions.handleSaveNotify({
                message: response?.data?.data?.success_message,
                isOpenNotify: true,
              })
            );
          })
          .catch((error) => {
            dispatch(commonActions.handleLogOut({}));
          });
        // navigate("/dang-nhap");
      }
    } catch (err) {
      dispatch({
        type: "CLEAR_TOKEN",
      });
    }
  };
  const checkHighLight = (href) =>
    hideLeftSide
      ? location?.pathname?.includes(href) ||
        MENU_ITEMS?.find(
          (e) => e?.parentHref && href?.includes(e?.parentHref) && location?.pathname?.includes(e?.parentHref)
        )
      : location?.pathname?.includes(href);

  useEffect(() => {
    // check hide overlay Menu
    const isHoverChildren = 0 < docX && docX < 200 && posY < docY;
    if (!isHoverChildren && !!showChildrenIcon) {
      setShowChildrenIcon("");
    }
  }, [posY, docX, docY]);

  return (
    <div className="text-white overflow-x-hidden h-full w-full p-6 relative left-side transition-transform">
      <button
        className="absolute left-side-spoiler w-[20px] h-[calc(100%-24px)] mix-blend-multiply right-0 flex items-center justify-center"
        onClick={() => setHideLeftSide(!hideLeftSide)}
      >
        {hideLeftSide ? <SideBarHoverIconRight /> : <SideBarHoverIconLeft />}
      </button>
      <div className="flex flex-col justify-between h-full">
        <div>
          <div
            className={`text-[24px] font-[600] leading-[36px] items-center flex gap-3 mb-6 ${
              hideLeftSide && "w-[40px]"
            }`}
          >
            <img src={LogoApp} alt="" className="w-[40px] h-[40px] ml-[-7px]" />
            {!hideLeftSide && <span className="font-[800]">{APP_NAME}</span>}
          </div>
          {MENU_ITEMS.map((item) => (
            <div key={item.title}>
              <div
                className={`relative py-4 flex justify-between items-center ${
                  (!item.children || hideLeftSide) &&
                  checkHighLight(item.href) &&
                  ` ${
                    hideLeftSide ? "ml-[-14px] pl-[14px] pr-[-4px] mr-[-4px]" : "mr-[-8px] ml-[-8px] pl-[8px]"
                  } rounded-[8px] bg-primary-600 relative`
                }`}
              >
                <Link
                  to={item?.developing || !userInfos?.member ? (null as any) : item.href}
                  onMouseEnter={() => !!item?.children && setShowChildrenIcon(item?.title)}
                  // onMouseLeave={() => !!item?.children && setShowChildrenIcon("")}
                  onClick={() => {
                    if (item?.children) {
                      onClickShowChildren(item.title);
                    }
                    if (item?.developing) {
                      dispatch(commonActions.handleOpenDialogFeatureDeveloping(true));
                    }
                    if (trackingEventNameOfMenuItem[item.title]) {
                      eventTracking(trackingEventNameOfMenuItem[item.title]);
                    }
                  }}
                  className={`gap-2 text-[16px] font-[600] leading-[24px] flex `}
                >
                  {item.icon} {!hideLeftSide && <FormattedMessage id={item.title} />}
                </Link>
                {item?.title === showChildrenIcon && hideLeftSide && (
                  <div
                    ref={overlayRef}
                    onMouseLeave={() => setShowChildrenIcon("")}
                    className="fixed left-[82px] bg-primary h-[56px] flex items-center px-4 gap-2 rounded-r-[8px] z-[1]"
                  >
                    {item?.children?.map((e) => (
                      <button
                        className="hover:bg-primary-600 p-2 rounded-[8px]"
                        onClick={() => {
                          if (trackingEventNameOfMenuItem[e.title]) {
                            eventTracking(trackingEventNameOfMenuItem[e.title]);
                          }
                          navigate(e?.href);
                        }}
                      >
                        {e?.icon}
                      </button>
                    ))}
                  </div>
                )}

                {!hideLeftSide && item?.children && item?.children.length > 0 && (
                  <button onClick={() => onClickShowChildren(item.title)}>
                    {showChildren === item?.title ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </button>
                )}
              </div>
              {!hideLeftSide && (
                <div className="flex flex-col">
                  {showChildren === item?.title &&
                    item?.children &&
                    item?.children?.map((e) => (
                      <button
                        onClick={() => {
                          if (trackingEventNameOfMenuItem[e.title]) {
                            eventTracking(trackingEventNameOfMenuItem[e.title]);
                          }
                          if (!!userInfos?.member) {
                            navigate(e?.href);
                          }
                        }}
                        className={`py-3 text-left flex gap-2 text-[16px] font-[600] pl-[40px] leading-[24px] ${
                          checkHighLight(e.href) && "bg-primary-600 rounded-[8px] ml-[-8px] pl-[48px] mr-[-8px]"
                        }`}
                      >
                        {e.icon} <FormattedMessage id={e.title} />
                      </button>
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-8 font-[600] mt-8">
          <button
            className="flex items-center gap-3"
            onClick={() => !!userInfos?.member && dispatch(commonActions.handleOpenDialogFeatureDeveloping(true))}
          >
            <FAQIcon /> {!hideLeftSide && "Hỏi đáp"}
          </button>
          <button
            className="flex items-center gap-3"
            onClick={() => {
              if (!!userInfos?.member) {
                eventTracking("select_menu_guide");
                navigate("/huong-dan-su-dung");
              }
            }}
          >
            <BookIcon /> {!hideLeftSide && "Hướng dẫn sử dụng"}
          </button>
          <Divider className="m-0" />
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Avatar
                label={(userInfos?.member?.display_name && userInfos?.member?.display_name[0]) || ""}
                image={userInfos?.member?.avatar}
                className="mr-2 w-[40px] h-[40px]"
                shape="circle"
                onClick={handleDirectAccountInfo}
              />
              {!hideLeftSide && (
                <div className="flex flex-col w-[calc(100%-40px)]">
                  <button onClick={handleDirectAccountInfo} className="line-clamp-2 text-left">
                    {userInfos?.member?.display_name || "Anonymous"}
                  </button>
                  <span className="text-[12px] font-[400] text-primary50 w-[calc(100%-15px)] truncate">
                    {userInfos?.member?.email && userInfos?.member?.email}
                  </span>
                </div>
              )}
            </div>
            {!hideLeftSide && (
              <button
                onClick={() => {
                  if (!userInfos?.member) {
                    navigate("/dang-nhap");
                  } else {
                    eventTracking("select_menu_logout");
                    handleSignOut();
                  }
                }}
              >
                {!userInfos?.member ? <LoginButton /> : <LogoutButton />}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
