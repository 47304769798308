import { isEqual, orderBy } from "lodash";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Editor, EditorTextChangeEvent } from "primereact/editor";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AddCircle, ArrowDownIcon } from "../../../assets";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import { convertAnswerKey, quizAnswerType } from "../../common/utils/constant";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { ANSWER_TYPE_OPTIONS, MAX_LENGTH_CORRECT_OPTION, MAX_LENGTH_DESCRIPTION_BEFORE_AFTER } from "../utils/constant";

const MAX_OPTIONS = 8;

type DialogEditExamProps = {
  open: any;
  onClose: any;
  question: any;
  listParentQuestion?: any[];
  onSubmit: (
    v: any,
    isParentQuestion: boolean,
    answerTypeChosen: keyof typeof ANSWER_TYPE_OPTIONS,
    parentQuestionId: number
  ) => void;
  title?: string;
  activeIndex?: number;
  isAdded?: boolean;
  isSelfInput?: boolean;
  ordinalQuestion?: any;
};

export default function DialogEditExam({
  open,
  onClose,
  question,
  listParentQuestion,
  onSubmit = (
    v: any,
    isParentQuestion: boolean,
    answerTypeChosen: keyof typeof ANSWER_TYPE_OPTIONS,
    parentQuestionId?: number
  ) => {},
  title = "",
  activeIndex = 0,
  isAdded = false,
  isSelfInput = false,
  ordinalQuestion = null,
}: DialogEditExamProps) {
  const intl = useIntl();
  const [tabActive, setTabActive] = useState<any>();
  const [text, setText] = useState<any>(() => {
    switch (question?.answer_type) {
      case quizAnswerType.CONSTRUCTED_RESPONSE:
        return [
          { content: question?.contents?.[0]?.content, ordinal: -1 },
          { content: question?.description_answer_before, ordinal: 0 },
          { ...question?.options?.[0], content: question?.options?.[0].contents?.[0]?.content, ordinal: 1, correct: 1 },
          { content: question?.description_answer_after, ordinal: 2 },
        ];
      case quizAnswerType.NONE:
        return [{ content: question?.contents?.[0]?.content, ordinal: -1 }].concat(
          Array(4)
            .fill(0)
            .map((_, ix) => ({ ordinal: ix, content: "", answer_key: convertAnswerKey(ix) }))
        );
      case quizAnswerType.ONE_ANSWER:
      case quizAnswerType.MANY_ANSWER:
      default:
        return [{ content: question?.contents?.[0]?.content, ordinal: -1 }].concat(
          question?.options?.map((op) => ({
            ...op,
            ordinal: op?.ordinal,
            content: op?.contents?.[0]?.content,
            answer_key: convertAnswerKey(op?.ordinal),
          }))
        );
    }
  });

  const [errorMessage, setErrorMessage] = useState<{
    before: string;
    correctOption: string;
    after: string;
  }>(() => {
    if (
      question?.answer_type === quizAnswerType.CONSTRUCTED_RESPONSE &&
      question?.options?.find((option) => option.correct === 1)?.contents?.[0]?.content.length >
        MAX_LENGTH_CORRECT_OPTION
    ) {
      return {
        before: "",
        correctOption: intl.formatMessage({ id: "createQuestion.errorMessage.characterLimitExceeded" }),
        after: "",
      };
    }
    return {
      before: "",
      correctOption: "",
      after: "",
    };
  });

  const isShowInkbar = useRef<boolean>(false);
  const [answerTypeChosen, setAnswerTypeChosen] = useState<keyof typeof ANSWER_TYPE_OPTIONS>(() => {
    switch (question?.answer_type) {
      case quizAnswerType.CONSTRUCTED_RESPONSE:
        return ANSWER_TYPE_OPTIONS.ENTER_ANSWER;
      case quizAnswerType.ONE_ANSWER:
      case quizAnswerType.MANY_ANSWER:
      default:
        return ANSWER_TYPE_OPTIONS.SELECT_ANSWER;
    }
  });

  const [isParentQuestion, setIsParentQuestion] = useState<boolean>(() => {
    if (question?.answer_type === quizAnswerType.NONE) return true;
    return false;
  });
  const [parentQuestionId, setParentQuestionId] = useState<number>(() => {
    return question?.parent || 0;
  });

  const handleChangeContent = (val, ordinal) => {
    setText((prev) => prev.map((e) => (e?.ordinal === ordinal ? { ...e, ordinal: ordinal, content: val } : e)));
  };

  const checkIsEditQuestion = useMemo<boolean>(() => {
    const answerTypeQuestionOrigin =
      question?.answer_type === quizAnswerType.CONSTRUCTED_RESPONSE
        ? ANSWER_TYPE_OPTIONS.ENTER_ANSWER
        : ANSWER_TYPE_OPTIONS.SELECT_ANSWER;

    if (!isEqual(answerTypeQuestionOrigin, answerTypeChosen)) return true;
    if (!isEqual(Boolean(question?.answer_type === quizAnswerType.NONE), isParentQuestion)) return true;
    if (!isEqual(question?.parent || 0, parentQuestionId)) return true;
    if (isParentQuestion) {
      return !isEqual(text?.[0]?.content, question?.contents[0]?.content);
    } else {
      switch (answerTypeChosen) {
        case ANSWER_TYPE_OPTIONS.SELECT_ANSWER:
          return !isEqual(
            orderBy(text, "ordinal")
              ?.map((e) => (e?.correct === 1 ? e?.content + e?.answer_key : e?.content))
              .toString(),
            [question?.contents[0]?.content]
              ?.concat(
                orderBy(question?.options, "ordinal")?.map((e) =>
                  e?.correct === 1 ? e?.contents?.[0]?.content + e?.answer_key : e?.contents?.[0]?.content
                )
              )
              .toString()
          );
        case ANSWER_TYPE_OPTIONS.ENTER_ANSWER:
          return !isEqual(
            text?.map((e) => e?.content).toString(),
            [
              question?.contents[0]?.content,
              question?.description_answer_before,
              question?.options[0].contents[0]?.content,
              question?.description_answer_after,
            ].toString()
          );
        default:
          return false;
      }
    }
  }, [text, question, isParentQuestion, parentQuestionId]);

  const checkDisabledButtonSave = useMemo<boolean>(() => {
    if (!text || text?.length === 0) return true;

    const hasNotEnoughData = isParentQuestion
      ? text?.[0]?.content?.trim() === ""
      : answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
      ? // ít nhất phải có 2 options, question_content không dc rỗng và phải có đáp án đúng
        text?.filter((e) => e?.content && e?.content?.trim()).length < 3
      : // question_content không dc rỗng và phải có đáp án đúng
        text?.[0]?.content?.trim() === "" || text?.[2]?.content?.trim() === "";

    const questionContent = text?.find((e) => e?.ordinal === -1)?.content;
    const invalidQuestionContent = !questionContent || questionContent?.trim()?.length === 0;

    const correctOptions = text.find((e) => e?.correct === 1);
    const invalidCorrectOption = isParentQuestion
      ? false
      : !correctOptions ||
        !correctOptions?.content ||
        Boolean(errorMessage.correctOption) ||
        (!!correctOptions?.content && correctOptions?.content?.trim().length === 0);

    const invalidOtherInfo =
      answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
        ? false
        : Boolean(errorMessage.before || errorMessage.after);

    const checkDisableButtonCreate =
      hasNotEnoughData || invalidQuestionContent || invalidCorrectOption || invalidOtherInfo;
    if (!isAdded) {
      return checkDisableButtonCreate || !checkIsEditQuestion;
    }
    return checkDisableButtonCreate;
  }, [text, isAdded, checkIsEditQuestion, isParentQuestion]);

  const handleAddQuestion = () => {
    setText(text.concat([{ ordinal: text.length - 1, content: "", answer_key: convertAnswerKey(text.length - 1) }]));
    setTabActive(text.length);
  };

  const renderHeaderTemplateEditor = () => {
    return (
      <div id="toolbar-container">
        <span className="ql-formats">
          <select className="ql-size"></select>
          <select className="ql-font"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
        </span>
        <span className="ql-formats">
          <select className="ql-color"></select>
          <select className="ql-background"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <select className="ql-align"></select>
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (isAdded) {
      setText(
        Array(5)
          .fill(0)
          .map((_, ix) => ({ ordinal: ix === 0 ? -1 : ix - 1, content: "", answer_key: convertAnswerKey(ix - 1) }))
      );
      setAnswerTypeChosen(ANSWER_TYPE_OPTIONS.SELECT_ANSWER);
      setIsParentQuestion(false);
    }
  }, [isAdded]);

  useEffect(() => {
    setTabActive(activeIndex);
  }, []);

  return (
    <Dialog
      header={<span className="flex justify-center mb-[-20px]">{title}</span>}
      visible={open}
      style={{ width: "560px" }}
      onHide={() => onClose()}
      closable={false}
      draggable={false}
      footer={
        <div
          className={`flex ${
            isSelfInput && answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER && !isParentQuestion
              ? "justify-between"
              : "justify-end"
          }`}
        >
          {isSelfInput && answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER && !isParentQuestion && (
            <button
              className="gap-1.5 flex text-primary text-[14px]"
              onClick={handleAddQuestion}
              disabled={text.length > MAX_OPTIONS + 1} // max la 8 option
            >
              <AddCircle />
              <FormattedMessage id="selfInput.addOption" />
            </button>
          )}
          <div className="flex gap-4">
            <Button
              severity="warning"
              outlined
              text
              className="w-[120px] h-[40px] flex justify-center font-bold"
              onClick={() => {
                onClose();
              }}
            >
              Huỷ
            </Button>
            <ButtonPrimary
              label="Lưu"
              disabled={checkDisabledButtonSave}
              className="w-[120px] h-[40px] flex justify-center font-bold"
              onClick={() => {
                const value =
                  isSelfInput && answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
                    ? text
                        .filter((e) => e?.content?.trim()?.length > 0)
                        ?.map((e, idx) =>
                          e?.ordinal >= 0 ? { ...e, ordinal: idx - 1, answer_key: convertAnswerKey(idx - 1) } : e
                        )
                    : text;
                onSubmit(value, isParentQuestion, answerTypeChosen, parentQuestionId);
                onClose();
              }}
            />
          </div>
        </div>
      }
    >
      {isSelfInput && (
        <div className="flex items-center justify-between border-b py-3">
          <span className="text-24 font-bold">Câu {ordinalQuestion}</span>
          <div className="flex items-center gap-2">
            <label className="text-[16px] leading-[24px] font-semibold text-gray-400">Loại đáp án:</label>
            <Dropdown
              dropdownIcon={<ArrowDownIcon stroke="#475467" />}
              disabled={isParentQuestion}
              value={answerTypeChosen}
              onChange={(event) => {
                setAnswerTypeChosen(event.value);
                setTabActive(0);
                setErrorMessage({
                  before: "",
                  correctOption: "",
                  after: "",
                });
                if (isAdded) {
                  if (event.value === ANSWER_TYPE_OPTIONS.SELECT_ANSWER) {
                    setText((prev) => {
                      return [
                        prev[0],
                        ...Array(4)
                          .fill(0)
                          .map((_, ix) => ({
                            ordinal: ix,
                            content: "",
                            answer_key: convertAnswerKey(ix),
                          })),
                      ];
                    });
                  } else {
                    setText((prev) => {
                      return [
                        prev[0],
                        ...Array(3)
                          .fill(0)
                          .map((_, ix) => ({
                            ordinal: ix,
                            content: "",
                            ...(ix === 1 && { correct: 1 }),
                          })),
                      ];
                    });
                  }
                } else {
                  switch (event.value) {
                    case ANSWER_TYPE_OPTIONS.SELECT_ANSWER:
                      if (
                        question.answer_type === quizAnswerType.ONE_ANSWER ||
                        question.answer_type === quizAnswerType.MANY_ANSWER
                      ) {
                        setText((prev) => {
                          return [
                            prev[0],
                            ...question?.options?.map((op) => ({
                              ...op,
                              ordinal: op?.ordinal,
                              content: op?.contents?.[0]?.content,
                              answer_key: convertAnswerKey(op?.ordinal),
                            })),
                          ];
                        });
                      } else {
                        setText((prev) => {
                          return [
                            prev[0],
                            ...Array(4)
                              .fill(0)
                              .map((_, ix) => ({
                                ordinal: ix,
                                content: "",
                                answer_key: convertAnswerKey(ix),
                              })),
                          ];
                        });
                      }
                      break;
                    case ANSWER_TYPE_OPTIONS.ENTER_ANSWER:
                      if (question.answer_type === quizAnswerType.CONSTRUCTED_RESPONSE) {
                        setText((prev) => {
                          return [
                            prev[0],
                            { content: question?.description_answer_before, ordinal: 0 },
                            {
                              ...question?.options[0],
                              content: question?.options[0].contents[0]?.content,
                              ordinal: 1,
                              correct: 1,
                            },
                            { content: question?.description_answer_after, ordinal: 2 },
                          ];
                        });
                      } else {
                        setText((prev) => {
                          return [
                            prev[0],
                            { content: "", ordinal: 0 },
                            { content: "", ordinal: 1, correct: 1 },
                            { content: "", ordinal: 2 },
                          ];
                        });
                      }
                      break;
                  }
                }
              }}
              options={[
                {
                  label: "Trắc nghiệm",
                  value: ANSWER_TYPE_OPTIONS.SELECT_ANSWER,
                },
                {
                  label: "Tự nhập",
                  value: ANSWER_TYPE_OPTIONS.ENTER_ANSWER,
                },
              ]}
              className="border-none font-semibold"
              pt={{
                input: {
                  className: "pr-0",
                },
              }}
            />
          </div>
        </div>
      )}
      {answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER ? (
        <TabView
          activeIndex={tabActive}
          scrollable
          onTabChange={(event) => {
            if (!isShowInkbar.current) isShowInkbar.current = true;
            setTabActive(event.index);
          }}
          pt={{
            root: {
              className: `
              [&>.p-tabview-panels]:px-0
              [&>.p-tabview-nav-container]:px-6
              [&_.p-tabview-title]:text-gray-500
              [&_.p-tabview-title]:font-semibold
              [&_.p-highlight_.p-tabview-title]:text-primary
              [&_.p-highlight>.p-tabview-nav-link]:border-primary
              [&_.p-tabview-nav-next]:text-primary
              [&_.p-tabview-nav-next]:shadow-none
              [&_.p-tabview-nav-prev]:text-primary
              [&_.p-tabview-nav-prev]:shadow-none
            `,
            },
            inkbar: {
              className: isShowInkbar.current ? "bg-primary" : "hidden",
            },
          }}
        >
          {text?.map((op, ix) => (
            <TabPanel
              header={op?.ordinal === -1 ? "Câu hỏi" : `Đáp án ${convertAnswerKey(op?.ordinal)}`}
              disabled={op?.ordinal !== -1 && isParentQuestion}
              contentClassName="flex flex-col gap-5"
            >
              {op?.ordinal === -1 && (
                <>
                  {!Boolean(parentQuestionId) && (
                    <div className="flex gap-x-2">
                      <Checkbox
                        inputId="isParentQuestion"
                        checked={isParentQuestion}
                        onChange={(event: CheckboxChangeEvent) => {
                          setIsParentQuestion(event.checked as boolean);
                        }}
                      />
                      <label htmlFor="isParentQuestion" className="text-gray-900">
                        Câu hỏi cha
                      </label>
                    </div>
                  )}
                  {!isParentQuestion && (
                    <div className="flex flex-col gap-y-2">
                      <label htmlFor="parentQuestionId" className="font-semibold text-gray-900">
                        Thuộc câu hỏi
                      </label>
                      <Dropdown
                        id="parentQuestionId"
                        options={[
                          { label: "Không thuộc câu hỏi nào", value: 0 },
                          ...(listParentQuestion?.map((question) => ({
                            label: question.contents[0].content,
                            value: question.id,
                          })) || []),
                        ]}
                        className="w-full rounded-full overflow-hidden"
                        value={parentQuestionId}
                        onChange={(event) => {
                          setParentQuestionId(event.value);
                        }}
                        disabled={listParentQuestion?.length === 0}
                        itemTemplate={(option) => (
                          <div className="text-gray-900">
                            <div
                              className="w-[472px] h-[24px] [&>p]:text-ellipsis [&>p]:overflow-hidden [&_img]:hidden"
                              dangerouslySetInnerHTML={{ __html: option.label }}
                            />
                          </div>
                        )}
                        valueTemplate={(option, props) => (
                          <div className="text-gray-900">
                            <div
                              className="w-[452px] h-[24px] [&>p]:text-ellipsis [&>p]:overflow-hidden [&_img]:hidden"
                              dangerouslySetInnerHTML={{ __html: option.label }}
                            />
                          </div>
                        )}
                        dropdownIcon={<ArrowDownIcon stroke="#475467" />}
                      />
                    </div>
                  )}
                </>
              )}

              <Editor
                tabIndex={ix + 1}
                key={op?.id}
                theme="snow"
                value={text?.find((e) => e?.ordinal === op?.ordinal)?.content}
                onTextChange={(ele: EditorTextChangeEvent) => {
                  handleChangeContent(ele?.htmlValue, op?.ordinal);
                }}
                style={{ height: "220px" }}
                pt={{
                  root: {
                    className: `
                    [&_.ql-active]:text-primary
                    [&_.ql-active_.ql-stroke:not(.ql-color-label)]:stroke-primary
                    [&_.ql-active_.ql-fill:not(.ql-color-label)]:stroke-primary
                  `,
                  },
                }}
              />
              {op?.ordinal !== -1 && (
                <div className="flex gap-2 items-center">
                  <Checkbox
                    onChange={(event) => {
                      setText(
                        text.map((textItem) => {
                          if (textItem.ordinal === op?.ordinal) {
                            return {
                              ...textItem,
                              correct: Number(event.checked),
                            };
                          }
                          return textItem;
                        })
                      );
                    }}
                    checked={Boolean(op?.correct)}
                    pt={{
                      input: {
                        className: "[&]:shadow-none",
                      },
                    }}
                  />
                  <p className="text-gray-500">Đáp án đúng</p>
                </div>
              )}
            </TabPanel>
          ))}
        </TabView>
      ) : (
        <TabView
          activeIndex={tabActive}
          onTabChange={(event) => {
            if (!isShowInkbar.current) isShowInkbar.current = true;
            setTabActive(event.index);
          }}
          pt={{
            root: {
              className: `
              [&>.p-tabview-panels]:px-0
              [&>.p-tabview-nav-container]:px-6
              [&_.p-tabview-title]:text-gray-500
              [&_.p-tabview-title]:font-semibold
              [&_.p-highlight_.p-tabview-title]:text-primary
              [&_.p-highlight>.p-tabview-nav-link]:border-primary
              [&_.p-tabview-nav-next]:text-primary
              [&_.p-tabview-nav-next]:shadow-none
              [&_.p-tabview-nav-prev]:text-primary
              [&_.p-tabview-nav-prev]:shadow-none
            `,
            },
            inkbar: {
              className: isShowInkbar.current ? "bg-primary" : "hidden",
            },
          }}
        >
          <TabPanel header="Câu hỏi" contentClassName="flex flex-col gap-5">
            {!Boolean(parentQuestionId) && (
              <div className="flex gap-x-2">
                <Checkbox
                  inputId="isParentQuestion"
                  checked={isParentQuestion}
                  onChange={(event: CheckboxChangeEvent) => {
                    setIsParentQuestion(event.checked as boolean);
                  }}
                />
                <label htmlFor="isParentQuestion" className="text-gray-900">
                  Câu hỏi cha
                </label>
              </div>
            )}
            {!isParentQuestion && (
              <div className="flex flex-col gap-y-2">
                <label htmlFor="parentQuestionId" className="font-semibold text-gray-900">
                  Thuộc câu hỏi
                </label>
                <Dropdown
                  id="parentQuestionId"
                  options={[
                    { label: "Không thuộc câu hỏi nào", value: 0 },
                    ...(listParentQuestion?.map((question) => ({
                      label: question.contents[0].content,
                      value: question.id,
                    })) || []),
                  ]}
                  className="w-full rounded-full overflow-hidden"
                  value={parentQuestionId}
                  onChange={(event) => {
                    setParentQuestionId(event.value);
                  }}
                  disabled={listParentQuestion?.length === 0}
                  itemTemplate={(option) => (
                    <div className="text-gray-900">
                      <div
                        className="w-[472px] h-[24px] [&>p]:text-ellipsis [&>p]:overflow-hidden [&_img]:hidden"
                        dangerouslySetInnerHTML={{ __html: option.label }}
                      />
                    </div>
                  )}
                  valueTemplate={(option, props) => (
                    <div className="text-gray-900">
                      <div
                        className="w-[452px] h-[24px] [&>p]:text-ellipsis [&>p]:overflow-hidden [&_img]:hidden"
                        dangerouslySetInnerHTML={{ __html: option.label }}
                      />
                    </div>
                  )}
                  dropdownIcon={<ArrowDownIcon stroke="#475467" />}
                />
              </div>
            )}
            <Editor
              theme="snow"
              value={text?.find((e) => e?.ordinal === -1)?.content}
              onTextChange={(ele: EditorTextChangeEvent) => {
                handleChangeContent(ele?.htmlValue, -1);
              }}
              style={{ height: "220px" }}
              pt={{
                root: {
                  className: `
                    [&_.ql-active]:text-primary
                    [&_.ql-active_.ql-stroke:not(.ql-color-label)]:stroke-primary
                    [&_.ql-active_.ql-fill:not(.ql-color-label)]:stroke-primary
                  `,
                },
              }}
            />
          </TabPanel>
          <TabPanel header="Trước" contentClassName="flex flex-col gap-3" disabled={isParentQuestion}>
            <Editor
              theme="snow"
              value={text?.find((e) => e?.ordinal === 0)?.content}
              onTextChange={(event: EditorTextChangeEvent) => {
                handleChangeContent(event?.htmlValue, 0);
                if ((event.htmlValue?.length || 0) > MAX_LENGTH_DESCRIPTION_BEFORE_AFTER) {
                  setErrorMessage((prev) => ({
                    ...prev,
                    before: intl.formatMessage({ id: "createQuestion.errorMessage.characterLimitExceeded" }),
                  }));
                } else {
                  setErrorMessage((prev) => ({
                    ...prev,
                    before: "",
                  }));
                }
              }}
              headerTemplate={renderHeaderTemplateEditor()}
              style={{ height: "246px" }}
              pt={{
                root: {
                  className: `
                    [&_.ql-active]:text-primary
                    [&_.ql-active_.ql-stroke:not(.ql-color-label)]:stroke-primary
                    [&_.ql-active_.ql-fill:not(.ql-color-label)]:stroke-primary
                    ${errorMessage.before && "[&_.ql-editor]:border [&_.ql-editor]:border-error"}
                  `,
                },
              }}
            />
            {errorMessage.before && <span className="text-error text-[13px]">{errorMessage.before}</span>}
          </TabPanel>
          <TabPanel header="Đáp án đúng" contentClassName="flex flex-col gap-3" disabled={isParentQuestion}>
            <InputTextarea
              className={`h-[290px] text-[12px] resize-none ${
                errorMessage.correctOption &&
                "[&.p-inputtextarea]:!border-error [&.p-inputtextarea:focus]:!border-error [&.p-inputtextarea:hover]:!border-error"
              }`}
              value={text?.find((e) => e?.ordinal === 1)?.content}
              onChange={(event) => {
                handleChangeContent(event.target.value, 1);
                if (event.target.value.length > MAX_LENGTH_CORRECT_OPTION) {
                  setErrorMessage((prev) => ({
                    ...prev,
                    correctOption: intl.formatMessage({ id: "createQuestion.errorMessage.characterLimitExceeded" }),
                  }));
                } else {
                  setErrorMessage((prev) => ({
                    ...prev,
                    correctOption: "",
                  }));
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
            {errorMessage.correctOption && <span className="text-error text-[13px]">{errorMessage.correctOption}</span>}
          </TabPanel>
          <TabPanel header="Sau" contentClassName="flex flex-col gap-3" disabled={isParentQuestion}>
            <Editor
              theme="snow"
              value={text?.find((e) => e?.ordinal === 2)?.content}
              onTextChange={(event: EditorTextChangeEvent) => {
                handleChangeContent(event?.htmlValue, 2);
                if ((event.htmlValue?.length || 0) > MAX_LENGTH_DESCRIPTION_BEFORE_AFTER) {
                  setErrorMessage((prev) => ({
                    ...prev,
                    after: intl.formatMessage({ id: "createQuestion.errorMessage.characterLimitExceeded" }),
                  }));
                } else {
                  setErrorMessage((prev) => ({
                    ...prev,
                    after: "",
                  }));
                }
              }}
              headerTemplate={renderHeaderTemplateEditor()}
              style={{ height: "246px" }}
              pt={{
                root: {
                  className: `
                    [&_.ql-active]:text-primary
                    [&_.ql-active_.ql-stroke:not(.ql-color-label)]:stroke-primary
                    [&_.ql-active_.ql-fill:not(.ql-color-label)]:stroke-primary
                    ${errorMessage.after && "[&_.ql-editor]:border [&_.ql-editor]:border-error"}
                  `,
                },
              }}
            />
            {errorMessage.after && <span className="text-error text-[13px]">{errorMessage.after}</span>}
          </TabPanel>
        </TabView>
      )}
    </Dialog>
  );
}
