import { ACTION_TYPES } from "./actionTypes";

const createFolder = (
  params,
  successCallback = () => {},
  errorCallback = (error) => {},
  finallyCallback = () => {}
) => {
  return {
    type: ACTION_TYPES.CREATE_FOLDER,
    params,
    successCallback,
    errorCallback,
    finallyCallback,
  };
};

const getListFolderAndFile = (
  params,
  successCallback = () => {},
  errorCallback = () => {},
  finallyCallback = () => {}
) => {
  return {
    type: ACTION_TYPES.GET_LIST_FOLDER_FILE,
    params,
    successCallback,
    errorCallback,
    finallyCallback,
  };
};

const updateFolder = (
  params,
  successCallback = () => {},
  errorCallback = (error) => {},
  finallyCallback = () => {}
) => {
  return {
    type: ACTION_TYPES.UPDATE_FOLDER,
    params,
    successCallback,
    errorCallback,
    finallyCallback,
  };
};

const deleteFolder = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.DELETE_FOLDER,
    params,
    successCallback,
  };
};

const shareDocument = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.SHARE_DOCUMENT,
    params,
    successCallback,
  };
};

const deleteExams = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.DELETE_EXAMS,
    params,
    successCallback,
  };
};

const previewExam = (
  params,
  successCallback = (exam: { questions: [] | undefined; solutions: [] | undefined }) => {}
) => {
  return {
    type: ACTION_TYPES.PREVIEW_EXAM,
    params,
    successCallback,
  };
};

const createGroupExam = (params, successCallback = (topicSlug: string) => {}) => {
  return {
    type: ACTION_TYPES.CREATE_GROUP_EXAM,
    params,
    successCallback,
  };
};

const getExamDetail = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_EXAM_DETAIL,
    params,
    successCallback,
  };
};

const handleCleanPreviewExam = () => {
  return {
    type: ACTION_TYPES.CLEAN_PREVIEW_EXAM,
  };
};

const clearListFolderFile = () => {
  return {
    type: ACTION_TYPES.CLEAR_LIST_FOLDER_FILE,
  };
};

export const documentActions = {
  createFolder,
  getListFolderAndFile,
  updateFolder,
  deleteFolder,
  shareDocument,
  deleteExams,
  previewExam,
  createGroupExam,
  getExamDetail,
  handleCleanPreviewExam,
  clearListFolderFile,
};
