export const ERROR_CODE_NOT_FOUND_GROUP = "ENFGRO";
export const ERROR_CODE_NOT_ACCEPTABLE_GROUP_STATUS_ADD_MEMBER_CLOSED = "ENAGSA";
export const ERROR_CODE_NOT_ACCEPTABLE_MEMBER_DELETED_GROUP = "ENAMDG";

export const COLUMN_KEY_MEMBER_RESULT_TABLE = {
  NO: "NO",
  TITLE_ROOM: "TITLE_ROOM",
  TITLE: "TITLE",
  TITLE_GROUP: "TITLE_GROUP",
  SCORE: "SCORE",
  RANKING: "RANKING",
  SPENT_TIME: "SPENT_TIME",
};

export const COLUMN_KEY_MEMBERS_TABLE = {
  NO: "NO",
  NAME: "NAME",
  MEMBER_HID: "MEMBER_HID",
  AVG_SCORE: "AVG_SCORE",
  SCORE_AVG: "SCORE_AVG",
  RANKING: "RANKING",
  NUM_OF_CLASS_JOIN: "NUM_OF_CLASS_JOIN",
  NUM_OF_ROOM_JOIN: "NUM_OF_ROOM_JOIN",
  NUM_OF_ROOM_SUBMITTED: "NUM_OF_ROOM_SUBMITTED",
  DELETE: "DELETE",
};
