import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ArrowDownIcon, RepeatIcon } from "../../../../assets";
import { EGroupStatusOption } from "../../../../types/group";
import {
  genSignature,
  getNonce,
  getTimestampWithoutSecond,
  isEmptyCustom,
  removeNullAttributeFromObject,
} from "../../../common/utils/helper";
import { documentActions } from "../../../manage_document/redux/action";
import { roomActions } from "../../../manage_room/redux/action";
import { manageStudentActions } from "../../../manage_student/redux/action";
import { ERROR_CODE_NOT_FOUND_GROUP } from "../../../manage_student/utils/constant";
import { commonActions } from "../../redux/action";
import {
  ADD_DAYS_MAX_END_TIME,
  ADD_DAYS_MAX_START_TIME,
  ERROR_CODE_NOT_FOUND_EXAM,
  QUIZ_LEVEL_OBJECT,
} from "../../utils/constant";
import { TCommonState } from "../../utils/type";
import ButtonPrimary from "../button/ButtonPrimary";
import ChooseExamDialog from "./ChooseExamDialog";
import Dialog from "./Dialog";
import InputTimeQuizDialog from "./InputTimeQuizDialog";
import { eventTracking } from "../../../../firebase/firebaseConfig";

const ROOM_DISPLAY_STATUS_OPEN = "open";
const ROOM_DISPLAY_STATUS_CLOSE = "closed";

const initialStateCreateRoom = (roomDetail: any, creationInfo: any) => {
  return {
    nameRoom: roomDetail?.title || "",
    subject_type_id: roomDetail?.subject_type_id || creationInfo?.grades?.[0]?.subject_types[0]?.id,
    grade: roomDetail?.grade?.slug || "",
    maxSlot: roomDetail
      ? creationInfo?.slots?.find((e: { max_slot: any }) => e?.max_slot === roomDetail.max_slot)?.id
      : "",
    roomType: roomDetail?.type || "simultaneously",
    quizLevel: !roomDetail
      ? QUIZ_LEVEL_OBJECT.NORMAL
      : roomDetail?.quiz_level_title === "Đề tự nhập"
      ? QUIZ_LEVEL_OBJECT.CREATED
      : roomDetail?.quiz_level,
    numOfQuestions: roomDetail?.num_of_questions || "5",
    timeQuiz: roomDetail?.time || "10",
    password: roomDetail?.password || "",
    delaySubmitTime: roomDetail?.delay_submit_time || creationInfo.delay_submit?.delay_def_time,
    isNotify: roomDetail?.is_notification || true,
    startTime: roomDetail?.start_time ? moment.unix(roomDetail?.start_time).toDate() : null,
    endTime: roomDetail?.end_time ? moment.unix(roomDetail?.end_time).toDate() : null,
    note: roomDetail?.note || "",
    topicId: roomDetail?.topic_id || "",
    teacher_invitee_only:
      typeof roomDetail?.teacher_invitee_only === "undefined" ? 1 : roomDetail?.teacher_invitee_only,
    display_status: roomDetail?.display_status || ROOM_DISPLAY_STATUS_OPEN,
    isShuffle: roomDetail ? Boolean(roomDetail.exam_codes?.length || roomDetail.exam_distribution) : false,
    numOfExamCodes: roomDetail?.exam_codes?.length || null,
    examDistribution: roomDetail?.exam_distribution || "random",
  };
};

type TAddOrEditRoomDialogProps = {
  isOpen: boolean;
  roomDetail?: any;
  examId?: any;
  groupDetail?: any;
  pageReloadDataWhenNotFoundExamError?: "shared" | "mine";
  onClose: () => void;
};

export default function AddOrEditRoomDialog({
  isOpen,
  roomDetail,
  examId,
  groupDetail,
  pageReloadDataWhenNotFoundExamError,
  onClose,
}: TAddOrEditRoomDialogProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const { roomCreationInfo, allFolderAndFile } = useSelector((state: { common: TCommonState }) => state.common);
  const [dataCreate, setDataCreate] = useState(
    initialStateCreateRoom(roomDetail?.room_info, roomCreationInfo)
    // {}
  );

  const isChangeTitle = useRef(false);
  const isChangeExamCodes = useRef(false);
  const [disableEndTime, setDisableEndTime] = useState(false);
  const [lockButtonCreate, setLockButtonCreate] = useState(true);
  const [isOpenChooseExamDialog, setIsOpenChooseExamDialog] = useState(false);
  const [isOpenInputTimeQuiz, setIsOpenInputTimeQuiz] = useState<boolean>(false);
  const [isTypePdf, setIsTypePdf] = useState(false);

  const isUpdateRoom = !!roomDetail;
  const arrayQuizTime = useMemo(() => {
    if (dataCreate.quizLevel === QUIZ_LEVEL_OBJECT.CREATED)
      return [...new Set(roomCreationInfo?.quiz_creation_info?.map((el: { time: any }) => el.time)), -1];
    else {
      return [...new Set(roomCreationInfo?.quiz_creation_info?.map((el: { time: any }) => el.time))];
    }
  }, [roomCreationInfo?.quiz_creation_info, dataCreate.quizLevel]);

  const isDisabledIsShuffle = useMemo(
    () => dataCreate.quizLevel === QUIZ_LEVEL_OBJECT.CREATED && isTypePdf,
    [dataCreate.quizLevel, isTypePdf]
  );

  const handleChangeNameRoom = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataCreate((prevState) => ({
      ...prevState,
      nameRoom: event.target.value,
    }));
    isChangeTitle.current = true;
  };

  const handleChangeSubject = (e) => {
    setDataCreate((prevState) => ({
      ...prevState,
      subject_type_id: e.value,
      quizLevel: QUIZ_LEVEL_OBJECT.NORMAL,
    }));
  };

  const handleChangeGrade = (e) => {
    setDataCreate((prevState) => ({
      ...prevState,
      grade: e.value,
    }));
  };

  const handleChangeSlots = (e) => {
    setDataCreate((prevState) => ({
      ...prevState,
      maxSlot: e.value,
    }));
  };

  const handleChangeRoomType = (e) => {
    setDataCreate((prevState) => ({
      ...prevState,
      roomType: e.value,
    }));
  };

  const handleChangeQuizLevel = (e) => {
    if (e.value < 2 && dataCreate.quizLevel > 2) {
      setDataCreate((prevState) => ({
        ...prevState,
        quizLevel: e.value,
        numOfQuestions: "5",
        timeQuiz: "10",
      }));
    } else {
      setDataCreate((prevState) => ({
        ...prevState,
        quizLevel: e.value,
      }));
      if (e.value === QUIZ_LEVEL_OBJECT.CREATED) {
        if (!dataCreate.topicId) {
          setIsOpenChooseExamDialog(true);
        } else if (isTypePdf) {
          setDataCreate((prevState) => ({
            ...prevState,
            isShuffle: false,
          }));
        }
      }
    }
  };

  const handleChangeNumOfQuestion = (event: { target: { value: any } }) => {
    setDataCreate((prevState) => ({
      ...prevState,
      numOfQuestions: event.target.value,
    }));
  };

  const handleChangeTimeQuiz = (e) => {
    if (e.value === -1) {
      setIsOpenInputTimeQuiz(true);
    } else {
      if (dataCreate.quizLevel !== QUIZ_LEVEL_OBJECT.CREATED) {
        const arrayNumOfQuestion = roomCreationInfo?.quiz_creation_info?.filter(
          (element: { time: number }) => element.time === Number(e.value)
        );
        setDataCreate((prevState) => ({
          ...prevState,
          timeQuiz: e.value,
          numOfQuestions: arrayNumOfQuestion[0]?.num_of_questions,
        }));
        if (dataCreate.roomType === "flexible") {
          setDataCreate((prevState) => ({
            ...prevState,
            startTime: null,
            endTime: null,
          }));
        }
      } else {
        setDataCreate((prevState) => ({
          ...prevState,
          timeQuiz: e.value,
        }));
      }
    }
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataCreate((prevState) => ({
      ...prevState,
      password: event.target.value.trim(),
    }));
  };

  const handleChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataCreate((prevState) => ({
      ...prevState,
      note: event.target.value,
    }));
  };

  const handleChangeDelaySubmitTime = (event) => {
    setDataCreate((prevState) => ({
      ...prevState,
      delaySubmitTime: event.target.value,
    }));
  };

  const handleChangeIsNotify = (event) => {
    setDataCreate((prevState) => ({
      ...prevState,
      isNotify: event.value,
    }));
  };

  const handleChangeStartTime = (e) => {
    setDataCreate((prevState) => ({ ...prevState, startTime: e.value }));
  };
  const handleChangeEndTime = (e) => {
    setDataCreate((prevState) => ({ ...prevState, endTime: e.value }));
  };

  const handleChooseExam = (exam) => {
    setDataCreate((prev) => ({
      ...prev,
      topicId: exam.id,
      quizLevel: QUIZ_LEVEL_OBJECT.CREATED,
    }));
    setIsOpenChooseExamDialog(false);

    // trigger exam type to turn off isShuffle
    if (exam.isTypePdf) {
      setDataCreate((prev) => ({
        ...prev,
        isShuffle: false,
      }));
      setIsTypePdf(true);
    } else {
      setIsTypePdf(false);
    }
  };

  const handleChangeIsShuffle = (event) => {
    setDataCreate((prev) => ({
      ...prev,
      isShuffle: event.value,
    }));
  };

  const handleChangeExamDistribution = (event) => {
    setDataCreate((prevState) => ({
      ...prevState,
      examDistribution: event.target.value,
    }));
  };

  const handleChangeNumOfExamCodes = (event) => {
    setDataCreate((prevState) => ({
      ...prevState,
      numOfExamCodes: event.value,
    }));
    isChangeExamCodes.current = true;
  };

  const handleClose = () => {
    onClose();
    setDataCreate(initialStateCreateRoom(roomDetail, { slots: [] }));
    isChangeTitle.current = false;
    isChangeExamCodes.current = false;
  };

  const handleAddMembersOfGroupToRoom = (roomId: string) => {
    const dataPostPre = {
      nonce: getNonce(),
      timestamp: moment().unix(),
      room_hid: roomId,
      group_hids: groupDetail.group_hid,
    };
    const dataPost = {
      ...dataPostPre,
      signature: genSignature(dataPostPre),
    };
    dispatch(manageStudentActions.addMembersOfGroupToRoom(dataPost, () => navigate(`/quan-ly-phong-thi/${roomId}`)));
  };

  const createRoom = (dataPost) => {
    dispatch(
      roomActions.createRoom(
        dataPost,
        (roomId) => {
          if (groupDetail) {
            handleAddMembersOfGroupToRoom(roomId);
          } else navigate(`/quan-ly-phong-thi/${roomId}`);
        },
        (errorCode) => {
          switch (errorCode) {
            case ERROR_CODE_NOT_FOUND_EXAM: {
              // clear form value and list document
              dispatch(commonActions.getAllFolderAndFile());
              setDataCreate((prev) => ({
                ...prev,
                topicId: null,
                quizLevel: QUIZ_LEVEL_OBJECT.NORMAL,
              }));

              // if create room at document page , have to reload to update new list.
              if (pageReloadDataWhenNotFoundExamError) {
                dispatch(documentActions.getListFolderAndFile({ folder_type: pageReloadDataWhenNotFoundExamError }));
              }
            }
          }
        }
      )
    );
  };

  const handleClickCreateRoom = () => {
    const dataPostPre = {
      title: dataCreate.nameRoom.trim().replace(/\s\s+/g, " "),
      subject_type_id: Number(dataCreate.subject_type_id),
      grade_slug: dataCreate.grade,
      slot_id: Number(dataCreate.maxSlot),
      is_premium_quiz: Number(dataCreate?.quizLevel) === QUIZ_LEVEL_OBJECT.PREMIUM ? 1 : 0,
      quiz_creation_info_id:
        dataCreate.quizLevel === QUIZ_LEVEL_OBJECT.CREATED
          ? undefined
          : roomCreationInfo?.quiz_creation_info?.find(
              (e: { num_of_questions: number }) => e.num_of_questions === Number(dataCreate.numOfQuestions)
            )?.id || 0,
      start_time: !isEmptyCustom(dataCreate.startTime)
        ? getTimestampWithoutSecond(moment(dataCreate.startTime).unix())
        : 0,
      end_time: !isEmptyCustom(dataCreate.startTime)
        ? !isEmptyCustom(dataCreate.endTime)
          ? getTimestampWithoutSecond(moment(dataCreate.endTime).unix())
          : getTimestampWithoutSecond(moment(dataCreate.startTime).add(3, "days").unix())
        : 0,
      type: dataCreate.roomType,
      is_notification: dataCreate.isNotify ? 1 : 0,
      delay_submit_time: Number(dataCreate.delaySubmitTime),
      invitee_only: 0,
      note: dataCreate.note.trim(),
      topic_id: dataCreate.quizLevel === QUIZ_LEVEL_OBJECT.CREATED ? dataCreate.topicId : undefined,
      time: dataCreate.quizLevel === QUIZ_LEVEL_OBJECT.CREATED ? dataCreate.timeQuiz : undefined,
      num_of_questions: dataCreate.quizLevel === QUIZ_LEVEL_OBJECT.CREATED ? dataCreate.numOfQuestions : undefined,
      nonce: getNonce(),
      timestamp: moment().unix(),
      password: undefined,
      teacher_invitee_only: dataCreate?.teacher_invitee_only,
      display_status: dataCreate?.display_status,
      num_of_exam_codes: dataCreate.isShuffle ? Number(dataCreate.numOfExamCodes) : undefined,
      exam_distribution: dataCreate.isShuffle ? dataCreate.examDistribution : undefined,
    };

    if (!isEmptyCustom(dataCreate.password)) {
      dataPostPre.password = dataCreate.password.trim();
    }

    const removedNullAttributeDataPostPre = removeNullAttributeFromObject(dataPostPre);

    const dataPost = {
      ...removedNullAttributeDataPostPre,
      signature: genSignature(removedNullAttributeDataPostPre),
    };

    if (!groupDetail) {
      createRoom(dataPost);
      return;
    }

    // Check add room status before create room for group.
    dispatch(
      manageStudentActions.getGroupDetail(
        { groupHid: groupDetail.group_hid },
        (groupDetail) => {
          if (groupDetail.status_add_room === EGroupStatusOption.CLOSED) {
            handleClose();
            toast.error(
              intl.formatMessage({ id: "AddOrEditRoomDialog.toastError.cannotCreateRoomWhenStatusAddRoomIsClosed" })
            );
            return;
          }
          if (groupDetail.members?.length === 0) {
            handleClose();
            toast.error(intl.formatMessage({ id: "AddOrEditRoomDialog.toastError.cannotCreateRoomWhenHaveNoMember" }));
            return;
          }

          createRoom(dataPost);
        },
        (error) => {
          switch (error.code) {
            case ERROR_CODE_NOT_FOUND_GROUP:
              navigate("/quan-ly-hoc-sinh/lop-hoc");
              break;
            default:
              break;
          }
        }
      )
    );
  };

  const handleClickUpdateRoom = () => {
    const dataPostPre: any = {
      nonce: getNonce(),
      timestamp: moment().unix(),
      is_edit_password:
        isEmptyCustom(roomDetail?.room_info?.password) && isEmptyCustom(dataCreate.password)
          ? 0
          : roomDetail?.room_info?.password !== dataCreate.password
          ? 1
          : 0,
    };
    if (roomDetail?.room_info?.title !== dataCreate.nameRoom?.trim()) {
      dataPostPre.title = dataCreate.nameRoom?.trim();
    }
    if ((roomDetail?.room_info?.note || "") !== dataCreate.note?.trim()) {
      dataPostPre.note = dataCreate.note?.trim();
    }
    if (!isEmptyCustom(dataCreate.password)) {
      dataPostPre.password = dataCreate.password;
    }
    if (roomDetail?.room_info?.delay_submit_time !== Number(dataCreate.delaySubmitTime)) {
      dataPostPre.delay_submit_time = Number(dataCreate.delaySubmitTime);
    }
    if (
      roomDetail?.room_info?.max_slot !==
      roomCreationInfo?.slots?.find((e) => e.id === Number(dataCreate.maxSlot))?.max_slot
    ) {
      dataPostPre.slot_id = Number(dataCreate.maxSlot);
    }
    if (roomDetail?.teacher_invitee_only !== dataCreate?.teacher_invitee_only) {
      dataPostPre.teacher_invitee_only = dataCreate?.teacher_invitee_only;
    }
    if (roomDetail?.display_status !== dataCreate?.display_status) {
      dataPostPre.display_status = dataCreate?.display_status;
    }
    const dataPost = {
      ...dataPostPre,
      signature: genSignature(dataPostPre),
      room_hid: roomDetail?.room_info?.room_hid,
    };
    eventTracking("select_update_room");
    dispatch(
      roomActions.updateRoom(dataPost, () => {
        onClose();
        dispatch(roomActions.getRoomDetail(roomDetail?.room_info?.room_hid));
      })
    );
  };

  useEffect(() => {
    if (
      !isEmptyCustom(dataCreate.timeQuiz) &&
      !isEmptyCustom(dataCreate.startTime) &&
      !isEmptyCustom(dataCreate.roomType) &&
      dataCreate.roomType === "simultaneously"
    ) {
      setDataCreate((prevState) => ({
        ...prevState,
        endTime: moment(dataCreate.startTime).add(Number(dataCreate.timeQuiz), "minutes").toDate(),
      }));
      setDisableEndTime(true);
    } else if (isEmptyCustom(dataCreate.startTime)) {
      setDisableEndTime(true);
    } else {
      setDisableEndTime(false);
    }
  }, [dataCreate.timeQuiz, dataCreate.startTime, dataCreate.roomType]);

  useEffect(() => {
    const nameRoomClean = dataCreate.nameRoom?.trim().replace(/\s\s+/g, " ");
    if (!isUpdateRoom) {
      if (
        !isEmptyCustom(nameRoomClean) &&
        nameRoomClean.length <= 64 &&
        !isEmptyCustom(dataCreate.grade) &&
        !isEmptyCustom(dataCreate.subject_type_id) &&
        !isEmptyCustom(dataCreate.maxSlot) &&
        !isEmptyCustom(dataCreate.roomType) &&
        !isEmptyCustom(dataCreate.timeQuiz) &&
        !isEmptyCustom(dataCreate.delaySubmitTime)
      ) {
        if (
          !isEmptyCustom(dataCreate.password) &&
          (dataCreate.password?.length < 4 || dataCreate.password?.length > 16)
        ) {
          setLockButtonCreate(true);
        } else if (dataCreate.isShuffle && Number(dataCreate.numOfExamCodes) < 2) {
          setLockButtonCreate(true);
        } else {
          setLockButtonCreate(false);
        }
      } else {
        setLockButtonCreate(true);
      }
    } else {
      if (
        nameRoomClean === roomDetail?.room_info?.title &&
        dataCreate.grade === roomDetail?.room_info?.grade?.slug &&
        dataCreate.subject_type_id === roomDetail?.room_info?.subject_type_id &&
        dataCreate.maxSlot ===
          roomCreationInfo?.slots?.find((e: { max_slot: any }) => e?.max_slot === roomDetail?.room_info?.max_slot)
            ?.id &&
        dataCreate.delaySubmitTime === roomDetail?.room_info?.delay_submit_time &&
        dataCreate.isNotify === roomDetail?.room_info?.is_notification &&
        (roomDetail?.room_info?.note
          ? dataCreate.note.trim() === roomDetail?.room_info?.note
          : isEmptyCustom(dataCreate.note.trim())) &&
        (roomDetail?.room_info?.password
          ? roomDetail?.room_info?.password === dataCreate.password
          : isEmptyCustom(dataCreate.password)) &&
        dataCreate?.display_status === roomDetail?.room_info?.display_status &&
        dataCreate?.teacher_invitee_only === roomDetail?.room_info?.teacher_invitee_only
      ) {
        setLockButtonCreate(true);
      } else {
        setLockButtonCreate(false);
      }
    }
  }, [dataCreate]);

  useEffect(() => {
    if (examId) {
      setDataCreate((prev) => ({
        ...prev,
        topicId: examId,
        quizLevel: QUIZ_LEVEL_OBJECT.CREATED,
      }));
      const exam = allFolderAndFile.files?.find((file) => file.id === examId);
      if (exam?.is_type_pdf) setIsTypePdf(true);
    }
  }, [examId, allFolderAndFile?.files]);

  useEffect(() => {
    if (dataCreate.quizLevel === QUIZ_LEVEL_OBJECT.CREATED && dataCreate.topicId) {
      const exam = allFolderAndFile.files?.find((file) => file.id === dataCreate.topicId);

      setDataCreate((prev) => ({
        ...prev,
        quizLevel: QUIZ_LEVEL_OBJECT.CREATED,
        numOfQuestions: exam?.num_of_questions,
        timeQuiz: exam?.time_quiz,
      }));
    }
  }, [dataCreate.quizLevel, dataCreate.topicId, allFolderAndFile?.files]);

  useEffect(() => {
    if (isUpdateRoom) {
      eventTracking("screen_edit_room_view");
    } else {
      eventTracking("screen_create_room_view");
    }
  }, []);

  const actions = (
    <div className="flex justify-center gap-4">
      {isUpdateRoom ? (
        <ButtonPrimary
          label={intl.formatMessage({
            id: "AddOrEditRoomDialog.button.saveRoom",
          })}
          onClick={handleClickUpdateRoom}
          disabled={lockButtonCreate}
          buttonSize="large"
        />
      ) : (
        <ButtonPrimary
          label={intl.formatMessage({
            id: "AddOrEditRoomDialog.button.createRoom",
          })}
          onClick={() => {
            eventTracking("select_create_room_click_button");
            handleClickCreateRoom();
          }}
          disabled={lockButtonCreate}
          buttonSize="large"
        />
      )}
    </div>
  );

  return (
    <Dialog
      title={
        isUpdateRoom
          ? intl.formatMessage({ id: "AddOrEditRoomDialog.updateRoom.title" })
          : intl.formatMessage({ id: "AddOrEditRoomDialog.createRoom.title" })
      }
      isOpen={isOpen}
      closable
      onClose={handleClose}
      actions={actions}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="name" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.nameField.label" />
            <span className="text-error"> *</span>
          </label>
          <InputText
            id="name"
            placeholder={intl.formatMessage({
              id: "AddOrEditRoomDialog.nameField.placeholder",
            })}
            className={classNames("rounded-full placeholder-gray-400 text-gray-900 px-4", {
              "p-invalid":
                (isChangeTitle.current && isEmptyCustom(dataCreate.nameRoom)) || dataCreate.nameRoom.length > 64,
            })}
            value={dataCreate.nameRoom}
            onChange={handleChangeNameRoom}
          />

          {!isEmptyCustom(dataCreate.nameRoom) && dataCreate.nameRoom.length > 64 && (
            <small className="p-error">
              <FormattedMessage id="AddOrEditRoomDialog.nameField.errorMessage" />
            </small>
          )}
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="subject" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.subjectField.label" />
            <span className="text-error"> *</span>
          </label>
          <Dropdown
            id="subject"
            options={
              roomCreationInfo?.grades?.[0].subject_types.map((item) => ({
                label: item.title,
                value: item.id,
              })) || []
            }
            placeholder={intl.formatMessage({
              id: "AddOrEditRoomDialog.subjectField.placeholder",
            })}
            className="w-full md:w-14rem rounded-full px-1"
            value={dataCreate.subject_type_id}
            onChange={handleChangeSubject}
            itemTemplate={(option) => {
              return <div className="text-gray-900">{option.label}</div>;
            }}
            valueTemplate={(option, props) => {
              if (option) return <div className="text-gray-900">{option.label}</div>;
              return <div className="text-gray-400">{props.placeholder}</div>;
            }}
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
          />
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="class" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.classField.label" />
            <span className="text-error"> *</span>
          </label>
          <Dropdown
            id="class"
            options={
              roomCreationInfo?.grades?.map((item) => ({
                label: item.title,
                value: item.slug,
              })) || []
            }
            placeholder={intl.formatMessage({
              id: "AddOrEditRoomDialog.classField.placeholder",
            })}
            className="w-full md:w-14rem rounded-full px-1"
            value={dataCreate.grade}
            onChange={handleChangeGrade}
            itemTemplate={(option) => {
              return <div className="text-gray-900">{option.label}</div>;
            }}
            valueTemplate={(option, props) => {
              if (option) return <div className="text-gray-900">{option.label}</div>;
              return <div className="text-gray-400">{props.placeholder}</div>;
            }}
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
          />
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="slots" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.slotsField.label" />
            <span className="text-error"> *</span>
          </label>
          <Dropdown
            id="slots"
            options={
              roomCreationInfo?.slots?.map((item) => ({
                label: item.max_slot,
                value: item.id,
                disabled: !item.available || groupDetail?.members?.length + 1 > Number(item.max_slot),
              })) || []
            }
            placeholder={intl.formatMessage({
              id: "AddOrEditRoomDialog.slotsField.placeholder",
            })}
            className="w-full md:w-14rem rounded-full px-1"
            value={dataCreate.maxSlot}
            onChange={handleChangeSlots}
            itemTemplate={(option) => {
              return <div className="text-gray-900">{option.label}</div>;
            }}
            valueTemplate={(option, props) => {
              if (option) return <div className="text-gray-900">{option.label}</div>;
              return <div className="text-gray-400">{props.placeholder}</div>;
            }}
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
          />
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="type" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.roomTypeField.label" />
            <span className="text-error"> *</span>
          </label>
          <Dropdown
            id="type"
            options={
              roomCreationInfo?.room_types?.map((item) => ({
                label: item.title,
                value: item.type,
              })) || []
            }
            placeholder={intl.formatMessage({
              id: "AddOrEditRoomDialog.roomTypeField.placeholder",
            })}
            className="w-full md:w-14rem rounded-full px-1"
            value={dataCreate.roomType}
            onChange={handleChangeRoomType}
            disabled={isUpdateRoom}
            itemTemplate={(option) => {
              return <div className="text-gray-900">{option.label}</div>;
            }}
            valueTemplate={(option, props) => {
              if (option) return <div className="text-gray-900">{option.label}</div>;
              return <div className="text-gray-400">{props.placeholder}</div>;
            }}
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
          />
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2 relative mt-2">
            <label
              htmlFor="quizLevel"
              className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5"
            >
              <FormattedMessage id="AddOrEditRoomDialog.quizLevelField.label" />
              <span className="text-error"> *</span>
            </label>
            <Dropdown
              id="quizLevel"
              options={[
                { label: "Đề chuẩn", value: QUIZ_LEVEL_OBJECT.NORMAL },
                {
                  label: "Đề Premium",
                  value: QUIZ_LEVEL_OBJECT.PREMIUM,
                },
                { label: "Chọn đề có sẵn", value: QUIZ_LEVEL_OBJECT.CREATED },
              ]}
              placeholder={intl.formatMessage({
                id: "AddOrEditRoomDialog.quizLevelField.placeholder",
              })}
              className="w-full md:w-14rem rounded-full px-1"
              value={dataCreate.quizLevel}
              onChange={handleChangeQuizLevel}
              disabled={isUpdateRoom}
              itemTemplate={(option) => {
                return <div className="text-gray-900">{option.label}</div>;
              }}
              valueTemplate={(option, props) => {
                if (option) return <div className="text-gray-900">{option.label}</div>;
                return <div className="text-gray-400">{props.placeholder}</div>;
              }}
              dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            />
          </div>
          {dataCreate.topicId && Number(dataCreate?.quizLevel) === QUIZ_LEVEL_OBJECT.CREATED && (
            <div className="flex justify-between px-4">
              <span className="text-[12px] text-gray-700">
                {allFolderAndFile.files?.find((file) => file.id === dataCreate.topicId).title}
              </span>
              <button className="flex items-center gap-1" onClick={() => setIsOpenChooseExamDialog(true)}>
                <RepeatIcon />
                <span className="text-[12px] text-primary">
                  <FormattedMessage id="AddOrEditRoomDialog.reselectExam" />
                </span>
              </button>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="timeQuiz" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.timeQuizField.label" />
            <span className="text-error"> *</span>
          </label>
          <Dropdown
            id="timeQuiz"
            options={arrayQuizTime || []}
            placeholder={intl.formatMessage({
              id: "AddOrEditRoomDialog.timeQuizField.placeholder",
            })}
            className="w-full md:w-14rem rounded-full px-1"
            value={dataCreate.timeQuiz}
            onChange={handleChangeTimeQuiz}
            itemTemplate={(option) => {
              if (option === -1) {
                return (
                  <div className="text-gray-900">
                    <FormattedMessage id="common.selfInput" />
                  </div>
                );
              }
              return (
                <div className="text-gray-900">
                  <FormattedMessage id="common.minutesWithValue" values={{ nums: option }} />
                </div>
              );
            }}
            valueTemplate={(option, props) => {
              if (option) {
                return (
                  <div className="text-gray-900">
                    <FormattedMessage id="common.minutesWithValue" values={{ nums: option }} />
                  </div>
                );
              }
              if (props.value)
                return (
                  <span className={typeof props.value === "string" ? "text-gray-400" : "text-gray-900"}>
                    <FormattedMessage id="common.minutesWithValue" values={{ nums: props.value }} />
                  </span>
                );
              return <span className="text-gray-400">{props.placeholder}</span>;
            }}
            disabled={isUpdateRoom}
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
          />
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="startTime" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.startTimeField.label" />
          </label>
          <Calendar
            id="startTime"
            value={dataCreate.startTime}
            onChange={handleChangeStartTime}
            onFocus={() => {
              if (moment(dataCreate.startTime) < moment()) {
                setDataCreate((prev) => ({ ...prev, startTime: moment().toDate() }));
              }
            }}
            showTime
            dateFormat="dd/mm/yy"
            hourFormat="24"
            minDate={moment().toDate()}
            maxDate={moment().add(ADD_DAYS_MAX_START_TIME, "days").hours(23).minutes(59).toDate()}
            disabled={isUpdateRoom}
            className="[&>.p-inputtext]:rounded-full [&>.p-inputtext]:text-gray-900 px-1 "
          />
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="endTime" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.endTimeField.label" />
          </label>
          <Calendar
            id="endTime"
            value={dataCreate.endTime}
            onChange={handleChangeEndTime}
            showTime
            dateFormat="dd/mm/yy"
            hourFormat="24"
            minDate={moment(dataCreate.startTime).add(Number(dataCreate.timeQuiz), "minutes").toDate()}
            maxDate={moment(dataCreate.startTime).add(ADD_DAYS_MAX_END_TIME, "days").hours(23).minutes(59).toDate()}
            disabled={disableEndTime || isUpdateRoom}
            className="[&>.p-inputtext]:rounded-full [&>.p-inputtext]:text-gray-900 px-1"
          />
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label
            htmlFor="numOfQuestion"
            className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5"
          >
            Số câu<span className="text-error"> *</span>
          </label>
          <Dropdown
            id="numOfQuestion"
            options={roomCreationInfo?.quiz_creation_info
              ?.filter((ele: { time: number }) => ele.time === Number(dataCreate.timeQuiz))
              .map((item) => ({
                label: item.num_of_questions,
                value: item.num_of_questions,
              }))}
            placeholder="Tuỳ chọn"
            className="w-full md:w-14rem rounded-full px-1"
            value={dataCreate.numOfQuestions}
            valueTemplate={(option, props) => {
              if (option) {
                return (
                  <div className="text-gray-900">
                    <FormattedMessage id="common.questionsWithValue" values={{ nums: option.label }} />
                  </div>
                );
              }
              if (props.value) {
                return (
                  <span className={typeof props.value === "string" ? "text-gray-400" : "text-gray-900"}>
                    <FormattedMessage id="common.questionsWithValue" values={{ nums: props.value }} />
                  </span>
                );
              }
              return <span className="text-gray-400">{props.placeholder}</span>;
            }}
            onChange={handleChangeNumOfQuestion}
            disabled={isUpdateRoom || dataCreate.quizLevel === QUIZ_LEVEL_OBJECT.CREATED}
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
          />
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="password" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.passwordField.label" />
          </label>
          <InputText
            id="password"
            placeholder={intl.formatMessage({
              id: "AddOrEditRoomDialog.passwordField.placeholder",
            })}
            value={dataCreate.password}
            onChange={handleChangePassword}
            className={classNames("rounded-full placeholder-gray-400 text-gray-900 px-4", {
              "p-invalid":
                !isEmptyCustom(dataCreate.password.trim()) &&
                (dataCreate.password.trim().length > 16 || dataCreate.password.trim().length < 4),
            })}
          />
          {!isEmptyCustom(dataCreate.password.trim()) &&
            (dataCreate.password.trim().length > 16 || dataCreate.password.trim().length < 4) && (
              <small className="p-error">
                <FormattedMessage id="AddOrEditRoomDialog.passwordField.errorMessage" />
              </small>
            )}
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label
            htmlFor="delaySubmit"
            className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5"
          >
            <FormattedMessage id="AddOrEditRoomDialog.delaySubmitField.label" />
            <span className="text-error"> *</span>
          </label>
          <Dropdown
            id="delaySubmit"
            options={roomCreationInfo?.delay_submit?.list_time?.map((item) => ({
              label: intl.formatMessage(
                {
                  id: "common.minutesWithValue",
                },
                { nums: item }
              ),
              value: item,
            }))}
            placeholder={intl.formatMessage({
              id: "AddOrEditRoomDialog.delaySubmitField.placeholder",
            })}
            className="w-full md:w-14rem rounded-full px-1"
            value={dataCreate.delaySubmitTime}
            itemTemplate={(option) => {
              return <div className="text-gray-900">{option.label}</div>;
            }}
            valueTemplate={(option, props) => {
              if (option) return <div className="text-gray-900">{option.label}</div>;
              return <div className="text-gray-400">{props.placeholder}</div>;
            }}
            onChange={handleChangeDelaySubmitTime}
            disabled={isUpdateRoom}
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
          />
        </div>

        <div className="flex flex-col gap-2 relative mt-2">
          <label htmlFor="note" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="AddOrEditRoomDialog.noteField.label" />
          </label>
          <InputText
            id="note"
            placeholder={intl.formatMessage({
              id: "AddOrEditRoomDialog.noteField.placeholder",
            })}
            value={dataCreate.note}
            onChange={handleChangeNote}
            className={classNames("rounded-full placeholder-gray-400 text-gray-900 px-4", {
              "p-invalid": !isEmptyCustom(dataCreate.note.trim()) && dataCreate.note.trim().length > 250,
            })}
          />
          {!isEmptyCustom(dataCreate.note.trim()) && dataCreate.note.trim().length > 250 && (
            <small className="p-error">
              <FormattedMessage id="AddOrEditRoomDialog.noteField.errorMessage" />
            </small>
          )}
        </div>

        <div>
          <span className="text-error text-[12px]">* </span>
          <span className="text-[12px] text-gray-600">
            <FormattedMessage id="AddOrEditRoomDialog.requiredInfomation" />
          </span>
        </div>

        <div className="flex justify-between gap-2">
          <label htmlFor="name" className="font-semibold text-gray-900">
            <FormattedMessage id="AddOrEditRoomDialog.showOnlyMemberOfRoom" />
          </label>
          <div className="small-input-switch">
            <InputSwitch
              className=""
              checked={dataCreate.display_status === ROOM_DISPLAY_STATUS_CLOSE}
              onChange={(e) => {
                setDataCreate((prevState) => ({
                  ...prevState,
                  display_status: e?.value ? ROOM_DISPLAY_STATUS_CLOSE : ROOM_DISPLAY_STATUS_OPEN,
                }));
              }}
              pt={{
                slider: {
                  className: `shadow-none border-0 ${
                    dataCreate.display_status === ROOM_DISPLAY_STATUS_CLOSE ? "bg-secondary" : ""
                  }`,
                },
              }}
            />
          </div>
        </div>
        <div className="flex justify-between gap-2">
          <label htmlFor="name" className="font-semibold text-gray-900">
            <FormattedMessage id="AddOrEditRoomDialog.acceptUserInvite" />
          </label>
          <div className="small-input-switch">
            <InputSwitch
              className=""
              checked={dataCreate.teacher_invitee_only === 0}
              onChange={(e) => {
                setDataCreate((prevState) => ({
                  ...prevState,
                  teacher_invitee_only: e?.value ? 0 : 1,
                }));
              }}
              pt={{
                slider: {
                  className: `shadow-none border-0 ${dataCreate.teacher_invitee_only === 0 ? "bg-secondary" : ""}`,
                },
              }}
            />
          </div>
        </div>
        <div className="flex justify-between gap-2">
          <label htmlFor="name" className="font-semibold text-gray-900">
            <FormattedMessage id="AddOrEditRoomDialog.notifyWhenStartField.label" />
          </label>
          <div className="small-input-switch">
            <InputSwitch
              className=""
              checked={dataCreate.isNotify}
              onChange={handleChangeIsNotify}
              pt={{
                slider: {
                  className: `shadow-none border-0 ${dataCreate.isNotify ? "bg-secondary" : ""}`,
                },
              }}
            />
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex justify-between gap-2">
            <label htmlFor="isShuffle" className="font-semibold text-gray-900">
              <FormattedMessage id="AddOrEditRoomDialog.mixQuestionField.label" />
            </label>
            <div className="small-input-switch">
              <InputSwitch
                id="isShuffle"
                disabled={isUpdateRoom || isDisabledIsShuffle}
                checked={dataCreate.isShuffle}
                onChange={handleChangeIsShuffle}
                pt={{
                  slider: {
                    className: classNames("shadow-none border-0", { "bg-secondary": dataCreate.isShuffle }),
                  },
                }}
              />
            </div>
          </div>

          {dataCreate.isShuffle && (
            <>
              <div className="flex flex-col gap-2 relative mt-2">
                <label
                  htmlFor="numOfExamCodes"
                  className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5"
                >
                  <FormattedMessage id="AddOrEditRoomDialog.numOfExamCodes.label" />
                  <span className="text-error"> *</span>
                </label>

                <InputNumber
                  id="numOfExamCodes"
                  useGrouping={false}
                  value={dataCreate.numOfExamCodes}
                  disabled={isUpdateRoom}
                  onChange={handleChangeNumOfExamCodes}
                  placeholder={intl.formatMessage({
                    id: "AddOrEditRoomDialog.numOfExamCodes.placeholder",
                  })}
                  min={1}
                  className={classNames({
                    "p-invalid":
                      isChangeExamCodes.current &&
                      (isEmptyCustom(dataCreate.numOfExamCodes) || Number(dataCreate.numOfExamCodes) < 2),
                  })}
                  inputClassName="rounded-full placeholder-gray-400 text-gray-900"
                />
                <small className="text-gray-600 pl-4">
                  <FormattedMessage id="AddOrEditRoomDialog.numOfExamCodes.info" />
                </small>
              </div>

              <div className="flex flex-col gap-2 relative mt-2">
                <label
                  htmlFor="examDistribution"
                  className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5"
                >
                  <FormattedMessage id="AddOrEditRoomDialog.examDistribution.label" />
                </label>
                <Dropdown
                  id="examDistribution"
                  options={[
                    {
                      label: intl.formatMessage({ id: "AddOrEditRoomDialog.examDistribution.options.random" }),
                      value: "random",
                    },
                    {
                      label: intl.formatMessage({ id: "AddOrEditRoomDialog.examDistribution.options.order" }),
                      value: "order",
                    },
                  ]}
                  className="w-full md:w-14rem rounded-full px-1"
                  disabled={isUpdateRoom}
                  value={dataCreate.examDistribution}
                  defaultValue={dataCreate.examDistribution}
                  itemTemplate={(option) => {
                    return <div className="text-gray-900">{option.label}</div>;
                  }}
                  valueTemplate={(option) => {
                    return <div className="text-gray-900">{option.label}</div>;
                  }}
                  onChange={handleChangeExamDistribution}
                  dropdownIcon={<ArrowDownIcon stroke="#475467" />}
                />
              </div>
            </>
          )}
        </div>

        <Divider />

        <div id="info" className="flex flex-col gap-2">
          <span className="font-semibold text-gray-900">
            <FormattedMessage id="AddOrEditRoomDialog.more.note" />
          </span>
          <span className="text-[14px] text-gray-600">
            <FormattedMessage id="AddOrEditRoomDialog.more.note.description1" />
            <br />
            <FormattedMessage id="AddOrEditRoomDialog.more.note.description2" />
          </span>
        </div>
      </div>

      {isOpenChooseExamDialog && (
        <ChooseExamDialog
          listFolderAndFile={allFolderAndFile}
          onClose={() => {
            setIsOpenChooseExamDialog(false);
            if (!dataCreate.topicId) {
              setDataCreate((prev) => ({
                ...prev,
                quizLevel: QUIZ_LEVEL_OBJECT.NORMAL,
              }));
            }
          }}
          onSubmit={handleChooseExam}
        />
      )}

      {isOpenInputTimeQuiz && (
        <InputTimeQuizDialog
          isOpen={isOpenInputTimeQuiz}
          onClose={() => setIsOpenInputTimeQuiz(false)}
          onSubmit={(timeQuiz) => {
            setDataCreate((prevState) => ({
              ...prevState,
              timeQuiz: timeQuiz,
            }));
          }}
        />
      )}
    </Dialog>
  );
}
