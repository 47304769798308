/* eslint-disable */
import axios from "axios";
import { getCookie, setCookie } from "cookies-next";
import isEmpty from "lodash/isEmpty";
import Qs from "qs";
import { toast } from "react-toastify";
import { store as appStore } from "../../app/store";
import { commonActions } from "../../components/common/redux/action";

const request = axios.create({ withCredentials: true });

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const res = (error && error.response) || {};
    setCookie("ServerError", JSON.stringify({ ...res?.data?.error, status: res.status }));
    if (res.status === 403 || res.status === 401 || res?.data?.error?.code === "ETKINI") {
      appStore.dispatch(commonActions.handleLogOut({}));
    }
    return Promise.reject(error.response || { data: {} });
  }
);

const getBaseUrl = () => {
  return process.env.REACT_APP_BASE_URL_API;
};

const api = (options: any, store?: any) => {
  let accessToken: string = "";

  if (store) {
    accessToken = store?.getState()?.common?.accessToken;
  } else {
    accessToken =
      appStore?.getState()?.common?.accessToken ||
      JSON.parse(getCookie("userInfos") || "{}")?.token?.access_token ||
      JSON.parse((getCookie("accessToken") as string) || "{}");
  }

  if (!options?.noAuthentication && !isEmpty(accessToken)) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }
  options.headers = {
    ...options.headers,
    ["Client-Key"]: process.env.REACT_APP_APPLICATION_CLIENT_KEY,
    "Accept-Language": "vi",
  };
  return request({
    baseURL: getBaseUrl(),
    ...options,
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "repeat" }),
    headers: {
      ...options.headers,
    },
  });
};

export default api;
