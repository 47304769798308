import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  EGroupActions,
  EGroupStatusOption,
  TAddGroupRequest,
  TEditGroupRequest,
  TGroupResponse,
} from "../../../types/group";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import Dialog from "../../common/components/dialog/Dialog";
import { containsSpecialChars } from "../../common/utils/helper";
import { eventTracking } from "../../../firebase/firebaseConfig";

const MAX_LENGTH_TITLE = 128;
const MAX_LENGTH_NOTE = 255;

type TAddOrEditGroupDialogProps = {
  isOpen: boolean;
  groupDetail?: TGroupResponse;
  onClose: () => void;
  onSubmit: (formValue: TAddGroupRequest | TEditGroupRequest, actionType: EGroupActions) => void;
};

export default function AddOrEditGroupDialog({ isOpen, groupDetail, onClose, onSubmit }: TAddOrEditGroupDialogProps) {
  const intl = useIntl();
  const actionType: EGroupActions = !groupDetail ? EGroupActions.ADD : EGroupActions.EDIT;

  const [formValue, setFormValue] = useState<TAddGroupRequest | TEditGroupRequest>({
    title: "",
    addRoomStatus: EGroupStatusOption.OPEN,
    addMemberStatus: EGroupStatusOption.OPEN,
  });

  const handleSubmit = () => {
    onSubmit(formValue, actionType);
  };

  const isDisableButtonSubmit = useMemo(() => {
    if (actionType === EGroupActions.ADD) return !formValue.title.length;
    else if (actionType === EGroupActions.EDIT) {
      if (!formValue.title.length) return true;

      if (
        formValue.title.trim() === groupDetail!.title &&
        formValue.addRoomStatus === groupDetail!.status_add_room &&
        formValue.addMemberStatus === groupDetail!.status_add_member &&
        formValue.description?.trim() === groupDetail!.description
      )
        return true;

      return false;
    }
  }, [formValue, actionType, groupDetail]);

  useEffect(() => {
    if (actionType === EGroupActions.EDIT && groupDetail) {
      setFormValue((prev) => ({
        ...prev,
        groupHid: groupDetail?.hid,
        title: groupDetail?.title,
        addRoomStatus: groupDetail?.status_add_room,
        addMemberStatus: groupDetail?.status_add_member,
        description: groupDetail?.description,
      }));
    }
  }, [actionType, groupDetail]);

  useEffect(() => {
    if (actionType === EGroupActions.ADD) {
      eventTracking("dl_create_group_view");
    }
  }, [actionType]);

  const actions = (
    <div className="flex justify-center gap-4">
      <ButtonPrimary
        label={
          actionType === EGroupActions.ADD
            ? intl.formatMessage({ id: "createGroupDialog.add.submitButton.label" })
            : intl.formatMessage({ id: "common.save" })
        }
        onClick={handleSubmit}
        disabled={isDisableButtonSubmit}
        buttonSize="large"
      />
    </div>
  );

  return (
    <Dialog
      title={
        actionType === EGroupActions.ADD
          ? intl.formatMessage({ id: "createGroupDialog.add.title" })
          : intl.formatMessage({ id: "createGroupDialog.edit.title" })
      }
      isOpen={isOpen}
      closable
      actions={actions}
      onClose={onClose}
    >
      <div className="flex flex-col gap-y-6 py-3">
        <div className="flex flex-col gap-2">
          <label htmlFor="title">
            <FormattedMessage id="createGroupDialog.titleField.label" />
            <span className="text-error">*</span>
          </label>
          <InputText
            id="title"
            className="w-full shadow-none"
            placeholder={intl.formatMessage({ id: "createGroupDialog.titleField.placeholder" })}
            value={formValue.title}
            onChange={(e) => {
              if (!containsSpecialChars(e.target.value)) {
                setFormValue((prev) => ({ ...prev, title: e.target.value }));
              }
            }}
            maxLength={MAX_LENGTH_TITLE}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="addRoomStatus">
            <FormattedMessage id="createGroupDialog.addRoomStatusField.label" />
          </label>
          <Dropdown
            id="addRoomStatus"
            options={[
              { label: intl.formatMessage({ id: "group.statusOption.open" }), value: EGroupStatusOption.OPEN },
              { label: intl.formatMessage({ id: "group.statusOption.closed" }), value: EGroupStatusOption.CLOSED },
            ]}
            className="w-full shadow-none"
            value={formValue.addRoomStatus}
            onChange={(e) => setFormValue((prev) => ({ ...prev, addRoomStatus: e.value }))}
            pt={{
              item: {
                className: "",
              },
            }}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="addMemberStatus">
            <FormattedMessage id="createGroupDialog.addMemberStatusField.label" />
          </label>
          <Dropdown
            id="addMemberStatus"
            options={[
              { label: intl.formatMessage({ id: "group.statusOption.open" }), value: EGroupStatusOption.OPEN },
              { label: intl.formatMessage({ id: "group.statusOption.closed" }), value: EGroupStatusOption.CLOSED },
            ]}
            className="w-full shadow-none"
            value={formValue.addMemberStatus}
            onChange={(e) => setFormValue((prev) => ({ ...prev, addMemberStatus: e.value }))}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="note">
            <FormattedMessage id="createGroupDialog.noteField.label" />
          </label>
          <InputTextarea
            className="w-full shadow-none resize-none"
            value={formValue.description}
            onChange={(e) => setFormValue((prev) => ({ ...prev, description: e.target.value }))}
            rows={3}
            placeholder={intl.formatMessage(
              { id: "createGroupDialog.noteField.placeholder" },
              { maxLength: MAX_LENGTH_NOTE }
            )}
            maxLength={MAX_LENGTH_NOTE}
          />
        </div>
      </div>
    </Dialog>
  );
}
