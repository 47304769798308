export const ACTION_TYPES: { [x: string]: any } = {
  CREATE_GROUP: Symbol("CREATE_GROUP"),
  CREATE_GROUP_SUCCESS: Symbol("CREATE_GROUP_SUCCESS"),
  CREATE_GROUP_FAILED: Symbol("CREATE_GROUP_FAILED"),
  GET_LIST_GROUP: Symbol("GET_LIST_GROUP"),
  GET_LIST_GROUP_SUCCESS: Symbol("GET_LIST_GROUP_SUCCESS"),
  GET_LIST_GROUP_FAILED: Symbol("GET_LIST_GROUP_FAILED"),
  DELETE_GROUP: Symbol("DELETE_GROUP"),
  DELETE_GROUP_SUCCESS: Symbol("DELETE_GROUP_SUCCESS"),
  DELETE_GROUP_FAILED: Symbol("DELETE_GROUP_FAILED"),
  UPDATE_GROUP: Symbol("UPDATE_GROUP"),
  UPDATE_GROUP_SUCCESS: Symbol("UPDATE_GROUP_SUCCESS"),
  UPDATE_GROUP_FAILED: Symbol("UPDATE_GROUP_FAILED"),
  GET_GROUP_DETAIL: Symbol("GET_GROUP_DETAIL"),
  GET_GROUP_DETAIL_SUCCESS: Symbol("GET_GROUP_DETAIL_SUCCESS"),
  GET_GROUP_DETAIL_FAILED: Symbol("GET_GROUP_DETAIL_FAILED"),
  ADD_MEMBERS_TO_GROUP: Symbol("ADD_MEMBERS_TO_GROUP"),
  ADD_MEMBERS_TO_GROUP_SUCCESS: Symbol("ADD_MEMBERS_TO_GROUP_SUCCESS"),
  ADD_MEMBERS_TO_GROUP_FAILED: Symbol("ADD_MEMBERS_TO_GROUP_FAILED"),
  GET_GROUP_ACTION_HISTORY: Symbol("GET_GROUP_ACTION_HISTORY"),
  GET_GROUP_ACTION_HISTORY_SUCCESS: Symbol("GET_GROUP_ACTION_HISTORY_SUCCESS"),
  GET_GROUP_ACTION_HISTORY_FAILED: Symbol("GET_GROUP_ACTION_HISTORY_FAILED"),
  REMOVE_MEMBER_OF_GROUP: Symbol("REMOVE_MEMBER_OF_GROUP"),
  REMOVE_MEMBER_OF_GROUP_SUCCESS: Symbol("REMOVE_MEMBER_OF_GROUP_SUCCESS"),
  REMOVE_MEMBER_OF_GROUP_FAILED: Symbol("REMOVE_MEMBER_OF_GROUP_FAILED"),
  ADD_MEMBERS_OF_GROUP_TO_ROOM: Symbol("ADD_MEMBERS_OF_GROUP_TO_ROOM"),
  ADD_MEMBERS_OF_GROUP_TO_ROOM_SUCCESS: Symbol("ADD_MEMBERS_OF_GROUP_TO_ROOM_SUCCESS"),
  ADD_MEMBERS_OF_GROUP_TO_ROOM_FAILED: Symbol("ADD_MEMBERS_OF_GROUP_TO_ROOM_FAILED"),
  GET_MEMBER_RESULT_IN_GROUP: Symbol("GET_MEMBER_RESULT_IN_GROUP"),
  GET_MEMBER_RESULT_IN_GROUP_SUCCESS: Symbol("GET_MEMBER_RESULT_IN_GROUP_SUCCESS"),
  GET_MEMBER_RESULT_IN_GROUP_FAILED: Symbol("GET_MEMBER_RESULT_IN_GROUP_FAILED"),
  GET_ALL_MEMBERS: Symbol("GET_ALL_MEMBERS"),
  GET_ALL_MEMBERS_SUCCESS: Symbol("GET_ALL_MEMBERS_SUCCESS"),
  GET_ALL_MEMBERS_FAILED: Symbol("GET_ALL_MEMBERS_FAILED"),
  GET_MEMBER_RESULT: Symbol("GET_MEMBER_RESULT"),
  GET_MEMBER_RESULT_SUCCESS: Symbol("GET_MEMBER_RESULT_SUCCESS"),
  GET_MEMBER_RESULT_FAILED: Symbol("GET_MEMBER_RESULT_FAILED"),
};
