import zlib from "react-zlib-js";

export const facebookLink = process.env.REACT_APP_FACEBOOK_LINK;
export const youtubeLink = process.env.REACT_APP_YOUTUBE_LINK;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const MAIN_DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;
export const APP_NAME = process.env.REACT_APP_THITOT_NAME;
export const APP_DESCRIPTION = process.env.REACT_APP_META_DESCRIPTION;
export const SERVER_NAME = process.env.REACT_APP_SERVER_NAME;

const clientInfo = {
  timezone: "Asia/Ho_Chi_Minh",
  device_name: "web",
  app_version: process.env.REACT_APP_VERSION,
  primary_language: "vi-VN",
  platform: "web",
};

// ENV Local
export const CONFIG_SERVER = {
  ci: zlib.gzipSync(JSON.stringify(clientInfo)).toString("base64"),
  language: "vi_VN",
  timezone: "Asia/Ho-Chi-Minh",
  platform: "web",
  os_version: "0.1.0",
};

export const FIREBASE_INFO = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};
