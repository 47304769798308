export type TExam = {
  id: number;
  title: string;
  slug: string;
  status: string;
  updated_at: number;
};

export enum EExamActions {
  SHARE = "SHARE",
  MOVE = "MOVE",
  EDIT = "EDIT",
  DOWNLOAD = "DOWNLOAD",
  DELETE = "DELETE",
}
