import React from "react";
import { FormattedMessage } from "react-intl";
import { OAuthCredential } from "firebase/auth";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../../../assets";
import { auth, eventTracking, providerApple, providerFacebook, providerGoogle } from "../../../firebase/firebaseConfig";
import { commonActions } from "../../common/redux/action";
import { useDispatch } from "react-redux";
import { trackingNameOfProvider } from "../../common/utils/constant";

type TLoginSocialProps = {
  onLoginSuccess: () => void;
};

export default function LoginSocial({ onLoginSuccess }: TLoginSocialProps) {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-6 w-full">
      <button
        className="flex items-center justify-center gap-2 w-full px-2.5 py-3 rounded-[100px] border border-gray-200"
        onClick={() => {
          eventTracking("select_login_type", { type_name: trackingNameOfProvider["google.com"] });
          auth.signInWithPopup(providerGoogle).then((result) => {
            const credential = result.credential as OAuthCredential;
            const unsubscribe = auth.onAuthStateChanged((user) => {
              user?.getIdToken()?.then((ele) => {
                dispatch(
                  commonActions.addFireBaseInfos({
                    accessToken: credential?.accessToken,
                    idToken: ele,
                    // pendingToken: credential?.pendingToken,
                    providerId: credential?.providerId,
                    signInMethod: credential?.signInMethod,
                  })
                );
                unsubscribe();
              });
            });
            onLoginSuccess();
          });
        }}
      >
        <GoogleIcon />
        <span className="text-[14px] font-semibold text-black">
          <FormattedMessage id="login.loginWithEmail" />
        </span>
      </button>
      <button
        className="flex items-center justify-center gap-2 w-full px-2.5 py-3 rounded-[100px] bg-black"
        onClick={() => {
          eventTracking("select_login_type", { type_name: trackingNameOfProvider["apple.com"] });
          auth.signInWithPopup(providerApple).then((result) => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
              user?.getIdToken()?.then((ele) => {
                const credential = result.credential as OAuthCredential;
                dispatch(
                  commonActions.addFireBaseInfos({
                    accessToken: credential?.accessToken,
                    idToken: ele,
                    // pendingToken: credential?.pendingToken,
                    providerId: credential?.providerId,
                    signInMethod: credential?.signInMethod,
                  })
                );
                unsubscribe();
              });
            });
            onLoginSuccess();
          });
        }}
      >
        <AppleIcon />
        <span className="text-[14px] font-semibold text-white">
          <FormattedMessage id="login.loginWithAppleID" />
        </span>
      </button>
      <button
        className="flex items-center justify-center gap-2 w-full px-2.5 py-3 rounded-[100px] bg-blue1"
        onClick={() => {
          eventTracking("select_login_type", { type_name: trackingNameOfProvider["facebook.com"] });
          auth.signInWithPopup(providerFacebook).then((result) => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
              user?.getIdToken()?.then((ele) => {
                const credential = result.credential as OAuthCredential;
                dispatch(
                  commonActions.addFireBaseInfos({
                    accessToken: credential?.accessToken,
                    idToken: ele,
                    // pendingToken: credential?.pendingToken,
                    providerId: credential?.providerId,
                    signInMethod: credential?.signInMethod,
                  })
                );
                unsubscribe();
              });
            });
            onLoginSuccess();
          });
        }}
      >
        <FacebookIcon />
        <span className="text-[14px] font-semibold text-white">
          <FormattedMessage id="login.loginWithFacebook" />
        </span>
      </button>
    </div>
  );
}
