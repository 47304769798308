import React from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "react-use";

import { PencilEdit, TrashIcon } from "../../../../assets";
import ShowBasicDataContent from "../ShowBasicDataContent";
import { quizAnswerType } from "../../../common/utils/constant";
import ConstructedResponseCard from "./ConstructedReponseCard";

const SelfInputQuestionRow = ({
  qs,
  questionNumber,
  isViewOnly = false,
  onClickDeleteQuestion,
  onClickEditQuestion,
  onClickShowSolution,
  onClickQuestionContent,
}) => {
  const { hideLeftSide } = useSelector((state: { common }) => state?.common);
  const windowSize = useWindowSize();
  const breakShowOption = windowSize?.width - 312 * 2 - (hideLeftSide ? 312 : 82) > 900;

  return (
    <div id={`question_${qs?.ordinal}`}>
      <div className="py-4 flex flex-col">
        {qs?.contents?.map((e, ix) => (
          <button
            key={e?.id}
            className="flex w-full"
            onClick={() => e?.content_type === "html" && onClickQuestionContent()}
          >
            {ix === 0 && <span className="font-[600] mr-2 w-max text-start">Câu {questionNumber}:</span>}{" "}
            <div className="w-[calc(100%-80px)] text-start">
              <ShowBasicDataContent ele={e} />
            </div>
          </button>
        ))}
      </div>
      {(() => {
        switch (qs?.answer_type) {
          case quizAnswerType.ONE_ANSWER:
          case quizAnswerType.MANY_ANSWER:
            return (
              <div className={`mt-[-12px] w-full grid ${breakShowOption ? "grid-cols-2 gap-x-6" : "grid-cols-1"}`}>
                {qs?.options?.map((op) => (
                  <div key={op.ordinal}>
                    <div
                      className={`min-h-[48px] py-2 border rounded-[8px] my-3 flex items-center px-3 ${
                        Boolean(op?.correct) && "border-primary"
                      }`}
                    >
                      {op?.contents?.map((opc, i) => (
                        <span key={opc.id} className="flex items-baseline gap-1">
                          <span>{i === 0 && op?.answer_key}.</span> <ShowBasicDataContent ele={opc} />
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            );
          case quizAnswerType.CONSTRUCTED_RESPONSE: {
            return <ConstructedResponseCard qs={qs} />;
          }
        }
      })()}

      {!isViewOnly && (
        <div className="flex justify-between items-center w-full font-[600] mt-6">
          <button className="text-secondary" onClick={onClickShowSolution}>
            Hướng dẫn giải
          </button>
          <div className="flex gap-[64px]">
            <button className="flex gap-2 text-primary" onClick={onClickEditQuestion}>
              <PencilEdit stroke="#38B000" /> Sửa
            </button>
            <button className="flex gap-2 text-red-500" onClick={onClickDeleteQuestion}>
              <TrashIcon />
              Xoá câu hỏi
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelfInputQuestionRow;
