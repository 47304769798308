import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import React from "react";
import { TrashIcon } from "../../../assets";

type TMemberItemProps = {
  member: any;
  mode: "ranking" | "member";
  deletable?: boolean;
  onDeleteMember: (memberId) => void;
  isLast?: boolean;
};

export default function MemberItem({ member, mode, deletable = true, onDeleteMember, isLast }: TMemberItemProps) {
  return (
    <div
      className={`flex justify-between items-center gap-2 px-1 py-2 border-gray-200 min-h-[65px] ${
        !isLast && "border-b"
      }`}
    >
      <div className="flex items-center gap-3 min-w-min">
        <Avatar size="normal" shape="circle" className="p-overlay-badge" image={member?.photo_url ?? "/logo192.png"}>
          {mode === "ranking" && member.ranking && (
            <Badge
              value={member.ranking}
              severity="success"
              pt={{
                root: {
                  className: "bottom-0 top-auto translate-y-1/3 translate-x-1/3 bg-primary rounded-[100px]",
                },
              }}
            />
          )}
        </Avatar>
        <div className="flex-1 ">
          <p className="text-[14px] font-semibold text-gray-900 line-clamp-2">{member.display_name}</p>
          {member.exam_code && <p className="text-[12px] text-gray-400 line-clamp-1">{member.exam_code}</p>}
        </div>
      </div>
      <div>
        {mode === "ranking"
          ? member.score && <div>{Number(member.score).toFixed(2)}</div>
          : deletable && (
              <Button
                icon={<TrashIcon />}
                rounded
                text
                severity="danger"
                onClick={() => onDeleteMember(member?.member_hid)}
              />
            )}
      </div>
    </div>
  );
}
