import React, { ReactNode } from "react";
import { AddFolderIcon } from "../../../assets";
import { TFolder } from "../../../types/folder";
import { FormattedMessage } from "react-intl";
import { eventTracking } from "../../../firebase/firebaseConfig";

type TFolderListProps = {
  folderList: TFolder[];
  isHideAddFolderButton: boolean;
  renderItem: (folder: TFolder) => ReactNode;
  onClickButtonCreateFolder?: () => void;
};

export default function FolderList({
  folderList,
  isHideAddFolderButton,
  renderItem,
  onClickButtonCreateFolder,
}: TFolderListProps) {
  return (
    <div className="px-10 py-3">
      <div className="max-h-[272px] grid grid-cols-6 grid-flow-row gap-6 overflow-auto">
        {!isHideAddFolderButton && (
          <button
            className="flex flex-col items-center justify-center w-full min-w-[148px] hover:bg-gray-200 duration-200"
            onClick={() => {
              eventTracking("select_create_folder_button");
              onClickButtonCreateFolder?.();
            }}
          >
            <div className="flex flex-col items-center justify-center gap-2 p-3 border border-gray200 rounded-[20px]">
              <AddFolderIcon />
              <span className="text-gray-900">
                <FormattedMessage id="createFolder.button" />
              </span>
            </div>
          </button>
        )}
        {folderList?.map(renderItem)}
      </div>
    </div>
  );
}
