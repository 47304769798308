import api from "../../../utils/configs/api";

const getCreationInfoCreateExam = (params) => {
  const option = {
    method: "get",
    url: "teacher/exam-creation-info",
    params,
  };
  return api(option);
};

export const getAuthorize = (data = {}) => {
  return api({
    method: "post",
    url: "authorize",
    data,
    noAuthentication: true,
  });
};

export const signIn = (store, data = {}, headers = {}) => {
  return api({
    method: "post",
    url: "sign-in",
    data,
    headers,
    noAuthentication: true,
  });
};

export const signOutApi = (store, data = {}) => {
  return api({
    method: "post",
    url: "sign-out",
    data,
  });
};

export const authentication = (data: {
  signature: string;
  timestamp: number;
  nonce: string;
  platform: string;
  app_version: string | undefined;
}) => {
  return api({
    method: "post",
    url: "auth",
    data,
  });
};

export const createExamination = (data) => {
  return api({
    method: "post",
    url: "teacher/create-examination",
    data,
  });
};

const handleGetListRoom = () => {
  return api({
    method: "get",
    url: "teacher/rooms",
  });
};

const handleConvertDocx = (data) => {
  return api({
    method: "post",
    url: "teacher/convert-docx",
    data,
  });
};
export const getRoomCreationInfo = () => {
  return api({
    method: "get",
    url: "teacher/room-creation-info",
  });
};

const getExamDetail = (params) => {
  return api({
    method: "get",
    url: `teacher/examination/${params?.topicSlug}`,
  });
};

const editExam = (data) => {
  return api({
    method: "patch",
    url: `teacher/examination/${data?.topicSlug}`,
    data: data?.data,
  });
};
const reportQuiz = (data) => {
  const option = {
    method: "post",
    url: `report/quiz`,
    data,
  };
  return api(option);
};
const getDashboard = () => {
  const option = {
    method: "get",
    url: `teacher/over-view-daily`,
  };
  return api(option);
};
const getTeacherInfo = () => {
  const option = {
    method: "get",
    url: `associate/info`,
  };
  return api(option);
};
const getGradeAndSubject = () => {
  const option = {
    method: "get",
    url: `asks/post/creation-info`,
  };
  return api(option);
};
const updateAccountInfo = (data) => {
  const option = {
    method: "patch",
    url: `teacher/update-info`,
    data,
  };
  return api(option);
};
const registerTeacher = (data) => {
  const option = {
    method: "post",
    url: `associate/register`,
    data,
  };
  return api(option);
};
const updateProfile = (data) => {
  return api({
    method: "put",
    url: `profile`,
    data,
  });
};
const getReportReview = () => {
  const option = {
    method: "get",
    url: `teacher/reported-question`,
  };
  return api(option);
};
const changeStatusReport = (data) => {
  const option = {
    method: "patch",
    url: `teacher/quiz-report/${data?.quiz_report_id}`,
    data,
  };
  return api(option);
};
const getUserInfo = (params) => {
  const option = {
    method: "get",
    url: `info/${params?.member_hid}`,
  };
  return api(option);
};

const createExam = (data) => {
  const option = {
    method: "post",
    url: "teacher/create-examination",
    data,
  };
  return api(option);
};
const editQuestion = (data) => {
  const option = {
    method: "patch",
    url: `teacher/${data?.question_id}/edit`,
    data: data?.data,
  };
  return api(option);
};

export const commonServices = {
  getCreationInfoCreateExam,
  getAuthorize,
  signIn,
  authentication,
  createExamination,
  handleGetListRoom,
  handleConvertDocx,
  getRoomCreationInfo,
  getExamDetail,
  editExam,
  reportQuiz,
  getDashboard,
  getTeacherInfo,
  getGradeAndSubject,
  updateAccountInfo,
  registerTeacher,
  updateProfile,
  getReportReview,
  changeStatusReport,
  getUserInfo,
  createExam,
  editQuestion,
};
