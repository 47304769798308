import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NoData from "../../../components/common/components/NoData";
import MemberResultTable from "../../../components/manage_student/components/MemberResultTable";
import { manageStudentActions } from "../../../components/manage_student/redux/action";
import { COLUMN_KEY_MEMBER_RESULT_TABLE } from "../../../components/manage_student/utils/constant";
import { eventTracking } from "../../../firebase/firebaseConfig";

export default function MemberDetailOfGroup() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { groupHid, memberHid } = useParams();

  const { memberResultInGroup, userInfos } = useSelector((state: { manageStudent; common }) => ({
    userInfos: state.common.userInfos,
    memberResultInGroup: state.manageStudent.memberResultInGroup,
  }));

  const [isLoadingGetMemberResultInGroup, setIsLoadingGetMemberResultInGroup] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (userInfos.member?.pub_id) {
      setIsLoadingGetMemberResultInGroup(true);
      dispatch(
        manageStudentActions.getMemberResultInGroup({ groupHid, memberHid }, () => {
          setIsLoadingGetMemberResultInGroup(false);
        })
      );
      dispatch(manageStudentActions.getGroupDetail({ groupHid }));
    }
  }, []);

  useEffect(() => {
    eventTracking("screen_detail_student_in_group_view");
  }, []);

  return (
    <div className="main-content">
      {isLoadingGetMemberResultInGroup ? (
        <div className="w-full pt-[200px] flex items-center justify-center">
          <ProgressSpinner
            strokeWidth="2"
            animationDuration="2s"
            pt={{
              circle: {
                className: "stroke-primary",
              },
            }}
          />
        </div>
      ) : memberResultInGroup.items.length === 0 ? (
        <div className="w-full pt-[100px] flex items-center justify-center">
          <NoData message={intl.formatMessage({ id: "common.nodata" })} />
        </div>
      ) : (
        <MemberResultTable
          memberResult={memberResultInGroup.items}
          columnKeysDisplay={[
            COLUMN_KEY_MEMBER_RESULT_TABLE.NO,
            COLUMN_KEY_MEMBER_RESULT_TABLE.TITLE,
            COLUMN_KEY_MEMBER_RESULT_TABLE.SCORE,
            COLUMN_KEY_MEMBER_RESULT_TABLE.RANKING,
            COLUMN_KEY_MEMBER_RESULT_TABLE.SPENT_TIME,
          ]}
        />
      )}
    </div>
  );
}
