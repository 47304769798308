import { isEmpty } from "lodash";
import { useEffect } from "react";
import React, { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo192 from "../assets/images/logo192.png";
import LoginManual from "../components/auth/components/LoginManual";
import LoginSocial from "../components/auth/components/LoginSocial";
import AlertDialog from "../components/common/components/dialog/AlertDialog";
import DialogRegisterTeacher from "../components/common/components/dialog/DialogRegisterTeacher";
import { commonActions } from "../components/common/redux/action";
import { ACCOUNT_TEACHER_STATUS } from "../components/common/utils/constant";
import { TCommonState } from "../components/common/utils/type";
import { eventTracking } from "../firebase/firebaseConfig";
import { APP_NAME } from "../utils/constant/env";

export default function Login() {
  const intl = useIntl();
  const { userInfos, openDialogRegisterTeacher, openDialogNotifyRegister, openDialogUpdateUsername } = useSelector(
    (state: { common: TCommonState }) => state.common
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !!userInfos?.member &&
      ![ACCOUNT_TEACHER_STATUS.NEW, ACCOUNT_TEACHER_STATUS.NOT_REGISTER].some((status) => status === userInfos?.status)
    ) {
      navigate("/");
    }
  }, [userInfos?.member, userInfos?.status]);

  useEffect(() => {
    if (openDialogNotifyRegister === ACCOUNT_TEACHER_STATUS?.REGISTER) {
      eventTracking("dl_waiting_review_account_view");
    }
  }, [openDialogNotifyRegister]);

  useEffect(() => {
    eventTracking("dl_login_view");
  }, []);

  useEffect(() => {
    if (userInfos?.member?.pub_id) {
      dispatch(commonActions.handleGetUserInfo({ member_hid: userInfos?.member?.pub_id }));
    }
  }, [userInfos?.member?.pub_id]);

  return (
    <div className="main-content h-[100vh] xl:h-[calc(100vh-16px)] xl:rounded-tl-[50px] ">
      <div className="flex items-center justify-center w-full bg-gray-200 min-h-[100vh] p-4">
        <div className="flex flex-col items-center justify-center gap-8 w-[560px] px-4 xl:px-10 py-6 xl:py-14 bg-white rounded-2xl xl:rounded-3xl">
          <div className="hidden xl:flex items-center gap-1">
            <img src={Logo192} alt="" className="w-[56px] h-[56px]" />
            <span className="font-bold text-gray-900 text-[24px]">{APP_NAME}</span>
          </div>

          <div className="hidden xl:flex flex-col items-center">
            <span className="text-[36px] text-gray-900 font-normal">
              <FormattedMessage id="login.hello" />
            </span>
            <span className="text-[24px] text-gray-900 font-normal">
              <FormattedMessage id="login.loginToContinue" />
            </span>
          </div>

          <LoginSocial onLoginSuccess={() => {}} />

          <div className="relative w-full">
            <hr className="text-gray-400" />
            <p className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white px-3 text-gray-400 text-[12px] uppercase">
              <FormattedMessage id="common.or" />
            </p>
          </div>

          <LoginManual />
        </div>
      </div>
      {openDialogRegisterTeacher && !openDialogUpdateUsername && (
        <DialogRegisterTeacher
          isOpen={openDialogRegisterTeacher}
          onClose={() => {
            dispatch(commonActions.handleSetOpenDialogRegisterTeacher(false));
          }}
        />
      )}
      {!isEmpty(openDialogNotifyRegister) && (
        <AlertDialog
          open={!isEmpty(openDialogNotifyRegister)}
          handleClose={() => {
            dispatch(commonActions.handleSetOpenDialogNotifyRegisterTeacher(""));
            dispatch(commonActions.handleLogOut({}));
          }}
          notify={{
            message: intl.formatMessage({
              id:
                openDialogNotifyRegister === ACCOUNT_TEACHER_STATUS?.REGISTER
                  ? "registerTeacher.registerWaiting"
                  : openDialogNotifyRegister === ACCOUNT_TEACHER_STATUS?.REJECTED
                  ? "registerTeacher.registerRejected"
                  : "registerTeacher.registerBlocked",
            }),
          }}
          isHideCancel
        />
      )}
    </div>
  );
}
