import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ErrorIcon } from "../../../assets";
import { EFolderActions } from "../../../types/folder";
import ButtonGhost from "../../common/components/button/ButtonGhost";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import Dialog from "../../common/components/dialog/Dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { containsSpecialChars } from "../../common/utils/helper";

type TAddOrEditFolderDialogProps = {
  isOpen: boolean;
  isLoadingSubmit: boolean;
  folder?: { id: number; title: string };
  onClose: () => void;
  onSubmit: (formValue: { id?: number; title: string }, actionType: EFolderActions) => void;
};

export default function AddOrEditFolderDialog({
  isOpen,
  isLoadingSubmit,
  folder,
  onClose,
  onSubmit,
}: TAddOrEditFolderDialogProps) {
  const intl = useIntl();
  const actionType: EFolderActions = !folder ? EFolderActions.ADD : EFolderActions.EDIT;

  const [formValue, setFormValue] = useState<{
    id?: number;
    title: string;
  }>({
    title: "",
  });

  const [errorMessage, setErrorMessage] = useState<{ title: string }>({
    title: "",
  });

  const validateTitle = (title: string) => {
    if (title.trim().length > 250) {
      setErrorMessage((prev) => ({
        ...prev,
        title: intl.formatMessage({ id: "createFolder.titleField.errorText" }, { max: 250 }),
      }));
      return false;
    } else if (title.trim().length === 0) {
      setErrorMessage((prev) => ({
        ...prev,
        title: "",
      }));
      return false;
    } else if (actionType === EFolderActions.EDIT) {
      if (folder?.title === formValue.title.trim()) {
        setErrorMessage((prev) => ({
          ...prev,
          title: "",
        }));
        return false;
      }
    }
    setErrorMessage((prev) => ({
      ...prev,
      title: "",
    }));
    return true;
  };

  const handleSubmit = () => {
    onSubmit(formValue, actionType);
  };

  const isDisableButtonSubmit = useMemo(() => !validateTitle(formValue.title), [formValue.title]);

  useEffect(() => {
    if (actionType === EFolderActions.EDIT && folder) {
      setFormValue((prev) => ({
        ...prev,
        id: folder.id,
        title: folder?.title,
      }));
    }
  }, [actionType, folder]);

  const actions = (
    <div className="flex justify-end gap-4">
      <ButtonGhost label="Huỷ" onClick={onClose} />
      <ButtonPrimary
        label={actionType === EFolderActions.ADD ? "Tạo" : "Lưu"}
        onClick={handleSubmit}
        disabled={isDisableButtonSubmit || isLoadingSubmit}
        icon={
          isLoadingSubmit && (
            <ProgressSpinner
              style={{ width: "28px", height: "28px", margin: 0 }}
              strokeWidth="1"
              animationDuration="2s"
              pt={{
                circle: {
                  className: "stroke-primary",
                },
              }}
            />
          )
        }
        buttonIconPos="right"
      />
    </div>
  );

  return (
    <Dialog
      title={intl.formatMessage({ id: "createFolder.title" })}
      isOpen={isOpen}
      onClose={onClose}
      actions={actions}
    >
      <InputText
        className="w-full shadow-none focus:border-secondary hover:border-secondary"
        placeholder={intl.formatMessage({
          id: "createFolder.titleField.placeholder",
        })}
        value={formValue.title}
        onChange={(e) => {
          if (!containsSpecialChars(e.target.value)) {
            validateTitle(e.target.value);
            setFormValue((prev) => ({ ...prev, title: e.target.value }));
          }
        }}
      />
      {errorMessage.title && (
        <Message
          icon={<ErrorIcon />}
          className="bg-transparent"
          severity="error"
          text={errorMessage.title}
          pt={{
            text: {
              className: "text-[12px]",
            },
            root: {
              className: "gap-1 px-0",
            },
          }}
        />
      )}
    </Dialog>
  );
}
