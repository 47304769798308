import { classNames } from "primereact/utils";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { EyeIcon, EyeSlashIcon } from "../../../assets";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import InputText from "../../common/components/lib/InputText";
import { commonActions } from "../../common/redux/action";
import { emailRegex } from "../../common/utils/constant";
import { TCommonState } from "../../common/utils/type";

const LoginManual = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { errorMessageFormLoginFromServer } = useSelector((state: { common: TCommonState }) => state.common);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });
  const [isShowPassword, setIsShowPassword] = useState(false);

  const isDisableSubmitButton = useMemo(() => {
    if (form.email.length === 0 || form.password.length === 0) return true;
    if (errorMessage.email || errorMessage.password) return true;
    return false;
  }, [form.email, form.password, errorMessage.email, errorMessage.password]);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      email: e.target.value,
    }));
    setErrorMessage((prev) => ({ ...prev, email: "" }));
    dispatch(commonActions.setErrorMessageFormLoginFromServer(""));
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      password: e.target.value.trim(),
    }));
    setErrorMessage((prev) => ({ ...prev, password: "" }));
    dispatch(commonActions.setErrorMessageFormLoginFromServer(""));
  };

  const handleSubmit = async () => {
    dispatch(
      commonActions.loginWithEmailAndPassword({
        email: form.email,
        password: form.password,
      })
    );
  };

  return (
    <form
      className="w-full space-y-4"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className="flex flex-col gap-2 relative">
        <label htmlFor="name" className="text-[12px] text-gray-700 absolute -top-0.5 left-4 bg-white z-50 p-0.5">
          <FormattedMessage id="login.emailField.label" />
        </label>
        <InputText
          id="email"
          placeholder={intl.formatMessage({
            id: "login.emailField.placeholder",
          })}
          className={classNames("rounded-full placeholder-gray-400 text-gray-900 px-4", {
            "p-invalid": false,
          })}
          value={form.email}
          onChange={handleChangeEmail}
          onBlur={(event) => {
            if (event.target.value.length === 0) {
              setErrorMessage({
                ...errorMessage,
                email: "login.emailField.error.empty",
              });
            } else if (!emailRegex.test(event.target.value)) {
              setErrorMessage({
                ...errorMessage,
                email: "login.emailField.error.invalid",
              });
            } else {
              setErrorMessage({
                ...errorMessage,
                email: "",
              });
            }
          }}
        />
        {errorMessage.email.length !== 0 && (
          <small className="p-error ml-4">
            <FormattedMessage id={errorMessage.email} />
          </small>
        )}
      </div>

      <div>
        <div className="relative">
          <label htmlFor="name" className="text-[12px] text-gray-700 absolute -top-2 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="login.passwordField.label" />
          </label>
          <InputText
            id="password"
            type={isShowPassword ? "text" : "password"}
            placeholder={intl.formatMessage({
              id: "login.passwordField.placeholder",
            })}
            className={classNames("rounded-full placeholder-gray-400 text-gray-900 px-4 pr-12", {
              "p-invalid": false,
            })}
            value={form.password}
            onChange={handleChangePassword}
            onBlur={(event) => {
              if (event.target.value.length === 0) {
                setErrorMessage({
                  ...errorMessage,
                  password: "login.passwordField.error.empty",
                });
              } else {
                setErrorMessage({
                  ...errorMessage,
                  password: "",
                });
              }
            }}
          />
          <button
            className="absolute right-4 top-1/2 -translate-y-1/2"
            type="button"
            onClick={() => setIsShowPassword((prev) => !prev)}
          >
            {isShowPassword ? (
              <EyeIcon stroke={form?.password?.length === 0 ? "#98A2B3" : "#46474A"} />
            ) : (
              <EyeSlashIcon stroke={form?.password?.length === 0 ? "#98A2B3" : "#46474A"}/>
            )}
          </button>
        </div>
        {errorMessage.password.length !== 0 && (
          <small className="p-error ml-4">
            <FormattedMessage id={errorMessage.password} />
          </small>
        )}
        {errorMessageFormLoginFromServer.length !== 0 && (
          <small className="p-error ml-4 mt-0.5 inline-block">
            <FormattedMessage id="login.errorMessageFormLoginFromServer" values={{ errorMessageFormLoginFromServer }} />
          </small>
        )}
      </div>

      <ButtonPrimary className="w-full" disabled={isDisableSubmitButton}>
        <span className="font-semibold">
          <FormattedMessage id="login.button" />
        </span>
      </ButtonPrimary>
    </form>
  );
};

export default LoginManual;
