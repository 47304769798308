import AccountInfo from "../../routes/AccountInfo";
import Dashboard from "../../routes/Dashboard";
import Login from "../../routes/Login";
import ManageDocument from "../../routes/ManageDocument";
import UseAppInDeskTop from "../../routes/UseAppInDeskTop";
import UserManual from "../../routes/UserManual";
import CreateDocxAndPDF from "../../routes/manage_exam/CreateDocxAndPDF";
import CreateGroupExam from "../../routes/manage_exam/CreateGroupExam";
import ExamDetail from "../../routes/manage_exam/ExamDetail";
import ExamDetailOfRoom from "../../routes/manage_room/ExamDetail";
import SelfInputQuestion from "../../routes/manage_exam/SelfInputQuestion";
import ThousandQuestions from "../../routes/manage_exam/ThousandQuestions";
import ReportReview from "../../routes/manage_report/ReportReview";
import ListRoom from "../../routes/manage_room/ListRoom";
import RoomDetail from "../../routes/manage_room/RoomDetail";
import GroupDetail from "../../routes/manage_student/groups/GroupDetail";
import ManageGroup from "../../routes/manage_student/groups/ManageGroup";
import MemberDetailOfGroup from "../../routes/manage_student/groups/MemberDetailOfGroup";
import ManageMember from "../../routes/manage_student/members/ManageMember";
import MemberDetail from "../../routes/manage_student/members/MemberDetail";

export const ROUTES = {
  home: {
    path: "/",
    index: "/",
  },
  login: {
    path: "/dang-nhap",
    index: "/dang-nhap",
  },
  manageStudent: {
    path: "/quan-ly-hoc-sinh",
    index: "/quan-ly-hoc-sinh",
  },
  accountInfo: {
    path: "/trang-ca-nhan",
    index: "/trang-ca-nhan",
  },
  manageDocument: {
    path: "/quan-ly-tai-lieu",
    index: "/quan-ly-tai-lieu",
    mine: {
      path: "/quan-ly-tai-lieu/cua-toi",
      index: "/quan-ly-tai-lieu/cua-toi",
      folder: {
        path: "/quan-ly-tai-lieu/cua-toi/:folderId",
        index: "/quan-ly-tai-lieu/cua-toi/:folderId",
      },
    },
    shared: {
      path: "/quan-ly-tai-lieu/duoc-chia-se",
      index: "/quan-ly-tai-lieu/duoc-chia-se",
      folder: {
        path: "/quan-ly-tai-lieu/duoc-chia-se/:folderId",
        index: "/quan-ly-tai-lieu/duoc-chia-se/:folderId",
      },
    },
  },
  createExam: {
    path: "/tao-de",
    index: "/tao-de",
    pdf: {
      path: "/tao-de/pdf",
      index: "/tao-de/pdf",
    },
    docx: {
      path: "/tao-de/docx",
      index: "/tao-de/docx",
    },
    nhbd: {
      path: "/tao-de/ngan-hang-bo-de",
      index: "/tao-de/ngan-hang-bo-de",
    },
    nhch: {
      path: "/tao-de/ngan-hang-cau-hoi",
      index: "/tao-de/ngan-hang-cau-hoi",
    },
    selfInput: {
      path: "/tao-de/tu-nhap",
      index: "/tao-de/tu-nhap",
    },
  },
  examDetail: {
    path: "/quan-ly-tai-lieu",
    index: "/quan-ly-tai-lieu",
    exam: {
      path: "/quan-ly-tai-lieu/:topicSlug",
      index: "/quan-ly-tai-lieu/:topicSlug",
    },
  },
  manageRoom: {
    listRoom: {
      path: "/quan-ly-phong-thi",
      index: "/quan-ly-phong-thi",
    },
    roomDetail: {
      path: "/quan-ly-phong-thi/:roomId",
      index: "/quan-ly-phong-thi/:roomId",
      examDetail: {
        path: "/quan-ly-phong-thi/:roomId/:examCode",
        index: "/quan-ly-phong-thi/:roomId/:examCode",
      },
    },
  },
  manageStudent: {
    path: "/quan-ly-hoc-sinh",
    index: "/quan-ly-hoc-sinh",
    groups: {
      path: "/quan-ly-hoc-sinh/lop-hoc",
      index: "/quan-ly-hoc-sinh/lop-hoc",
      groupDetail: {
        path: "/quan-ly-hoc-sinh/lop-hoc/:groupHid",
        index: "/quan-ly-hoc-sinh/lop-hoc/:groupHid",
        memberDetail: {
          path: "/quan-ly-hoc-sinh/lop-hoc/:groupHid/:memberHid",
          index: "/quan-ly-hoc-sinh/lop-hoc/:groupHid/:memberHid",
        },
      },
    },
    members: {
      path: "/quan-ly-hoc-sinh/tat-ca-hoc-sinh",
      index: "/quan-ly-hoc-sinh/tat-ca-hoc-sinh",
      memberDetail: {
        path: "/quan-ly-hoc-sinh/tat-ca-hoc-sinh/:memberHid",
        index: "/quan-ly-hoc-sinh/tat-ca-hoc-sinh/:memberHid",
      },
    },
  },
  userManual: {
    path: "/huong-dan-su-dung",
    index: "/huong-dan-su-dung",
  },
  handleReport: {
    path: "/xu-ly-khieu-nai",
    index: "/xu-ly-khieu-nai",
    waiting: {
      path: "/xu-ly-khieu-nai/cho-xu-ly",
      index: "/xu-ly-khieu-nai/cho-xu-ly",
    },
    done: {
      path: "/xu-ly-khieu-nai/da-xu-ly",
      index: "/xu-ly-khieu-nai/da-xu-ly",
    },
  },
  useAppInDesktop: {
    path: "/su-dung-desktop",
    index: "/su-dung-desktop",
  },
};

export const MAIN_LAYOUT_MENU = [
  {
    element: <Dashboard />,
    path: ROUTES.home.path,
    url: ROUTES.home.index,
  },
  {
    element: <CreateDocxAndPDF />,
    path: ROUTES.createExam.pdf.path,
    url: ROUTES.createExam.pdf.index,
  },
  {
    element: <CreateDocxAndPDF />,
    path: ROUTES.createExam.docx.path,
    url: ROUTES.createExam.docx.index,
  },
  {
    element: <ExamDetail />,
    path: ROUTES.examDetail.exam.path,
    url: ROUTES.examDetail.exam.index,
  },
  {
    element: <CreateGroupExam />,
    path: ROUTES.createExam.nhbd.path,
    url: ROUTES.createExam.nhbd.index,
  },
  {
    element: <ThousandQuestions />,
    path: ROUTES.createExam.nhch.path,
    url: ROUTES.createExam.nhch.index,
  },
  {
    element: <SelfInputQuestion />,
    path: ROUTES.createExam.selfInput.path,
    url: ROUTES.createExam.selfInput.index,
  },
  {
    element: <Login />,
    path: ROUTES.login.path,
    url: ROUTES.login.index,
  },
  {
    children: [
      {
        element: <ManageDocument />,
        path: ROUTES.manageDocument.mine.path,
        url: ROUTES.manageDocument.mine.path,
        children: [
          {
            element: <ManageDocument />,
            path: ROUTES.manageDocument.mine.folder.path,
            url: ROUTES.manageDocument.mine.folder.path,
          },
        ],
      },
      {
        element: <ManageDocument isShared />,
        path: ROUTES.manageDocument.shared.path,
        url: ROUTES.manageDocument.shared.path,
        children: [
          {
            element: <ManageDocument isShared />,
            path: ROUTES.manageDocument.shared.folder.path,
            url: ROUTES.manageDocument.shared.folder.path,
          },
        ],
      },
    ],
  },
  {
    element: <ListRoom />,
    path: ROUTES.manageRoom.listRoom.path,
    url: ROUTES.manageRoom.listRoom.index,
  },
  {
    element: <RoomDetail />,
    path: ROUTES.manageRoom.roomDetail.path,
    url: ROUTES.manageRoom.roomDetail.index,
  },
  {
    element: <ExamDetailOfRoom />,
    path: ROUTES.manageRoom.roomDetail.examDetail.path,
    url: ROUTES.manageRoom.roomDetail.examDetail.index,
  },
  {
    element: <ManageGroup />,
    path: ROUTES.manageStudent.groups.path,
    url: ROUTES.manageStudent.groups.index,
  },
  {
    element: <GroupDetail />,
    path: ROUTES.manageStudent.groups.groupDetail.path,
    url: ROUTES.manageStudent.groups.groupDetail.index,
  },
  {
    element: <MemberDetailOfGroup />,
    path: ROUTES.manageStudent.groups.groupDetail.memberDetail.path,
    url: ROUTES.manageStudent.groups.groupDetail.memberDetail.index,
  },
  {
    element: <ManageMember />,
    path: ROUTES.manageStudent.members.path,
    url: ROUTES.manageStudent.members.index,
  },
  {
    element: <MemberDetail />,
    path: ROUTES.manageStudent.members.memberDetail.path,
    url: ROUTES.manageStudent.members.memberDetail.index,
  },
  {
    element: <UserManual />,
    path: ROUTES.userManual.path,
    url: ROUTES.userManual.index,
  },
  {
    element: <AccountInfo />,
    path: ROUTES.accountInfo.path,
    url: ROUTES.accountInfo.index,
  },
  {
    element: <ReportReview />,
    path: ROUTES.handleReport.waiting.path,
    url: ROUTES.handleReport.waiting.index,
  },
  {
    element: <ReportReview isDone />,
    path: ROUTES.handleReport.done.path,
    url: ROUTES.handleReport.done.index,
  },
  {
    element: <UseAppInDeskTop />,
    path: ROUTES.useAppInDesktop.path,
    url: ROUTES.useAppInDesktop.index,
  },
];
