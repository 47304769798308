/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
import { setCookie } from "cookies-next";
import CryptoJS from "crypto-js";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { createContext, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { toast } from "react-toastify";
import { useCookie } from "react-use";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { CONFIG_SERVER } from "../../../utils/constant/env";
import { commonActions } from "../../common/redux/action";
import { ACCOUNT_TEACHER_STATUS, SIGN_IN_METHOD, trackingNameOfProvider } from "../../common/utils/constant";
import { genSignature, getNonce, isEmptyCustom } from "../../common/utils/helper";
import { TCommonState } from "../../common/utils/type";
import { getAuthorize, getUserInfos, loginWithEmailAndPassword, signIn } from "../utils/authAPI";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const toastId: any = React.useRef(null);
  const store = useStore();
  const [ServerError, updateCookie, _deleteCookie] = useCookie("ServerError");
  const { fireBaseInfos, userInfos, loginWithEmailAndPasswordInfo } = useSelector(
    (state: { common: TCommonState }) => state.common
  );
  const dispatch = useDispatch();

  const logout = (token: object) => {
    dispatch(commonActions.handleLogOut(token));
  };
  const fetchUserInfos = async (signInData) => {
    try {
      const params: any = {
        timestamp: moment().unix(),
        nonce: getNonce(),
        platform: CONFIG_SERVER.platform,
        app_version: process.env.REACT_APP_VERSION,
      };
      if (!signInData?.status || signInData?.status === ACCOUNT_TEACHER_STATUS.ACCEPTED) {
        params.is_web_teacher = 1;
      }
      const dataPost = { ...params, signature: genSignature(params) };
      const res = await getUserInfos(store, dataPost);
      if (res?.data) {
        toast.success(signInData?.success_messages[0]);
        if (isEmpty(res?.data?.data?.member?.name)) {
          dispatch(commonActions.handleSetOpenDialogUpdateProfile(true));
        }
        if (
          [ACCOUNT_TEACHER_STATUS.NEW, ACCOUNT_TEACHER_STATUS.NOT_REGISTER].some(
            (status) => status === signInData.status
          )
        ) {
          dispatch(commonActions.handleSetOpenDialogRegisterTeacher(true));
        }
        dispatch(commonActions.addUserInfos({ ...res?.data?.data, status: signInData?.status }));
        setCookie("userInfo", JSON.stringify(res?.data?.data));
        setCookie("accessToken", JSON.stringify(res?.data?.data?.token?.access_token));
        setCookie("refreshToken", JSON.stringify(res?.data?.data?.token?.ref_token));
      } else logout({});
    } catch (error) {
      toast.error(error?.data?.error?.message);
      dispatch(
        commonActions.handleSaveNotify({
          errorCode: error?.data?.error?.code,
          message: error?.data?.error?.message,
          isOpenDialog: true,
        })
      );
    }
  };
  const actionsSignIn = async (signInMethod, paramsSignIn) => {
    const { authCode } = paramsSignIn;
    const headers = {
      Authorization: `Bearer ${authCode}`,
    };
    let paramsSignatureSignIn: any = {
      app_version: process.env.REACT_APP_VERSION,
      ci: CONFIG_SERVER.ci,
      language: CONFIG_SERVER.language,
      timezone: CONFIG_SERVER.timezone,
      timestamp: moment().unix(),
      nonce: getNonce(),
      platform: CONFIG_SERVER.platform,
      os_version: CONFIG_SERVER.os_version,
    };

    switch (signInMethod) {
      case SIGN_IN_METHOD.FIREBASE: {
        const { idTokenParams, providerIdParams } = paramsSignIn;
        if (idTokenParams) {
          paramsSignatureSignIn = {
            ...paramsSignatureSignIn,
            id_token: idTokenParams,
            provider_id: providerIdParams,
          };
        }
        const params = {
          ...paramsSignatureSignIn,
          signature: genSignature(paramsSignatureSignIn),
        };

        await signInWithFirebase(params, headers);
        break;
      }
      case SIGN_IN_METHOD.EMAIL_AND_PASSWORD: {
        const { email, password } = paramsSignIn;
        paramsSignatureSignIn = {
          ...paramsSignatureSignIn,
          email,
          password: CryptoJS.MD5(password).toString(CryptoJS.enc.Hex),
        };
        const params = {
          ...paramsSignatureSignIn,
          signature: genSignature(paramsSignatureSignIn, undefined, undefined, ["email", "password"]),
        };
        await signInWithEmailAndPassword(params, headers);
        break;
      }
      default:
        throw new Error("Do not support this sign-in method.");
    }
  };

  const signInWithFirebase = async (params, headers) => {
    try {
      const response = await signIn(store, params, headers);
      if (response?.data) {
        dispatch(commonActions.addUserInfos(response?.data?.data));
        if (response?.data?.data?.success_messages[0]) {
          dispatch(
            commonActions.handleSaveNotify({
              message: response?.data?.data?.success_messages[0],
              isOpenNotify: true,
            })
          );
        }
        if (params.id_token) {
          if (response?.data?.data?.is_new_member) {
            setCookie("is_new_member", "true");
            eventTracking("select_create_new_account", { type_name: trackingNameOfProvider[params.provider_id] });
          }
          if ([ACCOUNT_TEACHER_STATUS.ACCEPTED, ACCOUNT_TEACHER_STATUS.NEW].includes(response?.data?.data?.status)) {
            fetchUserInfos(response?.data?.data);
          } else if (response?.data?.data?.status === ACCOUNT_TEACHER_STATUS.NOT_REGISTER) {
            if (params.provider_id === "apple.com") {
              fetchUserInfos(response?.data?.data);
            } else {
              dispatch(commonActions.handleSetOpenDialogRegisterTeacher(true));
            }
          } else {
            dispatch(commonActions.handleSetOpenDialogNotifyRegisterTeacher(response?.data?.data?.status));
          }
        }
      }
    } catch (err) {
      toast.error(err?.data?.error?.message);
      store.dispatch(commonActions.handleLogOut({}));
    }
  };

  const signInWithEmailAndPassword = async (params, headers) => {
    try {
      const response = await loginWithEmailAndPassword(store, params, headers);
      if (response?.data) {
        dispatch(commonActions.addUserInfos(response?.data?.data));
        fetchUserInfos(response?.data?.data);
      }
    } catch (error) {
      dispatch(commonActions.setErrorMessageFormLoginFromServer(error?.data?.error?.message));
      store.dispatch(commonActions.handleLogOut({}));
    }
  };

  const actionsAuthorize = async () => {
    const paramsSignature = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      ci: CONFIG_SERVER.ci,
      platform: CONFIG_SERVER.platform,
      app_version: process.env.REACT_APP_VERSION,
    };
    const params = {
      ...paramsSignature,
      signature: genSignature(paramsSignature),
    };
    try {
      const response = await getAuthorize(store, params);
      if (response?.data) {
        dispatch(
          commonActions.handleAuthorizeSuccess({
            providerId: fireBaseInfos.providerId,
            idToken: fireBaseInfos.idToken,
            authCode: response?.data?.data?.auth_code,
          })
        );
        const signInMethod = loginWithEmailAndPasswordInfo
          ? SIGN_IN_METHOD.EMAIL_AND_PASSWORD
          : SIGN_IN_METHOD.FIREBASE;
        actionsSignIn(signInMethod, {
          idTokenParams: fireBaseInfos.idToken,
          providerIdParams: fireBaseInfos.providerId,
          authCode: response?.data?.data?.auth_code,
          email: loginWithEmailAndPasswordInfo?.email,
          password: loginWithEmailAndPasswordInfo?.password,
        });
      }
    } catch (err) {
      dispatch({
        type: "GET_AUTHORIZE_FAILED",
        params: err?.data?.data?.error,
      });
    }
  };
  useEffect(() => {
    const shouldLoginWithFirebase = isEmpty(userInfos?.member) && !isEmpty(fireBaseInfos?.idToken);
    if (shouldLoginWithFirebase || loginWithEmailAndPasswordInfo) {
      actionsAuthorize();
    }
  }, [fireBaseInfos?.idToken, loginWithEmailAndPasswordInfo]);

  useEffect(() => {
    setCookie("userInfos", JSON.stringify(userInfos));
    setCookie("accessToken", JSON.stringify(userInfos?.token?.access_token));
    setCookie("refreshToken", JSON.stringify(userInfos?.token?.ref_token));
  }, [userInfos]);

  useEffect(() => {
    const serverError = JSON.parse(ServerError || "{}");
    if (!isEmptyCustom(serverError)) {
      // if ([401, 403].includes(serverError?.status)) {
      // dispatch(commonActions.handleLogOut({}));
      // }
      toast.error(serverError?.message, { toastId: serverError?.code });
      updateCookie("{}");
    }
  }, [ServerError]);

  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};
