import { toast } from "react-toastify";
import { call, debounce, put, takeLatest } from "redux-saga/effects";
import { documentServices } from "./../../manage_document/redux/services";
import { ACTION_TYPES } from "./actionTypes";
import { commonServices } from "./services";

function* getCreationInfoCreateExam(action) {
  try {
    const response = yield call(commonServices.getCreationInfoCreateExam, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_CREATION_INFO_CREATE_EXAM_SUCCESS,
        params: response?.data?.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.GET_CREATION_INFO_CREATE_EXAM_FAILED,
      params: error?.data?.error,
    });
  }
}

function* createQuizPDF(action) {
  try {
    const response = yield call(commonServices.createExamination, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.HANDLE_CREATE_EXAMINATION_SUCCESS,
        params: response?.data?.data,
      });
      action.callback();
      toast.success(response?.data?.data?.success_message);
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.HANDLE_CREATE_QUIZ_PDF_FAILED,
      params: error?.data?.error,
    });
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}
function* handleConvertDocx(action) {
  try {
    const response = yield call(commonServices.handleConvertDocx, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.HANDLE_CONVERT_DOCX_SUCCESS,
        params: response?.data?.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.HANDLE_CONVERT_DOCX_FAILED,
      params: error?.data?.error,
    });
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action.callback();
  }
}

function* handleGetListRoom(action) {
  try {
    const response = yield call(commonServices.handleGetListRoom);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_LIST_ROOM_SUCCESS,
        params: response?.data?.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.GET_LIST_ROOM_SUCCESS,
      params: error?.data?.error,
    });
  }
  action.finallyCallback();
}

function* getRoomCreationInfo() {
  try {
    const response = yield call(commonServices.getRoomCreationInfo);
    yield put({
      type: ACTION_TYPES.GET_ROOM_CREATION_INFO_SUCCESS,
      params: response?.data?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* getAllFolderAndFile(action) {
  try {
    const response = yield call(documentServices.getListFolderAndFile, action.params);
    yield put({
      type: ACTION_TYPES.GET_ALL_FOLDER_AND_FILE_SUCCESS,
      params: response?.data?.data,
    });
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* getExamDetail(action) {
  try {
    const response = yield call(commonServices.getExamDetail, action.params);
    const data = response?.data?.data;
    yield put({
      type: ACTION_TYPES.GET_EXAM_DETAIL_SUCCESS,
      params: {
        ...data,
        questions: data?.questions?.map((e) => ({
          ...e,
          grade_id: data?.grade?.id,
          subject_type_id: data?.subject_type?.id,
          learning_program_id: data?.learning_program?.id,
          semester_id: data.semester?.id,
        })),
      },
    });
    action?.successCallback();
  } catch (error: any) {
    action?.errorCallback();
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* handleEditExam(action) {
  try {
    const response = yield call(commonServices.editExam, action.params);
    yield put({
      type: ACTION_TYPES.EDIT_EXAM_SUCCESS,
      params: response?.data?.data,
    });
    action.successCallback(response?.data?.data?.topic_slug);
    toast.success(response?.data?.data?.success_message);
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.EDIT_EXAM_FAILED,
      params: error?.data?.error?.message,
    });
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    action?.errorCallback(error?.data?.error);
  }
}
function* reportQuiz(action) {
  try {
    const response = yield call(commonServices.reportQuiz, action.params);
    yield put({
      type: ACTION_TYPES.REPORT_QUIZ_SUCCESS,
      params: response?.data?.data,
    });
    toast.success(response?.data?.data?.success_message);
    action.successCallback();
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}
function* getDashboardInfo(action) {
  try {
    const response = yield call(commonServices.getDashboard);
    yield put({
      type: ACTION_TYPES.GET_DASHBOARD_SUCCESS,
      params: response?.data?.data,
    });
    action.successCallback();
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}
function* getTeacherInfo(action) {
  try {
    const response = yield call(commonServices.getTeacherInfo);
    yield put({
      type: ACTION_TYPES.GET_TEACHER_INFO_SUCCESS,
      params: response?.data?.data,
    });
    action.successCallback(response?.data?.data);
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}
function* getGradeAndSubject(action) {
  try {
    const response = yield call(commonServices.getGradeAndSubject);
    yield put({
      type: ACTION_TYPES.GET_GRADE_AND_SUBJECT_SUCCESS,
      params: response?.data?.data?.packages,
    });
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}
function* handleUpdateAccountInfo(action) {
  try {
    const response = yield call(commonServices.updateAccountInfo, action?.params);
    yield put({
      type: ACTION_TYPES.UPDATE_ACCOUNT_INFO_SUCCESS,
      params: response?.data?.data,
    });
    toast.success(response?.data?.data?.success_message);
    action.successCallback();
  } catch (error: any) {
    if (error?.data?.error?.code === "ENATIU") {
      // refresh page
      action.successCallback();
    }
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}
function* handleRegisterTeacher(action) {
  try {
    const response = yield call(commonServices.registerTeacher, action?.params);
    yield put({
      type: ACTION_TYPES.HANDLE_REGISTER_TEACHER_SUCCESS,
      params: response?.data?.data,
    });
    action.successCallback();
    toast.success(response?.data?.data?.success_message);
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.HANDLE_REGISTER_TEACHER_FAILED,
      params: error?.data?.error,
    });
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}
function* handleUpdateProfile(action) {
  try {
    const response = yield call(commonServices.updateProfile, action?.params);
    yield put({
      type: ACTION_TYPES.HANDLE_UPDATE_PROFILE_SUCCESS,
      params: response?.data?.data,
    });
    toast.success(response?.data?.data?.success_message);
    action.successCallback();
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.HANDLE_UPDATE_PROFILE_FAILED,
      params: error?.data?.error,
    });
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}
function* getReportReview(action) {
  try {
    const response = yield call(commonServices.getReportReview);
    yield put({
      type: ACTION_TYPES.GET_REPORT_REVIEW_SUCCESS,
      params: response?.data?.data,
    });
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.GET_REPORT_REVIEW_FAILED,
      params: error?.data?.error,
    });
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
  action.finallyCallback();
}
function* handleChangeStatusReport(action) {
  try {
    const response = yield call(commonServices.changeStatusReport, action.params);
    yield put({
      type: ACTION_TYPES.HANDLE_CHANGE_STATUS_REPORT_SUCCESS,
      params: response?.data?.data,
    });
    toast.success(response?.data?.data?.success_message);
    action.successCallback();
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.HANDLE_CHANGE_STATUS_REPORT_FAILED,
      params: error?.data?.error,
    });
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}
function* handleGetUserInfo(action) {
  try {
    const response = yield call(commonServices.getUserInfo, action.params);
    yield put({
      type: ACTION_TYPES.GET_USER_INFO_SUCCESS,
      params: response?.data?.data,
    });
  } catch (error: any) {}
}
function* handleCreateExam(action) {
  try {
    const response = yield call(commonServices.createExam, action.params);
    yield put({
      type: ACTION_TYPES.CREATE_EXAM_SUCCESS,
      params: response?.data?.data,
    });
    toast.success(response?.data?.data?.success_message);
    action.successCallback(response?.data?.data?.topic_slug);
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
  }
}

function* handleEditQuestionReport(action) {
  try {
    const response = yield call(commonServices.editQuestion, action.params);
    yield put({
      type: ACTION_TYPES.HANDLE_EDIT_QUESTION_SUCCESS,
      params: response?.data?.data,
    });
    if (action?.params?.isShowToast) {
      toast.success(response?.data?.data?.success_message);
    }
    action.successCallback();
  } catch (error: any) {
    toast.error(error?.data?.error?.message, { toastId: error.data?.error?.code });
    yield put({
      type: ACTION_TYPES.HANDLE_EDIT_QUESTION_FAILED,
      params: error?.data?.error,
    });
  }
}

function* commonSaga() {
  yield takeLatest(ACTION_TYPES.GET_CREATION_INFO_CREATE_EXAM, getCreationInfoCreateExam);
  yield takeLatest(ACTION_TYPES.HANDLE_CONVERT_DOCX, handleConvertDocx);
  yield debounce(300, ACTION_TYPES.HANDLE_CREATE_QUIZ_PDF, createQuizPDF);
  yield takeLatest(ACTION_TYPES.GET_LIST_ROOM, handleGetListRoom);
  yield takeLatest(ACTION_TYPES.GET_ROOM_CREATION_INFO, getRoomCreationInfo);
  yield takeLatest(ACTION_TYPES.GET_ALL_FOLDER_AND_FILE, getAllFolderAndFile);
  yield takeLatest(ACTION_TYPES.GET_EXAM_DETAIL, getExamDetail);
  yield debounce(300, ACTION_TYPES.EDIT_EXAM, handleEditExam);
  yield takeLatest(ACTION_TYPES.REPORT_QUIZ, reportQuiz);
  yield takeLatest(ACTION_TYPES.GET_DASHBOARD, getDashboardInfo);
  yield takeLatest(ACTION_TYPES.GET_TEACHER_INFO, getTeacherInfo);
  yield debounce(300, ACTION_TYPES.GET_GRADE_AND_SUBJECT, getGradeAndSubject);
  yield takeLatest(ACTION_TYPES.GET_REPORT_REVIEW, getReportReview);
  yield debounce(300, ACTION_TYPES.UPDATE_ACCOUNT_INFO, handleUpdateAccountInfo);
  yield debounce(300, ACTION_TYPES.HANDLE_REGISTER_TEACHER, handleRegisterTeacher);
  yield debounce(300, ACTION_TYPES.HANDLE_UPDATE_PROFILE, handleUpdateProfile);
  yield debounce(300, ACTION_TYPES.HANDLE_CHANGE_STATUS_REPORT, handleChangeStatusReport);
  yield debounce(500, ACTION_TYPES.CREATE_EXAM, handleCreateExam);
  yield debounce(300, ACTION_TYPES.HANDLE_EDIT_QUESTION, handleEditQuestionReport);
  yield takeLatest(ACTION_TYPES.GET_USER_INFO, handleGetUserInfo);
}
export default commonSaga;
