import React from "react";

export const RoomProgressTotalIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_1467_17154)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.53073 12.867C0.729249 12.535 0 11.8592 0 10.9917V2.5625C0 1.45793 0.89886 0.549625 1.99481 0.687338C3.41055 0.865236 4.79915 1.2321 6.12294 1.78043C8.06415 2.5845 9.82797 3.76306 11.3137 5.24879C12.7994 6.73453 13.978 8.49836 14.7821 10.4396C15.3304 11.7634 15.6973 13.152 15.8752 14.5677C16.0129 15.6636 15.1046 16.5625 14 16.5625L5.57079 16.5625C4.70326 16.5625 4.0275 15.8333 3.69552 15.0318C3.4945 14.5465 3.19986 14.1055 2.82843 13.7341C2.45699 13.3626 2.01604 13.068 1.53073 12.867Z"
          fill="#F2F4F7"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1467_17154"
          x="0"
          y="0.673828"
          width="15.889"
          height="15.8887"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.77 0 0 0 0 0.77 0 0 0 0 0.77 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1467_17154" />
        </filter>
      </defs>
    </svg>
  );
};

export const RoomProgressNoGroupIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53073 12.867C0.729249 12.535 0 11.8592 0 10.9917V2.5625C0 1.45793 0.89886 0.549625 1.99481 0.687338C3.41055 0.865236 4.79915 1.2321 6.12294 1.78043C8.06415 2.5845 9.82797 3.76306 11.3137 5.24879C12.7994 6.73453 13.978 8.49836 14.7821 10.4396C15.3304 11.7634 15.6973 13.152 15.8752 14.5677C16.0129 15.6636 15.1046 16.5625 14 16.5625L5.57079 16.5625C4.70326 16.5625 4.0275 15.8333 3.69552 15.0318C3.4945 14.5465 3.19986 14.1055 2.82843 13.7341C2.45699 13.3626 2.01604 13.068 1.53073 12.867Z"
        fill="#873FB6"
      />
    </svg>
  );
};

export const RoomProgressNoLockedIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53073 12.867C0.729249 12.535 0 11.8592 0 10.9917V2.5625C0 1.45793 0.89886 0.549625 1.99481 0.687338C3.41055 0.865236 4.79915 1.2321 6.12294 1.78043C8.06415 2.5845 9.82797 3.76306 11.3137 5.24879C12.7994 6.73453 13.978 8.49836 14.7821 10.4396C15.3304 11.7634 15.6973 13.152 15.8752 14.5677C16.0129 15.6636 15.1046 16.5625 14 16.5625L5.57079 16.5625C4.70326 16.5625 4.0275 15.8333 3.69552 15.0318C3.4945 14.5465 3.19986 14.1055 2.82843 13.7341C2.45699 13.3626 2.01604 13.068 1.53073 12.867Z"
        fill="#0596FF"
      />
    </svg>
  );
};
