import React, { PropsWithChildren, ReactNode } from "react";
import { Dialog as PrimeDialog, DialogProps } from "primereact/dialog";
import { CloseCircleIcon } from "../../../../assets";

type TDialogProps = PropsWithChildren & {
  isOpen: boolean;
  title: ReactNode;
  closable?: boolean;
  actions: ReactNode;
  className?: string;
  pt?: DialogProps["pt"];
  onClose: () => void;
};

export default function Dialog({
  isOpen,
  title,
  closable = false,
  actions,
  children,
  className,
  pt,
  onClose,
}: TDialogProps) {
  return (
    <PrimeDialog
      className={`w-[560px] ${className}`}
      visible={isOpen}
      header={title}
      footer={actions}
      onHide={onClose}
      draggable={false}
      closable={closable}
      closeIcon={<CloseCircleIcon />}
      focusOnShow={false}
      pt={{
        root: {
          className: "rounded-[20px] overflow-hidden",
        },
        headerTitle: {
          className: "text-center text-[24px] font-semibold text-gray-900",
        },
        content: {
          className: "py-0",
        },
        footer: {
          className: "py-6",
        },
        ...pt,
      }}
    >
      {children}
    </PrimeDialog>
  );
}
