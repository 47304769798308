import { Button } from "primereact/button";
import React from "react";
import { useIntl } from "react-intl";
import { AddExamIcon, ClockRewindIcon } from "../../../assets";
import { EGroupStatusOption } from "../../../types/group";
import SearchComponent from "../../common/components/SearchComponent";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import ButtonSecondary from "../../common/components/button/ButtonSecondary";
import { eventTracking } from "../../../firebase/firebaseConfig";

type TGroupDetailHeaderProps = {
  groupDetail?: any;
  isLoadingStatusAddRoom?: boolean;
  isLoadingStatusAddMember?: boolean;
  onChangeStatusAddRoom?: () => void;
  onChangeStatusAddMember?: () => void;
  onClickInviteMember?: () => void;
  onClickAddMembers?: () => void;
  onClickViewActionHistory?: () => void;
  onClickCreateRoom?: () => void;
  onSearch: (searchKeyword: string) => void;
};

export default function GroupDetailHeader({
  groupDetail,
  isLoadingStatusAddRoom,
  isLoadingStatusAddMember,
  onChangeStatusAddRoom,
  onChangeStatusAddMember,
  onClickInviteMember,
  onClickAddMembers,
  onClickViewActionHistory,
  onClickCreateRoom,
  onSearch,
}: TGroupDetailHeaderProps) {
  const intl = useIntl();

  return (
    <>
      <div className="flex justify-between px-10 py-6 border-t border-b border-gray200">
        {!groupDetail ? (
          <div />
        ) : (
          <div className="flex gap-4">
            <ButtonPrimary
              icon={<AddExamIcon />}
              label={intl.formatMessage({ id: "groupDetail.createRoomButton.label" })}
              disabled={groupDetail.status_add_room === EGroupStatusOption.CLOSED || groupDetail.members?.length === 0}
              onClick={() => {
                eventTracking("select_create_room_button_for_group");
                onClickCreateRoom?.();
              }}
            />
            <Button
              icon={<ClockRewindIcon />}
              className="w-[44px] h-[44px] focus:shadow-none bg-transparent hover:bg-secondary-50 border border-secondary"
              rounded
              onClick={() => {
                eventTracking("select_history_activites_icon");
                onClickViewActionHistory?.();
              }}
            />
            <ButtonSecondary
              label={
                groupDetail.status_add_room === EGroupStatusOption.OPEN
                  ? intl.formatMessage({ id: "groupDetail.buttonSwitchStatusAddRoom.close" })
                  : intl.formatMessage({ id: "groupDetail.buttonSwitchStatusAddRoom.open" })
              }
              className="px-2 rounded-[6px] min-w-min"
              disabled={isLoadingStatusAddRoom}
              onClick={() => {
                eventTracking(
                  groupDetail.status_add_room === EGroupStatusOption.OPEN
                    ? "select_close_creating_button"
                    : "select_open_creating_button"
                );
                onChangeStatusAddRoom?.();
              }}
            />
            <ButtonSecondary
              label={
                groupDetail.status_add_member === EGroupStatusOption.OPEN
                  ? intl.formatMessage({ id: "groupDetail.buttonSwitchStatusAddMember.close" })
                  : intl.formatMessage({ id: "groupDetail.buttonSwitchStatusAddMember.open" })
              }
              className="px-2 rounded-[6px] min-w-min"
              disabled={isLoadingStatusAddMember}
              onClick={() => {
                eventTracking(
                  groupDetail.status_add_member === EGroupStatusOption.OPEN
                    ? "select_close_adding_button"
                    : "select_open_adding_button"
                );
                onChangeStatusAddMember?.();
              }}
            />
            {groupDetail.status_add_member === EGroupStatusOption.OPEN && (
              <>
                <ButtonSecondary
                  label={intl.formatMessage({ id: "groupDetail.buttonAddMember" })}
                  className="px-2 rounded-[6px] min-w-min"
                  onClick={() => {
                    eventTracking("select_add_student_button");
                    onClickAddMembers?.();
                  }}
                />
                <ButtonSecondary
                  label={intl.formatMessage({ id: "groupDetail.buttonInvite" })}
                  className="px-2 rounded-[6px] min-w-min"
                  onClick={() => {
                    eventTracking("select_invition_button");
                    onClickInviteMember?.();
                  }}
                />
              </>
            )}
          </div>
        )}
        <SearchComponent
          placeholder={intl.formatMessage({ id: "common.search.placeholder" })}
          handleSearch={(searchKeyword: string) => onSearch(searchKeyword)}
        />
      </div>
    </>
  );
}
