import React from "react";
import { EGroupStatusOption, TGroupResponse } from "../../../types/group";
import { MoreIcon } from "../../../assets";
import { Button } from "primereact/button";
import { FormattedMessage } from "react-intl";

type TGroupItemProps = {
  groupDetail: TGroupResponse;
  onClick: () => void;
  onClickMore: (event) => void;
};

export default function GroupItem({ groupDetail, onClick, onClickMore }: TGroupItemProps) {
  return (
    <div
      role="button"
      className="flex flex-col gap-2 w-full h-full border border-gray-200 rounded-[16px] p-4 hover:cursor-pointer"
      onClick={onClick}
    >
      <div className="flex justify-between items-center gap-4">
        <span className="flex-1 text-gray-900 font-semibold text-[14px] line-clamp-1">{groupDetail.title}</span>
        <Button icon={<MoreIcon />} text className="focus:shadow-none h-[20px] p-0 w-min" onClick={onClickMore} />
      </div>

      <hr className="text-gray-200" />

      <span className="text-[14px] text-gray-900">
        <FormattedMessage id="groupItem.numsOfStudent" />
        :&nbsp;<span>{groupDetail.num_of_members}</span>
      </span>

      <span className="text-[14px] text-gray-900">
        <FormattedMessage id="groupItem.numsOfRoom" />
        :&nbsp;<span>{groupDetail.num_of_rooms}</span>
      </span>

      <span className="text-[14px] text-gray-900">
        <FormattedMessage id="groupItem.addRoomStatus" />
        :&nbsp;
        {groupDetail.status_add_room === EGroupStatusOption.OPEN ? (
          <span className="text-success">
            <FormattedMessage id="group.statusOption.open" />
          </span>
        ) : (
          <span className="text-error">
            <FormattedMessage id="group.statusOption.closed" />
          </span>
        )}
      </span>

      <span className="text-[14px] text-gray-900">
        <FormattedMessage id="groupItem.addMemberStatus" />
        :&nbsp;
        {groupDetail.status_add_member === EGroupStatusOption.OPEN ? (
          <span className="text-success">
            <FormattedMessage id="group.statusOption.open" />
          </span>
        ) : (
          <span className="text-error">
            <FormattedMessage id="group.statusOption.closed" />
          </span>
        )}
      </span>

      {groupDetail.description && (
        <span className="text-[14px] text-gray-900 line-clamp-2">
          <FormattedMessage id="groupItem.note" />
          :&nbsp;
          <span dangerouslySetInnerHTML={{ __html: groupDetail.description?.replace(/\n/g, "<br/>") }} />
        </span>
      )}
    </div>
  );
}
