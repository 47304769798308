/* eslint-disable no-else-return */
// import Login from 'features/auth/page/Login';

import React from "react";
import { useRoutes, Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { MAIN_LAYOUT_MENU } from "./utils/configs/router";
import DefaultLayout from "./routes/root";
import ErrorPage from "./error-page";
import { SERVER_NAME } from "./utils/constant/env";

function App() {
  const location = useLocation();
  const element: any = useRoutes([
    {
      path: "/",
      element: <DefaultLayout />,
      errorElement: <ErrorPage />,
      children: MAIN_LAYOUT_MENU,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);
  
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Thi Tốt là ứng dụng ôn thi, luyện thi trắc nghiệm mới nhất, tốt nhất, cập nhật mới nhất toàn bộ bài giảng theo chương trình hiện hành, bám sát chương trình học đổi mới mới nhất của bộ giáo dục, phù hợp với mọi học sinh từ THCS đến THPT. Chính Xác cung cấp tính năng thi online, thi thử, hỏi bài cho học sinh và thống kê kết quả học tập giúp học sinh theo dõi được sự tiến bộ của mình qua từng giai đoạn học tập."
        />
        <meta property="og:title" content="Thi tốt - Học, Thi Đấu online" />
        <meta
          property="og:description"
          content="Thi Tốt là ứng dụng ôn thi, luyện thi trắc nghiệm mới nhất, tốt nhất, cập nhật mới nhất toàn bộ bài giảng theo chương trình hiện hành, bám sát chương trình học đổi mới mới nhất của bộ giáo dục, phù hợp với mọi học sinh từ THCS đến THPT. Thi Tốt cung cấp tính năng thi online, thi thử, hỏi bài cho học sinh và thống kê kết quả học tập giúp học sinh theo dõi được sự tiến bộ của mình qua từng giai đoạn học tập."
        />
        <meta property="og:url" content={SERVER_NAME} />
        <meta property="og:image" content={`${SERVER_NAME}/graph_thitot.png`} />
        <meta property="og:image:alt" content="Thi Tốt" />
        <meta property="og:image:secure_url" content={`${SERVER_NAME}/graph_thitot.png`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="canonical" href={`${SERVER_NAME}${location.pathname}`} />
      </Helmet>
      {element}
    </>
  );
}

export default App;
