import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import ButtonSecondary from "../../common/components/button/ButtonSecondary";
import { commonActions } from "../../common/redux/action";
import "../style.css";
import DialogInputNumber from "./DialogInputNumber";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { ArrowDownIcon, ArrowLeftIcon2 } from "../../../assets";
import isEqual from "lodash/isEqual";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { EXAM_DIFFICULT_DEGREE } from "../../common/utils/constant";
import { useIntl } from "react-intl";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { containsSpecialChars } from "../../common/utils/helper";
import { MAX_LENGTH_TITLE } from "../utils/constant";

const INPUT_TIME = "time";
const INPUT_NUM_OF_QUESTIONS = "question";

type TMode = "select" | "preview" | "edit";

type TCreationInfoExamWareHouseProps = {
  examInfo: any;
  setExamInfo: any;
  errorMessage: any;
  setErrorMessage: any;
  totalQuestions: number;
  isLoadingPreviewButton?: boolean;
  disablePreviewButton?: boolean;
  disableCreateExamButton?: boolean;
  disableSaveExamButton?: boolean;
  mode?: TMode;
  onClickPreviewButton?: () => void;
  onClickBackButton?: () => void;
  onClickCreateExam?: () => void;
  onClickSaveExam?: () => void;
  isThousandQuestions?: boolean;
};

function CreationInfoExamWareHouse({
  examInfo,
  setExamInfo,
  errorMessage,
  setErrorMessage,
  isLoadingPreviewButton = false,
  disablePreviewButton = false,
  disableCreateExamButton = false,
  disableSaveExamButton = false,
  mode = "select",
  totalQuestions,
  isThousandQuestions = false,
  onClickPreviewButton,
  onClickBackButton,
  onClickCreateExam,
  onClickSaveExam,
}: TCreationInfoExamWareHouseProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { creationInfoExam, userInfos } = useSelector((state: { common }) => state.common);
  const [openDialogInput, setOpenDialogInput] = useState<string>("");
  const isFocusedTitleInput = useRef(false);

  const handleGetCreationInfo = (params) => {
    dispatch(commonActions.handleGetCreationInfoCreateExam(params));
  };

  const timeOptions = [...new Set(creationInfoExam?.quiz_creation_info?.map((e) => e.time))]
    ?.map((el) => ({
      label: el,
      value: el,
    }))
    .concat([{ label: "Tự nhập", value: 0 }]);

  useEffect(() => {
    if (userInfos?.member) {
      handleGetCreationInfo({ exam_type: "warehouse" });
    }
  }, []);
  const EXAM_DIFFICULT_DEGREE_CHOICE = [
    { value: EXAM_DIFFICULT_DEGREE.ONE, label: intl.formatMessage({ id: "thousandQuestions.difficultDegree.one" }) },
    { value: EXAM_DIFFICULT_DEGREE.TWO, label: intl.formatMessage({ id: "thousandQuestions.difficultDegree.two" }) },
    {
      value: EXAM_DIFFICULT_DEGREE.THREE,
      label: intl.formatMessage({ id: "thousandQuestions.difficultDegree.three" }),
    },
    { value: EXAM_DIFFICULT_DEGREE.FOUR, label: intl.formatMessage({ id: "thousandQuestions.difficultDegree.four" }) },
    { value: null, label: intl.formatMessage({ id: "common.unChoose" }) },
  ];

  return (
    <>
      <div className="flex flex-col gap-4 justify-between w-full px-10 pt-4 pb-4">
        <div className="h-[104px] flex-1 flex items-center flex-wrap gap-x-5">
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.grade?.id}
            onChange={(e) =>
              setExamInfo({
                ...examInfo,
                grade: creationInfoExam?.grades.find((el) => el.id === e.value),
                subject_types: null,
                learningProgram: null,
                difficultDegree: null,
              })
            }
            options={creationInfoExam?.grades?.map((e) => ({
              label: e?.title,
              value: e?.id,
            }))}
            // valueTemplate={valueTemplate}
            placeholder="Lớp"
            className="border-none font-[600]"
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.subject_types?.id}
            onChange={(e) => {
              const subjectType = examInfo.grade?.subject_types?.find((el) => el.id === e.value);
              setExamInfo({
                ...examInfo,
                subject_types: subjectType,
                learningProgram:
                  subjectType?.learning_programs?.length === 1 ? subjectType?.learning_programs[0] : null,
                difficultDegree: null,
              });
            }}
            options={creationInfoExam?.grades
              ?.find((e) => e?.id === examInfo?.grade?.id)
              ?.subject_types?.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            placeholder="Môn học"
            className="border-none font-[600]"
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.learningProgram?.id}
            onChange={(e) => {
              const learningProgram = creationInfoExam?.grades
                ?.find((e) => e?.id === examInfo?.grade?.id)
                ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
                ?.learning_programs?.find((el) => el.id === e.value);

              setExamInfo({
                ...examInfo,
                learningProgram: learningProgram,
                difficultDegree: null,
                semesters:
                  Array.isArray(learningProgram?.semesters) && learningProgram?.semesters?.length > 0
                    ? learningProgram?.semesters[0]
                    : undefined,
                thematics: undefined,
              });
            }}
            options={creationInfoExam?.grades
              ?.find((e) => e?.id === examInfo?.grade?.id)
              ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
              ?.learning_programs.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            placeholder="Chương trình học"
            className="border-none font-[600] max-w-[200px] text-ellipsis"
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.semesters?.id}
            onChange={(e) =>
              setExamInfo({
                ...examInfo,
                semesters: creationInfoExam?.grades
                  ?.find((e) => e?.id === examInfo?.grade?.id)
                  ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
                  ?.learning_programs?.find((e) => e?.id === examInfo?.learningProgram?.id)
                  ?.semesters?.find((el) => el.id === e.value),
                thematics: undefined,
                difficultDegree: null,
              })
            }
            options={creationInfoExam?.grades
              ?.find((e) => e?.id === examInfo?.grade?.id)
              ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
              ?.learning_programs?.find((e) => e?.id === examInfo?.learningProgram?.id)
              ?.semesters?.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            placeholder="Học kỳ"
            className="border-none font-[600]"
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
          <MultiSelect
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={examInfo?.thematics}
            onChange={(e) => {
              setExamInfo({
                ...examInfo,
                thematics: e.value,
              });
            }}
            showSelectAll={false}
            panelHeaderTemplate={null}
            options={creationInfoExam?.grades
              ?.find((e) => e?.id === examInfo?.grade?.id)
              ?.subject_types?.find((e) => e?.id === examInfo?.subject_types?.id)
              ?.learning_programs?.find((e) => e?.id === examInfo?.learningProgram?.id)
              ?.semesters?.find((e) => e?.id === examInfo?.semesters?.id)
              ?.thematics.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            placeholder="Chuyên đề"
            className="border-none font-[600] max-w-[200px] text-ellipsis"
            pt={{
              input: {
                className: "pr-0",
              },
              header: {
                className: "hidden",
              },
            }}
          />
          {isThousandQuestions && mode === "select" && (
            <Dropdown
              dropdownIcon={<ArrowDownIcon stroke="#475467" />}
              value={examInfo?.difficultDegree}
              disabled={!examInfo?.grade?.id || !examInfo?.subject_types?.id || !examInfo?.learningProgram?.id}
              onChange={(e) =>
                setExamInfo({
                  ...examInfo,
                  difficultDegree: e?.value,
                })
              }
              options={EXAM_DIFFICULT_DEGREE_CHOICE}
              placeholder="Độ khó"
              className="border-none font-[600]"
              pt={{
                input: {
                  className: "pr-0",
                },
                wrapper: {
                  className: "max-h-[300px]",
                },
              }}
            />
          )}

          {mode !== "select" && (
            <Dropdown
              dropdownIcon={<ArrowDownIcon stroke="#475467" />}
              value={examInfo?.time}
              valueTemplate={examInfo?.time && <span className="text-[#495057]">{examInfo?.time} phút</span>}
              onChange={(e) => {
                if (Number(e.value) === 0) {
                  setOpenDialogInput(INPUT_TIME);
                } else {
                  setExamInfo({
                    ...examInfo,
                    time: Number(e.value),
                  });
                }
              }}
              options={timeOptions}
              placeholder="Thời gian"
              className="border-none font-[600]"
              pt={{
                input: {
                  className: "pr-0",
                },
              }}
            />
          )}
          {(!isThousandQuestions || mode === "edit" || mode === "preview") && (
            <span className="text-gray-900 px-3 font-semibold text-[16px]">{totalQuestions} câu</span>
          )}
        </div>
        <div className="flex gap-x-8">
          <div className="flex gap-4">
            {isThousandQuestions && mode !== "preview" && mode !== "edit" && (
              <span className="font-[600] text-primary flex items-center">Số câu hỏi: {totalQuestions}</span>
            )}
            {mode === "preview" && !isThousandQuestions && (
              <Button
                icon={<ArrowLeftIcon2 />}
                className="bg-transparent w-[44px] h-[44px] border border-gray-200"
                onClick={onClickBackButton}
              />
            )}
            {isThousandQuestions && (mode === "preview" || mode === "edit") && (
              <ButtonSecondary
                label="Thêm câu hỏi"
                className="min-w-[120px] h-[44px] px-2"
                onClick={onClickBackButton}
              />
            )}
            {mode !== "edit" && !(isThousandQuestions && mode === "preview") && (
              <ButtonSecondary
                className="min-w-min"
                label={mode === "preview" ? "Gen lại" : "Xem trước"}
                icon={
                  isLoadingPreviewButton ? (
                    <ProgressSpinner
                      style={{ width: "28px", height: "28px" }}
                      strokeWidth="1"
                      animationDuration="2s"
                      pt={{
                        circle: {
                          className: "stroke-primary",
                        },
                      }}
                    />
                  ) : null
                }
                buttonIconPos="right"
                disabled={disablePreviewButton}
                onClick={() => {
                  if (isThousandQuestions) {
                    eventTracking("select_question_preview_button");
                  } else {
                    eventTracking("select_random_preview_button");
                  }
                  onClickPreviewButton?.();
                }}
              />
            )}
            {mode === "preview" && (
              <ButtonPrimary
                label="Tạo mới"
                onClick={onClickCreateExam}
                disabled={disableCreateExamButton}
                className="min-w-min w-[116px]"
              />
            )}
            {mode === "edit" && (
              <ButtonPrimary
                label="Lưu"
                onClick={onClickSaveExam}
                disabled={disableSaveExamButton}
                className="min-w-min w-[116px]"
              />
            )}
          </div>
          {(mode === "preview" || mode === "edit") && (
            <div className="flex-1">
              <InputText
                id="title"
                placeholder={intl.formatMessage({
                  id: "dialogInputTitle.inputPlaceholder",
                })}
                className={classNames("w-full h-[44px] rounded-full placeholder-gray-400 text-gray-900 px-4", {
                  "p-invalid": isFocusedTitleInput.current && (!examInfo.title || errorMessage.title),
                })}
                onFocus={() => {
                  isFocusedTitleInput.current = true;
                }}
                value={examInfo.title}
                onChange={(event) => {
                  if (!containsSpecialChars(event.target.value)) {
                    setExamInfo((prev) => ({ ...prev, title: event.target.value }));
                    if (event.target.value.length > MAX_LENGTH_TITLE) {
                      setErrorMessage({
                        title: intl.formatMessage(
                          { id: "createExam.titleField.errorMessage" },
                          { max: MAX_LENGTH_TITLE }
                        ),
                      });
                    } else {
                      setErrorMessage({
                        title: "",
                      });
                    }
                  }
                }}
              />
              {errorMessage.title && <span className="text-error ml-4 text-xs">{errorMessage.title}</span>}
            </div>
          )}
        </div>
      </div>
      {openDialogInput && (
        <DialogInputNumber
          open={openDialogInput}
          handleClose={(val) => {
            setOpenDialogInput("");
            if (openDialogInput === INPUT_TIME) {
              setExamInfo({ ...examInfo, time: val });
            } else {
              setExamInfo({
                ...examInfo,
                numOfQuestions: val,
              });
            }
          }}
          cancelFunction={() => setOpenDialogInput("")}
          title={openDialogInput === INPUT_TIME ? "Nhập thời gian" : "Nhập số câu"}
        />
      )}
      <div className="border-b border-gray-200" />
    </>
  );
}
const checkEqualProps = (preProps, nextProps) => {
  return (
    isEqual(preProps.examInfo, nextProps?.examInfo) &&
    isEqual(preProps.totalQuestions, nextProps?.totalQuestions) &&
    isEqual(preProps.disablePreviewButton, nextProps?.disablePreviewButton) &&
    isEqual(preProps.disableSaveExamButton, nextProps?.disableSaveExamButton) &&
    isEqual(preProps.isLoadingPreviewButton, nextProps?.isLoadingPreviewButton) &&
    isEqual(preProps.disableCreateExamButton, nextProps?.disableCreateExamButton) &&
    isEqual(preProps.mode, nextProps?.mode) &&
    isEqual(preProps.isThousandQuestions, nextProps?.isThousandQuestions) &&
    isEqual(preProps.onClickSaveExam, nextProps?.onClickSaveExam)
  );
};

export default React.memo(CreationInfoExamWareHouse, checkEqualProps);
