import moment from "moment";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DownloadIcon, Edit2Icon, ShareIcon, TrashIcon } from "../assets";
import ButtonGhost from "../components/common/components/button/ButtonGhost";
import ButtonPrimary from "../components/common/components/button/ButtonPrimary";
import AddOrEditRoomDialog from "../components/common/components/dialog/AddOrEditRoomDialog";
import confirmDialog from "../components/common/components/dialog/confirmDialog";
import { commonActions } from "../components/common/redux/action";
import { genSignature, getNonce, stringToSlug } from "../components/common/utils/helper";
import { TCommonState } from "../components/common/utils/type";
import AddOrEditFolderDialog from "../components/manage_document/components/AddOrEditFolderDialog";
import ExamTable from "../components/manage_document/components/ExamTable";
import Folder from "../components/manage_document/components/Folder";
import FolderList from "../components/manage_document/components/FolderList";
import ManageDocumentHeader from "../components/manage_document/components/ManageDocumentHeader";
import ShareDialog from "../components/manage_document/components/ShareDialog";
import { documentActions } from "../components/manage_document/redux/action";
import { EExamActions, TExam } from "../types/exam";
import { EFolderActions, TFolder } from "../types/folder";
import { eventTracking } from "../firebase/firebaseConfig";

type TManageDocumentProps = {
  isShared?: boolean;
};

export default function ManageDocument({ isShared = false }: TManageDocumentProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { folderId } = useParams();

  const { folders, files, userInfos } = useSelector((state: { document; common: TCommonState }) => ({
    folders: state.document.listFolderAndFile.folders,
    files: state.document.listFolderAndFile.files,
    userInfos: state.common.userInfos,
  }));

  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [addOrEditFolderDialog, setAddOrEditFolderDialog] = useState<{
    isOpen: boolean;
    isLoadingSubmit: boolean;
  }>({
    isOpen: false,
    isLoadingSubmit: false,
  });

  const [addOrEditRoomDialog, setAddOrEditRoomDialog] = useState<{
    isOpen: boolean;
    examId?: number;
  }>({ isOpen: false });

  const [rightClickFolder, setRightClickFolder] = useState<{ id: number; title: string } | undefined>(undefined);

  const [shareDialog, setShareDialog] = useState<{
    isOpen: boolean;
    type: "file" | "folder" | undefined;
    sharedIds: number[] | undefined;
  }>({
    isOpen: false,
    type: undefined,
    sharedIds: undefined,
  });

  const [isLoadingGetFolderAndFile, setIsLoadingGetFolderAndFile] = useState<boolean>(false);

  const isSearchMode = useMemo(() => searchKeyword.length > 0, [searchKeyword]);

  const examList = useMemo(() => {
    let examRender: TExam[] = [];

    if (folderId) examRender = files?.filter((file) => file?.folder_id === Number(folderId));
    else {
      if (isShared) {
        examRender = files?.filter((file) => {
          if (!file.folder_id) return true;
          const isSharedFileWithoutFolder = !folders?.find((folder) => folder.id === file.folder_id);
          return isSharedFileWithoutFolder;
        });
      } else {
        examRender = files?.filter((file) => !file.folder_id);
      }
    }

    if (isSearchMode)
      examRender = examRender?.filter((exam: TExam) => exam?.slug?.includes(stringToSlug(searchKeyword)));

    return examRender;
  }, [files, folders, folderId, searchKeyword, isSearchMode, isShared]);

  const folderList = useMemo(() => {
    let folderRender = folders;

    if (isSearchMode)
      folderRender = folderRender?.filter((exam: TExam) => exam?.slug?.includes(stringToSlug(searchKeyword)));

    return folderRender;
  }, [folders, searchKeyword, isSearchMode]);

  const menuRef = useRef<Menu>(null);

  const handleCloseAddOrEditFolderDialog = () => {
    setAddOrEditFolderDialog((prev) => ({ ...prev, isOpen: false }));
    setRightClickFolder(undefined);
  };

  const handleCloseShareDocumentDialog = () => {
    setShareDialog({
      isOpen: false,
      type: undefined,
      sharedIds: undefined,
    });
  };

  const getListFolderAndFile = () => {
    dispatch(
      documentActions.getListFolderAndFile({
        folder_type: isShared ? "shared" : "mine",
      })
    );
  };

  const handleAddOrEditFolder = (formValue: { id?: number; title: string }, actionType: EFolderActions) => {
    if (actionType === EFolderActions.ADD) {
      const dataPostPre = {
        title: formValue.title.trim(),
        nonce: getNonce(),
        timestamp: moment().unix(),
      };
      const dataPost = {
        ...dataPostPre,
        signature: genSignature(dataPostPre),
      };
      setAddOrEditFolderDialog((prev) => ({ ...prev, isLoadingSubmit: true }));
      dispatch(
        documentActions.createFolder(
          dataPost,
          () => {
            handleCloseAddOrEditFolderDialog();
            getListFolderAndFile();
            dispatch(commonActions.getAllFolderAndFile());
          },
          undefined,
          () => {
            setAddOrEditFolderDialog((prev) => ({ ...prev, isLoadingSubmit: false }));
          }
        )
      );
    } else if (actionType === EFolderActions.EDIT) {
      const dataPostPre = {
        title: formValue.title.trim(),
        nonce: getNonce(),
        timestamp: moment().unix(),
      };
      const dataPost = {
        ...dataPostPre,
        signature: genSignature({
          ...dataPostPre,
          id_folder: rightClickFolder?.id,
        }),
      };
      setAddOrEditFolderDialog((prev) => ({ ...prev, isLoadingSubmit: true }));
      dispatch(
        documentActions.updateFolder(
          {
            folderId: rightClickFolder?.id,
            data: dataPost,
          },
          () => {
            handleCloseAddOrEditFolderDialog();
            getListFolderAndFile();
            dispatch(commonActions.getAllFolderAndFile());
          },
          undefined,
          () => {
            setAddOrEditFolderDialog((prev) => ({ ...prev, isLoadingSubmit: false }));
          }
        )
      );
    }
  };

  const handleClickShareExams = (examIds: number[]) => {
    setShareDialog({
      isOpen: true,
      sharedIds: examIds,
      type: "file",
    });
  };

  const handleClickDeleteExams = (examIds: number[]) => {
    confirmDialog({
      header: intl.formatMessage({ id: "confirmDialog.title" }),
      message: intl.formatMessage({
        id: "confirmDialog.deleteExamDescription",
      }),
      accept: () => {
        const dataPostPre = {
          nonce: getNonce(),
          timestamp: moment().unix(),
          exam_ids: examIds,
        };
        const dataPost = {
          ...dataPostPre,
          signature: genSignature(dataPostPre),
        };
        dispatch(
          documentActions.deleteExams(dataPost, () => {
            getListFolderAndFile();
            dispatch(commonActions.getAllFolderAndFile());
          })
        );
      },
      reject: () => {},
      footer: (options) => {
        return (
          <div className="flex justify-end gap-4">
            <ButtonPrimary label={intl.formatMessage({ id: "common.cancel" })} onClick={options.reject} />
            <ButtonGhost label={intl.formatMessage({ id: "common.delete" })} onClick={options.accept} />
          </div>
        );
      },
    });
  };

  const handleShareDocument = (formValue: { referenceIds: string[] }) => {
    const dataPostPre = {
      nonce: getNonce(),
      timestamp: moment().unix(),
      shared_type: shareDialog.type,
      shared_ids: shareDialog.sharedIds,
      shared_members: formValue.referenceIds,
    };
    const dataPost = {
      ...dataPostPre,
      signature: genSignature(dataPostPre, undefined, undefined, ["shared_ids", "shared_members"]),
    };
    dispatch(
      documentActions.shareDocument(dataPost, () => {
        handleCloseShareDocumentDialog();
        getListFolderAndFile();
      })
    );
  };

  const handleClickCreateRoomDialog = (examId: number) => {
    setAddOrEditRoomDialog({ isOpen: true, examId });
  };

  const handleCloseCreateRoomDialog = () => {
    setAddOrEditRoomDialog({ isOpen: false });
  };

  const folderActionMenu = [
    {
      key: EFolderActions.SHARE,
      label: intl.formatMessage({ id: "folderMenuActions.share" }),
      icon: <ShareIcon />,
      command: () => {
        setShareDialog({
          isOpen: true,
          sharedIds: [rightClickFolder?.id as number],
          type: "folder",
        });
      },
    },
    {
      key: EFolderActions.EDIT,
      label: intl.formatMessage({ id: "folderMenuActions.update" }),
      icon: <Edit2Icon />,
      command: () => {
        setAddOrEditFolderDialog((prev) => ({ ...prev, isOpen: true }));
      },
    },
    {
      key: EFolderActions.DOWNLOAD,
      label: intl.formatMessage({ id: "folderMenuActions.download" }),
      icon: <DownloadIcon />,
      command: () => {
        dispatch(commonActions.handleOpenDialogFeatureDeveloping(true));
        // TODO: handle download
      },
    },
    {
      key: EFolderActions.DELETE,
      label: intl.formatMessage({ id: "folderMenuActions.delete" }),
      icon: <TrashIcon />,
      command: () => {
        confirmDialog({
          header: intl.formatMessage({ id: "confirmDialog.title" }),
          message: intl.formatMessage({
            id: "confirmDialog.deleteFolderDescription",
          }),
          accept: () => {
            const dataPostPre = {
              delete_files: 1,
              nonce: getNonce(),
              timestamp: moment().unix(),
            };
            const dataPost = {
              ...dataPostPre,
              signature: genSignature({
                ...dataPostPre,
                id_folder: rightClickFolder?.id,
              }),
            };
            dispatch(
              documentActions.deleteFolder(
                {
                  folderId: rightClickFolder?.id,
                  data: dataPost,
                },
                () => {
                  getListFolderAndFile();
                  dispatch(commonActions.getAllFolderAndFile());
                }
              )
            );
          },
          reject: () => {},
          footer: (options) => {
            return (
              <div className="flex justify-end gap-4">
                <ButtonPrimary label={intl.formatMessage({ id: "common.cancel" })} onClick={options.reject} />
                <ButtonGhost label={intl.formatMessage({ id: "common.delete" })} onClick={options.accept} />
              </div>
            );
          },
        });
      },
    },
  ];

  useLayoutEffect(() => {
    if (userInfos.member?.pub_id) {
      setIsLoadingGetFolderAndFile(true);
      dispatch(
        documentActions.getListFolderAndFile(
          {
            folder_type: isShared ? "shared" : "mine",
          },
          undefined,
          () => {
            dispatch(documentActions.clearListFolderFile());
          },
          () => {
            setIsLoadingGetFolderAndFile(false);
          }
        )
      );
    }
  }, [isShared]);

  useEffect(() => {
    if (userInfos.member?.pub_id) {
      dispatch(commonActions.getRoomCreationInfo());
      dispatch(commonActions.getAllFolderAndFile());
    }
  }, []);

  useEffect(() => {
    if (!isShared) {
      eventTracking("screen_mine_data_view");
    }
  }, [isShared]);

  return (
    <div className="main-content">
      <ManageDocumentHeader isHideAddExamButton={isShared} onSearch={(keyword: string) => setSearchKeyword(keyword)} />
      {isLoadingGetFolderAndFile ? (
        <div className="w-full pt-[200px] flex items-center justify-center">
          <ProgressSpinner
            strokeWidth="2"
            animationDuration="2s"
            pt={{
              circle: {
                className: "stroke-primary",
              },
            }}
          />
        </div>
      ) : (
        <>
          {!folderId && (
            <FolderList
              folderList={folderList}
              renderItem={(folder: TFolder) => (
                <Folder
                  key={folder.id}
                  folder={folder}
                  onClick={(id) => {
                    eventTracking("select_a_folder_item");
                    navigate(`${id}`, { relative: "path" });
                  }}
                  onRightClick={(event, folder) => {
                    event.preventDefault();
                    menuRef.current?.toggle(event);
                    setRightClickFolder(folder);
                  }}
                />
              )}
              isHideAddFolderButton={isShared}
              onClickButtonCreateFolder={() => {
                setAddOrEditFolderDialog((prev) => ({ ...prev, isOpen: true }));
                setRightClickFolder(undefined);
              }}
            />
          )}
          <ExamTable
            examList={examList}
            hiddenActionKeys={
              isShared ? [EExamActions.DELETE, EExamActions.SHARE, EExamActions.EDIT, EExamActions.MOVE] : []
            }
            onClickShareExams={handleClickShareExams}
            onClickDeleteExams={handleClickDeleteExams}
            onClickCreateRoom={handleClickCreateRoomDialog}
          />
        </>
      )}

      {addOrEditFolderDialog.isOpen && (
        <AddOrEditFolderDialog
          isOpen={addOrEditFolderDialog.isOpen}
          isLoadingSubmit={addOrEditFolderDialog.isLoadingSubmit}
          folder={rightClickFolder}
          onClose={handleCloseAddOrEditFolderDialog}
          onSubmit={handleAddOrEditFolder}
        />
      )}
      {shareDialog.isOpen && (
        <ShareDialog
          isOpen={shareDialog.isOpen}
          onSubmit={handleShareDocument}
          onClose={handleCloseShareDocumentDialog}
        />
      )}
      {addOrEditRoomDialog.isOpen && (
        <AddOrEditRoomDialog
          isOpen={addOrEditRoomDialog.isOpen}
          examId={addOrEditRoomDialog.examId}
          pageReloadDataWhenNotFoundExamError={isShared ? "shared" : "mine"}
          onClose={handleCloseCreateRoomDialog}
        />
      )}
      <Menu
        model={
          folderActionMenu
            ?.map((action) => {
              if (isShared && [EFolderActions.SHARE, EFolderActions.EDIT, EFolderActions.DELETE].includes(action.key))
                return;
              return action;
            })
            .filter((item) => item) as MenuItem[]
        }
        popup
        ref={menuRef}
        popupAlignment="left"
        pt={{
          root: {
            className: "w-[320px]",
          },
          label: {
            className: "ml-3",
          },
        }}
      />
    </div>
  );
}
