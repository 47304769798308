import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { TCommonState } from "../../components/common/utils/type";
import { commonActions } from "../../components/common/redux/action";
import { isNumber, orderBy } from "lodash";
import moment from "moment";
import ShowBasicDataContent from "../../components/manage_exam/components/ShowBasicDataContent";
import { MathJax } from "better-react-mathjax";
import { useSearchParams } from "react-router-dom";
import ReportQuestionDetail from "../../components/manage_report/ReportQuestionDetail";
import { convertAnswerKey } from "../../components/common/utils/constant";
import { ReportImageIcon } from "../../assets";
import DialogShowImageReport from "../../components/manage_report/DialogShowImageReport";
import Paging from "../../components/common/components/Paging";
import { genSignature, getNonce } from "../../components/common/utils/helper";
import NoData from "../../components/common/components/NoData";
import { ProgressSpinner } from "primereact/progressspinner";

const ITEMS_PER_PAGE = 15;

export default function ReportReview({ isDone = false }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [questionIdToShowImage, setQuestionIdToShowImage] = useState(null);
  const { reportReview, userInfos } = useSelector((state: { common: TCommonState }) => state.common);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  // const [dynamicMathJax, setDynamicMathJax] = useState(false);

  const reports = useMemo(() => {
    return orderBy(reportReview?.quiz_report, "created_at", ["desc"])
      ?.filter((e) => e?.status === (isDone ? "closed" : "open"))
      .map((ele, idx) => ({
        stt: idx + 1,
        quizReportId: ele?.id,
        questionId: ele?.quiz_question_id,
        questions: reportReview?.questions?.find((qs) => qs?.id === ele?.quiz_question_id)?.questions,
        reason: ele?.reason,
        images: ele?.images,
        user: ele?.report_user?.name,
        group: ele?.report_user?.grade,
        time: moment.unix(ele?.created_at).format("DD-MM-yyyy HH:mm"),
        action: ele?.status,
      }));
  }, [reportReview, isDone]);

  const questionId = searchParams.get("qs");

  const questionInfo = useMemo(() => {
    const qs = reportReview?.questions?.find((e) => e?.id === Number(questionId));
    const latestQuestion = reportReview?.latest_questions?.find((e) => e?.report_question_id === Number(questionId));
    return {
      ...qs,
      contents: qs?.questions,
      options: qs?.options?.map((e, idx) => ({
        ...e,
        answer_key: convertAnswerKey(idx),
        contents: e?.items,
      })),
      solutions: reportReview?.solutions?.find((e) => e?.quiz_question_id === Number(questionId)),
      quiz_report: reportReview?.quiz_report?.find((e) => e?.quiz_question_id === Number(questionId)),
      latestQuestion: latestQuestion
        ? {
            ...latestQuestion,
            contents: latestQuestion.questions,
            options: latestQuestion?.options?.map((lo, ix) => ({
              ...lo,
              contents: lo.items,
              answer_key: convertAnswerKey(ix),
            })),
            solutions: reportReview?.solutions?.find((e) => e?.quiz_question_id === Number(latestQuestion.id)),
            quiz_report: reportReview?.quiz_report?.find((e) => e?.quiz_question_id === Number(questionId)),
          }
        : undefined,
    };
  }, [reportReview, questionId]);

  const maxPage = Math.floor((reports?.length - 1) / ITEMS_PER_PAGE) + 1;

  const imagesToPreview = useMemo(
    () => reports?.find((e) => e?.questionId === questionIdToShowImage)?.images,
    [questionIdToShowImage]
  );

  const handleCloseReport = (quizReportId) => {
    const paramsPre = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      status: isDone ? "open" : "closed",
      quiz_report_id: quizReportId,
    };
    const params = {
      ...paramsPre,
      signature: genSignature(paramsPre),
    };
    dispatch(
      commonActions.handleChangeStatusReport(params, () => {
        dispatch(commonActions.handleGetReportReview());
        // setDynamicMathJax(false);
      })
    );
  };

  const questionBodyTemplate = (report) => {
    return (
      <div
        className="hover:cursor-pointer max-h-[100px] overflow-hidden"
        onClick={() => setSearchParams({ qs: report?.questionId })}
      >
        <MathJax dynamic>{report?.questions?.map((e) => <ShowBasicDataContent ele={e} />)}</MathJax>
      </div>
    );
  };
  const reasonBody = (report) => {
    return <div className="line-clamp-4">{report.reason}</div>;
  };
  const actionBodyTemplate = (report) => {
    return (
      <button
        onClick={() => {
          handleCloseReport(report?.quizReportId);
          // setDynamicMathJax(true);
        }}
        className={`${isDone ? "text-primary" : "text-secondary"} font-bold`}
      >
        {isDone ? "Mở lại" : "Đóng"}
      </button>
    );
  };
  const imageBodyTemplate = (report) => {
    return (
      <div>
        {report?.images && report?.images?.length > 0 ? (
          <button onClick={() => setQuestionIdToShowImage(report?.questionId)}>
            <ReportImageIcon />
          </button>
        ) : (
          "-"
        )}
      </div>
    );
  };

  useLayoutEffect(() => {
    if (!!userInfos?.member) {
      setIsLoading(true);
      dispatch(commonActions.handleGetReportReview(() => setIsLoading(false)));
    }
  }, [isDone]);

  useEffect(() => {
    document.querySelector(".main-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [page]);

  return searchParams.get("qs") ? (
    <ReportQuestionDetail
      question={questionInfo}
      handleCloseReport={handleCloseReport}
      isDone={isDone}
      handleGetReportReview={() => dispatch(commonActions.handleGetReportReview())}
    />
  ) : (
    <div className="main-content py-8 px-10">
      <DataTable
        value={reports?.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)}
        emptyMessage={
          isLoading ? (
            <div className="flex items-center justify-center min-h-[258px] h-full">
              <ProgressSpinner
                style={{ width: "60px", height: "60px" }}
                pt={{
                  circle: {
                    className: "stroke-primary",
                  },
                }}
              />
            </div>
          ) : (
            <div className="flex justify-center">
              <NoData />
            </div>
          )
        }
        pt={{
          root: {
            className: "[&_.p-datatable-emptymessage>td]:border-none",
          },
        }}
      >
        <Column field="stt" header="STT"></Column>
        <Column field="questions" style={{ width: "22%" }} header="Câu hỏi" body={questionBodyTemplate}></Column>
        <Column field="reason" style={{ width: "20%" }} header="Lý do khiếu nại" body={reasonBody}></Column>
        <Column field="image" style={{ width: "12%" }} header="Image" body={imageBodyTemplate}></Column>
        <Column field="user" header="Tài khoản khiếu nại"></Column>
        <Column field="group" header="Lớp"></Column>
        <Column field="time" header="Thời gian khiếu nại"></Column>
        <Column field="action" header="" body={actionBodyTemplate}></Column>
      </DataTable>
      <div className="w-full flex justify-end">
        {maxPage > 1 && (
          <div className="w-[256px] mt-[16px]">
            <Paging page={page} setPage={setPage} maxPage={maxPage} />
          </div>
        )}
      </div>

      {isNumber(questionIdToShowImage) && (
        <DialogShowImageReport
          open={isNumber(questionIdToShowImage)}
          onClose={() => setQuestionIdToShowImage(null)}
          images={imagesToPreview}
        />
      )}
    </div>
  );
}
