export const ACTION_TYPES: { [x: string]: any } = {
  CREATE_ROOM: Symbol("CREATE_ROOM"),
  CREATE_ROOM_SUCCESS: Symbol("CREATE_ROOM_SUCCESS"),
  CREATE_ROOM_FAILED: Symbol("CREATE_ROOM_FAILED"),
  UPDATE_ROOM: Symbol("UPDATE_ROOM"),
  UPDATE_ROOM_SUCCESS: Symbol("UPDATE_ROOM_SUCCESS"),
  UPDATE_ROOM_FAILED: Symbol("UPDATE_ROOM_FAILED"),
  GET_ROOM_DETAIL: Symbol("GET_ROOM_DETAIL"),
  GET_ROOM_DETAIL_SUCCESS: Symbol("GET_ROOM_DETAIL_SUCCESS"),
  GET_ROOM_DETAIL_FAILED: Symbol("GET_ROOM_DETAIL_FAILED"),
  DELETE_ROOM: Symbol("DELETE_ROOM"),
  DELETE_ROOM_SUCCESS: Symbol("DELETE_ROOM_SUCCESS"),
  DELETE_ROOM_FAILED: Symbol("DELETE_ROOM_FAILED"),
  GET_MEMBERS_OF_ROOM: Symbol("GET_MEMBERS_OF_ROOM"),
  GET_MEMBERS_OF_ROOM_SUCCESS: Symbol("GET_MEMBERS_OF_ROOM_SUCCESS"),
  GET_MEMBERS_OF_ROOM_FAILED: Symbol("GET_MEMBERS_OF_ROOM_FAILED"),
  DELETE_MEMBER_OF_ROOM: Symbol("DELETE_MEMBER_OF_ROOM"),
  DELETE_MEMBER_OF_ROOM_SUCCESS: Symbol("DELETE_MEMBER_OF_ROOM_SUCCESS"),
  DELETE_MEMBER_OF_ROOM_FAILED: Symbol("DELETE_MEMBER_OF_ROOM_FAILED"),
  ADD_MEMBER_OF_ROOM: Symbol("ADD_MEMBER_OF_ROOM"),
  ADD_MEMBER_OF_ROOM_SUCCESS: Symbol("ADD_MEMBER_OF_ROOM_SUCCESS"),
  ADD_MEMBER_OF_ROOM_FAILED: Symbol("ADD_MEMBER_OF_ROOM_FAILED"),
  RELOAD_DETAIL_ROOM: Symbol("RELOAD_DETAIL_ROOM"),
  GET_EXAM_DETAIL_OF_ROOM: Symbol("GET_EXAM_DETAIL_OF_ROOM"),
  GET_EXAM_DETAIL_OF_ROOM_SUCCESS: Symbol("GET_EXAM_DETAIL_OF_ROOM_SUCCESS"),
  GET_EXAM_DETAIL_OF_ROOM_FAILED: Symbol("GET_EXAM_DETAIL_OF_ROOM_FAILED"),
};
