import { InputText } from "primereact/inputtext";
import React, { useState } from "react";

export default function SearchComponent({ handleSearch, placeholder, trackingFocusInput = () => {} }) {
  const [inputValue, setInputValue] = useState("");
  return (
    <span className="p-input-icon-left">
      <i className="pi pi-search hover:cursor-pointer" onClick={() => handleSearch(inputValue)} />
      <InputText
        placeholder={placeholder}
        className="w-[270px] h-[44px] border border-gray200 rounded-lg"
        value={inputValue}
        onClick={() => trackingFocusInput()}
        onChange={(e) => {
          setInputValue(e.target.value);
          if (e.target.value.length === 0) handleSearch("");
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch(inputValue);
          }
        }}
      />
    </span>
  );
}
