import React, { useEffect, useState } from "react";
import CreateQuizInputScoreTable from "./CreateQuizInputScoreTable";
import CreateQuizSelectTopics from "./CreateQuizSelectTopics";
import { eventTracking } from "../../../firebase/firebaseConfig";

type TSelectTopicGroupExamPageProps = {
  listTopicLv1: any[];
  chosenTopics: any;
  onClickDeleteTopic: (topicLv1Id: number, topicLv2Id: number) => void;
  onClickTopicLv1: (topicLv1Id: number) => void;
  onClickTopicLv2: (topicLv1Id: number, topicLv2Id: number) => void;
  onUpdateNumsOfQuestion: (topicLv1Id: number, topicLv2Id: number, difficultDegreeField: number, value: number) => void;
};

export default function SelectTopicGroupExamPage({
  listTopicLv1,
  chosenTopics,
  onClickDeleteTopic,
  onClickTopicLv1,
  onClickTopicLv2,
  onUpdateNumsOfQuestion,
}: TSelectTopicGroupExamPageProps) {
  const [openDialogInputTitle, setOpenDialogInputTitle] = useState<boolean>(false);

  useEffect(() => {
    eventTracking("screen_random_input_type_view");
  }, []);

  return (
    <div className="flex flex-row min-h-[calc(100%-106px)] overflow-auto w-full">
      <div className="w-[312px] pl-[28px]">
        {listTopicLv1.length === 0 ? (
          <div className="h-full flex items-center">
            <span className="text-gray-900">Vui lòng nhập lớp, môn học, chương trình học để hiển thị các bài học</span>
          </div>
        ) : (
          <CreateQuizSelectTopics
            listTopicLv1={listTopicLv1}
            onClickTopicLv1={onClickTopicLv1}
            onClickTopicLv2={onClickTopicLv2}
          />
        )}
      </div>
      <div className="flex-1 border-l border-r pr-[40px]">
        <CreateQuizInputScoreTable
          chosenTopics={chosenTopics}
          onUpdateNumsOfQuestion={onUpdateNumsOfQuestion}
          onClickDeleteTopic={onClickDeleteTopic}
        />
      </div>
    </div>
  );
}
