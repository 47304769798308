import React from "react";
import ShowBasicDataContent from "../ShowBasicDataContent";
import { convertAnswerKey } from "../../../common/utils/constant";

type TOneAnswerCardProps = {
  options: any;
};

function OneAnswerCard({ options }: TOneAnswerCardProps) {
  return (
    <div className="grid grid-cols-2 gap-x-12 gap-y-6">
      {options?.map((op, idx) => (
        <div key={op.ordinal} className="flex justify-center w-full">
          <div
            className={`min-h-[48px] p-2 flex border rounded-[8px] md:w-[400px] 2xl:w-[686px] items-center ${
              Boolean(op?.correct) && "border-[#12B76A]"
            }`}
          >
            {op?.items?.map((opc, i) => (
              <span key={opc.id} className="flex items-baseline gap-1">
                <span>{i === 0 && convertAnswerKey(idx)}.</span> <ShowBasicDataContent ele={opc} />
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
export default React.memo(OneAnswerCard);
