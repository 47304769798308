export const ACTION_TYPES: { [x: string]: any } = {
  GET_CREATION_INFO_CREATE_EXAM: Symbol("GET_CREATION_INFO_CREATE_EXAM"),
  GET_CREATION_INFO_CREATE_EXAM_SUCCESS: Symbol("GET_CREATION_INFO_CREATE_EXAM_SUCCESS"),
  GET_CREATION_INFO_CREATE_EXAM_FAILED: Symbol("GET_CREATION_INFO_CREATE_EXAM_FAILED"),
  HANDLE_LOGOUT: Symbol("HANDLE_LOGOUT"),
  ADD_USER_INFOS: Symbol("ADD_USER_INFOS"),
  ADD_FIRE_BASE_INFOS: Symbol("ADD_FIRE_BASE_INFOS"),
  SAVE_NOTIFY: Symbol("SAVE_NOTIFY"),
  AUTHORIZE_SUCCESS: Symbol("AUTHORIZE_SUCCESS"),
  DELETE_NOTIFY: Symbol("DELETE_NOTIFY"),
  HANDLE_CREATE_QUIZ_PDF: Symbol("HANDLE_CREATE_QUIZ_PDF"),
  HANDLE_CREATE_EXAMINATION_SUCCESS: Symbol("HANDLE_CREATE_EXAMINATION_SUCCESS"),
  HANDLE_CREATE_QUIZ_PDF_FAILED: Symbol("HANDLE_CREATE_QUIZ_PDF_FAILED"),
  GET_LIST_ROOM: Symbol("GET_LIST_ROOM"),
  GET_LIST_ROOM_SUCCESS: Symbol("GET_LIST_ROOM_SUCCESS"),
  GET_LIST_ROOM_FAILED: Symbol("GET_LIST_ROOM_FAILED"),
  HANDLE_CONVERT_DOCX: Symbol("HANDLE_CONVERT_DOCX"),
  HANDLE_CONVERT_DOCX_SUCCESS: Symbol("HANDLE_CONVERT_DOCX_SUCCESS"),
  HANDLE_CONVERT_DOCX_FAILED: Symbol("HANDLE_CONVERT_DOCX_FAILED"),
  GET_ROOM_CREATION_INFO: Symbol("GET_ROOM_CREATION_INFO"),
  GET_ROOM_CREATION_INFO_SUCCESS: Symbol("GET_ROOM_CREATION_INFO_SUCCESS"),
  GET_ROOM_CREATION_INFO_FAILED: Symbol("GET_ROOM_CREATION_INFO_FAILED"),
  GET_ALL_FOLDER_AND_FILE: Symbol("GET_ALL_FOLDER_AND_FILE"),
  GET_ALL_FOLDER_AND_FILE_SUCCESS: Symbol("GET_ALL_FOLDER_AND_FILE_SUCCESS"),
  GET_ALL_FOLDER_AND_FILE_FAILED: Symbol("GET_ALL_FOLDER_AND_FILE_FAILED"),
  CLEAN_QUIZ_FILE: Symbol("CLEAN_QUIZ_FILE"),
  ADD_FOLDER_CREATE_EXAM: Symbol("ADD_FOLDER_CREATE_EXAM"),
  FEATURE_DEVELOPING: Symbol("FEATURE_DEVELOPING"),
  GET_EXAM_DETAIL: Symbol("GET_EXAM_DETAIL"),
  GET_EXAM_DETAIL_SUCCESS: Symbol("GET_EXAM_DETAIL_SUCCESS"),
  GET_EXAM_DETAIL_FAILED: Symbol("GET_EXAM_DETAIL_FAILED"),
  EDIT_EXAM: Symbol("EDIT_EXAM"),
  EDIT_EXAM_SUCCESS: Symbol("EDIT_EXAM_SUCCESS"),
  EDIT_EXAM_FAILED: Symbol("EDIT_EXAM_FAILED"),
  HIDE_LEFT_SIDE: Symbol("HIDE_LEFT_SIDE"),
  REPORT_QUIZ: Symbol("REPORT_QUIZ"),
  REPORT_QUIZ_SUCCESS: Symbol("REPORT_QUIZ_SUCCESS"),
  REPORT_QUIZ_FAILED: Symbol("REPORT_QUIZ_FAILED"),
  GET_DASHBOARD: Symbol("GET_DASHBOARD"),
  GET_DASHBOARD_SUCCESS: Symbol("GET_DASHBOARD_SUCCESS"),
  GET_DASHBOARD_FAILED: Symbol("GET_DASHBOARD_FAILED"),
  GET_TEACHER_INFO: Symbol("GET_TEACHER_INFO"),
  GET_TEACHER_INFO_SUCCESS: Symbol("GET_TEACHER_INFO_SUCCESS"),
  GET_TEACHER_INFO_FAILED: Symbol("GET_TEACHER_INFO_FAILED"),
  GET_GRADE_AND_SUBJECT: Symbol("GET_GRADE_AND_SUBJECT"),
  GET_GRADE_AND_SUBJECT_SUCCESS: Symbol("GET_GRADE_AND_SUBJECT_SUCCESS"),
  UPDATE_ACCOUNT_INFO: Symbol("UPDATE_ACCOUNT_INFO"),
  UPDATE_ACCOUNT_INFO_SUCCESS: Symbol("UPDATE_ACCOUNT_INFO_SUCCESS"),
  UPDATE_ACCOUNT_INFO_FAILED: Symbol("UPDATE_ACCOUNT_INFO_FAILED"),
  SET_OPEN_DIALOG_REGISTER_TEACHER: Symbol("SET_OPEN_DIALOG_REGISTER_TEACHER"),
  SET_OPEN_DIALOG_NOTIFY_REGISTER: Symbol("SET_OPEN_DIALOG_NOTIFY_REGISTER"),
  SET_OPEN_DIALOG_UPDATE_PROFILE: Symbol("SET_OPEN_DIALOG_UPDATE_PROFILE"),
  HANDLE_REGISTER_TEACHER: Symbol("HANDLE_REGISTER_TEACHER"),
  HANDLE_REGISTER_TEACHER_SUCCESS: Symbol("HANDLE_REGISTER_TEACHER_SUCCESS"),
  HANDLE_REGISTER_TEACHER_FAILED: Symbol("HANDLE_REGISTER_TEACHER_FAILED"),
  HANDLE_UPDATE_PROFILE: Symbol("HANDLE_UPDATE_PROFILE"),
  HANDLE_UPDATE_PROFILE_SUCCESS: Symbol("HANDLE_UPDATE_PROFILE_SUCCESS"),
  HANDLE_UPDATE_PROFILE_FAILED: Symbol("HANDLE_UPDATE_PROFILE_FAILED"),
  GET_REPORT_REVIEW: Symbol("GET_REPORT_REVIEW"),
  GET_REPORT_REVIEW_SUCCESS: Symbol("GET_REPORT_REVIEW_SUCCESS"),
  GET_REPORT_REVIEW_FAILED: Symbol("GET_REPORT_REVIEW_FAILED"),
  HANDLE_CHANGE_STATUS_REPORT: Symbol("HANDLE_CHANGE_STATUS_REPORT"),
  HANDLE_CHANGE_STATUS_REPORT_SUCCESS: Symbol("HANDLE_CHANGE_STATUS_REPORT_SUCCESS"),
  HANDLE_CHANGE_STATUS_REPORT_FAILED: Symbol("HANDLE_CHANGE_STATUS_REPORT_FAILED"),
  GET_USER_INFO: Symbol("GET_USER_INFO"),
  GET_USER_INFO_SUCCESS: Symbol("GET_USER_INFO_SUCCESS"),
  GET_USER_INFO_FAILED: Symbol("GET_USER_INFO_FAILED"),
  CREATE_EXAM: Symbol("CREATE_EXAM"),
  CREATE_EXAM_SUCCESS: Symbol("CREATE_EXAM_SUCCESS"),
  CREATE_EXAM_FAILED: Symbol("CREATE_EXAM_FAILED"),
  HANDLE_EDIT_QUESTION: Symbol("HANDLE_EDIT_QUESTION"),
  HANDLE_EDIT_QUESTION_SUCCESS: Symbol("HANDLE_EDIT_QUESTION_SUCCESS"),
  HANDLE_EDIT_QUESTION_FAILED: Symbol("HANDLE_EDIT_QUESTION_FAILED"),
  LOGIN_WITH_EMAIL_AND_PASSWORD: Symbol("LOGIN_WITH_EMAIL_AND_PASSWORD"),
  SET_ERROR_MESSAGE_FORM_LOGIN_FROM_SERVER: Symbol("SET_ERROR_MESSAGE_FORM_LOGIN_FROM_SERVER"),
};
