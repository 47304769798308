import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import { quizAnswerType } from "../../common/utils/constant";
import DialogEditExam from "./DialogEditExam";
import ParentQuestionCard from "./QuizComponents/ParentQuestionCard";
import QuestionCard from "./QuizComponents/QuestionCard";
import { getCorrectOrdinalOfListQuestion, isParentQuestion } from "../utils/helpers";

function QuestionsDocx({
  listQuestion,
  setOrdinalQuestion,
  updateListQuestion,
  setOpenDialogSolution,
  isViewOnly = false,
  isEdited = false,
  setOpenDialogConfirmDeleteFile = () => {},
  isSelfInput = false,
  handleOpenDialogEditQuestion = (v) => {},
}) {
  const { hideLeftSide } = useSelector((state: { common }) => state?.common);
  const [questionEdit, setQuestionEdit] = useState<any>();
  const [isDynamicMathJax, setIsDynamicMathJax] = useState(false);
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const breakShowOption = windowSize?.width - 312 * 2 - (hideLeftSide ? 312 : 82) > 900;

  const handleUpdateListQuestion = (data) => {
    // xử lý question và option chỉ có 1 contents html (image thì chuyển sang base64)
    const updatedListQuestion = listQuestion?.map((qs) =>
      qs?.ordinal === questionEdit?.ordinal
        ? {
            ...qs,
            contents: [{ ...qs?.contents[0], content: data?.find((e) => e?.ordinal === -1)?.content, edited: true }],
            options: qs?.options?.map((op) => ({
              ...op,
              contents: [
                { ...op?.contents[0], content: data?.find((e) => e?.ordinal === op?.ordinal)?.content, edited: true },
              ],
            })),
          }
        : qs
    );
    updateListQuestion(updatedListQuestion);
  };
  const handleDeleteQuestion = (ordinal) => {
    setOrdinalQuestion(ordinal);
    setIsDynamicMathJax(false);

    let newListQuestion = [...listQuestion];
    const deleteQuestionIndex = newListQuestion.findIndex((qs) => qs.ordinal === ordinal);
    if (isParentQuestion(newListQuestion[deleteQuestionIndex])) {
      newListQuestion = newListQuestion.map((question) => {
        if (question.parent === newListQuestion[deleteQuestionIndex].id) {
          return {
            ...question,
            parent: undefined,
          };
        } else {
          return question;
        }
      });
    }
    newListQuestion[deleteQuestionIndex] = {
      ...newListQuestion[deleteQuestionIndex],
      deleted: true,
      ordinal: undefined,
    };
    newListQuestion = getCorrectOrdinalOfListQuestion(newListQuestion);

    if (newListQuestion?.filter((e) => !e?.deleted)?.length === 0) {
      if (isEdited) {
        setOpenDialogConfirmDeleteFile();
      } else {
        navigate(0);
        updateListQuestion(newListQuestion);
      }
    } else {
      updateListQuestion(newListQuestion);
    }
    setIsDynamicMathJax(true);
  };

  return (
    <div className={`w-full p-6 ${isSelfInput ? "h-max flex gap-6 flex-col" : "h-full"}`}>
      {listQuestion
        ?.filter((e) => !e?.deleted)
        ?.filter((question) => !question?.parent)
        ?.map((qs, idx) => {
          const currentIndex = listQuestion
            ?.filter((e) => !e?.deleted)
            ?.findIndex((question) => question.ordinal === qs.ordinal);

          const countPreviousParentQuestion = listQuestion
            ?.filter((e) => !e?.deleted)
            ?.filter((question) => !question?.parent)
            ?.slice(0, idx)
            .filter((question) => question.answer_type === quizAnswerType.NONE)?.length;

          if (qs.answer_type === quizAnswerType.NONE) {
            return (
              <ParentQuestionCard
                qs={qs}
                isDynamicMathJax={isDynamicMathJax}
                isViewOnly={isViewOnly}
                onClickEditButton={() => {
                  if (isSelfInput) {
                    handleOpenDialogEditQuestion(qs);
                    setIsDynamicMathJax(true);
                  } else {
                    setIsDynamicMathJax(false);
                    setQuestionEdit(qs);
                  }
                }}
                onClickDeleteQuestion={handleDeleteQuestion}
              >
                {listQuestion
                  ?.filter((question) => !question?.deleted)
                  ?.filter((question) => question?.parent)
                  ?.filter((question) => question.parent === qs.id)
                  .map((question, childrenQuestionIndex) => {
                    return (
                      <QuestionCard
                        qs={question}
                        isDynamicMathJax={isDynamicMathJax}
                        setOrdinalQuestion={setOrdinalQuestion}
                        idx={currentIndex - (countPreviousParentQuestion as number) + childrenQuestionIndex}
                        breakShowOption={breakShowOption}
                        isViewOnly={isViewOnly}
                        onClickShowSolution={() => {
                          setOpenDialogSolution(question?.ordinal);
                        }}
                        onClickEditButton={() => {
                          if (isSelfInput) {
                            handleOpenDialogEditQuestion(question);
                            setIsDynamicMathJax(true);
                          } else {
                            setIsDynamicMathJax(false);
                            setQuestionEdit(question);
                          }
                        }}
                        onClickDeleteQuestion={handleDeleteQuestion}
                      />
                    );
                  })}
              </ParentQuestionCard>
            );
          } else {
            return (
              <QuestionCard
                qs={qs}
                isDynamicMathJax={isDynamicMathJax}
                setOrdinalQuestion={setOrdinalQuestion}
                idx={currentIndex - (countPreviousParentQuestion as number)}
                breakShowOption={breakShowOption}
                isViewOnly={isViewOnly}
                onClickShowSolution={() => {
                  setOpenDialogSolution(qs?.ordinal);
                }}
                onClickEditButton={() => {
                  if (isSelfInput) {
                    handleOpenDialogEditQuestion(qs);
                    setIsDynamicMathJax(true);
                  } else {
                    setIsDynamicMathJax(false);
                    setQuestionEdit(qs);
                  }
                }}
                onClickDeleteQuestion={handleDeleteQuestion}
              />
            );
          }
        })}
      {questionEdit && (
        <DialogEditExam
          question={questionEdit}
          open={Boolean(questionEdit)}
          onClose={() => {
            setQuestionEdit(null);
            setIsDynamicMathJax(true);
          }}
          onSubmit={(val) => {
            handleUpdateListQuestion(val);
            setIsDynamicMathJax(true);
          }}
          title="Sửa câu hỏi"
        />
      )}
    </div>
  );
}

// const checkEqualProps = (preProps, nextProps) => {
//   return isEqual(preProps?.listQuestion, nextProps?.listQuestion);
//   return isEqual(preProps?.listQuestion, nextProps?.listQuestion);
// };

export default QuestionsDocx;
