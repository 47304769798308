import React from "react";
import { InputText as PrimeInputText, InputTextProps } from "primereact/inputtext";

type TInputTextProps = InputTextProps & {
  label?: string;
  placeholder?: string;
  isError?: boolean;
  messageError?: string;
  isRequired?: boolean;
};

export default function InputText({
  label = "",
  isRequired = false,
  placeholder = "",
  isError = false,
  messageError = "",
  ...props
}: TInputTextProps) {
  return (
    <div className="flex flex-col gap-2 relative mt-2">
      <label htmlFor="inputtext" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-2 p-0.5">
        {label} {isRequired && <span className="text-error"> *</span>}
      </label>
      <PrimeInputText id="inputtext" placeholder={placeholder} {...props} />
      {isError && <small className="p-error">{messageError}</small>}
    </div>
  );
}
