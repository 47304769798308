import { ACTION_TYPES } from "./actionTypes";
import { ACTION_TYPES as COMMON_ACTION_TYPES } from "../../common/redux/actionTypes";

export const initialStateManageStudent = {
  groupList: [],
  groupDetail: {},
  memberResultInGroup: { items: [] },
  actionHistory: [],
  allMembers: {},
  memberResult: { items: [] },
};

const manageStudentReducer = (state = initialStateManageStudent, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.GET_LIST_GROUP_SUCCESS: {
      return {
        ...state,
        groupList: action.params.items,
      };
    }
    case COMMON_ACTION_TYPES.HANDLE_LOGOUT: {
      return {
        ...state,
        groupList: [],
      };
    }
    case ACTION_TYPES.GET_GROUP_DETAIL_SUCCESS: {
      return {
        ...state,
        groupDetail: action.params,
      };
    }
    case ACTION_TYPES.GET_GROUP_ACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        actionHistory: action.params.group_action_history,
      };
    }
    case ACTION_TYPES.GET_MEMBER_RESULT_IN_GROUP_SUCCESS: {
      return {
        ...state,
        memberResultInGroup: action.params,
      };
    }
    case ACTION_TYPES.GET_ALL_MEMBERS_SUCCESS: {
      return {
        ...state,
        allMembers: action.params,
      };
    }
    case ACTION_TYPES.GET_MEMBER_RESULT_SUCCESS: {
      return {
        ...state,
        memberResult: action.params,
      };
    }

    default:
      return state;
  }
};

export default manageStudentReducer;
