import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoData from "../../../components/common/components/NoData";
import { stringToSlug } from "../../../components/common/utils/helper";
import GroupDetailHeader from "../../../components/manage_student/components/GroupDetailHeader";
import MembersTable from "../../../components/manage_student/components/MembersTable";
import { manageStudentActions } from "../../../components/manage_student/redux/action";
import { COLUMN_KEY_MEMBERS_TABLE } from "../../../components/manage_student/utils/constant";
import { eventTracking } from "../../../firebase/firebaseConfig";

export default function ManageMember() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const [isLoadingGetGroupDetail, setIsLoadingGetGroupDetail] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const isSearching = searchKeyword.length > 0;

  const { allMembers, userInfos } = useSelector((state: { manageStudent; common }) => ({
    ...state.manageStudent,
    ...state.common,
  }));

  const filteredMembers = useMemo(() => {
    let filteredMembers = allMembers?.items;

    if (isSearching) {
      filteredMembers = filteredMembers.filter(
        (member) =>
          member.member_hid === searchKeyword || stringToSlug(member.name)?.includes(stringToSlug(searchKeyword))
      );
    }

    return filteredMembers || [];
  }, [searchKeyword, allMembers?.items]);

  const handleClickViewMember = (memberHid: string) => {
    navigate(`/quan-ly-hoc-sinh/tat-ca-hoc-sinh/${memberHid}`);
  };

  useLayoutEffect(() => {
    if (userInfos.member?.pub_id) {
      setIsLoadingGetGroupDetail(true);
      dispatch(manageStudentActions.getAllMembers(() => setIsLoadingGetGroupDetail(false)));
    }
  }, []);

  useEffect(() => {
    eventTracking("screen_list_student_view");
  }, []);

  return (
    <div className="main-content">
      {isLoadingGetGroupDetail ? (
        <div className="w-full pt-[200px] flex items-center justify-center">
          <ProgressSpinner
            strokeWidth="2"
            animationDuration="2s"
            pt={{
              circle: {
                className: "stroke-primary",
              },
            }}
          />
        </div>
      ) : (
        <>
          <GroupDetailHeader onSearch={(searchKeyword) => setSearchKeyword(searchKeyword)} />
          {filteredMembers.length === 0 ? (
            !isSearching ? (
              <div className="w-full pt-[100px] flex items-center justify-center">
                <NoData message={intl.formatMessage({ id: "noStudentData" })} />
              </div>
            ) : (
              <div className="w-full pt-[100px] flex items-center justify-center">
                <NoData message={intl.formatMessage({ id: "common.notFoundData" })} />
              </div>
            )
          ) : (
            <MembersTable
              members={filteredMembers}
              columnKeysDisplay={[
                COLUMN_KEY_MEMBERS_TABLE.NO,
                COLUMN_KEY_MEMBERS_TABLE.NAME,
                COLUMN_KEY_MEMBERS_TABLE.MEMBER_HID,
                COLUMN_KEY_MEMBERS_TABLE.AVG_SCORE,
                COLUMN_KEY_MEMBERS_TABLE.NUM_OF_CLASS_JOIN,
                COLUMN_KEY_MEMBERS_TABLE.NUM_OF_ROOM_JOIN,
                COLUMN_KEY_MEMBERS_TABLE.NUM_OF_CLASS_JOIN,
              ]}
              onClickViewMember={(memberHid: string) => {
                eventTracking("select_a_student_item");
                handleClickViewMember(memberHid);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
