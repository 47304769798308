import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { CloseCircleIcon } from "../../assets";
import { Image } from "primereact/image";
import { ArrowCircleLeft, ArrowCircleRight } from "../../assets/icons/arrow_circle";

export default function DialogShowImageReport({ open, onClose, images }) {
  const [imageOrdinal, setImageOrdinal] = useState(0);

  const footer = () => {
    return (
      <div className="flex justify-center items-center gap-10 pt-6">
        <button disabled={imageOrdinal === 0} onClick={() => setImageOrdinal(imageOrdinal - 1)}>
          <ArrowCircleLeft stroke={imageOrdinal === 0 ? "#666A72" : "#101828"} />
        </button>
        <span className="font-bold">
          {imageOrdinal + 1}/{images?.length}
        </span>
        <button disabled={imageOrdinal >= images?.length - 1} onClick={() => setImageOrdinal(imageOrdinal + 1)}>
          <ArrowCircleRight stroke={imageOrdinal >= images?.length - 1 ? "#666A72" : "#101828"} />
        </button>
      </div>
    );
  };
  return (
    <Dialog
      className="w-[789px]"
      visible={open}
      footer={footer}
      onHide={onClose}
      draggable={false}
      closable
      closeIcon={<CloseCircleIcon />}
    >
      <div className="flex justify-center">
        <Image
          src={images?.find((_, ix) => ix === imageOrdinal)}
          alt=""
          preview
          pt={{ image: { className: "object-scale-down max-h-[60vh]" } }}
        />
      </div>
    </Dialog>
  );
}
