import moment from "moment";
import { Menu } from "primereact/menu";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Edit2Icon, TrashIcon } from "../../../assets";
import ButtonGhost from "../../../components/common/components/button/ButtonGhost";
import ButtonPrimary from "../../../components/common/components/button/ButtonPrimary";
import confirmDialog from "../../../components/common/components/dialog/confirmDialog";
import { genSignature, getNonce, removeNullAttributeFromObject } from "../../../components/common/utils/helper";
import AddOrEditGroupDialog from "../../../components/manage_student/components/AddOrEditGroupDialog";
import GroupItem from "../../../components/manage_student/components/GroupItem";
import GroupList from "../../../components/manage_student/components/GroupList";
import ManageGroupHeader from "../../../components/manage_student/components/ManageGroupHeader";
import { manageStudentActions } from "../../../components/manage_student/redux/action";
import { ERROR_CODE_NOT_FOUND_GROUP } from "../../../components/manage_student/utils/constant";
import { EGroupActions, TAddGroupRequest, TEditGroupRequest, TGroupResponse } from "../../../types/group";
import { eventTracking } from "../../../firebase/firebaseConfig";

export default function ManageGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const menuRef = useRef<Menu>(null);

  const { groupList, userInfos } = useSelector((state: { manageStudent; common }) => ({
    groupList: state.manageStudent.groupList,
    userInfos: state.common.userInfos,
  }));

  const [isOpenAddOrEditGroupDialog, setIsOpenAddOrEditGroupDialog] = useState<boolean>(false);
  const [chosenGroup, setChosenGroup] = useState<TGroupResponse | undefined>(undefined);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [isLoadingGetListGroup, setIsLoadingGetListGroup] = useState<boolean>(false);

  const groupListRender = useMemo(() => {
    return (
      groupList?.filter((group: TGroupResponse) =>
        group.title.toLowerCase().includes(searchKeyword.trim().toLowerCase())
      ) || []
    );
  }, [searchKeyword, groupList]);

  const handleCloseAddOrEditGroupDialog = () => {
    setIsOpenAddOrEditGroupDialog(false);
    setChosenGroup(undefined);
  };

  const handleAddOrEditGroup = (formValue: TAddGroupRequest | TEditGroupRequest, actionType: EGroupActions) => {
    if (actionType === EGroupActions.ADD) {
      eventTracking("select_create_group_done");
      const addFormValue = formValue as TAddGroupRequest;

      const dataPre = {
        timestamp: moment().unix(),
        nonce: getNonce(),
        title: addFormValue.title.trim(),
        description: addFormValue.description?.trim(),
        status_add_room: addFormValue.addRoomStatus,
        status_add_member: addFormValue.addMemberStatus,
      };
      const data = {
        ...removeNullAttributeFromObject(dataPre),
        signature: genSignature(removeNullAttributeFromObject(dataPre)),
      };
      dispatch(
        manageStudentActions.createGroup(data, (groupHid: string) => {
          navigate(`/quan-ly-hoc-sinh/lop-hoc/${groupHid}`);
        })
      );
    } else if (actionType === EGroupActions.EDIT) {
      eventTracking("select_update_group_done");
      const editFormValue = formValue as TEditGroupRequest;

      const dataPre = {
        timestamp: moment().unix(),
        nonce: getNonce(),
        title: editFormValue.title.trim(),
        description: editFormValue.description?.trim() || "",
        status_add_room: editFormValue.addRoomStatus,
        status_add_member: editFormValue.addMemberStatus,
      };
      const data = {
        ...dataPre,
        signature: genSignature({ ...dataPre, group_hid: editFormValue.groupHid }),
      };
      dispatch(
        manageStudentActions.updateGroup(
          { group_hid: editFormValue.groupHid, data },
          () => {
            handleCloseAddOrEditGroupDialog();
            dispatch(manageStudentActions.getListGroup());
          },
          (error) => {
            switch (error.code) {
              case ERROR_CODE_NOT_FOUND_GROUP: {
                handleCloseAddOrEditGroupDialog();
                dispatch(manageStudentActions.getListGroup());
                break;
              }
              default:
                break;
            }
          }
        )
      );
    }
  };

  useLayoutEffect(() => {
    if (userInfos.member?.pub_id) {
      setIsLoadingGetListGroup(true);
      dispatch(manageStudentActions.getListGroup(() => setIsLoadingGetListGroup(false)));
    }
  }, []);

  useEffect(() => {
    eventTracking("screen_list_group_view");
  }, []);

  return (
    <div className="main-content">
      <ManageGroupHeader
        onClickCreateGroupButton={() => {
          setIsOpenAddOrEditGroupDialog(true);
          setChosenGroup(undefined);
        }}
        onSearch={(keyword: string) => {
          eventTracking("select_search_group");
          setSearchKeyword(keyword);
        }}
      />
      {isLoadingGetListGroup ? (
        <div className="w-full pt-[200px] flex items-center justify-center">
          <ProgressSpinner
            strokeWidth="2"
            animationDuration="2s"
            pt={{
              circle: {
                className: "stroke-primary",
              },
            }}
          />
        </div>
      ) : (
        <GroupList
          groupList={groupListRender}
          renderItem={(groupDetail: TGroupResponse) => (
            <GroupItem
              key={groupDetail.id}
              groupDetail={groupDetail}
              onClick={() => {
                eventTracking("select_click_group");
                navigate(`/quan-ly-hoc-sinh/lop-hoc/${groupDetail.hid}`);
              }}
              onClickMore={(event) => {
                event.stopPropagation();
                menuRef.current?.toggle(event);
                setChosenGroup(groupDetail);
              }}
            />
          )}
        />
      )}
      <Menu
        model={[
          {
            label: intl.formatMessage({ id: "groupAction.edit" }),
            icon: <Edit2Icon />,
            command: () => {
              eventTracking("select_update_group");
              setIsOpenAddOrEditGroupDialog(true);
            },
          },
          {
            label: intl.formatMessage({ id: "groupAction.delete" }),
            icon: <TrashIcon />,
            command: () => {
              eventTracking("select_update_delete");
              confirmDialog({
                header: intl.formatMessage({ id: "confirmDialog.title" }),
                message: intl.formatMessage({
                  id: "confirmDialog.deleteGroupDescription",
                }),
                accept: () => {
                  eventTracking("select_update_delete_done");
                  const dataPostPre = {
                    nonce: getNonce(),
                    timestamp: moment().unix(),
                  };
                  const dataPost = {
                    ...dataPostPre,
                    signature: genSignature({
                      ...dataPostPre,
                      group_hid: chosenGroup?.hid,
                    }),
                  };
                  dispatch(
                    manageStudentActions.deleteGroup(
                      { group_hid: chosenGroup?.hid, data: dataPost },
                      () => {
                        dispatch(manageStudentActions.getListGroup());
                      },
                      (error) => {
                        switch (error.code) {
                          case ERROR_CODE_NOT_FOUND_GROUP: {
                            dispatch(manageStudentActions.getListGroup());
                            break;
                          }
                          default:
                            break;
                        }
                      }
                    )
                  );
                },
                reject: () => {},
                footer: (options) => {
                  return (
                    <div className="flex justify-end gap-4">
                      <ButtonPrimary label={intl.formatMessage({ id: "common.cancel" })} onClick={options.reject} />
                      <ButtonGhost label={intl.formatMessage({ id: "common.delete" })} onClick={options.accept} />
                    </div>
                  );
                },
              });
            },
          },
        ]}
        popup
        ref={menuRef}
        id="group_actions"
        popupAlignment="left"
        pt={{
          root: {
            className: "w-[320px]",
          },
          label: {
            className: "ml-3",
          },
        }}
      />
      {isOpenAddOrEditGroupDialog && (
        <AddOrEditGroupDialog
          isOpen={isOpenAddOrEditGroupDialog}
          groupDetail={chosenGroup}
          onClose={handleCloseAddOrEditGroupDialog}
          onSubmit={handleAddOrEditGroup}
        />
      )}
    </div>
  );
}
