import React, { useEffect, useRef, useState } from "react";
import { IconBorderGrade } from "../../assets";
import { Image } from "primereact/image";

function useDynamicSVGImport(name, options = {}) {
  const ImportedIconRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [image, setImage] = useState(null);

  const { onCompleted, onError }: any = options;
  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        const response = await import(`../../assets/icons/${name}.svg`);
        setImage(response?.default);

        if (onCompleted) {
          onCompleted(name, ImportedIconRef?.current);
        }
      } catch (err) {
        if (onError) {
          onError(err);
        }
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name, onCompleted, onError]);

  return { error, loading, SvgIcon: image };
}


interface IProps {
  fileName: string;
  netPhoto?: any;
  onCompleted?: Function;
  onError?: Function;
  styleSvg?: any;
  [x: string]: any;
}
function LoadingSvg({ fileName, netPhoto, onCompleted, onError, ...rest }: IProps) {
  const { error, loading, SvgIcon } = useDynamicSVGImport(fileName, {
    onCompleted,
    onError,
  });

  if (error) {
    return <Image src={netPhoto} alt="error" />;
  }
  if (loading) {
    return <span>...</span>;
  }
  if (SvgIcon) {
    return (
      <div className="flex justify-center items-center">
        <img
          alt=""
          src={SvgIcon}
          style={{
            width: 32,
            height: 30,
            objectFit: "contain",
            position: "absolute",
          }}
          {...rest}
        />
        <IconBorderGrade />
      </div>
    );
  }
  return null;
}
export default LoadingSvg;
export { useDynamicSVGImport };
