import { MathJax } from "better-react-mathjax";
import React, { ReactNode } from "react";
import ShowBasicDataContent from "../ShowBasicDataContent";
import { PencilEdit, TrashIcon } from "../../../../assets";

type ParentQuestionCardProps = {
  qs: any;
  isDynamicMathJax: boolean;
  isViewOnly: boolean;
  onClickEditButton?: any;
  onClickDeleteQuestion?: any;
  children: ReactNode;
};

const ParentQuestionCard = ({
  qs,
  isDynamicMathJax,
  isViewOnly,
  onClickEditButton,
  onClickDeleteQuestion,
  children,
}: ParentQuestionCardProps) => {
  const questionContents = qs?.isSelfInput ? qs?.contents : qs.questions;
  return (
    <MathJax key={qs.ordinal} dynamic={isDynamicMathJax}>
      <div id={`question_${qs?.ordinal}`} className="">
        <div className="py-4 flex flex-col">
          {questionContents?.map((e, ix) => (
            <button key={e?.id} className="flex w-full cursor-default">
              <div className="w-full text-start">
                <ShowBasicDataContent ele={e} textClassName="font-semibold text-gray-900" />
              </div>
            </button>
          ))}
        </div>
        {!isViewOnly && (
          <div className="flex justify-end items-center w-full font-[600] mt-6">
            <div className="flex gap-[64px]">
              <button className="flex gap-2 text-primary" onClick={onClickEditButton}>
                <PencilEdit stroke="#38B000" /> Sửa
              </button>
              <button className="flex gap-2 text-red-500" onClick={() => onClickDeleteQuestion(qs?.ordinal)}>
                <TrashIcon />
                Xoá câu hỏi
              </button>
            </div>
          </div>
        )}
        <div className="ml-4">{children}</div>
      </div>
    </MathJax>
  );
};

export default ParentQuestionCard;
