import { isEmpty, isEqual, isNumber } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import DialogTextEditor from "../../components/common/components/dialog/DialogTextEditor";
import { commonActions } from "../../components/common/redux/action";
import {
  ERROR_CODE_NOT_FOUND_EXAM,
  TYPE_GENERATE_EXAM,
  convertAnswerKey,
  quizAnswerType,
} from "../../components/common/utils/constant";
import { genSignature, getNonce, removeNullAttributeFromObject } from "../../components/common/utils/helper";
import { documentActions } from "../../components/manage_document/redux/action";
import AnswerBoardGroupExam from "../../components/manage_exam/components/AnswerBoardGroupExam";
import CreateQuizRightSide from "../../components/manage_exam/components/CreateQuizRightSide";
import CreationInfoExamViewOnly from "../../components/manage_exam/components/CreationInfoExamViewOnly ";
import CreationInfoExamWareHouse from "../../components/manage_exam/components/CreationInfoExamWareHouse";
import DialogEditExam from "../../components/manage_exam/components/DialogEditExam";
import QuestionsGroupExam from "../../components/manage_exam/components/QuestionsGroupExam";
import SelectTopicGroupExamPage from "../../components/manage_exam/components/SelectTopicGroupExamPage";
import {
  ANSWER_TYPE_OPTIONS,
  DIALOG_TYPE,
  MAX_LENGTH_CORRECT_OPTION,
  MODE,
} from "../../components/manage_exam/utils/constant";
import { eventTracking } from "../../firebase/firebaseConfig";
import {
  getAnswerTypeOfQuestion,
  getBaseOption,
  getCorrectOrdinalOfListQuestion,
  getFirstOrdinalQuestionWithoutParent,
  getSortedListQuestion,
} from "../../components/manage_exam/utils/helpers";

type TCreateGroupExamProps = {
  isEdited?: boolean;
};

export default function CreateGroupExam({ isEdited = false }: TCreateGroupExamProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mainRef: any = useRef(null);

  const { previewExam } = useSelector((state: { document }) => state.document);
  const { creationInfoExam, examDetail, createExamFromFolderId } = useSelector((state: { common }) => state.common);
  const { topics_lv1 } = creationInfoExam;

  const [creationInfo, setCreationInfo] = useState<any>({
    thematics: [],
    title: "",
  });
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
  }>({
    title: "",
  });

  const [formValues, setFormValues] = useState<
    {
      id: number;
      title: string;
      gradeId: number;
      subjectTypeId: number;
      learningProgramId: number;
      semesterId: number;
      questionsInfo: { difficultDegree: number; count: number; maxCount: number }[];
      topicLv2: {
        id: number;
        title: string;
        questionsInfo: { difficultDegree: number; count: number; maxCount: number }[];
      }[];
    }[]
  >([]);

  const [ordinalQuestion, setOrdinalQuestion] = useState<number>(1);
  const [openDialogAddOrEditExam, setOpenDialogAddOrEditExam] = useState<{ type: number | null; question?: any }>({
    type: null,
  });
  const [openEditor, setOpenEditor] = useState<boolean>(false);

  const [isLoadingGenerateExam, setIsLoadingGenerateExam] = useState<boolean>(false);

  const listQuestionWithoutParent = useMemo(() => {
    return (
      creationInfo?.questions
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE) || []
    );
  }, [creationInfo?.questions]);

  const totalQuestionSelected = useMemo<number>(() => {
    return (
      creationInfo.questions
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
        ?.filter((question) => !question.deleted)?.length || 0
    );
  }, [formValues, creationInfo.questions]);

  const { checkIsEditQuestion, checkIsEditSolution } = useMemo<{
    checkIsEditQuestion: boolean;
    checkIsEditSolution: boolean;
  }>(() => {
    if (!examDetail) return { checkIsEditQuestion: false, checkIsEditSolution: false };

    const mapQuestion = (question) => {
      // format self-input question from server to client
      return {
        ...question,
        contents: question.questions,
        options: question?.options?.map((option) => ({
          ...option,
          contents: [...option.items],
          answer_key: convertAnswerKey(option.ordinal),
        })),
        solutions:
          examDetail.solutions?.find((solution) => solution.quiz_question_id === question.id)?.items?.detail || [],
        isSelfInput: true,
      };
    };

    const originExamQuestionData = examDetail?.questions
      ?.filter((question) => !question.original_id)
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
      ?.map(mapQuestion);

    const currentQuestionData = creationInfo.questions
      ?.filter((question) => !question.original_id)
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE);

    const originParentQuestion = examDetail?.questions
      ?.filter((question) => !question.original_id)
      ?.filter((question) => question.answer_type === quizAnswerType.NONE)
      ?.map(mapQuestion);

    const currentParentQuestion = creationInfo.questions
      ?.filter((question) => !question.original_id)
      ?.filter((question) => question.answer_type === quizAnswerType.NONE);

    const checkIsEditQuestion =
      !isEqual(originParentQuestion, currentParentQuestion) ||
      !isEqual(originExamQuestionData?.length, currentQuestionData?.filter((e) => !e?.deleted)?.length) ||
      !isEqual(
        originExamQuestionData?.map((e) => e?.options?.map((op) => [op?.correct, op?.items?.[0]?.content])).flat(),
        currentQuestionData?.map((e) => e?.options?.map((op) => [op?.correct, op?.contents?.[0]?.content])).flat()
      ) ||
      !isEqual(
        originExamQuestionData?.map((e) => e?.questions?.map((c) => c?.content)).flat(),
        currentQuestionData?.map((e) => e?.contents?.map((c) => c?.content)).flat()
      ) ||
      !isEqual(
        originExamQuestionData?.map((e) => ({
          description_answer_before: e?.description_answer_before,
          description_answer_after: e?.description_answer_after,
        })),
        currentQuestionData?.map((e) => ({
          description_answer_before: e?.description_answer_before,
          description_answer_after: e?.description_answer_after,
        }))
      );

    const checkIsEditSolution = !isEqual(
      originExamQuestionData
        ?.filter((question) => question.isSelfInput)
        ?.map((e) => e.solutions?.map((e) => e?.content))
        .flat(),
      currentQuestionData
        ?.filter((question) => question.isSelfInput)
        ?.filter((e) => !isEmpty(e?.solutions))
        ?.map((e) => e?.solutions?.map((e) => e?.content))
        ?.flat()
    );

    return { checkIsEditQuestion, checkIsEditSolution };
  }, [examDetail, creationInfo.questions]);

  const isDisableButtonSaveExam = useMemo(() => {
    const listParentQuestion = creationInfo?.questions
      ?.filter((question) => question.isSelfInput)
      ?.filter((question) => !question.deleted)
      ?.filter((question) => question.answer_type === quizAnswerType.NONE);

    const isAllParentHasChild = listParentQuestion?.every((parentQuestionItem) => {
      return listQuestionWithoutParent?.some((question) => question.parent === parentQuestionItem.id);
    });

    if (
      !creationInfo?.title ||
      !creationInfo?.grade?.id ||
      !creationInfo?.subject_types?.id ||
      !creationInfo?.learningProgram?.id ||
      !creationInfo?.semesters?.id ||
      !(creationInfo.questions?.filter((question) => question.answer_type !== quizAnswerType.NONE)?.length > 0) ||
      !creationInfo.questions
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.isSelfInput)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
        ?.every(
          (e: any) =>
            !!e?.options?.some(
              (op) =>
                op.correct === 1 &&
                op.contents?.[0]?.content?.trim() !== "" &&
                (e.answer_type === quizAnswerType.CONSTRUCTED_RESPONSE
                  ? op.contents?.[0]?.content.length <= MAX_LENGTH_CORRECT_OPTION
                  : true)
            )
        ) ||
      !isAllParentHasChild
    ) {
      return true;
    }
    // don't change data
    if (
      creationInfo?.title?.trim() === examDetail?.title &&
      creationInfo?.grade?.id === examDetail?.grade?.id &&
      creationInfo?.subject_types?.id === examDetail?.subject_type?.id &&
      creationInfo?.learningProgram?.id === examDetail?.learning_program?.id &&
      creationInfo?.semesters?.id === examDetail?.semester?.id &&
      creationInfo?.time === examDetail?.time &&
      !checkIsEditQuestion &&
      !checkIsEditSolution
    ) {
      return true;
    }

    // invalid data
    if (errorMessage.title) {
      return true;
    }

    return false;
  }, [creationInfo]);

  const isDisableButtonCreateExam = useMemo(() => {
    const listParentQuestion = creationInfo?.questions
      ?.filter((question) => question.isSelfInput)
      ?.filter((question) => !question.deleted)
      ?.filter((question) => question.answer_type === quizAnswerType.NONE);

    const isAllParentHasChild = listParentQuestion?.every((parentQuestionItem) => {
      return listQuestionWithoutParent?.some((question) => question.parent === parentQuestionItem.id);
    });

    return (
      !creationInfo.title ||
      !!errorMessage.title ||
      !creationInfo?.grade?.id ||
      !creationInfo?.subject_types?.id ||
      !creationInfo?.learningProgram?.id ||
      !creationInfo?.semesters?.id ||
      !creationInfo?.time ||
      !(creationInfo.questions?.filter((question) => question.answer_type !== quizAnswerType.NONE)?.length > 0) ||
      !creationInfo.questions
        ?.filter((question) => question.isSelfInput)
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
        ?.every(
          (e: any) =>
            !!e?.options?.some(
              (op) =>
                op.correct === 1 &&
                op.contents?.[0]?.content?.trim() !== "" &&
                (e.answer_type === quizAnswerType.CONSTRUCTED_RESPONSE
                  ? op.contents?.[0]?.content.length <= MAX_LENGTH_CORRECT_OPTION
                  : true)
            )
        ) ||
      !isAllParentHasChild
    );
  }, [creationInfo]);

  const currentQuestion = useMemo(() => {
    const cq = creationInfo.questions
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
      ?.find((e: any) => e.ordinal === ordinalQuestion);
    if (cq) {
      return cq;
    }

    // nếu câu hỏi có ordinalQuestion đã bị xoá thì setOrdinalQuestion là câu trước nó, nếu không có câu trước đó thì tìm câu đầu tiên.
    setOrdinalQuestion(
      ordinalQuestion - 1 >= 1 ? ordinalQuestion - 1 : getFirstOrdinalQuestionWithoutParent(creationInfo.questions)
    );
  }, [creationInfo.questions, ordinalQuestion]);

  const listTopicLv1 = useMemo(() => {
    if (!creationInfo.grade?.id || !creationInfo.subject_types?.id || !creationInfo.learningProgram?.id) return [];

    let topicRender = [...(topics_lv1 || [])];

    if (creationInfo.grade?.id) {
      topicRender = topicRender.filter((topic) => topic.grade_id === creationInfo.grade.id);
    }

    if (creationInfo.subject_types?.id) {
      topicRender = topicRender.filter((topic) => topic.subject_type_id === creationInfo.subject_types.id);
    }

    if (creationInfo.learningProgram?.id) {
      topicRender = topicRender.filter((topic) => topic.learning_program_id === creationInfo.learningProgram.id);
    }

    if (creationInfo.semesters?.id) {
      topicRender = topicRender.filter((topic) => topic.semester_id === creationInfo.semesters.id);
    }

    if (creationInfo.thematics?.id) {
      topicRender = topicRender.filter((topic) => topic.thematic_id === creationInfo.thematics.id);
    }

    return topicRender;
  }, [creationInfo, topics_lv1]);

  const isShowRightSide = useMemo(
    () =>
      creationInfo.questions
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
        ?.filter((question) => question.isSelfInput).length > 0,
    [creationInfo.questions]
  );

  const handleClickTopicLv1 = (topicLv1Id: number) => {
    const foundTopicLv1Index = formValues.findIndex((topicLv1) => topicLv1.id === topicLv1Id);
    if (foundTopicLv1Index < 0) {
      const topicLv1Data = topics_lv1.find((topic) => topic.id === topicLv1Id);
      const questionInfoTopicLv1 = topicLv1Data.topics_lv2
        .reduce(
          (total, current) => {
            return total.map((item) => {
              const currentQuestionsInfoByLv = current.questions_info.find(
                (e) => e.difficult_degree === item.difficultDegree
              );
              if (!currentQuestionsInfoByLv) return item;
              return {
                ...item,
                count: item.count + currentQuestionsInfoByLv.count,
                maxCount: item.count + currentQuestionsInfoByLv.count,
              };
            });
          },
          [
            { difficultDegree: 1, count: 0, maxCount: 0 },
            { difficultDegree: 2, count: 0, maxCount: 0 },
            { difficultDegree: 3, count: 0, maxCount: 0 },
            { difficultDegree: 4, count: 0, maxCount: 0 },
          ]
        )
        .filter((element) => element.count);

      setFormValues((prev) => [
        ...prev,
        {
          id: topicLv1Id,
          title: topicLv1Data.title,
          gradeId: topicLv1Data.grade_id,
          subjectTypeId: topicLv1Data.subject_type_id,
          learningProgramId: topicLv1Data.learning_program_id,
          semesterId: topicLv1Data.semester_id,
          questionsInfo: questionInfoTopicLv1,
          topicLv2: [],
        },
      ]);
    }
  };

  const handleClickTopicLv2 = (topicLv1Id: number, topicLv2Id: number) => {
    const foundTopicLv1Index = formValues.findIndex((topicLv1) => topicLv1.id === topicLv1Id);

    const topicLv1Data = topics_lv1.find((topicLv1) => topicLv1.id === topicLv1Id);
    const topicLv2Data = topicLv1Data.topics_lv2.find((topicLv2) => topicLv2.id === topicLv2Id);

    // if(no topic lv 1) add topic lv 1 & topic lv 2
    if (foundTopicLv1Index < 0) {
      setFormValues((prev) => [
        ...prev,
        {
          id: topicLv1Id,
          title: topicLv1Data.title,
          gradeId: topicLv1Data.grade_id,
          subjectTypeId: topicLv1Data.subject_type_id,
          learningProgramId: topicLv1Data.learning_program_id,
          semesterId: topicLv1Data.semester_id,
          questionsInfo: [],
          topicLv2: [
            {
              id: topicLv2Id,
              title: topicLv2Data.title,
              questionsInfo: topicLv2Data.questions_info.map((qi) => ({
                ...qi,
                difficultDegree: qi.difficult_degree,
                maxCount: qi.count,
              })),
            },
          ],
        },
      ]);

      return;
    }

    const foundTopicLv2Index = formValues[foundTopicLv1Index]?.topicLv2.findIndex((topic) => topic.id === topicLv2Id);
    // if(have topic lv1 and no topic lv2) add topic lv2
    if (foundTopicLv2Index < 0) {
      setFormValues((prev) => {
        const prevClone = cloneDeep(prev);
        prevClone[foundTopicLv1Index]?.topicLv2?.push({
          id: topicLv2Id,
          title: topicLv2Data.title,
          questionsInfo: topicLv2Data.questions_info.map((qi) => ({
            ...qi,
            difficultDegree: qi.difficult_degree,
            maxCount: qi.count,
          })),
        });
        return prevClone;
      });
      return;
    }
  };

  const handleUpdateNumsOfQuestion = (
    topicLv1Id: number,
    topicLv2Id: number,
    difficultDegreeField: number,
    value: number
  ) => {
    const foundTopicLv1Index = formValues.findIndex((topicLv1) => topicLv1.id === topicLv1Id);
    // Update at topicLv1
    if (!topicLv2Id) {
      setFormValues((prev) => {
        const prevClone = cloneDeep(prev);

        prevClone[foundTopicLv1Index].questionsInfo = prevClone[foundTopicLv1Index].questionsInfo.map((qi) => {
          if (qi.difficultDegree !== difficultDegreeField) return qi;
          return {
            ...qi,
            count: value,
          };
        });

        return prevClone;
      });
    }
    // Update at topicLv2
    else {
      setFormValues((prev) => {
        const prevClone = cloneDeep(prev);

        const foundTopicLv2Index = prevClone[foundTopicLv1Index]?.topicLv2?.findIndex(
          (topicLv2) => topicLv2.id === topicLv2Id
        );
        prevClone[foundTopicLv1Index].topicLv2[foundTopicLv2Index].questionsInfo = prevClone[
          foundTopicLv1Index
        ].topicLv2[foundTopicLv2Index].questionsInfo.map((qi) => {
          if (qi.difficultDegree !== difficultDegreeField) return qi;
          return {
            ...qi,
            count: value,
          };
        });

        return prevClone;
      });
    }
  };

  const handleClickDeleteTopic = (topicLv1Id: number, topicLv2Id: number) => {
    const foundTopicLv1Index = formValues.findIndex((topicLv1) => topicLv1.id === topicLv1Id);

    // Delete topic lv1
    if (!topicLv2Id) {
      // Delete topicLv1
      setFormValues((prev) => {
        return prev.filter((topicLv1) => topicLv1.id !== topicLv1Id);
      });
    }
    // Delete topic lv2
    else {
      // Delete topicLv1 if it only contains only 1 topicLv2
      if (formValues[foundTopicLv1Index].topicLv2.length === 1) {
        setFormValues((prev) => {
          return prev.filter((topicLv1) => topicLv1.id !== topicLv1Id);
        });
      }
      // Delete only topicLv2
      else {
        const foundTopicLv2Index = formValues[foundTopicLv1Index].topicLv2?.findIndex(
          (topicLv2) => topicLv2.id === topicLv2Id
        );
        setFormValues((prev) => {
          const prevClone = cloneDeep(prev);
          prevClone[foundTopicLv1Index]?.topicLv2?.splice(foundTopicLv2Index, 1);
          return prevClone;
        });
      }
    }
  };

  const handleClickBackButton = () => {
    navigate("/tao-de/ngan-hang-bo-de");
  };

  const handleCreateNewQuestion = (
    data: any,
    isParentQuestion: boolean,
    answerTypeChosen: keyof typeof ANSWER_TYPE_OPTIONS,
    parentQuestionId: number
  ) => {
    let qs: any;
    if (isParentQuestion) {
      qs = {
        id: -((creationInfo.questions?.length + 1) as number),
        ordinal: creationInfo.questions?.filter((question) => !question.deleted)?.length + 1,
        contents: [{ content: data?.find((e) => e?.ordinal === -1)?.content, content_type: "html", ordinal: 1 }],
        answer_type: quizAnswerType.NONE,
        isSelfInput: true,
      };
    } else {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;

      let options;
      if (answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER) {
        options = data
          ?.filter((e) => e?.ordinal >= 0)
          ?.map((op) => ({
            contents: [{ content: op?.content, content_type: "html", ordinal: 1 }],
            answer_key: op?.answer_key,
            correct: op?.correct || 0,
            ordinal: op?.ordinal,
          }));
      } else {
        const correctOption = data?.find((e) => e.ordinal === 1);
        options = [
          {
            contents: [{ content: correctOption.content, content_type: "html", ordinal: 1 }],
            correct: correctOption.correct,
            ordinal: correctOption.ordinal,
          },
        ];
      }

      let ordinal: number;
      if (!Boolean(parentQuestionId)) {
        ordinal = creationInfo.questions?.filter((question) => !question.deleted)?.length + 1;
      } else {
        const previousOrdinal = creationInfo.questions
          ?.filter((question) => !question.deleted)
          ?.reduce((accumulate, question) => {
            if (question.parent === parentQuestionId || question.id === parentQuestionId) {
              return question.ordinal;
            } else {
              return accumulate;
            }
          }, -1);
        ordinal = previousOrdinal + 1;
      }

      qs = {
        ordinal,
        contents: [{ content: data?.find((e) => e?.ordinal === -1)?.content, content_type: "html", ordinal: 1 }],
        ...(answerTypeChosen === ANSWER_TYPE_OPTIONS.ENTER_ANSWER && {
          description_answer_before: data?.find((e) => e?.ordinal === 0).content,
          description_answer_after: data?.find((e) => e?.ordinal === 2).content,
        }),
        options,
        solutions: [],
        answer_type: answerType,
        isSelfInput: true,
        parent: parentQuestionId,
      };
      setOrdinalQuestion(ordinal);
    }

    if (parentQuestionId) {
      const previousQuestionIndex = creationInfo.questions.findIndex((question) => question.ordinal === qs.ordinal - 1);
      const clonedListQuestion = [...creationInfo.questions];
      clonedListQuestion.splice(previousQuestionIndex + 1, 0, qs);
      setCreationInfo({ ...creationInfo, questions: getCorrectOrdinalOfListQuestion(clonedListQuestion) });
    } else {
      setCreationInfo({ ...creationInfo, questions: [...creationInfo.questions].concat([qs]) });
    }
  };

  const handleUpdateListQuestion = useCallback((questions) => {
    setCreationInfo((prev) => ({ ...prev, questions: [...questions] }));
    const newListQuestionLength = questions
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
      ?.filter((e) => !e?.deleted)?.length;
    setOrdinalQuestion((prev) => {
      if (prev > newListQuestionLength) {
        return newListQuestionLength;
      }
      return prev;
    });
  }, []);

  const handleGenerateGroupExam = () => {
    let dataPost: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
    };

    dataPost = {
      ...dataPost,
      signature: genSignature(dataPost),
      quiz_data: formValues
        .map((topicLv1) => {
          if (!topicLv1.topicLv2?.length) {
            return {
              topic_id: topicLv1.id,
              question_info: topicLv1.questionsInfo.map((qi) => ({
                difficult_degree: qi.difficultDegree,
                count: qi.count,
              })),
            };
          } else {
            return topicLv1.topicLv2.map((topicLv2) => ({
              topic_id: topicLv2.id,
              question_info: topicLv2.questionsInfo.map((qi) => ({
                difficult_degree: qi.difficultDegree,
                count: qi.count,
              })),
            }));
          }
        })
        .flat(),
    };

    setIsLoadingGenerateExam(true);
    setOrdinalQuestion(1);
    dispatch(
      documentActions.previewExam(dataPost, () => {
        setIsLoadingGenerateExam(false);
        // setSelfInputQuestions([]);
        if (searchParams.get("mode") === "preview") {
        } else {
          navigate("/tao-de/ngan-hang-bo-de?mode=preview");
          //set creationInfo

          // Update grade
          const gradeId = formValues.reduce((total, item) => {
            if (total > item.gradeId) return item.gradeId;
            return total;
          }, Number.MAX_VALUE);

          const topicsOfGradeId = formValues.filter((topic) => topic.gradeId === gradeId);
          // Update learningProgram
          const listTotalQuestionOfEachTopic = topicsOfGradeId.map((topic) => {
            const totalQuestion =
              topic.topicLv2.length === 0
                ? topic.questionsInfo.reduce((total, qi) => total + qi.count, 0)
                : topic.topicLv2.reduce((total, topicLv2) => {
                    return total + topicLv2.questionsInfo.reduce((total, qi) => total + qi.count, 0);
                  }, 0);
            return {
              learningProgramId: topic.learningProgramId,
              totalQuestion,
            };
          });

          let map = {};

          for (let i = 0; i < listTotalQuestionOfEachTopic.length; i++) {
            if (map[listTotalQuestionOfEachTopic[i].learningProgramId]) {
              map[listTotalQuestionOfEachTopic[i].learningProgramId] += listTotalQuestionOfEachTopic[i].totalQuestion;
            } else {
              map[listTotalQuestionOfEachTopic[i].learningProgramId] = listTotalQuestionOfEachTopic[i].totalQuestion;
            }
          }

          const learningProgramId = Object.keys(map).reduce((total, current) =>
            map[total] > map[current] ? total : current
          );

          // Update semester
          const semesterId = topicsOfGradeId.reduce((total, current) => {
            if (total > current.semesterId) return total;
            return current.semesterId;
          }, Number.MIN_VALUE);

          setCreationInfo((prev) => {
            const grade = creationInfoExam?.grades.find((el) => el.id === gradeId);
            return {
              ...prev,
              grade: grade,
              subject_types: grade.subject_types[0],
              learningProgram: grade.subject_types[0]?.learning_programs?.find(
                (el) => el.id === Number(learningProgramId)
              ),
              semesters: grade.subject_types[0]?.learning_programs
                ?.find((el) => el.id === Number(learningProgramId))
                ?.semesters?.find((el) => el.id === semesterId),
            };
          });
          //end set creationInfo
        }
      })
    );
  };

  const handleClickCreateGroupExam = () => {
    eventTracking("select_random_input_type_done");

    const folderId = createExamFromFolderId;

    const _mapQuestion = (qs) => {
      if (qs.solutions) return qs;
      const solutionObj = previewExam?.solutions?.find((e) => e?.quiz_question_id === qs?.id);
      let solution = solutionObj?.items?.detail || solutionObj?.items?.suggestion;

      return {
        ...qs,
        solutions: solution,
        contents: qs?.questions || qs?.contents,
        options: qs?.options?.map((e) => ({
          ...e,
          contents: [quizAnswerType?.ONE_ANSWER, quizAnswerType.SORT_POSITION].includes(qs?.answer_type)
            ? e?.items
            : [{ ...e?.items[0], content: solutionObj?.correct_options?.join(" ") }],
        })),
      };
    };
    let dataPost: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      title: creationInfo.title?.trim(),
      grade_id: creationInfo?.grade?.id,
      subject_type_id: creationInfo?.subject_types?.id,
      learning_program_id: creationInfo?.learningProgram?.id,
      folder_id: folderId,
      semester_ids: creationInfo?.semesters?.id ? [creationInfo?.semesters?.id] : undefined,
      thematic_ids: creationInfo?.thematics,
      type_generate: TYPE_GENERATE_EXAM.GROUP_EXAM,
      json_data: {
        time: creationInfo?.time,
        num_of_questions: creationInfo?.questions
          ?.filter((question) => question?.answer_type !== quizAnswerType.NONE)
          ?.filter((question) => !question.deleted)?.length,
        data: JSON.stringify(creationInfo?.questions?.filter((question) => !question.deleted)?.map(_mapQuestion)),
      },
    };

    dataPost = {
      ...removeNullAttributeFromObject(dataPost),
      signature: genSignature(removeNullAttributeFromObject(dataPost), undefined, undefined, [
        "semester_ids",
        "thematic_ids",
        "json_data",
      ]),
    };

    dispatch(
      commonActions.handleCreateExam(dataPost, (topicSlug: string) => {
        navigate(`/quan-ly-tai-lieu/${topicSlug}`);
      })
    );
  };

  const isViewOnly = isEdited && examDetail?.permission !== "mine";

  const handleClickSaveExam = () => {
    const removeQuestions =
      examDetail?.questions
        ?.filter((e) => creationInfo.questions?.findIndex((qs) => qs?.id === e?.id) < 0)
        ?.map((e) => ({ ...e, deleted: true })) || [];

    const listQuestionEdit = creationInfo.questions
      ?.map((question) => {
        const isExistingQuestion = examDetail?.questions?.findIndex((qs) => qs?.id === question?.id) >= 0;

        if (!isExistingQuestion) {
          return { ...question, added: true };
        } else {
          const getRemoveOptions = (qs) => {
            const oldQuestion = examDetail?.questions?.find((e) => e?.id === qs.id);
            if (!oldQuestion) return [];
            return (
              oldQuestion.options
                ?.filter((e) => {
                  return qs?.options?.findIndex((o) => o?.id === e?.id) < 0;
                })
                ?.map((e) => ({ ...e, deleted: true })) || []
            );
          };

          const oldAnswerType = examDetail?.questions?.find((questionItem) => questionItem.id === question.id)
            ?.answer_type;
          const isEditQuestion =
            question?.answer_type !== oldAnswerType ||
            !isEqual(
              [
                examDetail?.questions?.find((qs) => qs.id === question.id)?.description_answer_before,
                examDetail?.questions?.find((qs) => qs.id === question.id)?.description_answer_after,
              ],
              [question.description_answer_before, question.description_answer_after]
            ) ||
            question?.parent !== examDetail?.questions?.find((questionItem) => questionItem.id === question.id)?.parent;

          return {
            ...question,
            options: question.isSelfInput
              ? question.options
                  ?.map((op, i) =>
                    !op?.id
                      ? {
                          ...op,
                          added: true,
                          ordinal: i,
                        }
                      : { ...op, ordinal: i, edited: true }
                  )
                  ?.concat(getRemoveOptions(question))
              : question.options,
            edited: isEditQuestion,
          };
        }
      })
      ?.concat(removeQuestions);

    const dataEditPre: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade_id: creationInfo?.grade?.id,
      subject_type_id: creationInfo?.subject_types?.id,
      learning_program_id: creationInfo?.learningProgram?.id,
      semester_ids: creationInfo?.semesters?.id ? [creationInfo?.semesters?.id] : undefined,
      thematic_ids: creationInfo?.thematics,
      json_data: {
        time: creationInfo?.time,
        num_of_questions: creationInfo.questions
          ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
          ?.filter((question) => !question.deleted)?.length,
        data: JSON.stringify(listQuestionEdit),
      },
    };
    if (creationInfo.title?.trim() !== examDetail.title) {
      dataEditPre.title = creationInfo.title?.trim();
    }
    const dataEdit = {
      ...removeNullAttributeFromObject(dataEditPre),
      signature: genSignature(
        removeNullAttributeFromObject({ ...dataEditPre, topic_slug: examDetail?.slug }),
        undefined,
        undefined,
        ["semester_ids", "thematic_ids", "json_data"]
      ),
    };
    dispatch(
      commonActions.handleEditExam(
        { topicSlug: examDetail?.slug, data: dataEdit },
        (topicSlug: string) => {
          if (topicSlug === examDetail?.slug) {
            navigate(0);
          } else {
            navigate(`/quan-ly-tai-lieu/${topicSlug}?mode=edit`, { replace: true });
            window.location.reload();
          }
        },
        (error) => {
          switch (error.code) {
            case ERROR_CODE_NOT_FOUND_EXAM: {
              navigate(-1);
              break;
            }
            default:
              break;
          }
        }
      )
    );
  };

  const handleChooseAnswerKey = (key: any, isChecked: boolean) => {
    const parentQuestions = creationInfo.questions?.filter((question) => question.answer_type === quizAnswerType.NONE);
    const questionsWithoutParentQuestions = creationInfo.questions?.filter(
      (question) => question.answer_type !== quizAnswerType.NONE
    );

    const newListQuestionWithoutParentQuestions = questionsWithoutParentQuestions?.map((e: any) => {
      if (e?.ordinal === ordinalQuestion) {
        const newOptions = e?.options?.map((op) =>
          key === op?.answer_key
            ? !!op?.id
              ? { ...getBaseOption(op), correct: Number(isChecked), edited: true }
              : { ...getBaseOption(op), correct: Number(isChecked) }
            : getBaseOption(op)
        );
        const answerType = getAnswerTypeOfQuestion(newOptions);
        return {
          ...e,
          options: newOptions,
          answer_type: answerType,
        };
      } else {
        return e;
      }
    });
    setCreationInfo((prev) => ({ ...prev, questions: [...newListQuestionWithoutParentQuestions, ...parentQuestions] }));
  };

  const handleChangeCorrectAnswer = (content: string) => {
    const parentQuestions = creationInfo.questions?.filter((question) => question.answer_type === quizAnswerType.NONE);
    const questionsWithoutParentQuestions = creationInfo.questions?.filter(
      (question) => question.answer_type !== quizAnswerType.NONE
    );
    const newListQuestionWithoutParentQuestions = questionsWithoutParentQuestions.map((e: any) => {
      if (e?.ordinal === ordinalQuestion) {
        const newOptions = e?.options?.map((op) =>
          !!op?.id
            ? {
                ...getBaseOption(op),
                contents: [
                  {
                    id: op?.items ? op?.items[0]?.id : null,
                    content: content,
                    content_type: "html",
                    ordinal: 1,
                    edited: true,
                  },
                ],
                edited: true,
              }
            : {
                ...getBaseOption(op),
                contents: [
                  {
                    content: content,
                    content_type: "html",
                    ordinal: 1,
                  },
                ],
              }
        );
        return {
          ...e,
          options: newOptions,
          answer_type: quizAnswerType.CONSTRUCTED_RESPONSE,
        };
      } else {
        return e;
      }
    });
    setCreationInfo((prev) => ({ ...prev, questions: [...newListQuestionWithoutParentQuestions, ...parentQuestions] }));
  };

  const handleFillSolution = (solution: any) => {
    const oldContent = currentQuestion?.solutions.find((e: any) => e.content_type === "html")?.content;
    if ((!oldContent || oldContent?.length === 0) && solution?.length === 0) return;

    const parentQuestions = creationInfo.questions?.filter((question) => question.answer_type === quizAnswerType.NONE);
    const questionsWithoutParentQuestions = creationInfo.questions?.filter(
      (question) => question.answer_type !== quizAnswerType.NONE
    );

    const newListQuestionWithoutParentQuestions = questionsWithoutParentQuestions.map((e: any) => {
      const newSolution = {
        ordinal: 1,
        content: solution,
        content_type: "html",
      };
      return e?.ordinal === ordinalQuestion
        ? {
            ...e,
            solutions: [
              !!e?.id
                ? !solution || solution?.length === 0
                  ? { ...newSolution, id: e?.solutions[0]?.id, deleted: true }
                  : !oldContent || oldContent?.length === 0
                  ? {
                      ...newSolution,
                      id: e?.solutions[0]?.id,
                      added: true,
                    }
                  : {
                      ...newSolution,
                      id: e?.solutions[0]?.id,
                      edited: true,
                    }
                : newSolution,
            ],
          }
        : e;
    });
    setCreationInfo((prev) => ({ ...prev, questions: [...newListQuestionWithoutParentQuestions, ...parentQuestions] }));
  };

  const handleClickQuestionContent = useCallback((ordinal) => setOrdinalQuestion(ordinal), []);

  const handleClickSelfInputQuestion = useCallback(() => {
    setOpenDialogAddOrEditExam({ type: DIALOG_TYPE.CREATE });
  }, []);

  const handleClickOpenSolutionSelfInputQuestionDialog = useCallback((ordinal) => {
    setOpenEditor(true);
    setOrdinalQuestion(ordinal);
  }, []);

  useEffect(() => {
    if (isEdited && examDetail) {
      const newListQuestion = examDetail.questions.map((question) => {
        if (question.original_id) return question;
        // format self-input question from server to client
        const solution = examDetail.solutions?.find((solution) => solution.quiz_question_id === question.id);
        const solutionId = solution?.id;
        return {
          ...question,
          contents: question.questions,
          options: question.options.map((option) => ({
            ...option,
            contents: [...option.items],
            ...(question.answer_type !== quizAnswerType.CONSTRUCTED_RESPONSE && {
              answer_key: convertAnswerKey(option?.ordinal),
            }),
          })),
          solutions: solution?.items?.detail?.map((item) => ({ ...item, id: solutionId })) || [],
          isSelfInput: true,
        };
      });
      setCreationInfo((prev) => ({
        ...prev,
        title: examDetail.title,
        grade: examDetail.grade,
        subject_types: examDetail.subject_type,
        learningProgram: examDetail.learning_program,
        semesters: examDetail.semester,
        thematics: examDetail.thematics,
        time: examDetail.time,
        questions: newListQuestion,
        solutions: examDetail.solutions,
      }));

      setCreationInfo((prev) => ({ ...prev, questions: getSortedListQuestion(prev.questions) }));

      const firstOrdinalQuestionWithoutParent = getSortedListQuestion(newListQuestion)
        ?.filter((question) => !question.deleted)
        ?.find((question) => question.answer_type !== quizAnswerType.NONE).ordinal;
      setOrdinalQuestion(firstOrdinalQuestionWithoutParent);
    } else if (previewExam.questions) {
      setCreationInfo((prev) => ({
        ...prev,
        questions: previewExam.questions,
        solutions: previewExam.solutions,
      }));
      setCreationInfo((prev) => ({ ...prev, questions: getSortedListQuestion(prev.questions) }));
    }
  }, [isEdited, examDetail, previewExam]);

  useEffect(() => {
    if (searchParams.get("mode") === "preview") {
      eventTracking("screen_random_preview_view");
    }
  }, [searchParams.get("mode")]);

  useEffect(() => {
    return () => {
      // clean quiz khi ra khoi page
      dispatch(documentActions.handleCleanPreviewExam());
    };
  }, []);

  useEffect(() => {
    document.getElementById(`question_${ordinalQuestion}`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [ordinalQuestion]);

  useEffect(() => {
    if (!isEdited) {
      dispatch(
        commonActions.handleGetTeacherInfo((teacherInfoResponse) => {
          const formattedTime = moment().format("DDMMyyyy hhmm");
          const subjectTitle = teacherInfoResponse?.subject_register?.title;
          const initialTitle = `Đề ${subjectTitle} ${formattedTime}`;
          setCreationInfo((prev) => ({ ...prev, title: initialTitle }));
        })
      );
    }
  }, [isEdited]);

  return (
    <div className="main-content overflow-auto" ref={mainRef}>
      <div>
        {isViewOnly ? (
          <CreationInfoExamViewOnly examInfo={creationInfo} numOfQuestions={totalQuestionSelected} />
        ) : (
          <CreationInfoExamWareHouse
            examInfo={creationInfo}
            setExamInfo={setCreationInfo}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            isLoadingPreviewButton={isLoadingGenerateExam}
            disablePreviewButton={isLoadingGenerateExam || formValues.length === 0}
            disableCreateExamButton={isDisableButtonCreateExam}
            disableSaveExamButton={isDisableButtonSaveExam}
            totalQuestions={totalQuestionSelected}
            mode={searchParams.get("mode") === "preview" ? "preview" : isEdited ? "edit" : "select"}
            onClickPreviewButton={handleGenerateGroupExam}
            onClickBackButton={handleClickBackButton}
            onClickCreateExam={handleClickCreateGroupExam}
            onClickSaveExam={handleClickSaveExam}
          />
        )}
      </div>
      {searchParams.get("mode") === "preview" || isEdited ? (
        <div className="flex flex-row w-full">
          <div className="w-[312px] h-[calc(100vh-232px)] overflow-x-hidden overflow-y-auto scrollbar-thin">
            <AnswerBoardGroupExam
              questions={creationInfo.questions}
              solutions={creationInfo.solutions}
              setOrdinalQuestion={setOrdinalQuestion}
            />
          </div>
          <div
            className={`${
              isShowRightSide ? "w-[calc(100%-624px)]" : "flex-1"
            } border-l border-r border-gray-200 h-[calc(100vh-232px)] overflow-auto scrollbar-thin`}
          >
            <QuestionsGroupExam
              questions={creationInfo.questions}
              solutions={creationInfo.solutions}
              isPreview={searchParams.get("mode") === MODE.PREVIEW}
              isEdited={isEdited}
              onClickQuestionContent={handleClickQuestionContent}
              onClickCreateSelfInputQuestion={handleClickSelfInputQuestion}
              onClickOpenSolutionSelfInputQuestionDialog={handleClickOpenSolutionSelfInputQuestionDialog}
              updateListQuestion={handleUpdateListQuestion}
              isShowCreateQuestionButton
              isShowRightSide={isShowRightSide}
              isShowBorder={false}
            />
          </div>
          {isShowRightSide && (
            <CreateQuizRightSide
              disabled={false}
              isViewOnly={isViewOnly || !currentQuestion?.isSelfInput}
              currentQuestion={currentQuestion}
              solution={creationInfo.solutions?.find((solution) => solution.quiz_question_id === currentQuestion?.id)}
              ordinalQuestionWithoutParent={
                listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) + 1
              }
              handleChooseAnswerKey={handleChooseAnswerKey}
              onChangeCorrectAnswer={handleChangeCorrectAnswer}
              setOpenEditor={setOpenEditor}
              mainRef={mainRef}
              disablePreviousQuestionButton={
                listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) <= 0
              }
              onClickPreviousQuestionButton={() => {
                const currentChildQuestionIndex = listQuestionWithoutParent.findIndex(
                  (question) => question.ordinal === ordinalQuestion
                );
                const previousChildQuestion = listQuestionWithoutParent[currentChildQuestionIndex - 1];
                setOrdinalQuestion(previousChildQuestion.ordinal);
              }}
              disableNextQuestionButton={
                listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) >=
                listQuestionWithoutParent.length - 1
              }
              onClickNextQuestionButton={() => {
                const currentChildQuestionIndex = listQuestionWithoutParent.findIndex(
                  (question) => question.ordinal === ordinalQuestion
                );
                const nextChildQuestion = listQuestionWithoutParent[currentChildQuestionIndex + 1];
                setOrdinalQuestion(nextChildQuestion.ordinal);
              }}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-row min-h-[calc(100%-106px)] overflow-auto w-full quiz-scrollbar">
          <SelectTopicGroupExamPage
            listTopicLv1={listTopicLv1}
            chosenTopics={formValues}
            onClickDeleteTopic={handleClickDeleteTopic}
            onClickTopicLv1={handleClickTopicLv1}
            onClickTopicLv2={handleClickTopicLv2}
            onUpdateNumsOfQuestion={handleUpdateNumsOfQuestion}
          />
        </div>
      )}

      {isNumber(openDialogAddOrEditExam.type) && (
        <DialogEditExam
          question={openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE ? undefined : currentQuestion}
          listParentQuestion={creationInfo.questions
            ?.filter((e) => !e?.deleted)
            ?.filter((question) => question.answer_type === quizAnswerType.NONE)
            ?.filter((question) => question.id !== openDialogAddOrEditExam.question?.id)
            ?.filter((question) => (isEdited ? !question.original_id || question.id < 0 : question.id < 0))}
          open={isNumber(openDialogAddOrEditExam.type)}
          onClose={() => {
            setOpenDialogAddOrEditExam({ type: null });
          }}
          onSubmit={(val, isParentQuestion, answerTypeChosen, parentQuestionId) => {
            if (openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE) {
              handleCreateNewQuestion(val, isParentQuestion, answerTypeChosen, parentQuestionId);
            }
          }}
          title={
            openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE
              ? intl.formatMessage({ id: "selfInput.addQuestion.title" })
              : intl.formatMessage({ id: "selfInput.editQuestion.title" })
          }
          isAdded={openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE}
          isSelfInput
          ordinalQuestion={
            ((creationInfo?.questions
              ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
              ?.filter((question) => !question?.deleted)?.length as number) + 1) as any
          }
        />
      )}

      {openEditor && (
        <DialogTextEditor
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          onSubmit={(val: any) => {
            handleFillSolution(val);
          }}
          content={currentQuestion?.solutions.find((e: any) => e.content_type === "html")?.content}
          title="Nhập hướng dẫn giải"
        />
      )}
    </div>
  );
}
