import uniqBy from "lodash/uniqBy";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { classNames } from "primereact/utils";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ArrowLeftIcon, NoDataIcon, SmallFolderIcon } from "../../../../assets";
import { stringToSlug } from "../../utils/helper";
import ButtonGhost from "../button/ButtonGhost";
import ButtonPrimary from "../button/ButtonPrimary";
import Dialog from "./Dialog";

type TChooseExamDialogProps = {
  listFolderAndFile: any;
  onClose: () => void;
  onSubmit: (exam) => void;
};

export default function ChooseExamDialog({ listFolderAndFile, onClose, onSubmit }: TChooseExamDialogProps) {
  const intl = useIntl();

  const [searchText, setSearchText] = useState("");

  const [chosenFolder, setChosenFolder] = useState<{ id: number; title: string } | undefined>(undefined);

  const [tab, setTab] = useState<number>(0);

  const [activeItem, setActiveItem] = useState<
    | {
        type: "exam" | "folder";
        id: number;
        title: string;
        isTypePdf?: boolean;
      }
    | undefined
  >(undefined);

  const listFile = useMemo(() => {
    return (
      uniqBy(
        listFolderAndFile?.files
          ?.filter((file) => file.slug.includes(stringToSlug(searchText)))
          ?.filter((file) => {
            if (!file.folder_id) return true;
            const isSharedFileWithoutFolder = !listFolderAndFile?.folders?.find(
              (folder) => folder.id === file.folder_id
            );
            return isSharedFileWithoutFolder;
          }),
        "id"
      ) || []
    );
  }, [listFolderAndFile, searchText]);

  const listFolder = useMemo(() => {
    return (
      uniqBy(listFolderAndFile?.folders?.filter((folder) => folder.slug.includes(stringToSlug(searchText))), "id") || []
    );
  }, [listFolderAndFile, searchText]);

  const title = chosenFolder ? (
    <div className="flex items-center justify-start gap-2">
      <span onClick={() => setChosenFolder(undefined)} className="hover:cursor-pointer">
        <ArrowLeftIcon />
      </span>
      <span className="font-semibold line-clamp-1">{chosenFolder.title}</span>
    </div>
  ) : (
    <div className="flex justify-between items-center">
      <span className="font-semibold">
        <FormattedMessage id="chooseExamDialog.title" />
      </span>
      <span className="p-input-icon-left">
        <i className="pi pi-search hover:cursor-pointer" />
        <InputText
          placeholder={intl.formatMessage({
            id: "chooseExamDialog.inputField.placeholder",
          })}
          className="border border-gray-200 w-[270px]"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </span>
    </div>
  );

  const handleClickAccept = () => {
    if (activeItem?.type === "exam") {
      onSubmit(activeItem);
    } else {
      setActiveItem(undefined);
      setChosenFolder(activeItem);
    }
  };

  const actions = (
    <div className="flex justify-end gap-4">
      <ButtonGhost label={intl.formatMessage({ id: "common.cancel" })} onClick={onClose} />
      <ButtonPrimary
        label={
          tab === 1 && !chosenFolder
            ? intl.formatMessage({ id: "common.open" })
            : intl.formatMessage({ id: "common.choose" })
        }
        disabled={!activeItem}
        onClick={handleClickAccept}
      />
    </div>
  );

  return (
    <Dialog
      title={title}
      isOpen={true}
      onClose={() => {}}
      actions={actions}
      className="h-[548px]"
      pt={{ content: { className: "p-0" } }}
    >
      {chosenFolder ? (
        <div className="flex flex-col h-full">
          {listFolderAndFile?.files?.filter((file) => file.folder_id === chosenFolder.id).length === 0 ? (
            <div className="flex justify-center items-center h-full">
              <NoDataIcon />
            </div>
          ) : (
            uniqBy(listFolderAndFile?.files?.filter((file) => file.folder_id === chosenFolder.id), "id")?.map(
              (exam) => (
                <span
                  key={exam.id}
                  className={classNames("py-4 px-6 border-b border-gray hover:cursor-pointer line-clamp-2", {
                    "bg-primary-50": activeItem?.type === "exam" && activeItem.id === exam.id,
                  })}
                  onClick={() =>
                    setActiveItem({
                      type: "exam",
                      id: exam.id,
                      title: exam.title,
                      isTypePdf: exam.is_type_pdf,
                    })
                  }
                >
                  {exam.title}
                </span>
              )
            )
          )}
        </div>
      ) : (
        <TabView
          activeIndex={tab}
          onTabChange={(e) => {
            setTab(e.index);
            setActiveItem(undefined);
          }}
          pt={{
            root: {
              className: `
              [&>.p-tabview-panels]:px-0
              [&>.p-tabview-nav-container]:px-6
              [&_.p-tabview-title]:text-gray-500
              [&_.p-tabview-title]:font-semibold
              [&_.p-highlight_.p-tabview-title]:text-primary
              [&_.p-highlight>.p-tabview-nav-link]:border-primary
              `,
            },
            inkbar: {
              className: "bg-primary",
            },
          }}
        >
          <TabPanel
            header={intl.formatMessage({ id: "chooseExamDialog.tab.exam" })}
            pt={{
              header: {
                className: "w-1/2 focus:shadow-none [&>*]:flex [&>*]:justify-center",
              },
            }}
          >
            <div className="flex flex-col h-full">
              {listFile?.length === 0 ? (
                <div className="flex justify-center items-center h-full">
                  <NoDataIcon />
                </div>
              ) : (
                listFile?.map((exam) => (
                  <span
                    key={exam.id}
                    className={classNames("py-4 px-6 border-b border-gray hover:cursor-pointer line-clamp-2", {
                      "bg-primary-50": activeItem?.type === "exam" && activeItem.id === exam.id,
                    })}
                    onClick={() =>
                      setActiveItem({
                        type: "exam",
                        id: exam.id,
                        title: exam.title,
                        isTypePdf: exam.is_type_pdf,
                      })
                    }
                  >
                    {exam.title}
                  </span>
                ))
              )}
            </div>
          </TabPanel>
          <TabPanel
            header={intl.formatMessage({ id: "chooseExamDialog.tab.folder" })}
            pt={{
              header: {
                className: "w-1/2 focus:shadow-none [&>*]:flex [&>*]:justify-center",
              },
            }}
          >
            <div className="flex flex-col h-full">
              {listFolder?.length === 0 ? (
                <div className="flex justify-center items-center h-full">
                  <NoDataIcon />
                </div>
              ) : (
                listFolder?.map((folder) => (
                  <span
                    key={folder.id}
                    className={classNames("flex gap-3 py-4 px-6 hover:cursor-pointer", {
                      "bg-primary-50": activeItem?.type === "folder" && activeItem.id === folder.id,
                    })}
                    onClick={() =>
                      setActiveItem({
                        type: "folder",
                        id: folder.id,
                        title: folder.title,
                      })
                    }
                  >
                    <SmallFolderIcon className="mt-[2px]" />
                    <span className="flex-1 line-clamp-2">{folder.title}</span>
                  </span>
                ))
              )}
            </div>
          </TabPanel>
        </TabView>
      )}
    </Dialog>
  );
}
