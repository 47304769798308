import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import Dialog from "../../common/components/dialog/Dialog";
import { CopyIcon } from "../../../assets";

type TInviteMemberDialogProps = {
  isOpen: boolean;
  inviteLink: string;
  onClose: () => void;
};

export default function InviteMemberDialog({ isOpen, inviteLink, onClose }: TInviteMemberDialogProps) {
  const intl = useIntl();

  return (
    <Dialog
      title={intl.formatMessage({ id: "inviteMemberDialog.title" })}
      isOpen={isOpen}
      closable
      actions={undefined}
      onClose={onClose}
    >
      <div className="flex justify-center mb-6 border border-gray-200 rounded-[12px] p-4 gap-3">
        <span className="flex-1 line-clamp-1 text-gray-700">{inviteLink}</span>
        <CopyToClipboard
          text={inviteLink}
          onCopy={() => toast.success(intl.formatMessage({ id: "common.copiedSuccessfully" }))}
        >
          <CopyIcon className="hover:cursor-pointer" />
        </CopyToClipboard>
      </div>
    </Dialog>
  );
}
