/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "primereact/button";
// import { MathJax } from "better-react-mathjax";
import { FormattedMessage } from "react-intl";
// import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { ProgressSpinner } from "primereact/progressspinner";

function DisplayQuizPDF({ file }: { file: File }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

  const parentRef: any = useRef();
  const [numOfPages, setNumOfPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [widthPdf, setWidthPdf] = useState<number>(0);

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    setPageNumber(pageNumber - 1 > 0 ? pageNumber - 1 : 1);
  };
  useEffect(() => {
    setWidthPdf(parentRef?.current?.clientWidth);
  }, [parentRef?.current]);

  return (
    <div ref={parentRef} className="flex flex-col w-full min-h-[600px] justify-center overflow-x-hidden">
      <Document
        file={file}
        onLoadSuccess={(pdf: any) => {
          const { numPages } = pdf;
          setNumOfPages(numPages);
        }}
        onLoadError={(e) => console.log(e)}
        loading={
          <div className="flex justify-center h-full">
            <ProgressSpinner
              style={{ width: "60px", height: "60px" }}
              pt={{
                circle: {
                  className: "stroke-primary",
                },
              }}
            />
          </div>
        }
        className="w-full flex-1 flex items-center justify-center mt-[-12px]"
      >
        <Page pageNumber={pageNumber} width={widthPdf} />
      </Document>
      <div className="flex items-center py-6 justify-center gap-10">
        <Button
          severity="success"
          onClick={prevPage}
          outlined
          icon="pi pi-arrow-left"
          className={`${
            pageNumber <= 1 ? "text-gray-400" : "text-primary"
          } normal-case sm:text-[12px] lg:text-[14px] h-[40px]`}
          disabled={pageNumber <= 1}
        >
          <span className="ml-2">
            <FormattedMessage id="IDS_Previous_Btn" />
          </span>
        </Button>
        <Button
          severity="success"
          onClick={nextPage}
          outlined
          icon="pi pi-arrow-right"
          className={`normal-case sm:text-[12px] lg:text-[14px] h-[40px] ${
            pageNumber >= numOfPages ? "text-gray-400" : "text-primary"
          }`}
          disabled={pageNumber >= numOfPages}
        >
          <span className="ml-2">
            <FormattedMessage id="IDS_Next_Btn" />
          </span>
        </Button>
      </div>
    </div>
  );
}

export default React.memo(DisplayQuizPDF);
