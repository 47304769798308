import { isEmpty } from "lodash";
import { ConfirmDialog } from "primereact/confirmdialog";
import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Breadcrumb from "../components/common/components/Breadcrumb";
import AlertDialog from "../components/common/components/dialog/AlertDialog";
import DialogUpdateProfile from "../components/common/components/dialog/DialogUpdateProfile";
import { commonActions } from "../components/common/redux/action";
import { ACCOUNT_TEACHER_STATUS } from "../components/common/utils/constant";
import { TCommonState } from "../components/common/utils/type";
import DefaultLeftSide from "./DefaultLeftSide";
import ResponsiveHeader from "./ResponsiveHeader";

export default function DefaultLayout() {
  const { hideLeftSide } = useSelector((state: { common }) => state?.common);
  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [openDialogUseDesktop, setOpenDialogUseDesktop] = useState(false);
  // const navigate = useNavigate();
  const { userInfos, openDialogDeveloping, openDialogUpdateUsername, openDialogRegisterTeacher } = useSelector(
    (state: { common: TCommonState }) => state.common
  );
  const isLogin = !!userInfos?.member;
  const isDashboard = location.pathname === "/";

  const setHideLeftSide = (params) => {
    dispatch(commonActions.handleSetHideLeftSide(params));
  };
  const handleCloseDialogUpdateProfile = useCallback(() => {
    dispatch(commonActions.handleSetOpenDialogUpdateProfile(false));
  }, []);

  // useEffect(() => {
  //   if (!isLogin) {
  //     navigate("/dang-nhap");
  //   }
  // }, [isLogin]);
  useEffect(() => {
    if (!!userInfos?.member && isEmpty(userInfos?.member?.name)) {
      dispatch(commonActions.handleSetOpenDialogUpdateProfile(true));
    }
    if (
      !!userInfos?.member &&
      [ACCOUNT_TEACHER_STATUS.NEW, ACCOUNT_TEACHER_STATUS.NOT_REGISTER].includes(userInfos?.status as string)
    ) {
      dispatch(commonActions.handleSetOpenDialogRegisterTeacher(true));
    }
    if (!!userInfos?.member && !isDesktop) {
      setOpenDialogUseDesktop(true);
      setHideLeftSide(true);
    }
  }, [userInfos]);

  // useEffect(() => {
  //   const reloadFunc = (e) => {
  //     e.preventDefault();
  //     if ([ACCOUNT_TEACHER_STATUS.NEW, ACCOUNT_TEACHER_STATUS.NOT_REGISTER].includes(userInfos?.status as string)) {
  //       dispatch(commonActions.handleSetOpenDialogRegisterTeacher(true));
  //     }
  //   };
  //   window.addEventListener("beforeunload", reloadFunc);
  //   return () => {
  //     window.removeEventListener("beforeunload", reloadFunc);
  //   };
  // }, []);

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          width: "100vw",
        }}
        className="bg-primary flex overflow-hidden"
      >
        <div className={`${hideLeftSide ? "w-[82px]" : "w-[312px]"} h-[100vh]`}>
          <DefaultLeftSide hideLeftSide={hideLeftSide} setHideLeftSide={setHideLeftSide} />
        </div>
        <div
          className={`w-full ${isDashboard ? "bg-gray-200" : "bg-white"} xl:rounded-tl-[50px] ${
            hideLeftSide ? "xl:w-[calc(100vw-82px)]" : "xl:w-[calc(100vw-312px)]"
          } xl:mt-4`}
        >
          {/* <div className="fixed top-0 xl:hidden bg-primary w-screen">
            <ResponsiveHeader />
          </div> */}
          {location?.pathname !== "/dang-nhap" && (
            <>
              <div className="hidden xl:block h-[72px] px-10">
                <Breadcrumb />
              </div>
              {!isDashboard && <div className="hidden xl:block border-b border-gray-200" />}
            </>
          )}

          <div className="w-full">
            {/* <Outlet /> */}
            {!isLogin && location.pathname !== "/su-dung-desktop" ? (
              <>
                <Navigate to="/dang-nhap" />
                <Outlet />
              </>
            ) : (
              <>
                <Outlet />
              </>
            )}
          </div>
        </div>
        <ConfirmDialog />
      </div>
      {openDialogDeveloping && (
        <AlertDialog
          open={openDialogDeveloping}
          handleClose={() => dispatch(commonActions.handleOpenDialogFeatureDeveloping(false))}
          notify={{ message: intl.formatMessage({ id: "Dialog.developingMessage" }) }}
          isHideCancel
        />
      )}
      {openDialogUpdateUsername && (
        <DialogUpdateProfile isOpen={openDialogUpdateUsername} onClose={handleCloseDialogUpdateProfile} />
      )}
      {!openDialogUpdateUsername && !openDialogRegisterTeacher && openDialogUseDesktop && (
        <AlertDialog
          open={openDialogUseDesktop}
          handleClose={() => setOpenDialogUseDesktop(false)}
          notify={{ message: intl.formatMessage({ id: "UseAppInDeskTop.description" }) }}
          isHideCancel
        />
      )}
      {/* {notify?.isOpenDialog && (
        <AlertDialog
          open={notify.isOpenDialog}
          handleClose={() => dispatch(commonActions.handleDeleteNotify())}
          notify={notify}
          isHideCancel
        />
      )} */}
    </>
  );
}
