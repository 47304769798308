import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { commonActions } from "../../components/common/redux/action";
import { ProgressSpinner } from "primereact/progressspinner";
import CreateDocxAndPDF from "./CreateDocxAndPDF";
import CreateGroupExam from "./CreateGroupExam";
import { EExam } from "../../components/manage_exam/utils/constant";
import ThousandQuestions from "./ThousandQuestions";
import SelfInputQuestion from "./SelfInputQuestion";
import { ROUTES } from "../../utils/configs/router";

export default function ExamDetail() {
  const dispatch = useDispatch();
  const routerParams = useParams();
  const navigate = useNavigate();

  const { examDetail, userInfos } = useSelector((state: { common }) => state.common);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!!routerParams?.topicSlug && !!userInfos?.member) {
      setIsLoading(true);
      dispatch(
        commonActions.handleGetExamDetail(
          { topicSlug: routerParams?.topicSlug },
          () => setIsLoading(false),
          () => {
            navigate(ROUTES.manageDocument.mine.path);
          }
        )
      );
    }
  }, []);

  return isLoading ? (
    <div className="flex justify-center items-center w-full h-full pt-[200px]">
      <ProgressSpinner
        style={{ width: "80px", height: "80px" }}
        pt={{
          circle: {
            className: "stroke-primary",
          },
        }}
      />
    </div>
  ) : (
    <div>
      {(() => {
        switch (examDetail?.type_generate) {
          case EExam.DOCX:
          case EExam.PDF:
            return <CreateDocxAndPDF isEdited />;
          case EExam.NHBD:
            return <CreateGroupExam isEdited />;
          case EExam.NHCH:
            return <ThousandQuestions isEdited />;
          case EExam.SELFINPUT:
            return <SelfInputQuestion isEdited />;
          default:
            return;
        }
      })()}
    </div>
  );
}
