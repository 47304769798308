import { MathJax } from "better-react-mathjax";
import isEqual from "lodash/isEqual";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AddQuestionSelfInput } from "../../../assets";
import ReportDialog from "../../common/components/dialog/ReportDialog";
import SolutionDialog from "../../common/components/dialog/SolutionDialog";
import { commonActions } from "../../common/redux/action";
import { quizAnswerType } from "../../common/utils/constant";
import { genSignature, getFormData, getNonce, removeNullAttributeFromObject } from "../../common/utils/helper";
import { ANSWER_TYPE_OPTIONS, REPORT_SCREEN_TYPE } from "../utils/constant";
import { getCorrectOrdinalOfListQuestion, getUpdatedListQuestion, isParentQuestion } from "../utils/helpers";
import DialogEditExam from "./DialogEditExam";
import QuestionRow from "./QuestionRow";
import ParentQuestionCard from "./QuizComponents/ParentQuestionCard";
import SelfInputQuestionRow from "./QuizComponents/SelfInputQuestionRow";

type TQuestionsGroupExamProps = {
  questions: any;
  solutions: any;
  isThousandQuestions?: boolean;
  questionsSelected?: any[];
  onChooseQuestion?: (question: any, actionType: "add" | "remove") => void;
  isPreview?: boolean;
  isSelect?: boolean;
  isEdited?: boolean;
  isShowSolution?: boolean;
  isShowBorder?: boolean;
  isShowCreateQuestionButton?: boolean;
  isShowRightSide?: boolean;
  onClickCreateSelfInputQuestion?: () => void;
  onClickQuestionContent?: (ordinal) => void;
  onClickDeleteSelfInputQuestions?: (ordinal: number) => void;
  onClickOpenSolutionSelfInputQuestionDialog?: (ordinal: number) => void;
  updateListQuestion?: (questions) => void;
};

function QuestionsGroupExam({
  questions,
  solutions,
  isThousandQuestions = false,
  questionsSelected = [],
  onChooseQuestion = (question, actionType) => {},
  isPreview = false,
  isSelect = false,
  isEdited = false,
  isShowSolution = true,
  isShowBorder = true,
  isShowCreateQuestionButton = false,
  isShowRightSide = false,
  onClickCreateSelfInputQuestion,
  onClickQuestionContent,
  onClickOpenSolutionSelfInputQuestionDialog,
  updateListQuestion,
}: TQuestionsGroupExamProps) {
  const dispatch = useDispatch();
  const params = useParams();
  const [reportDialogState, setReportDialogState] = useState<{ isOpen: boolean; id?: number }>({
    isOpen: false,
  });
  const [isDynamicMathJax, setIsDynamicMathJax] = useState(false);
  const [showSolution, setShowSolution] = useState<{ questionId: number; isSelfInput: boolean } | null>(null);
  const [questionEdit, setQuestionEdit] = useState<any>();

  const handleShowSolutions = (questionId) => {
    setIsDynamicMathJax(true);
    setShowSolution(questionId);
  };

  const handleSubmitReport = (formValues) => {
    let dataPost: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      quiz_question_id: reportDialogState.id,
    };

    const isPreviewScreen = isThousandQuestions
      ? // if question have original_id => question from main data 2
        isPreview || isSelect || !questionsSelected?.find((qs) => qs.id === reportDialogState.id)?.original_id
      : isPreview;

    dataPost = {
      ...dataPost,
      signature: genSignature(dataPost),
      reason: formValues?.textContent.trim(),
      images: formValues?.images ? formValues?.images?.map((e) => e?.file) : undefined,
      screen: isPreviewScreen ? REPORT_SCREEN_TYPE.PREVIEW : REPORT_SCREEN_TYPE.DETAIL,
    };

    if (params.roomId) {
      delete dataPost.screen;
      dataPost.room_hid = params.roomId;
    }

    dispatch(
      commonActions.reportQuiz(getFormData(removeNullAttributeFromObject(dataPost)), () => {
        setReportDialogState({ isOpen: false });
      })
    );
  };

  const handleUpdateListQuestion = (
    oldQuestion: any,
    data: any,
    isCheckedParentQuestion: boolean,
    answerTypeChosen: keyof typeof ANSWER_TYPE_OPTIONS,
    parentQuestionId: number
  ) => {
    let { updatedListQuestion, targetOrdinal } = getUpdatedListQuestion(
      questions,
      oldQuestion,
      data,
      isCheckedParentQuestion,
      answerTypeChosen,
      parentQuestionId
    );
    if (targetOrdinal) onClickQuestionContent?.(targetOrdinal);
    updateListQuestion?.(updatedListQuestion);
  };

  const handleClickDeleteSelfInputQuestion = (ordinal) => {
    setIsDynamicMathJax(false);
    onClickQuestionContent?.(ordinal);

    let newListQuestion = [...questions];
    const deleteQuestionIndex = newListQuestion.findIndex((qs) => qs.ordinal === ordinal);
    if (isParentQuestion(newListQuestion[deleteQuestionIndex])) {
      newListQuestion = newListQuestion.map((question) => {
        if (question.parent === newListQuestion[deleteQuestionIndex].id) {
          return {
            ...question,
            parent: undefined,
          };
        } else {
          return question;
        }
      });
    }
    newListQuestion[deleteQuestionIndex] = {
      ...newListQuestion[deleteQuestionIndex],
      deleted: true,
      ordinal: undefined,
    };
    newListQuestion = getCorrectOrdinalOfListQuestion(newListQuestion);

    updateListQuestion?.(newListQuestion);

    setIsDynamicMathJax(true);
  };

  useEffect(() => {
    setIsDynamicMathJax(true);
  }, [questions]);

  return (
    <div className={`h-full w-full ${!isShowRightSide && "pr-[50px]"} ${isShowBorder && "border"}`}>
      <div className="flex flex-col gap-6 pb-6 p-6">
        {questions
          ?.filter((question) => !question.deleted)
          ?.filter((question) => !question?.parent)
          ?.map((qs, index) => {
            const countPreviousParentQuestion = questions
              ?.filter((e) => !e?.deleted)
              ?.filter((question) => !question?.parent)
              ?.slice(0, index)
              .filter((question) => question.answer_type === quizAnswerType.NONE)?.length;

            if (!qs.isSelfInput) {
              if (qs.answer_type !== quizAnswerType.NONE) {
                return (
                  <MathJax key={qs.id || qs.ordinal} dynamic={isDynamicMathJax} inline>
                    <QuestionRow
                      qs={qs}
                      isShowSolution={isShowSolution}
                      solution={solutions?.find((solution) => solution?.quiz_question_id === qs?.id)}
                      questionNumber={
                        isThousandQuestions && isSelect
                          ? qs?.ordinal
                          : qs.ordinal - (countPreviousParentQuestion as number)
                      }
                      parentQs={questions?.find((e) => e.id === qs.parent)}
                      isThousandQuestions={isThousandQuestions}
                      questionsSelected={questionsSelected}
                      isShowSmallCancelButton={isThousandQuestions && isShowRightSide}
                      onOpenReportDialog={(questionId: number) =>
                        setReportDialogState({ isOpen: true, id: questionId })
                      }
                      onClickShowSolution={(questionId) => handleShowSolutions(questionId)}
                      onChooseQuestion={onChooseQuestion}
                      onClickQuestionContent={() => onClickQuestionContent?.(qs.ordinal)}
                    />
                  </MathJax>
                );
              } else {
                const listChildQuestion = questions
                  ?.filter((question) => !question.deleted)
                  ?.filter((question) => question?.parent)
                  ?.filter((questionItem) => questionItem.parent === qs.id);
                if (listChildQuestion.length === 0) {
                  return;
                } else if (listChildQuestion.length === 1) {
                  return (
                    <MathJax key={qs.id || qs.ordinal} dynamic={isDynamicMathJax} inline>
                      <QuestionRow
                        qs={listChildQuestion[0]}
                        isShowSolution={isShowSolution}
                        solution={solutions?.find(
                          (solution) => solution?.quiz_question_id === listChildQuestion[0]?.id
                        )}
                        questionNumber={
                          isThousandQuestions && isSelect
                            ? listChildQuestion[0]?.ordinal
                            : listChildQuestion[0].ordinal - ((countPreviousParentQuestion as number) + 1)
                        }
                        parentQs={questions?.find((e) => e.id === listChildQuestion[0].parent)}
                        isThousandQuestions={isThousandQuestions}
                        questionsSelected={questionsSelected}
                        isShowSmallCancelButton={isThousandQuestions && isShowRightSide}
                        onOpenReportDialog={(questionId: number) =>
                          setReportDialogState({ isOpen: true, id: questionId })
                        }
                        onClickShowSolution={(questionId) => handleShowSolutions(questionId)}
                        onChooseQuestion={onChooseQuestion}
                        onClickQuestionContent={() => onClickQuestionContent?.(listChildQuestion[0].ordinal)}
                      />
                    </MathJax>
                  );
                } else {
                  return (
                    <ParentQuestionCard qs={qs} isDynamicMathJax={false} isViewOnly={true}>
                      <div className="flex flex-col gap-6">
                        {listChildQuestion.map((question) => {
                          return (
                            <MathJax key={question.id || question.ordinal} dynamic={isDynamicMathJax} inline>
                              <QuestionRow
                                qs={question}
                                isShowSolution={isShowSolution}
                                solution={solutions?.find((solution) => solution?.quiz_question_id === question?.id)}
                                questionNumber={
                                  isThousandQuestions && isSelect
                                    ? question?.ordinal
                                    : question.ordinal - ((countPreviousParentQuestion as number) + 1)
                                }
                                isThousandQuestions={isThousandQuestions}
                                questionsSelected={questionsSelected}
                                isShowSmallCancelButton={isThousandQuestions && isShowRightSide}
                                onOpenReportDialog={(questionId: number) =>
                                  setReportDialogState({ isOpen: true, id: questionId })
                                }
                                onClickShowSolution={(questionId) => handleShowSolutions(questionId)}
                                onChooseQuestion={onChooseQuestion}
                                onClickQuestionContent={() => onClickQuestionContent?.(question.ordinal)}
                              />
                            </MathJax>
                          );
                        })}
                      </div>
                    </ParentQuestionCard>
                  );
                }
              }
            } else {
              if (qs.answer_type !== quizAnswerType.NONE) {
                return (
                  <MathJax key={qs.id || qs.ordinal} dynamic={isDynamicMathJax} inline>
                    <SelfInputQuestionRow
                      qs={qs}
                      questionNumber={qs.ordinal - (countPreviousParentQuestion as number)}
                      onClickQuestionContent={() => onClickQuestionContent?.(qs?.ordinal)}
                      onClickShowSolution={() => onClickOpenSolutionSelfInputQuestionDialog?.(qs.ordinal)}
                      onClickEditQuestion={() => {
                        setIsDynamicMathJax(false);
                        setQuestionEdit(qs);
                      }}
                      onClickDeleteQuestion={() => handleClickDeleteSelfInputQuestion?.(qs.ordinal)}
                    />
                  </MathJax>
                );
              } else {
                const listChildQuestion = questions
                  ?.filter((question) => !question.deleted)
                  ?.filter((question) => question?.parent)
                  .filter((questionItem) => questionItem.parent === qs.id);

                return (
                  <ParentQuestionCard
                    qs={qs}
                    isDynamicMathJax={false}
                    isViewOnly={false}
                    onClickEditButton={() => {
                      setIsDynamicMathJax(false);
                      setQuestionEdit(qs);
                    }}
                    onClickDeleteQuestion={() => handleClickDeleteSelfInputQuestion?.(qs.ordinal)}
                  >
                    {listChildQuestion.map((question) => {
                      return (
                        <MathJax key={question.id || question.ordinal} dynamic={isDynamicMathJax} inline>
                          <SelfInputQuestionRow
                            qs={question}
                            questionNumber={question.ordinal - ((countPreviousParentQuestion as number) + 1)}
                            onClickQuestionContent={() => onClickQuestionContent?.(question?.ordinal)}
                            onClickShowSolution={() => onClickOpenSolutionSelfInputQuestionDialog?.(question.ordinal)}
                            onClickEditQuestion={() => {
                              setIsDynamicMathJax(false);
                              setQuestionEdit(question);
                            }}
                            onClickDeleteQuestion={() => handleClickDeleteSelfInputQuestion?.(question.ordinal)}
                          />
                        </MathJax>
                      );
                    })}
                  </ParentQuestionCard>
                );
              }
            }
          })}
        {isShowCreateQuestionButton && (
          <div className={"w-full flex justify-center items-center my-10"}>
            <button
              id="add-question"
              className="w-[512px] h-[108px] rounded-[12px] border flex justify-center items-center flex-col"
              onClick={onClickCreateSelfInputQuestion}
            >
              <div>
                <AddQuestionSelfInput />
              </div>
              <span className="text-secondary pt-3 font-bold">
                <FormattedMessage id="selfInput.add" />
              </span>
            </button>
          </div>
        )}
      </div>
      {reportDialogState.isOpen && (
        <ReportDialog
          isOpen={reportDialogState.isOpen}
          onClose={() => setReportDialogState({ isOpen: false })}
          onSubmit={handleSubmitReport}
        />
      )}
      {Boolean(showSolution) && (
        <SolutionDialog
          solution={solutions?.find((solution) => solution?.quiz_question_id === showSolution)}
          isOpen={Boolean(showSolution)}
          onClose={() => {
            setShowSolution(null);
            setIsDynamicMathJax(false);
          }}
        />
      )}
      {questionEdit && (
        <DialogEditExam
          question={questionEdit}
          listParentQuestion={questions
            ?.filter((e) => !e?.deleted)
            ?.filter((question) => question.answer_type === quizAnswerType.NONE)
            ?.filter((question) => question.id !== questionEdit?.id)
            ?.filter((question) => (isEdited ? !question.original_id || question.id < 0 : question.id < 0))}
          open={Boolean(questionEdit)}
          onClose={() => {
            setQuestionEdit(null);
            setIsDynamicMathJax(true);
          }}
          onSubmit={(val, isParentQuestion, answerTypeChosen, parentQuestionId) => {
            handleUpdateListQuestion(questionEdit, val, isParentQuestion, answerTypeChosen, parentQuestionId);
            setIsDynamicMathJax(true);
          }}
          isSelfInput
          title="Sửa câu hỏi"
        />
      )}
    </div>
  );
}
const checkEqualProps = (preProps, nextProps) => {
  return (
    isEqual(preProps?.questions, nextProps?.questions) &&
    isEqual(preProps?.solutions, nextProps?.solutions) &&
    isEqual(preProps?.isThousandQuestions, nextProps?.isThousandQuestions) &&
    isEqual(preProps?.questionsSelected, nextProps?.questionsSelected) &&
    isEqual(preProps?.isPreview, nextProps?.isPreview) &&
    isEqual(preProps?.updateListQuestion, nextProps?.updateListQuestion) &&
    isEqual(preProps?.onChooseQuestion, nextProps?.onChooseQuestion) &&
    isEqual(preProps?.onClickQuestionContent, nextProps?.onClickQuestionContent) &&
    isEqual(preProps?.onClickCreateSelfInputQuestion, nextProps?.onClickCreateSelfInputQuestion) &&
    isEqual(preProps?.onClickOpenSolutionSelfInputQuestionDialog, nextProps?.onClickOpenSolutionSelfInputQuestionDialog)
  );
};
export default React.memo(QuestionsGroupExam, checkEqualProps);
