import { Chips } from "primereact/chips";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ButtonGhost from "../../common/components/button/ButtonGhost";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import Dialog from "../../common/components/dialog/Dialog";

type TShareDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formValue: { referenceIds: string[] }) => void;
};

export default function ShareDialog({ isOpen, onClose, onSubmit }: TShareDialogProps) {
  const intl = useIntl();

  const [formValue, setFormValue] = useState<{
    referenceIds: string[];
  }>({
    referenceIds: [],
  });

  const validateReferenceIds = (referenceIds: string[]) => {
    if (referenceIds.length === 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    onSubmit(formValue);
  };

  const isDisableButtonSubmit = useMemo(() => !validateReferenceIds(formValue.referenceIds), [formValue]);

  const actions = (
    <div className="flex justify-end gap-4">
      <ButtonGhost label={intl.formatMessage({ id: "common.cancel" })} onClick={onClose} />
      <ButtonPrimary
        label={intl.formatMessage({ id: "common.share" })}
        onClick={handleSubmit}
        disabled={isDisableButtonSubmit}
      />
    </div>
  );

  return (
    <Dialog title={intl.formatMessage({ id: "shareFolder.title" })} isOpen={isOpen} onClose={onClose} actions={actions}>
      <Chips
        placeholder={
          formValue.referenceIds.length === 0
            ? intl.formatMessage({
                id: "shareFolder.referenceField.placeholder",
              })
            : ""
        }
        autoFocus
        addOnBlur
        value={formValue.referenceIds}
        onChange={(e) => {
          validateReferenceIds(e.value || []);
          setFormValue((prev) => ({ ...prev, referenceIds: e.value?.map((referenceId) => referenceId.trim()) || [] }));
        }}
        pt={{
          root: {
            className: "w-full",
          },
          container: {
            className: "w-full shadow-none border-secondary",
          },
          token: {
            className: "my-0.5",
          },
        }}
      />
    </Dialog>
  );
}
