import { ConfirmDialogProps, confirmDialog as primeConfirmDialog } from "primereact/confirmdialog";

export default function confirmDialog(confirmDialogProps: ConfirmDialogProps) {
  const defaultProps = {
    draggable: false,
    closable: false,
    pt: {
      root: {
        className: "rounded-[20px] overflow-hidden w-[560px]",
      },
      header: {
        className: "text-center",
      },
      content: {
        className: "pb-0 [&>.p-confirm-dialog-message]:ml-0",
      },
      footer: {
        className: "p-6",
      },
    },
  };
  primeConfirmDialog({
    ...defaultProps,
    ...confirmDialogProps,
  });
}
