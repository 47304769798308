import api from "../../../utils/configs/api";

const createFolder = (data) => {
  const option = {
    method: "post",
    url: "teacher/folder",
    data,
  };
  return api(option);
};

const getListFolderAndFile = (params) => {
  const option = {
    method: "get",
    url: "teacher/folder-files",
    params,
  };

  return api(option);
};

const updateFolder = (folderId, data) => {
  const option = {
    method: "patch",
    url: `teacher/folder/${folderId}`,
    data,
  };

  return api(option);
};

const deleteFolder = (folderId, data) => {
  const option = {
    method: "delete",
    url: `teacher/folder/${folderId}`,
    data,
  };

  return api(option);
};

const shareDocument = (data) => {
  const option = {
    method: "post",
    url: "teacher/share-exams",
    data,
  };

  return api(option);
};

const deleteExams = (data) => {
  const option = {
    method: "delete",
    url: "teacher/delete-exams",
    data,
  };

  return api(option);
};

const previewExam = (data) => {
  const option = {
    method: "post",
    url: "teacher/preview-exam",
    data,
  };

  return api(option);
};

const createGroupExam = (data) => {
  return api({
    method: "post",
    url: "teacher/create-exam",
    data,
  });
};

const getExamDetail = (topicSlug) => {
  return api({
    method: "get",
    url: `teacher/exam/${topicSlug}`,
  });
};

export const documentServices = {
  createFolder,
  getListFolderAndFile,
  updateFolder,
  deleteFolder,
  shareDocument,
  deleteExams,
  previewExam,
  createGroupExam,
  getExamDetail,
};
