/* eslint-disable func-names */
/* eslint-disable no-bitwise */
import CryptoJS from "crypto-js";
import axios from "axios";
import JSZip from "jszip";

const has = Object.prototype.hasOwnProperty;

export const hexToRgbA = (hex: string) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    if (c.length === 6) {
      c = ["F", "F", c[0], c[1], c[2], c[3], c[4], c[5]];
    }
    c = `0x${c.join("")}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255, ((c >> 24) & 255) / 255.0].join(",")})`;
  }
  throw new Error("Bad format");
};

export const formatTime = (timer: number | string) => {
  const tempTimer = Number(timer);
  const getSeconds = `0${tempTimer % 60}`.slice(-2);
  const minutes = `${Math.floor(tempTimer / 60)}`;
  const getMinutes = `0${Number(minutes) % 60}`.slice(-2);
  const getHours = `0${Math.floor(tempTimer / 3600)}`.slice(-2);

  return `${getHours}:${getMinutes}:${getSeconds}`;
};

export const getNonce = () => {
  return Math.random().toString(36).slice(2, 13);
};

export const getRoutePath = (location: any, params: any) => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

export const genSignature = (data: any, clientKey = "", secretKey = "", ignoreKeys: string[] = []) => {
  const dictData = { ...data };
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in dictData) {
    if (ignoreKeys.includes(key)) {
      delete dictData[key];
    }
    // trường hợp trong dict có 1 key có value là dạng object/list thì phải chuyển về dạng string trước khi tạo sinature
    if (key === "answers") {
      // trường hợp gửi kết quả bài quiz thì cần sắp xếp lại thứ tự trả lời theo question_id trước khi tạo signature
      const answers = dictData[key];
      answers.sort((first: any, second: any) => {
        return first.question_id > second.question_id ? 1 : -1;
      });

      dictData[key] = JSON.stringify(answers);
    }
  }
  dictData.client_key = clientKey || process.env.REACT_APP_APPLICATION_CLIENT_KEY;

  // sắp xếp dictionary key theo thứ tự
  const sortedDict = Object.keys(dictData)
    .sort() // eslint-disable-next-line
    .reduce((r: any, k) => ((r[k] = dictData[k]), r), {});

  // nối các cặp {key: value, key2: value2} trong dict thành dạng key=value,key2=value2
  const dataStr = Object.entries(sortedDict)
    .map(([k, v]) => (Array?.isArray(v) ? `${k}=[${v?.toString()?.replace(/,/g, ", ")}]` : `${k}=${v}`))
    .join();

  console.log("dataStr", dataStr);
  // tạo signature
  const signature = CryptoJS.HmacSHA256(dataStr, secretKey || process.env.REACT_APP_APPLICATION_SECRET_KEY || "")
    .toString(CryptoJS.enc.Hex)
    .toUpperCase();

  return signature;
};

export const isEmptyCustom = (prop: any) => {
  return (
    prop === null ||
    prop === undefined ||
    (has.call(prop, "length") && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
  );
};

export const removeNullAttributeFromObject = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => !isEmptyCustom(v)));

export const getFormData = (object: { [s: string]: unknown } | ArrayLike<unknown>) =>
  Object.entries(object).reduce((fd, [key, val]: any) => {
    if (Array.isArray(val)) {
      val.forEach((v) => fd.append(key, v));
    } else {
      fd.append(key, val);
    }
    return fd;
  }, new FormData());

export const stringToSlug = (str) => {
  // Chuyển hết sang chữ thường
  let newStr = str.toLowerCase();
  // xóa dấu
  newStr = newStr
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
  // Thay ký tự đĐ
  newStr = newStr.replace(/[đĐ]/g, "d");
  // Xóa ký tự đặc biệt
  newStr = newStr.replace(/([^0-9a-z-\s])/g, "");
  // Xóa khoảng trắng thay bằng ký tự -
  newStr = newStr.replace(/(\s+)/g, "-");
  // Xóa ký tự - liên tiếp
  newStr = newStr.replace(/-+/g, "-");
  // xóa phần dư - ở đầu & cuối
  newStr = newStr.replace(/^-+|-+$/g, "");
  return newStr;
};

async function getFromUrl(url) {
  let urlConvert = "";
  if (url.includes("http://")) {
    urlConvert = url.replace("http://", "https://");
  } else {
    urlConvert = url;
  }
  const { data } = await axios.get(urlConvert, { responseType: "arraybuffer" });
  return data;
}

export async function getAndUnZipFile(url) {
  let zipFiles: any = [];
  await fetch(url)
    .then(function (response) {
      if (response.status === 200 || response.status === 0) {
        return Promise.resolve(response.blob());
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    })
    .then(JSZip.loadAsync)
    .then(async function (zip) {
      for (const file of Object.keys(zip.files)) {
        await zip.files[file].async("base64").then(function (fileData) {
          zipFiles.push({
            name: file,
            data: "data:image/*;base64," + fileData,
          });
        });
      }
    });
  return zipFiles;
}

export const distinctArrayObject = (array, key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};

export const containsSpecialChars = (str) => {
  const specialChars = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
};

export const getTimestampWithoutSecond = (timestamp: number) => {
  return Math.floor(timestamp / 60) * 60;
};

export const isMobileOrTabletDevice = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}
