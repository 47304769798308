import React from "react";
import ButtonBase, { TButtonBaseProps } from "./ButtonBase";

type TButtonGhostProps = TButtonBaseProps;

export default function ButtonGhost({ className, ...props }: TButtonGhostProps) {
  return (
    <ButtonBase
      className={`
      text-secondary hover:text-secondary-700 disabled:text-secondary-50
        bg-transparent hover:bg-secondary-50 disabled:bg-transparent
        border border-transparent
        ${className}
      `}
      {...props}
    ></ButtonBase>
  );
}
