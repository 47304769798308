/* eslint-disable no-shadow */
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { getPersistConfig } from "redux-deep-persist";
import logger from "redux-logger";
import { fork } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";
// import hardSet from "redux-persist/lib/stateReconciler/hardSet";
// import crossBrowserListener from "./reduxpersist-listener";
import commonSaga from "../components/common/redux/saga";
import commonReducer from "../components/common/redux/reducers";
import documentSaga from "../components/manage_document/redux/saga";
import documentReducer from "../components/manage_document/redux/reducers";
import roomSaga from "../components/manage_room/redux/saga";
import roomReducer from "../components/manage_room/redux/reducers";
import manageStudentSaga from "../components/manage_student/redux/saga";
import manageStudentReducer from "../components/manage_student/redux/reducers";

const sagaMiddleware = createSagaMiddleware();
function* rootSaga() {
  yield fork(commonSaga);
  yield fork(documentSaga);
  yield fork(roomSaga);
  yield fork(manageStudentSaga);
}

const reducers = combineReducers({
  common: commonReducer,
  document: documentReducer,
  room: roomReducer,
  manageStudent: manageStudentReducer,
});

const persistConfig = getPersistConfig({
  key: "root",
  storage,
  timeout: null,
  whitelist: ["common.fireBaseInfos", "common.userInfos", "common.accessToken", "common.refreshToken"],
  rootReducer: reducers,
  // stateReconciler: hardSet,
});
const persistedReducer = persistReducer(persistConfig, reducers);
const middleware = process.env.NODE_ENV === "development" ? [sagaMiddleware, logger] : [sagaMiddleware];

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middleware),
});
sagaMiddleware.run(rootSaga);
// window.addEventListener("storage", crossBrowserListener(store, persistConfig));
