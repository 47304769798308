import React, { useCallback, useEffect, useRef } from "react";
import ButtonBase, { TButtonBaseProps } from "./ButtonBase";
import { debounce } from "lodash";

type TButtonPrimaryProps = TButtonBaseProps;

export default function ButtonPrimary({ className, onClick = () => {}, ...props }: TButtonPrimaryProps) {
  const onClickOnce: any = useRef(null);

  const resetClick = useCallback(
    debounce(() => {
      onClickOnce.current = null;
    }, 2000),
    [onClickOnce.current]
  );
  return (
    <ButtonBase
      className={`
      text-white
      bg-secondary hover:bg-secondary-700 disabled:bg-secondary-50
        border
      border-secondary hover:border-secondary-700 disabled:border-secondary-50
        ${className}
      `}
      onClick={(e) => {
        if (!onClickOnce.current) {
          onClick(e);
          onClickOnce.current = true;
          resetClick();
        }
      }}
      {...props}
    ></ButtonBase>
  );
}
