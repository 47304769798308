import { ACTION_TYPES } from "./actionTypes";
import { ACTION_TYPES as COMMON_ACTION_TYPES } from "../../common/redux/actionTypes";

export const initialStateDocument = {
  listFolderAndFile: {
    folders: [],
    files: [],
  },
  previewExam: {},
  examDetail: {},
};

const documentReducer = (state = initialStateDocument, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.GET_LIST_FOLDER_FILE_SUCCESS: {
      return {
        ...state,
        listFolderAndFile: {
          folders: action.params.folders || [],
          files: action.params.files || [],
        },
      };
    }
    case ACTION_TYPES.PREVIEW_EXAM_SUCCESS: {
      return {
        ...state,
        previewExam: action.params,
      };
    }
    case COMMON_ACTION_TYPES.HANDLE_LOGOUT: {
      return {
        ...state,
        listFolderAndFile: {},
      };
    }
    case ACTION_TYPES.GET_EXAM_DETAIL_SUCCESS: {
      return {
        ...state,
        examDetail: action.params,
      };
    }
    case ACTION_TYPES.CLEAN_PREVIEW_EXAM: {
      return {
        ...state,
        previewExam: {},
      };
    }
    case ACTION_TYPES.CLEAR_LIST_FOLDER_FILE: {
      return {
        ...state,
        listFolderAndFile: {
          folders: [],
          files: [],
        },
      };
    }
    default:
      return state;
  }
};

export default documentReducer;
