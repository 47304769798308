export enum EGroupActions {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export enum EGroupStatusOption {
  OPEN = "open",
  CLOSED = "closed",
}

export type TGroupStatusAddRoom = EGroupStatusOption;
export type TGroupStatusAddMember = EGroupStatusOption;

export type TEditGroupRequest = {
  groupHid: string;
  title: string;
  description?: string;
  addRoomStatus: TGroupStatusAddRoom;
  addMemberStatus: TGroupStatusAddMember;
};

export type TAddGroupRequest = Omit<TEditGroupRequest, "groupHid">;

export type TGroupResponse = {
  id: number;
  hid: string;
  title: string;
  description?: string;
  is_active: boolean;
  status_add_room: TGroupStatusAddRoom;
  status_add_member: TGroupStatusAddMember;
  num_of_members: number;
  num_of_rooms: number;
};
