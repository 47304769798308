import api from "../../../utils/configs/api";

const createGroup = (data) => {
  const option = {
    method: "post",
    url: "teacher/group",
    data,
  };
  return api(option);
};

const getListGroup = () => {
  const option = {
    method: "get",
    url: "teacher/group",
  };
  return api(option);
};

const deleteGroup = ({ group_hid, data }) => {
  const option = {
    method: "delete",
    url: `teacher/group/${group_hid}`,
    data,
  };
  return api(option);
};

const updateGroup = ({ group_hid, data }) => {
  const option = {
    method: "patch",
    url: `teacher/group/${group_hid}`,
    data,
  };
  return api(option);
};

const getGroupDetail = ({ groupHid }) => {
  const option = {
    method: "get",
    url: `teacher/group/${groupHid}`,
  };
  return api(option);
};

const addMembersToGroup = ({ groupHid, data }) => {
  const option = {
    method: "post",
    url: `teacher/group/${groupHid}/add`,
    data,
  };
  return api(option);
};

const removeMemberOfGroup = ({ groupHid, data }) => {
  const option = {
    method: "post",
    url: `teacher/group/${groupHid}/remove`,
    data,
  };
  return api(option);
};

const getGroupActionHistory = ({ groupHid }) => {
  const option = {
    method: "get",
    url: `teacher/group-action-history/${groupHid}`,
  };
  return api(option);
};

const addMembersOfGroupToRoom = (data) => {
  const option = {
    method: "post",
    url: "teacher/add-group-member",
    data,
  };
  return api(option);
};

const getMemberResultInGroup = ({ groupHid, memberHid }) => {
  const option = {
    method: "get",
    url: `teacher/result/${groupHid}/member/${memberHid}`,
  };
  return api(option);
};

const getAllMembers = () => {
  const option = {
    method: "get",
    url: "teacher/students",
  };
  return api(option);
};

const getMemberResult = ({ memberHid }) => {
  const option = {
    method: "get",
    url: `teacher/student/${memberHid}`,
  };
  return api(option);
};

export const manageStudentServices = {
  createGroup,
  getListGroup,
  deleteGroup,
  updateGroup,
  getGroupDetail,
  addMembersToGroup,
  removeMemberOfGroup,
  getGroupActionHistory,
  addMembersOfGroupToRoom,
  getMemberResultInGroup,
  getAllMembers,
  getMemberResult,
};
