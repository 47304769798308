import JSZip from "jszip";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DisplayQuizPDF from "../../components/common/components/DisplayPDF";
import { UploadFileComponent } from "../../components/common/components/UploadFile";
import CreationInfoExam from "../../components/manage_exam/components/CreationInfoExam";
// import { useIntl } from "react-intl";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { commonActions } from "../../components/common/redux/action";
import {
  genSignature,
  getAndUnZipFile,
  getFormData,
  getNonce,
  removeNullAttributeFromObject,
} from "../../components/common/utils/helper";
// import AlertDialog from "../../components/common/components/dialog/AlertDialog";
import { isNumber } from "lodash";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { FormattedMessage, useIntl } from "react-intl";
import { useUnmount } from "react-use";
import { AddQuestionSelfInput } from "../../assets";
import AlertDialog from "../../components/common/components/dialog/AlertDialog";
import DialogTextEditor from "../../components/common/components/dialog/DialogTextEditor";
import {
  ANSWER_KEY,
  TYPE_GENERATE_EXAM,
  convertAnswerKey,
  quizAnswerType,
} from "../../components/common/utils/constant";
import { documentActions } from "../../components/manage_document/redux/action";
import CreateQuizLeftSide from "../../components/manage_exam/components/CreateQuizLeftSide";
import CreateQuizRightSide from "../../components/manage_exam/components/CreateQuizRightSide";
import CreationInfoExamViewOnly from "../../components/manage_exam/components/CreationInfoExamViewOnly ";
import DialogEditExam from "../../components/manage_exam/components/DialogEditExam";
import QuestionsDocx from "../../components/manage_exam/components/QuestionsDocx";
import {
  ANSWER_TYPE_OPTIONS,
  DIALOG_TYPE,
  MAX_LENGTH_CORRECT_OPTION,
} from "../../components/manage_exam/utils/constant";
import {
  getAnswerTypeOfQuestion,
  getBaseOption,
  getCorrectOrdinalOfListQuestion,
  getFirstOrdinalQuestionWithoutParent,
  getOrdinalQuestionForUI,
  getUpdatedListQuestion,
} from "../../components/manage_exam/utils/helpers";
import { eventTracking } from "../../firebase/firebaseConfig";

const createZipFile = async (pdfFile) => {
  const zip = new JSZip();
  zip.file(pdfFile?.name, pdfFile, { base64: true });
  const zipBlob = await zip.generateAsync({ type: "blob" }).then((content) => content);
  const zipFile = new File([zipBlob], "zipfile", {
    type: zipBlob.type,
  });
  return zipFile;
};

export default function CreateDocxAndPDF({ isEdited = false }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const routerParams = useParams();
  const dispatch = useDispatch();
  const { convertDocx, examDetail, createExamFromFolderId } = useSelector((state: { common }) => state.common);
  const [openDialogConfirmDeleteFile, setOpenDialogConfirmDeleteFile] = useState(false);
  const [openDialogAddOrEditExam, setOpenDialogAddOrEditExam] = useState<{ type: number | null; question?: any }>({
    type: null,
  });
  const [ordinalQuestion, setOrdinalQuestion] = useState<number>(1);
  const [examInfo, setExamInfo] = useState<any>({
    thematics: [],
    numOfQuestions: -1,
    title: "",
    pdfFile: null,
    listQuestion: [],
  });
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
  }>({
    title: "",
  });
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainRef: any = useRef(null);
  const listQuestion = examInfo?.listQuestion;
  const listQuestionWithoutParent =
    examInfo?.listQuestion
      ?.filter((question) => !question.deleted)
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE) || [];
  const listQuestionLength = listQuestion?.filter((qs) => !qs?.deleted)?.length;

  const currentQuestion = useMemo(() => {
    const cq = listQuestion
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
      ?.find((e) => e?.ordinal === ordinalQuestion);
    if (cq) {
      return cq;
    }
    // nếu câu hỏi có ordinalQuestion đã bị xoá thì setOrdinalQuestion là câu trước nó
    setOrdinalQuestion(
      ordinalQuestion - 1 >= 1 ? ordinalQuestion - 1 : getFirstOrdinalQuestionWithoutParent(listQuestion)
    );
  }, [listQuestion, ordinalQuestion, quizAnswerType]);

  const isInputDocx = isEdited ? !examDetail?.pdf : location?.pathname?.includes("docx");

  const handleFillSolution = (solution: any) => {
    const oldContent = currentQuestion?.solutions.find((e: any) => e.content_type === "html")?.content;
    if ((!oldContent || oldContent?.length === 0) && solution?.length === 0) return;
    const newListQuestion = listQuestion.map((e: any) => {
      const newSolution = {
        ordinal: 1,
        content: solution,
        content_type: "html",
      };
      return e?.ordinal === ordinalQuestion
        ? {
            ...e,
            solutions: [
              !!e?.id
                ? !solution || solution?.length === 0
                  ? { ...newSolution, id: e?.solutions[0]?.id, deleted: true }
                  : {
                      ...newSolution,
                      id: e?.solutions[0]?.id,
                      edited: true,
                    }
                : newSolution,
            ],
          }
        : e;
    });
    setExamInfo({ ...examInfo, listQuestion: newListQuestion });
  };

  const handleChooseAnswerKey = (key: any, isChecked: boolean) => {
    const newListQuestion = listQuestion.map((e: any) => {
      if (e?.ordinal === ordinalQuestion) {
        const newOptions = e?.options?.map((op) =>
          key === op?.answer_key
            ? !!op?.id
              ? { ...getBaseOption(op), correct: Number(isChecked), edited: true }
              : { ...getBaseOption(op), correct: Number(isChecked) }
            : getBaseOption(op)
        );
        const answerType = getAnswerTypeOfQuestion(newOptions);
        return {
          ...e,
          options: newOptions,
          answer_type: answerType,
        };
      } else {
        return e;
      }
    });
    setExamInfo({ ...examInfo, listQuestion: newListQuestion });
  };

  const handleChangeCorrectAnswer = (content: string) => {
    const newListQuestion = listQuestion.map((e: any) => {
      if (e?.ordinal === ordinalQuestion) {
        const newOptions = e?.options?.map((op) =>
          !!op?.id
            ? {
                ...getBaseOption(op),
                contents: [
                  {
                    id: op?.items ? op?.items[0]?.id : null,
                    content: content,
                    content_type: "html",
                    ordinal: 1,
                    edited: true,
                  },
                ],
                edited: true,
              }
            : {
                ...getBaseOption(op),
                contents: [
                  {
                    content: content,
                    content_type: "html",
                    ordinal: 1,
                  },
                ],
              }
        );
        return {
          ...e,
          options: newOptions,
          answer_type: quizAnswerType.CONSTRUCTED_RESPONSE,
        };
      } else {
        return e;
      }
    });
    setExamInfo({ ...examInfo, listQuestion: newListQuestion });
  };

  const checkIsEditQuestion = useMemo(() => {
    return (
      !isEqual(examDetail?.questions?.length, examInfo?.listQuestion?.filter((e) => !e?.deleted)?.length) ||
      !isEqual(
        examDetail?.questions?.map((e) => e?.options?.map((op) => [op?.correct, op?.items[0]?.content])).flat(),
        examInfo?.listQuestion?.map((e) => e?.options?.map((op) => [op?.correct, op?.contents?.[0]?.content])).flat()
      ) ||
      !isEqual(
        examDetail?.questions?.map((e) => e?.questions?.map((c) => c?.content)).flat(),
        examInfo?.listQuestion?.map((e) => e?.contents?.map((c) => c?.content)).flat()
      ) ||
      !isEqual(
        examDetail?.questions?.map((e) => ({
          description_answer_before: e?.description_answer_before,
          description_answer_after: e?.description_answer_after,
        })),
        examInfo?.listQuestion?.map((e) => ({
          description_answer_before: e?.description_answer_before,
          description_answer_after: e?.description_answer_after,
        }))
      )
    );
  }, [examDetail, examInfo]);

  const checkEditCreationInfo = useMemo(() => {
    return !isEqual(
      [
        examDetail?.title,
        examDetail?.time,
        examDetail?.count,
        examDetail?.grade?.id,
        examDetail?.subject_type?.id,
        examDetail?.learning_program?.id,
        examDetail?.semester,
        // examDetail?.thematics,
      ],
      [
        examInfo?.title?.trim(),
        examInfo?.time,
        examInfo?.numOfQuestions,
        examInfo?.grade?.id,
        examInfo?.subject_types?.id,
        examInfo?.learningProgram?.id,
        examInfo?.semesters,
        // examInfo?.thematics || [],
      ]
    );
  }, [examDetail, examInfo]);

  const checkIsEditSolution = useMemo(() => {
    return !isEqual(
      examDetail?.solutions?.map((e) => e?.items?.detail?.map((e) => e?.content)).flat(),
      listQuestion
        ?.filter((e) => !isEmpty(e?.solutions))
        ?.map((e) => e.solutions?.map((e) => e?.content))
        .flat()
    );
  }, [examDetail, examInfo, listQuestion]);

  const handleCreateOrEditQuizPDFOrDocx = async () => {
    if (!isEdited) {
      eventTracking(isInputDocx ? "select_doc_input_type_done" : "select_pdf_input_type_done");
    }

    const folderId = createExamFromFolderId;

    let dataPost: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade_id: examInfo?.grade?.id,
      subject_type_id: examInfo?.subject_types?.id,
      learning_program_id: examInfo?.learningProgram?.id,
      folder_id: folderId,
      semester_ids: examInfo?.semesters?.id ? [examInfo?.semesters?.id] : undefined,
      thematic_ids: examInfo?.thematics,
      file_data: isEdited ? undefined : isInputDocx ? null : await createZipFile(examInfo?.pdfFile),
      is_edit_question: isEdited ? (checkIsEditQuestion ? 1 : 0) : undefined,
      is_edit_solution: isEdited ? (checkIsEditSolution ? 1 : 0) : undefined,
      topic_slug: isEdited ? routerParams?.topicSlug : undefined,
      type_generate: isEdited ? undefined : isInputDocx ? TYPE_GENERATE_EXAM.DOCX : TYPE_GENERATE_EXAM.PDF,
    };
    if (!isEdited || (isEdited && examInfo.title?.trim() !== examDetail.title)) {
      dataPost.title = examInfo.title?.trim();
    }

    const removeQuestions =
      examDetail?.questions
        ?.filter((e) => listQuestion?.findIndex((qs) => qs?.id === e?.id) < 0)
        ?.map((e) => ({ ...e, deleted: true })) || [];

    const getRemoveOptions = (qs) => {
      const oldQuestion = examDetail?.questions?.find((e) => e?.id === qs.id);
      if (!oldQuestion) return [];
      return (
        oldQuestion.options
          ?.filter((e) => {
            return qs?.options?.findIndex((o) => o?.id === e?.id) < 0;
          })
          ?.map((e) => ({ ...e, deleted: true })) || []
      );
    };

    const listQuestionEdit = listQuestion
      ?.map((e, ix) => {
        if (!e?.id) {
          return {
            ...e,
            added: true,
            ordinal: ix + 1,
          };
        } else {
          const oldAnswerType = examDetail?.questions?.find((question) => question.id === e.id)?.answer_type;
          const isEditQuestion =
            e?.answer_type !== oldAnswerType ||
            !isEqual(
              [
                examDetail?.questions?.find((question) => question.id === e.id)?.description_answer_before,
                examDetail?.questions?.find((question) => question.id === e.id)?.description_answer_after,
              ],
              [e.description_answer_before, e.description_answer_after]
            ) ||
            e?.parent !== examDetail?.questions?.find((question) => question.id === e.id)?.parent;
          return {
            ...e,
            ordinal: ix + 1,
            options: e?.options
              ?.map((op, i) =>
                !op?.id
                  ? {
                      ...op,
                      added: true,
                      ordinal: i,
                    }
                  : { ...op, ordinal: i, edited: true }
              )
              .concat(getRemoveOptions(e)),
            edited: isEditQuestion,
          };
        }
      })
      .concat(removeQuestions);

    const ignoreFields = ["semester_ids", "thematic_ids", "file_data"];
    dataPost = getFormData({
      ...removeNullAttributeFromObject(dataPost),
      signature: genSignature(removeNullAttributeFromObject(dataPost), undefined, undefined, ignoreFields),
      json_data: JSON.stringify({
        time: examInfo?.time,
        num_of_questions: listQuestionLength,
        data: isEdited ? JSON.stringify(listQuestionEdit) : JSON.stringify(listQuestion?.filter((e) => !e?.deleted)),
      }),
    });

    if (isEdited) {
      dispatch(
        commonActions.handleEditExam({ data: dataPost, topicSlug: routerParams?.topicSlug }, () =>
          navigate(folderId ? `/quan-ly-tai-lieu/cua-toi/${folderId}` : "/quan-ly-tai-lieu/cua-toi")
        )
      );
    } else {
      dispatch(
        commonActions.handleCreateQuizPDF(dataPost, () =>
          navigate(folderId ? `/quan-ly-tai-lieu/cua-toi/${folderId}` : "/quan-ly-tai-lieu/cua-toi")
        )
      );
    }
  };

  const handleCreateNewQuestion = (
    data: any,
    isParentQuestion: boolean,
    answerTypeChosen: keyof typeof ANSWER_TYPE_OPTIONS,
    parentQuestionId: number
  ) => {
    let qs: any;
    if (isParentQuestion) {
      qs = {
        id: -((listQuestion?.length + 1) as number),
        ordinal: listQuestion?.filter((question) => !question.deleted)?.length + 1,
        contents: [{ content: data?.find((e) => e?.ordinal === -1)?.content, content_type: "html", ordinal: 1 }],
        answer_type: quizAnswerType.NONE,
        isSelfInput: true,
      };
    } else {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;

      let options;
      if (answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER) {
        options = data
          ?.filter((e) => e?.ordinal >= 0)
          ?.map((op) => ({
            contents: [{ content: op?.content, content_type: "html", ordinal: 1 }],
            answer_key: op?.answer_key,
            correct: op?.correct || 0,
            ordinal: op?.ordinal,
          }));
      } else {
        const correctOption = data?.find((e) => e.ordinal === 1);
        options = [
          {
            contents: [{ content: correctOption.content, content_type: "html", ordinal: 1 }],
            correct: correctOption.correct,
            ordinal: correctOption.ordinal,
          },
        ];
      }

      let ordinal: number;
      if (!Boolean(parentQuestionId)) {
        ordinal = listQuestion?.filter((question) => !question.deleted)?.length + 1;
      } else {
        const previousOrdinal = listQuestion
          ?.filter((question) => !question.deleted)
          ?.reduce((accumulate, question) => {
            if (question.parent === parentQuestionId || question.id === parentQuestionId) {
              return question.ordinal;
            } else {
              return accumulate;
            }
          }, -1);
        ordinal = previousOrdinal + 1;
      }

      qs = {
        ordinal,
        contents: [{ content: data?.find((e) => e?.ordinal === -1)?.content, content_type: "html", ordinal: 1 }],
        ...(answerTypeChosen === ANSWER_TYPE_OPTIONS.ENTER_ANSWER && {
          description_answer_before: data?.find((e) => e?.ordinal === 0).content,
          description_answer_after: data?.find((e) => e?.ordinal === 2).content,
        }),
        options,
        solutions: [],
        answer_type: answerType,
        isSelfInput: true,
        parent: parentQuestionId,
      };
      setOrdinalQuestion(qs.ordinal);
    }

    if (parentQuestionId) {
      const previousQuestionIndex = listQuestion.findIndex((question) => question.ordinal === qs?.ordinal - 1);
      const clonedListQuestion = [...listQuestion];
      clonedListQuestion.splice(previousQuestionIndex + 1, 0, qs);
      setExamInfo({ ...examInfo, listQuestion: getCorrectOrdinalOfListQuestion(clonedListQuestion) });
    } else {
      setExamInfo({ ...examInfo, listQuestion: listQuestion.concat([qs]) });
    }
  };

  const handleUpdateListQuestion = (newListQuestion) => {
    setExamInfo({ ...examInfo, listQuestion: newListQuestion });
    const newListQuestionLength = newListQuestion?.filter((e) => !e?.deleted)?.length;
    setOrdinalQuestion((prev) => {
      if (prev > newListQuestionLength) {
        return newListQuestionLength;
      }
      return prev;
    });
  };

  const handleDeleteFile = () => {
    const dataPostPre = {
      nonce: getNonce(),
      timestamp: moment().unix(),
      exam_ids: [examDetail?.id],
    };
    const dataPost = {
      ...dataPostPre,
      signature: genSignature(dataPostPre),
    };
    dispatch(documentActions.deleteExams(dataPost, () => navigate("/quan-ly-tai-lieu/cua-toi")));
  };

  const handleConvertDocxFile = (f: File) => {
    setIsLoading(true);
    dispatch(commonActions.handleConvertDocx(getFormData({ file_data: f }), () => setIsLoading(false)));
  };

  const docxJson = useMemo(() => !isEmpty(convertDocx?.json) && JSON.parse(convertDocx?.json), [convertDocx]);
  const isViewOnly = examDetail?.permission === "shared";
  const checkEnableButtonEditExam = isInputDocx ? !!examInfo?.listQuestion : !!examInfo?.pdfFile;

  const checkEnableButtonCreate = useMemo(() => {
    const listParentQuestion = listQuestion
      ?.filter((question) => !question.deleted)
      ?.filter((question) => question.answer_type === quizAnswerType.NONE);

    const isAllParentHasChild = listParentQuestion?.every((parentQuestionItem) => {
      return listQuestionWithoutParent?.some((question) => question.parent === parentQuestionItem.id);
    });

    const checkBtnCreate =
      !!examInfo.title &&
      !errorMessage.title &&
      !!examInfo?.grade?.id &&
      !!examInfo?.subject_types?.id &&
      !!examInfo?.learningProgram?.id &&
      !!examInfo?.time &&
      !!examInfo?.numOfQuestions &&
      listQuestionWithoutParent?.length > 0 &&
      listQuestionWithoutParent?.every(
        (e: any) =>
          !!e?.options?.some(
            (op) =>
              op.correct === 1 &&
              op.contents?.[0]?.content?.trim() !== "" &&
              (e.answer_type === quizAnswerType.CONSTRUCTED_RESPONSE
                ? op.contents?.[0]?.content.length <= MAX_LENGTH_CORRECT_OPTION
                : true)
          )
      ) &&
      isAllParentHasChild;

    if (isEdited) {
      return checkBtnCreate && (checkIsEditQuestion || checkIsEditSolution || checkEditCreationInfo);
    }
    return checkBtnCreate;
  }, [examInfo, listQuestion]);

  useEffect(() => {
    if (examInfo?.numOfQuestions > 0 && examInfo?.numOfQuestions !== listQuestionLength) {
      const getQuestionInfo = (index) => ({
        ordinal: index + 1,
        contents: [],
        answer_type: 0,
        options: ANSWER_KEY.map((e, ix) => ({
          answer_key: e,
          correct: 0,
          ordinal: ix,
          contents: [],
        })),
        solutions: [],
        isSelfInput: true,
      });
      if (!isInputDocx) {
        if (listQuestionLength > 0) {
          if (examInfo?.numOfQuestions < listQuestionLength) {
            // nếu sửa numOfQuestions nhỏ hơn số questions đang có thì cắt
            const newListQuestion = listQuestion
              .map((e, ix) => (ix < examInfo?.numOfQuestions ? e : { ...e, deleted: true }))
              .filter((e) => !(e?.deleted && !e?.id));

            setExamInfo({
              ...examInfo,
              listQuestion: newListQuestion,
            });
            setOrdinalQuestion(examInfo?.numOfQuestions);
          } else {
            // nếu sửa numOfQuestions nhỏ hơn số questions đang có thì cộng thêm
            const additionQuestions = Array(examInfo?.numOfQuestions - listQuestionLength)
              .fill(0)
              .map((_, idx) => getQuestionInfo(idx + listQuestionLength));

            setExamInfo({ ...examInfo, listQuestion: listQuestion.concat(additionQuestions) });
          }
        } else {
          // set init
          setExamInfo({
            ...examInfo,
            listQuestion: Array(examInfo?.numOfQuestions)
              .fill(0)
              .map((_, idx) => getQuestionInfo(idx)),
          });
        }
      }
    }
  }, [examInfo?.numOfQuestions]);

  useEffect(() => {
    if (!isEmpty(convertDocx?.media)) {
      const getListQuestionDocx = async () => {
        // unzip
        let mediaFiles: any = await getAndUnZipFile(convertDocx?.media);
        // content image thi chuyen ve base64 => them the image
        const getContent = (obj) => {
          if (obj?.content_type === "image") {
            const file = mediaFiles?.find((e) => e?.name === obj?.content);
            if (file) {
              return `<img style="max-height:300px" src="${file?.data}" alt="" />`;
            }
          }
          return obj?.content;
        };
        const getObjectContents = (contents) => {
          if (contents?.length === 0) return {};
          return contents?.length === 1
            ? {
                id: 1,
                content_type: "html",
                content: getContent(contents[0]),
                ordinal: 1,
              }
            : contents?.reduce((a, b) => {
                return {
                  id: 1,
                  content_type: "html",
                  content: getContent(a) + " <br/> " + getContent(b),
                  ordinal: 1,
                };
              });
        };
        setExamInfo({
          ...examInfo,
          listQuestion: docxJson?.map((e, ix) => ({
            ordinal: ix + 1,
            answer_type: e?.answer_type,
            contents: [getObjectContents(e?.quiz_question_contents)],
            options: e?.quiz_options?.map((op) => ({
              ...op,
              contents: [getObjectContents(op?.option_contents)],
              answer_key: convertAnswerKey(op?.id),
            })),
            solutions: [getObjectContents(e?.solutions)],
            isSelfInput: true,
          })),
        });
      };
      getListQuestionDocx();
    }
    setIsLoading(false);
    setExamInfo({ ...examInfo, numOfQuestions: docxJson?.length });
  }, [convertDocx?.media]);

  useEffect(() => {
    if (listQuestionLength > 0) {
      setExamInfo({ ...examInfo, numOfQuestions: listQuestionLength });
    }
  }, [listQuestionLength]);

  useEffect(() => {
    document.getElementById(`question_${ordinalQuestion}`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [ordinalQuestion]);

  useEffect(() => {
    if (isEdited && !!examDetail?.questions) {
      const getSolution = (qsId) => examDetail?.solutions?.find((so) => so?.quiz_question_id === qsId);

      setExamInfo({
        ...examInfo,
        title: examDetail.title,
        numOfQuestions: examDetail?.count,
        time: examDetail?.time,
        grade: examDetail?.grade,
        learningProgram: examDetail?.learning_program,
        semesters: examDetail?.semester,
        thematics: examDetail?.thematics?.map((e) => e?.id),
        subject_types: { ...examDetail?.subject_type, learning_programs: [] },
        pdfFile: examDetail?.pdf && examDetail?.pdf[0]?.content,
        listQuestion: examDetail?.questions?.map((e, idx) => ({
          ...e,
          id: e?.id,
          ordinal: idx + 1,
          answer_type: e?.answer_type,
          contents: e?.questions,
          options: e?.options?.map((op, ix) => ({
            ...op,
            id: op?.id,
            ordinal: ix,
            correct: op?.correct ? 1 : 0,
            ...(e.answer_type !== quizAnswerType.CONSTRUCTED_RESPONSE && {
              answer_key: convertAnswerKey(op?.ordinal),
            }),
            contents: op?.items,
          })),
          solutions:
            getSolution(e?.id)?.items?.detail?.map((s, ix) => ({
              id: getSolution(e?.id)?.id,
              ordinal: ix + 1,
              content_type: s?.content_type,
              content: s?.content,
            })) || [],
          isSelfInput: true,
        })),
      });

      const firstOrdinalQuestionWithoutParent = examDetail?.questions
        ?.filter((question) => !question.deleted)
        ?.find((question) => question.answer_type !== quizAnswerType.NONE).ordinal;

      setOrdinalQuestion(firstOrdinalQuestionWithoutParent);
    }
  }, [examDetail]);

  useEffect(() => {
    if (isInputDocx) {
      eventTracking("screen_doc_input_type_view");
    } else {
      eventTracking("screen_pdf_input_type_view");
    }
  }, [isInputDocx]);

  useUnmount(() => {
    dispatch(commonActions.handleCleanQuizFile());
  });

  useEffect(() => {
    if (!isEdited) {
      dispatch(
        commonActions.handleGetTeacherInfo((teacherInfoResponse) => {
          const formattedTime = moment().format("DDMMyyyy hhmm");
          const subjectTitle = teacherInfoResponse?.subject_register?.title;
          const initialTitle = `Đề ${subjectTitle} ${formattedTime}`;
          setExamInfo((prev) => ({ ...prev, title: initialTitle }));
        })
      );
    }
  }, [isEdited]);

  return (
    <div className="main-content overflow-auto" ref={mainRef}>
      <div>
        {isViewOnly ? (
          <CreationInfoExamViewOnly examInfo={examInfo} />
        ) : (
          <CreationInfoExam
            examInfo={examInfo}
            setExamInfo={setExamInfo}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            isInputDocx={isInputDocx}
            checkEnableButtonCreate={checkEnableButtonCreate}
            // isViewOnly={isViewOnly}
            isEdited={isEdited}
            handleSubmit={handleCreateOrEditQuizPDFOrDocx}
          />
        )}
      </div>
      <div className="flex flex-row w-full">
        <div className="w-[312px] h-[calc(100vh-232px)] overflow-x-hidden overflow-y-auto scrollbar-thin border-r border-gray-200">
          <CreateQuizLeftSide
            listQuestion={listQuestion?.filter((question) => question?.ordinal)}
            checkEnableEditExam={checkEnableButtonEditExam}
            setOrdinalQuestion={setOrdinalQuestion}
          />
        </div>
        <div
          className={`flex justify-center items-center w-[calc(100%-624px)] h-[calc(100vh-232px)] overflow-auto scrollbar-thin border-r`}
        >
          {isLoading ? (
            <div className="">
              <ProgressSpinner
                style={{ width: "80px", height: "80px" }}
                pt={{
                  circle: {
                    className: "stroke-primary",
                  },
                }}
              />
            </div>
          ) : (!isInputDocx && !examInfo?.pdfFile) || (isInputDocx && isEmpty(examInfo?.listQuestion)) ? (
            <UploadFileComponent
              fileTypes={isInputDocx ? ["DOC", "DOCX"] : ["PDF"]}
              handleChange={(f) => (isInputDocx ? handleConvertDocxFile(f) : setExamInfo({ ...examInfo, pdfFile: f }))}
            />
          ) : isInputDocx ? (
            <div className="h-full">
              <QuestionsDocx
                listQuestion={listQuestion}
                setOrdinalQuestion={setOrdinalQuestion}
                setOpenDialogSolution={(ordinal) => {
                  setOrdinalQuestion(ordinal);
                  setOpenEditor(true);
                }}
                setOpenDialogConfirmDeleteFile={() => setOpenDialogConfirmDeleteFile(true)}
                isViewOnly={isViewOnly}
                isEdited={isEdited}
                isSelfInput
                handleOpenDialogEditQuestion={(qs) => {
                  setOpenDialogAddOrEditExam({ type: DIALOG_TYPE.EDIT, question: qs });
                  if (qs.answer_type !== quizAnswerType.NONE) {
                    setOrdinalQuestion(qs.ordinal);
                  }
                }}
                updateListQuestion={handleUpdateListQuestion}
              />
              {!isViewOnly && (
                <div
                  className={`w-full flex justify-center items-center ${
                    listQuestion?.length === 0 ? "h-full" : "py-10"
                  }`}
                >
                  <button
                    id="add-question"
                    className="w-[512px] h-[108px] rounded-[12px] border flex justify-center items-center flex-col"
                    onClick={() => setOpenDialogAddOrEditExam({ type: DIALOG_TYPE.CREATE })}
                  >
                    <div>
                      <AddQuestionSelfInput />
                    </div>
                    <span className="text-secondary pt-3 font-bold">
                      <FormattedMessage id="selfInput.add" />
                    </span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="h-full items-start w-full">
              <DisplayQuizPDF file={examInfo?.pdfFile as File} />
            </div>
          )}
        </div>
        <CreateQuizRightSide
          disabled={!checkEnableButtonEditExam}
          isViewOnly={isViewOnly}
          currentQuestion={currentQuestion}
          ordinalQuestionWithoutParent={
            listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) + 1
          }
          handleChooseAnswerKey={handleChooseAnswerKey}
          onChangeCorrectAnswer={handleChangeCorrectAnswer}
          setOpenEditor={setOpenEditor}
          mainRef={mainRef}
          disablePreviousQuestionButton={
            listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) <= 0
          }
          onClickPreviousQuestionButton={() => {
            const currentChildQuestionIndex = listQuestionWithoutParent.findIndex(
              (question) => question.ordinal === ordinalQuestion
            );
            const previousChildQuestion = listQuestionWithoutParent[currentChildQuestionIndex - 1];
            setOrdinalQuestion(previousChildQuestion.ordinal);
          }}
          disableNextQuestionButton={
            listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) >=
            listQuestionWithoutParent.length - 1
          }
          onClickNextQuestionButton={() => {
            const currentChildQuestionIndex = listQuestionWithoutParent.findIndex(
              (question) => question.ordinal === ordinalQuestion
            );
            const nextChildQuestion = listQuestionWithoutParent[currentChildQuestionIndex + 1];
            setOrdinalQuestion(nextChildQuestion.ordinal);
          }}
        />
      </div>
      {isNumber(openDialogAddOrEditExam.type) && (
        <DialogEditExam
          question={openDialogAddOrEditExam.question}
          listParentQuestion={listQuestion
            ?.filter((e) => !e?.deleted)
            ?.filter((question) => question.answer_type === quizAnswerType.NONE)
            ?.filter((question) => question.id !== openDialogAddOrEditExam.question?.id)}
          open={isNumber(openDialogAddOrEditExam.type)}
          onClose={() => {
            setOpenDialogAddOrEditExam({ type: null });
          }}
          onSubmit={(data, isCheckedParentQuestion, answerTypeChosen, parentQuestionId) => {
            if (openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE) {
              handleCreateNewQuestion(data, isCheckedParentQuestion, answerTypeChosen, parentQuestionId);
            } else {
              const oldQuestion = openDialogAddOrEditExam.question;
              let { updatedListQuestion, targetOrdinal } = getUpdatedListQuestion(
                listQuestion,
                oldQuestion,
                data,
                isCheckedParentQuestion,
                answerTypeChosen,
                parentQuestionId
              );
              if (targetOrdinal) setOrdinalQuestion(targetOrdinal);
              handleUpdateListQuestion(updatedListQuestion);
            }
          }}
          title={
            openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE
              ? intl.formatMessage({ id: "selfInput.addQuestion.title" })
              : intl.formatMessage({ id: "selfInput.editQuestion.title" })
          }
          isAdded={openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE}
          isSelfInput
          ordinalQuestion={getOrdinalQuestionForUI(listQuestion, openDialogAddOrEditExam.question)}
        />
      )}
      {openEditor && (
        <DialogTextEditor
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          onSubmit={(val: any) => {
            handleFillSolution(val);
          }}
          content={currentQuestion?.solutions.find((e: any) => e.content_type === "html")?.content}
          title="Nhập hướng dẫn giải"
        />
      )}
      {openDialogConfirmDeleteFile && (
        <AlertDialog
          open={openDialogConfirmDeleteFile}
          handleClose={() => setOpenDialogConfirmDeleteFile(false)}
          triggerFunc={() => handleDeleteFile()}
          notify={{ message: intl.formatMessage({ id: "manageExam.deleteFile" }) }}
        />
      )}

      {/* {notify.isOpenDialog && (
        <AlertDialog
          open={notify.isOpenDialog}
          handleClose={() => dispatch(commonActions.handleDeleteNotify())}
          notify={notify}
          isHideCancel
        />
      )} */}
    </div>
  );
}
