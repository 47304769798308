import React, { useEffect, useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import { ArrowDownIcon, CloseCircleIcon } from "../../../../assets";
import { Divider } from "primereact/divider";
import { FormattedMessage, useIntl } from "react-intl";
import InputText from "../lib/InputText";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../redux/action";
import { genSignature, getNonce, removeNullAttributeFromObject } from "../../utils/helper";
import { GENDER, emailRegex, phoneRegex } from "../../utils/constant";
import ButtonPrimary from "../button/ButtonPrimary";
import moment from "moment";
import { isEmpty } from "lodash";
import { Calendar } from "primereact/calendar";
import { TCommonState } from "../../utils/type";
import { eventTracking } from "../../../../firebase/firebaseConfig";

function DialogUpdateProfile({ isOpen, onClose }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { userInfos } = useSelector((state: { common: TCommonState }) => state.common);
  const [profile, setProfile] = useState<any>({});
  const [errorText, setErrorText] = useState<any>({});

  const checkEnableButtonUpdate = useMemo(() => {
    if (!isEmpty(profile?.phone) && !phoneRegex.test(profile?.phone)) return false;
    if (!isEmpty(profile?.email) && !emailRegex.test(profile.email)) return false;
    return !isEmpty(profile.name);
  }, [profile]);

  const GENDER_MAPPING = {
    [GENDER.UNDISCLOSED]: intl.formatMessage({ id: "updateProfile.gender.undisclosed" }),
    [GENDER.MALE]: intl.formatMessage({ id: "updateProfile.gender.male" }),
    [GENDER.FEMALE]: intl.formatMessage({ id: "updateProfile.gender.female" }),
    [GENDER.OTHER]: intl.formatMessage({ id: "updateProfile.gender.other" }),
  };

  const handleUpdateProfile = () => {
    const paramsPre = removeNullAttributeFromObject({
      name: profile?.name,
      nickname: profile?.nickname,
      phone: profile?.phone,
      gender: profile?.gender,
      birthday: profile?.dateOfBirth ? moment(profile?.dateOfBirth).format("YYYYMMDD") : undefined,
      email: profile?.email,
      timestamp: moment().unix(),
      nonce: getNonce(),
    });
    const params = {
      ...paramsPre,
      signature: genSignature(paramsPre),
    };
    dispatch(
      commonActions.handleUpdateProfile(params, () => {
        dispatch(commonActions.handleSetOpenDialogUpdateProfile(false));
        dispatch(commonActions.handleSetOpenDialogRegisterTeacher(true));
        dispatch(commonActions.handleGetUserInfo({ member_hid: userInfos?.member?.pub_id }));
      })
    );
  };

  useEffect(() => {
    eventTracking("dl_update_account_info_view");
  }, []);

  return (
    <Dialog
      className={`w-[560px] min-h-[400px]`}
      maskClassName="items-start md:items-center pt-4 md:pt-0"
      visible={isOpen}
      header={
        <span>
          <FormattedMessage id="updateProfile.title" />
        </span>
      }
      footer={
        <>
          <Divider />
          <div className="text-left font-[400] text-[12px] mb-6">
            <span className="text-red-500">*</span> <FormattedMessage id="common.noteRequired" />
          </div>
          <div className="flex gap-6 justify-center">
            <ButtonPrimary
              disabled={!checkEnableButtonUpdate}
              rounded
              className="w-[343px] h-[44px] flex justify-center font-bold"
              onClick={() => eventTracking("select_update_dl_info", undefined, () => handleUpdateProfile())}
            >
              <FormattedMessage id="common.update" />
            </ButtonPrimary>
          </div>
        </>
      }
      onHide={onClose}
      draggable={false}
      closable={false}
      closeIcon={<CloseCircleIcon />}
      focusOnShow={false}
      pt={{
        root: {
          className: "rounded-[20px] overflow-hidden",
        },
        headerTitle: {
          className: "text-center text-[24px] font-semibold text-gray-900",
        },
        content: {
          className: "py-0",
        },
        footer: {
          className: "py-6",
        },
      }}
    >
      <div className="flex flex-col gap-6">
        <InputText
          id="name"
          placeholder={intl.formatMessage({
            id: "updateProfile.name.placeholder",
          })}
          value={profile?.name}
          onChange={(e) => {
            if (e?.target?.value.length <= 64) {
              setProfile({ ...profile, name: e?.target?.value });
            } else {
              setErrorText({
                ...errorText,
                name: intl.formatMessage({ id: "account.fields.name.errorMessage" }),
              });
            }
          }}
          onBlur={(event) => {
            setErrorText({
              ...errorText,
              name:
                event?.target?.value.length <= 64
                  ? undefined
                  : intl.formatMessage({ id: "account.fields.name.errorMessage" }),
            });
          }}
          label={intl.formatMessage({ id: "updateProfile.name" })}
          className="rounded-[30px] px-4"
          isError={!!errorText?.name}
          messageError={errorText?.name}
          isRequired
        />
        <InputText
          id="nickname"
          placeholder={intl.formatMessage({
            id: "updateProfile.nickname.placeholder",
          })}
          value={profile?.nickname}
          onChange={(e) => {
            if (e?.target?.value.length <= 64) {
              setProfile({ ...profile, nickname: e?.target?.value });
            } else {
              setErrorText({
                ...errorText,
                nickname: intl.formatMessage({ id: "account.fields.nickname.errorMessage" }),
              });
            }
          }}
          onBlur={(event) => {
            setErrorText({
              ...errorText,
              nickname:
                event?.target?.value.length <= 64
                  ? undefined
                  : intl.formatMessage({ id: "account.fields.name.errorMessage" }),
            });
          }}
          label={intl.formatMessage({ id: "updateProfile.nickname" })}
          className="rounded-[30px] px-4"
          isError={!!errorText?.nickname}
          messageError={errorText?.nickname}
        />
        <div className="flex flex-col gap-2 relative mt-2">
          <label
            htmlFor="dateOfBirth"
            className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5"
          >
            <FormattedMessage id="updateProfile.dateOfBirth" />
          </label>
          <Calendar
            id="dateOfBirth"
            value={profile?.dateOfBirth}
            onChange={(e) => setProfile({ ...profile, dateOfBirth: e.value })}
            placeholder={`${moment().format("DD/MM/yyyy")}`}
            maxDate={new Date()}
            pt={{
              input: {
                root: { className: "px-4 rounded-[30px]" },
              },
            }}
            panelClassName="[&_.p-datepicker-calendar-container]:my-0 [&_.p-datepicker-calendar]:my-0 [&_tr_td]:p-0"
          />
        </div>

        <div className="flex w-full flex-col relative mt-2">
          <label htmlFor="gender" className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-50 p-0.5">
            <FormattedMessage id="updateProfile.gender" />
          </label>
          <Dropdown
            id="gender"
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={profile?.gender}
            options={Object.keys(GENDER_MAPPING)?.map((e) => ({ label: GENDER_MAPPING[e], value: e }))}
            onChange={(e) => setProfile({ ...profile, gender: e?.value })}
            placeholder={intl.formatMessage({
              id: "updateProfile.gender.placeholder",
            })}
            pt={{
              input: {
                className: "pr-0",
              },
              root: {
                className: "px-2 rounded-[30px]",
              },
            }}
          />
        </div>
        <InputText
          id="phone"
          placeholder={intl.formatMessage({
            id: "updateProfile.phone.placeholder",
          })}
          value={profile?.phone}
          onChange={(e) => {
            setProfile({ ...profile, phone: e?.target?.value });
          }}
          onBlur={(event) => {
            setErrorText({
              ...errorText,
              phone: phoneRegex.test(event.target.value)
                ? undefined
                : event.target.value?.length === 0
                ? undefined
                : intl.formatMessage({ id: "account.fields.phone.errorMessage" }),
            });
          }}
          label={intl.formatMessage({ id: "updateProfile.phone" })}
          className="rounded-[30px] px-4"
          isError={!!errorText?.phone}
          messageError={errorText?.phone}
        />
        <InputText
          id="email"
          placeholder={intl.formatMessage({
            id: "updateProfile.email.placeholder",
          })}
          value={profile?.email}
          onChange={(e) => {
            setProfile({ ...profile, email: e?.target?.value });
          }}
          onBlur={(event) => {
            setErrorText({
              ...errorText,
              email:
                event.target.value?.length === 0 || emailRegex.test(event.target.value)
                  ? undefined
                  : intl.formatMessage({ id: "account.fields.email.errorMessage" }),
            });
          }}
          label={intl.formatMessage({ id: "updateProfile.email" })}
          className="rounded-[30px] px-4"
          isError={!!errorText?.email}
          messageError={errorText?.email}
        />
      </div>
    </Dialog>
  );
}
export default React.memo(DialogUpdateProfile);
