import React from "react";
import KnobProgress from "./KnobProgress";

export default function DashboardRoomProgress({ value1, value2 }) {
  return (
    <div className="relative">
      <KnobProgress value={0} text="" circleRatio={1} className="w-[195px]" strokeWidth={15} strokeLinecap="butt" />
      <KnobProgress
        value={value1}
        text=""
        circleRatio={1}
        className="w-[205px] absolute top-[-5px] left-[-5px] opacity-80"
        strokeWidth={20}
        pathColor="#B455FF"
        shadowColor="#6204AF75"
        trailColor="white"
        strokeLinecap="butt"
      />
      <KnobProgress
        value={value2}
        text=""
        circleRatio={1}
        className="w-[217px] absolute top-[-11px] left-[-11px] mix-blend-multiply"
        trailColor="white"
        strokeWidth={25}
        shadowColor="#004A8075"
        pathColor="#0596FF"
        strokeLinecap="butt"
      />
    </div>
  );
}
