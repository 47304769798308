import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import NoData from "../../../components/common/components/NoData";
import ButtonGhost from "../../../components/common/components/button/ButtonGhost";
import ButtonPrimary from "../../../components/common/components/button/ButtonPrimary";
import AddOrEditRoomDialog from "../../../components/common/components/dialog/AddOrEditRoomDialog";
import confirmDialog from "../../../components/common/components/dialog/confirmDialog";
import { commonActions } from "../../../components/common/redux/action";
import { genSignature, getNonce, stringToSlug } from "../../../components/common/utils/helper";
import ActionHistoryDialog from "../../../components/manage_student/components/ActionHistoryDialog";
import AddMembersToGroupDialog from "../../../components/manage_student/components/AddMembersToGroupDialog";
import GroupDetailHeader from "../../../components/manage_student/components/GroupDetailHeader";
import InviteMemberDialog from "../../../components/manage_student/components/InviteMemberDialog";
import MembersTable from "../../../components/manage_student/components/MembersTable";
import { manageStudentActions } from "../../../components/manage_student/redux/action";
import {
  COLUMN_KEY_MEMBERS_TABLE,
  ERROR_CODE_NOT_ACCEPTABLE_GROUP_STATUS_ADD_MEMBER_CLOSED,
  ERROR_CODE_NOT_ACCEPTABLE_MEMBER_DELETED_GROUP,
  ERROR_CODE_NOT_FOUND_GROUP,
} from "../../../components/manage_student/utils/constant";
import { EGroupStatusOption } from "../../../types/group";
import { eventTracking } from "../../../firebase/firebaseConfig";

export default function GroupDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { groupHid } = useParams();

  const { groupDetail, userInfos } = useSelector((state: { manageStudent; common }) => ({
    ...state.manageStudent,
    ...state.common,
  }));

  const [isLoadingStatusAddRoom, setIsLoadingStatusAddRoom] = useState<boolean>(false);
  const [isLoadingStatusAddMember, setIsLoadingStatusAddMember] = useState<boolean>(false);
  const [isLoadingGetGroupDetail, setIsLoadingGetGroupDetail] = useState<boolean>(false);
  const [isOpenInviteMemberDialog, setIsOpenInviteMemberDialog] = useState<boolean>(false);
  const [isOpenAddMembersToGroupDialog, setIsOpenAddMembersToGroupDialog] = useState<boolean>(false);
  const [isOpenActionHistoryDialog, setIsOpenActionHistoryDialog] = useState<boolean>(false);
  const [isOpenAddOrEditRoomDialog, setIsOpenAddOrEditRoomDialog] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const isSearching = searchKeyword.length > 0;

  const filteredMembers = useMemo(() => {
    let filteredMembers = groupDetail.members;

    if (isSearching) {
      filteredMembers = filteredMembers.filter(
        (member) =>
          member.member_hid === searchKeyword || stringToSlug(member.name)?.includes(stringToSlug(searchKeyword))
      );
    }

    return filteredMembers || [];
  }, [searchKeyword, groupDetail.members]);

  const handleChangeStatusAddRoom = () => {
    const newStatusAddRoom =
      groupDetail.status_add_room === EGroupStatusOption.OPEN ? EGroupStatusOption.CLOSED : EGroupStatusOption.OPEN;
    const dataPre = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      status_add_room: newStatusAddRoom,
    };
    const data = {
      ...dataPre,
      signature: genSignature({ ...dataPre, group_hid: groupHid }),
    };
    setIsLoadingStatusAddRoom(true);
    dispatch(
      manageStudentActions.updateGroup({ group_hid: groupHid, data }, () => {
        dispatch(
          manageStudentActions.getGroupDetail({ groupHid }, undefined, undefined, () => {
            setIsLoadingStatusAddRoom(false);
          })
        );
      })
    );
  };

  const handleChangeStatusAddMember = () => {
    const newStatusAddRoom =
      groupDetail.status_add_member === EGroupStatusOption.OPEN ? EGroupStatusOption.CLOSED : EGroupStatusOption.OPEN;
    const dataPre = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      status_add_member: newStatusAddRoom,
    };
    const data = {
      ...dataPre,
      signature: genSignature({ ...dataPre, group_hid: groupHid }),
    };
    setIsLoadingStatusAddMember(true);
    dispatch(
      manageStudentActions.updateGroup({ group_hid: groupHid, data }, () => {
        dispatch(
          manageStudentActions.getGroupDetail({ groupHid }, undefined, undefined, () => {
            setIsLoadingStatusAddMember(false);
          })
        );
      })
    );
  };

  const handleSubmitAddMemberToGroup = (formValues) => {
    const dataPostPre = {
      nonce: getNonce(),
      timestamp: moment().unix(),
      member_hids: formValues.referenceIds.join(","),
    };
    const dataPost = {
      ...dataPostPre,
      signature: genSignature({ ...dataPostPre, group_hid: groupHid }),
    };
    dispatch(
      manageStudentActions.addMembersToGroup(
        { groupHid, data: dataPost },
        () => {
          setIsOpenAddMembersToGroupDialog(false);
          dispatch(manageStudentActions.getGroupDetail({ groupHid }));
        },
        (error) => {
          switch (error.code) {
            case ERROR_CODE_NOT_FOUND_GROUP:
              navigate("/quan-ly-hoc-sinh/lop-hoc");
              break;
            case ERROR_CODE_NOT_ACCEPTABLE_GROUP_STATUS_ADD_MEMBER_CLOSED:
              setIsOpenAddMembersToGroupDialog(false);
              dispatch(manageStudentActions.getGroupDetail({ groupHid }));
              break;
            default:
              break;
          }
        }
      )
    );
  };

  const handleClickViewMember = (memberHid: string) => {
    navigate(`/quan-ly-hoc-sinh/lop-hoc/${groupHid}/${memberHid}`);
  };

  const handleClickDeleteMember = (memberHid: string) => {
    confirmDialog({
      header: intl.formatMessage({ id: "confirmDialog.title" }),
      message: intl.formatMessage({ id: "confirmDialog.deleteMemberDescription" }),
      accept: () => {
        const dataPostPre = {
          nonce: getNonce(),
          timestamp: moment().unix(),
          member_hids: memberHid,
        };
        const dataPost = {
          ...dataPostPre,
          signature: genSignature({ ...dataPostPre, group_hid: groupHid }),
        };
        dispatch(
          manageStudentActions.removeMemberOfGroup(
            { groupHid, data: dataPost },
            () => {
              dispatch(manageStudentActions.getGroupDetail({ groupHid }));
            },
            (error) => {
              switch (error.code) {
                case ERROR_CODE_NOT_FOUND_GROUP:
                  navigate("/quan-ly-hoc-sinh/lop-hoc");
                  break;
                case ERROR_CODE_NOT_ACCEPTABLE_MEMBER_DELETED_GROUP:
                  dispatch(manageStudentActions.getGroupDetail({ groupHid }));
                  break;
                default:
                  break;
              }
            }
          )
        );
      },
      reject: () => {},
      footer: (options) => {
        return (
          <div className="flex justify-end gap-4">
            <ButtonPrimary label={intl.formatMessage({ id: "common.cancel" })} onClick={options.reject} />
            <ButtonGhost label={intl.formatMessage({ id: "common.delete" })} onClick={options.accept} />
          </div>
        );
      },
    });
  };

  useLayoutEffect(() => {
    if (userInfos.member?.pub_id) {
      setIsLoadingGetGroupDetail(true);
      dispatch(
        manageStudentActions.getGroupDetail(
          { groupHid },
          undefined,
          (error) => {
            switch (error.code) {
              case ERROR_CODE_NOT_FOUND_GROUP:
                navigate("/quan-ly-hoc-sinh/lop-hoc");
                break;
              default:
                break;
            }
          },
          () => setIsLoadingGetGroupDetail(false)
        )
      );
      dispatch(commonActions.getRoomCreationInfo());
      dispatch(commonActions.getAllFolderAndFile());
    }
  }, []);

  useEffect(() => {
    eventTracking("screen_detail_group_view");
  }, []);

  return (
    <div className="main-content">
      {isLoadingGetGroupDetail ? (
        <div className="w-full pt-[200px] flex items-center justify-center">
          <ProgressSpinner
            strokeWidth="2"
            animationDuration="2s"
            pt={{
              circle: {
                className: "stroke-primary",
              },
            }}
          />
        </div>
      ) : (
        <>
          <GroupDetailHeader
            groupDetail={groupDetail}
            isLoadingStatusAddRoom={isLoadingStatusAddRoom}
            isLoadingStatusAddMember={isLoadingStatusAddMember}
            onChangeStatusAddRoom={handleChangeStatusAddRoom}
            onChangeStatusAddMember={handleChangeStatusAddMember}
            onClickInviteMember={() => setIsOpenInviteMemberDialog(true)}
            onClickAddMembers={() => setIsOpenAddMembersToGroupDialog(true)}
            onClickViewActionHistory={() => setIsOpenActionHistoryDialog(true)}
            onClickCreateRoom={() => setIsOpenAddOrEditRoomDialog(true)}
            onSearch={(searchKeyword) => setSearchKeyword(searchKeyword)}
          />
          {filteredMembers.length === 0 ? (
            !isSearching ? (
              <div className="w-full pt-[100px] flex items-center justify-center">
                <NoData message={intl.formatMessage({ id: "noStudentData" })} />
              </div>
            ) : (
              <div className="w-full pt-[100px] flex items-center justify-center">
                <NoData message={intl.formatMessage({ id: "common.notFoundData" })} />
              </div>
            )
          ) : (
            <MembersTable
              members={filteredMembers}
              columnKeysDisplay={[
                COLUMN_KEY_MEMBERS_TABLE.NO,
                COLUMN_KEY_MEMBERS_TABLE.NAME,
                COLUMN_KEY_MEMBERS_TABLE.MEMBER_HID,
                COLUMN_KEY_MEMBERS_TABLE.SCORE_AVG,
                COLUMN_KEY_MEMBERS_TABLE.RANKING,
                COLUMN_KEY_MEMBERS_TABLE.NUM_OF_ROOM_SUBMITTED,
                COLUMN_KEY_MEMBERS_TABLE.DELETE,
              ]}
              onClickViewMember={(memberHid: string) => {
                eventTracking("select_a_student_in_group_item");
                handleClickViewMember(memberHid);
              }}
              onClickDeleteMember={handleClickDeleteMember}
            />
          )}
        </>
      )}
      <InviteMemberDialog
        isOpen={isOpenInviteMemberDialog}
        inviteLink={groupDetail.direct_link_invite}
        onClose={() => setIsOpenInviteMemberDialog(false)}
      />
      {isOpenAddMembersToGroupDialog && (
        <AddMembersToGroupDialog
          isOpen={isOpenAddMembersToGroupDialog}
          onClose={() => setIsOpenAddMembersToGroupDialog(false)}
          onSubmit={handleSubmitAddMemberToGroup}
        />
      )}
      {isOpenActionHistoryDialog && (
        <ActionHistoryDialog
          groupHid={groupHid as string}
          isOpen={isOpenActionHistoryDialog}
          onClose={() => setIsOpenActionHistoryDialog(false)}
        />
      )}
      {isOpenAddOrEditRoomDialog && (
        <AddOrEditRoomDialog
          isOpen={isOpenAddOrEditRoomDialog}
          groupDetail={groupDetail}
          onClose={() => setIsOpenAddOrEditRoomDialog(false)}
        />
      )}
    </div>
  );
}
