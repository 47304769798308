import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NoData from "../../../components/common/components/NoData";
import MemberResultTable from "../../../components/manage_student/components/MemberResultTable";
import { manageStudentActions } from "../../../components/manage_student/redux/action";
import { COLUMN_KEY_MEMBER_RESULT_TABLE } from "../../../components/manage_student/utils/constant";
import { eventTracking } from "../../../firebase/firebaseConfig";

export default function MemberDetail() {
  const dispatch = useDispatch();
  const { memberHid } = useParams();
  const intl = useIntl();

  const { memberResult, userInfos } = useSelector((state: { manageStudent; common }) => ({
    userInfos: state.common.userInfos,
    memberResult: state.manageStudent.memberResult,
  }));

  const [isLoadingGetMemberResult, setIsLoadingGetMemberResult] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (userInfos.member?.pub_id) {
      setIsLoadingGetMemberResult(true);
      dispatch(
        manageStudentActions.getMemberResult({ memberHid }, () => {
          setIsLoadingGetMemberResult(false);
        })
      );
    }
  }, []);

  useEffect(() => {
    eventTracking("screen_detail_student_view");
  }, []);

  return (
    <div className="main-content">
      {isLoadingGetMemberResult ? (
        <div className="w-full pt-[200px] flex items-center justify-center">
          <ProgressSpinner
            strokeWidth="2"
            animationDuration="2s"
            pt={{
              circle: {
                className: "stroke-primary",
              },
            }}
          />
        </div>
      ) : memberResult.items.length === 0 ? (
        <div className="w-full pt-[100px] flex items-center justify-center">
          <NoData message={intl.formatMessage({ id: "common.nodata" })} />
        </div>
      ) : (
        <MemberResultTable
          memberResult={memberResult.items}
          columnKeysDisplay={[
            COLUMN_KEY_MEMBER_RESULT_TABLE.NO,
            COLUMN_KEY_MEMBER_RESULT_TABLE.TITLE_ROOM,
            COLUMN_KEY_MEMBER_RESULT_TABLE.TITLE_GROUP,
            COLUMN_KEY_MEMBER_RESULT_TABLE.SCORE,
            COLUMN_KEY_MEMBER_RESULT_TABLE.RANKING,
            COLUMN_KEY_MEMBER_RESULT_TABLE.SPENT_TIME,
          ]}
        />
      )}
    </div>
  );
}
