import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { AddExamIcon } from "../../../assets";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import DialogChooseTypeAddExam from "../../manage_exam/components/DialogChooseTypeAddExam";
import { eventTracking } from "../../../firebase/firebaseConfig";

type TManageDocumentHeaderProps = {
  isHideAddExamButton: boolean;
  onSearch: (keyword: string) => void;
};

export default function ManageDocumentHeader({ isHideAddExamButton, onSearch }: TManageDocumentHeaderProps) {
  const [inputValue, setInputValue] = useState<string>("");
  const [openDialogCreateExam, setOpenDialogCreateExam] = useState<boolean>(false);

  const intl = useIntl();

  const handleSearch = () => {
    onSearch(inputValue);
  };

  return (
    <>
      <div
        className={`flex ${
          isHideAddExamButton ? "justify-end" : "justify-between"
        } px-10 py-6 border-t border-b border-gray200`}
      >
        {!isHideAddExamButton && (
          <ButtonPrimary
            icon={<AddExamIcon />}
            label={intl.formatMessage({ id: "createExam.button" })}
            onClick={() => {
              eventTracking("select_create_file_button");
              setOpenDialogCreateExam(true);
            }}
          />
        )}
        <span className="p-input-icon-left">
          <i className="pi pi-search hover:cursor-pointer" onClick={handleSearch} />
          <InputText
            placeholder={intl.formatMessage({ id: "common.search.placeholder" })}
            className="w-[270px] h-[44px] border border-gray200 rounded-lg"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
        </span>
      </div>
      {openDialogCreateExam && (
        <DialogChooseTypeAddExam isOpen={openDialogCreateExam} onClose={() => setOpenDialogCreateExam(false)} />
      )}
    </>
  );
}
