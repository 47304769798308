import { InputText } from "primereact/inputtext";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ButtonGhost from "../button/ButtonGhost";
import ButtonPrimary from "../button/ButtonPrimary";
import Dialog from "./Dialog";

type TInputTimeQuizDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (value: number) => void;
};

export default function InputTimeQuizDialog({ isOpen, onClose, onSubmit }: TInputTimeQuizDialogProps) {
  const intl = useIntl();

  const [value, setValue] = useState<string>("");

  const isDisableButtonSubmit = useMemo(() => value.trim() === "" || Number(value.trim()) <= 0, [value]);

  const handleSubmit = () => {
    onSubmit(Number(value.trim()));
    onClose();
  };

  const actions = (
    <div className="flex justify-end gap-4">
      <ButtonGhost label={intl.formatMessage({ id: "common.cancel" })} onClick={onClose} />
      <ButtonPrimary
        label={intl.formatMessage({ id: "common.confirm" })}
        onClick={handleSubmit}
        disabled={isDisableButtonSubmit}
      />
    </div>
  );

  return (
    <Dialog
      isOpen={isOpen}
      title={intl.formatMessage({ id: "inputTimeQuizDialog.title" })}
      actions={actions}
      onClose={onClose}
    >
      <InputText
        keyfilter="int"
        placeholder={intl.formatMessage({
          id: "inputTimeQuizDialog.textField.placeholder",
        })}
        className="w-full"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Dialog>
  );
}
