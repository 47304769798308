import React, { useEffect, useMemo, useState } from "react";

import { isEmpty, isNumber } from "lodash";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import TagManager from "react-gtm-module";
import { FormattedMessage, useIntl } from "react-intl";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowDownIcon, CloseCircleIcon } from "../../../../assets";
import { eventTracking } from "../../../../firebase/firebaseConfig";
import { CONFIG_SERVER } from "../../../../utils/constant/env";
import { getUserInfos } from "../../../auth/utils/authAPI";
import { commonActions } from "../../redux/action";
import { emailRegex, phoneRegex } from "../../utils/constant";
import {
  distinctArrayObject,
  genSignature,
  getNonce,
  isMobileOrTabletDevice,
  removeNullAttributeFromObject,
} from "../../utils/helper";
import { TCommonState } from "../../utils/type";
import ButtonPrimary from "../button/ButtonPrimary";
import InputText from "../lib/InputText";

export default function DialogRegisterTeacher({ isOpen, onClose }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();

  const [registerInfo, setRegisterInfo] = useState<any>({ grades: [] });
  const [errorText, setErrorText] = useState<any>({});
  const { gradeSubjects } = useSelector((state: { common: TCommonState }) => state?.common);

  const gradeChoices = useMemo(
    () => gradeSubjects.filter((e) => !e?.parent_id)?.map((e) => ({ value: e?.id, label: e?.title })),
    [gradeSubjects]
  );

  const subjectFromGradeIds = useMemo(() => {
    let countOccurrences: any = {};
    let subjectIds: number[] = [];

    const allSubjectsOfGrades = gradeSubjects.filter(
      (e) => registerInfo?.grades.map((e) => e?.value)?.includes(e?.parent_id)
    );
    allSubjectsOfGrades.forEach((e) => {
      if (countOccurrences[e?.id]) {
        countOccurrences[e?.id] += 1;
      } else {
        countOccurrences[e?.id] = 1;
      }
    });
    Object.keys(countOccurrences).forEach((e) => {
      if (countOccurrences[e] === registerInfo?.grades?.length) {
        subjectIds.push(Number(e));
      }
    });
    return distinctArrayObject(
      allSubjectsOfGrades.filter((e) => subjectIds?.includes(e?.id)),
      "id"
    );
  }, [registerInfo, gradeSubjects]);

  const checkEnableButtonRegister = useMemo(() => {
    return (
      !isEmpty(registerInfo?.phone) &&
      phoneRegex.test(registerInfo?.phone) &&
      !isEmpty(registerInfo?.grades) &&
      isNumber(registerInfo?.subject)
    );
  }, [registerInfo]);

  const handleRegisterTeacher = () => {
    const paramsGenSignature = removeNullAttributeFromObject({
      full_name: registerInfo.name && registerInfo.name !== "" ? registerInfo.name?.trim() : undefined,
      phone: registerInfo?.phone.trim(),
      email: registerInfo.email && registerInfo.email !== "" ? registerInfo.email?.trim() : undefined,
      school: registerInfo.school && registerInfo.school !== "" ? registerInfo.school?.trim() : undefined,
      grades_subjects_id: JSON.stringify(
        registerInfo.grades.map((grade) => [grade.value, registerInfo.subject])
      ).replace(/,/g, ", "),
      nonce: getNonce(),
      timestamp: moment().unix(),
    });

    const params = {
      ...paramsGenSignature,
      signature: genSignature(paramsGenSignature, undefined, undefined, ["grades_subjects_id"]),
    };
    dispatch(
      commonActions.handleRegisterTeacher(params, async () => {
        dispatch(commonActions.handleSetOpenDialogNotifyRegisterTeacher(true));
        // (window as any)?.dataLayer?.push({
        //   event: "register_user",
        // });
        TagManager.dataLayer({
          dataLayer: { event: "register_user" },
        });
        // dispatch(commonActions.addUserInfos({ ...userInfos, status: ACCOUNT_TEACHER_STATUS.REGISTER }));
        if (isMobileOrTabletDevice()) navigate("/su-dung-desktop");
        else {
          const params: any = {
            timestamp: moment().unix(),
            nonce: getNonce(),
            platform: CONFIG_SERVER.platform,
            app_version: process.env.REACT_APP_VERSION,
            is_web_teacher: 1,
          };
          const dataPost = { ...params, signature: genSignature(params) };
          const res = await getUserInfos(store, dataPost);
          dispatch(commonActions.addUserInfos({ ...res?.data?.data }));
          navigate("/");
        }
        onClose();
      })
    );
  };

  const handleCancelRegisterTeacher = () => {
    dispatch(commonActions.handleLogOut({}));
    onClose();
  };

  useEffect(() => {
    if (gradeSubjects?.length === 0) {
      dispatch(commonActions.handleGetGradeAndSubjects());
    }
  }, []);

  useEffect(() => {
    eventTracking("dl_register_teacher_account_view");
  }, []);

  return (
    <Dialog
      className={`w-[560px] min-h-[560px]`}
      maskClassName="items-start md:items-center pt-4 md:pt-0"
      visible={isOpen}
      header={
        <div>
          <span className="text-[16px] text-gray-700 font-[400] text-left flex">
            <FormattedMessage id="registerTeacher.header.note" />
          </span>
          <Divider />
          <span>
            <FormattedMessage id="registerTeacher.header.title" />
          </span>
        </div>
      }
      footer={
        <>
          <Divider />
          <div className="flex flex-col text-left font-[400] text-[12px] mb-6">
            <div>
              <span className="text-red-500">*</span> <FormattedMessage id="common.noteRequired" />
            </div>
            <div>
              <span className="text-red-500">*</span> <FormattedMessage id="dialogRegister.note" />
            </div>
          </div>
          <div className="flex gap-6 justify-center">
            <Button
              text
              severity="warning"
              className="font-bold w-[145px] rounded-[30px] flex justify-center"
              onClick={handleCancelRegisterTeacher}
            >
              Huỷ
            </Button>
            <ButtonPrimary
              disabled={!checkEnableButtonRegister}
              rounded
              className="w-[145px] h-[44px] flex justify-center font-bold"
              onClick={() => eventTracking("select_register_teacher_account", undefined, () => handleRegisterTeacher())}
            >
              <FormattedMessage id="registerTeacher.register" />
            </ButtonPrimary>
          </div>
        </>
      }
      onHide={handleCancelRegisterTeacher}
      draggable={false}
      closable={false}
      closeIcon={<CloseCircleIcon />}
      focusOnShow={false}
      pt={{
        root: {
          className: "rounded-[20px] overflow-hidden",
        },
        headerTitle: {
          className: "text-center text-[24px] font-semibold text-gray-900",
        },
        content: {
          className: "py-0",
        },
        footer: {
          className: "py-6",
        },
      }}
    >
      <div className="flex flex-col gap-6">
        <InputText
          id="name"
          placeholder={intl.formatMessage({
            id: "registerTeacher.name.placeholder",
          })}
          value={registerInfo?.name}
          onChange={(e) => {
            if (e?.target?.value?.length <= 64) {
              setRegisterInfo({ ...registerInfo, name: e?.target?.value });
            } else {
              setErrorText({
                ...errorText,
                name: intl.formatMessage({ id: "account.fields.name.errorMessage" }),
              });
            }
          }}
          onBlur={(event) => {
            setErrorText({
              ...errorText,
              name:
                event?.target?.value?.length <= 64
                  ? undefined
                  : intl.formatMessage({ id: "account.fields.name.errorMessage" }),
            });
          }}
          label={intl.formatMessage({ id: "registerTeacher.name.label" })}
          className="rounded-[30px] px-4"
          isError={!!errorText?.name}
          messageError={errorText?.name}
        />
        <InputText
          id="phone"
          isRequired
          placeholder={intl.formatMessage({
            id: "registerTeacher.phone.placeholder",
          })}
          value={registerInfo?.phone}
          onChange={(e) => {
            setRegisterInfo({ ...registerInfo, phone: e?.target?.value });
          }}
          onBlur={(event) => {
            setErrorText({
              ...errorText,
              phone: phoneRegex.test(event.target.value)
                ? undefined
                : event.target.value?.length === 0
                ? intl.formatMessage({ id: "account.fields.phone.emptyMessage" })
                : intl.formatMessage({ id: "account.fields.phone.errorMessage" }),
            });
          }}
          label={intl.formatMessage({ id: "registerTeacher.phone.label" })}
          className="rounded-[30px] px-4"
          isError={!!errorText?.phone}
          messageError={errorText?.phone}
        />
        <InputText
          id="email"
          placeholder={intl.formatMessage({
            id: "registerTeacher.email.placeholder",
          })}
          value={registerInfo?.email}
          onChange={(e) => {
            setRegisterInfo({ ...registerInfo, email: e?.target?.value });
          }}
          onBlur={(event) => {
            setErrorText({
              ...errorText,
              email:
                event.target.value?.length === 0 || emailRegex.test(event.target.value)
                  ? undefined
                  : intl.formatMessage({ id: "account.fields.email.errorMessage" }),
            });
          }}
          label={intl.formatMessage({ id: "registerTeacher.email.label" })}
          className="rounded-[30px] px-4"
          isError={!!errorText?.email}
          messageError={errorText?.email}
        />
        <InputText
          id="school"
          placeholder={intl.formatMessage({
            id: "registerTeacher.school.placeholder",
          })}
          value={registerInfo?.school}
          onChange={(e) => {
            if (e?.target?.value.length <= 250) {
              setRegisterInfo({ ...registerInfo, school: e?.target?.value });
            } else {
              setErrorText({
                ...errorText,
                school: intl.formatMessage({ id: "account.fields.school.errorMessage" }),
              });
            }
          }}
          onBlur={(event) => {
            setErrorText({
              ...errorText,
              school:
                event?.target?.value.length <= 250
                  ? undefined
                  : intl.formatMessage({ id: "account.fields.school.errorMessage" }),
            });
          }}
          label={intl.formatMessage({ id: "registerTeacher.school.label" })}
          className="rounded-[30px] px-4"
          isError={!!errorText?.school}
          messageError={errorText?.school}
        />
        <div className="flex w-full flex-col relative">
          <label className="text-[12px] text-gray-700 absolute -top-2.5 left-4  bg-white z-50 p-0.5">
            <FormattedMessage id="accountInfo.grade" /> <span className="text-error"> *</span>
          </label>
          <MultiSelect
            hasSelectAll={false}
            options={gradeChoices}
            disableSearch
            value={registerInfo?.grades}
            onChange={(e) => {
              if (e?.length <= 3) {
                setRegisterInfo({ ...registerInfo, grades: e, subject: null });
              } else {
                setErrorText({ ...errorText, grade: intl.formatMessage({ id: "account.fields.grade.errorMessage" }) });
              }
            }}
            labelledBy=""
            className="z-[2] override-multiselect"
            ArrowRenderer={() => <ArrowDownIcon stroke="#475467" />}
            ClearSelectedIcon={null}
            overrideStrings={{
              selectSomeItems: " ",
            }}
          />
        </div>
        <div className="flex w-full flex-col relative">
          <label className="text-[12px] text-gray-700 absolute -top-2.5 left-4 bg-white z-[1] p-0.5">
            <FormattedMessage id="accountInfo.subject" /> <span className="text-error"> *</span>
          </label>
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={registerInfo?.subject}
            onChange={(e) => setRegisterInfo({ ...registerInfo, subject: e?.value })}
            options={subjectFromGradeIds?.map((e: any) => ({
              label: e?.title,
              value: e?.id,
            }))}
            pt={{
              input: {
                className: "pr-0",
              },
              root: {
                className: "px-2 rounded-[30px]",
              },
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}
