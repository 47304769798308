import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import { TGroupResponse } from "../../../types/group";
import NoData from "../../common/components/NoData";
import Paging from "../../common/components/Paging";

const ITEMS_PER_PAGE = 15;

type TGroupListProps = {
  groupList: TGroupResponse[];
  renderItem: (groupDetail: TGroupResponse) => ReactNode;
};

export default function GroupList({ groupList, renderItem }: TGroupListProps) {
  const intl = useIntl();

  const { hideLeftSide } = useSelector((state: { common }) => state?.common);

  const [page, setPage] = useState<number>(1);

  const maxPage = useMemo(() => {
    return Math.ceil(groupList.length / ITEMS_PER_PAGE);
  }, [groupList]);

  const isShowPaging = maxPage > 1;

  useUpdateEffect(() => {
    if (page > maxPage && maxPage > 0) {
      setPage(maxPage);
    }
  }, [maxPage]);

  useEffect(() => {
    document.querySelector(".main-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [page]);

  return (
    <div className="px-10 py-8">
      {groupList.length > 0 ? (
        <div className={`grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 ${isShowPaging && "pb-[78px]"}`}>
          {groupList.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE).map(renderItem)}
        </div>
      ) : (
        <div className="w-full pt-[100px] flex items-center justify-center">
          <NoData message={intl.formatMessage({ id: "noGroupData" })} />
        </div>
      )}
      {isShowPaging && (
        <div
          className={`fixed bottom-0 right-0 flex justify-end ${
            hideLeftSide ? "w-[calc(100%-82px)]" : "w-[calc(100%-312px)]"
          } bg-white border border-t-gray-200 px-10 py-4`}
        >
          <div className="w-[312px]">
            <Paging page={page} setPage={setPage} maxPage={maxPage} />
          </div>
        </div>
      )}
    </div>
  );
}
