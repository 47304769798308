import lodash, { isEmpty, isEqual, isNumber, uniqBy } from "lodash";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NoDataIcon } from "../../assets";
import Paging from "../../components/common/components/Paging";
import DialogTextEditor from "../../components/common/components/dialog/DialogTextEditor";
import { commonActions } from "../../components/common/redux/action";
import { TYPE_GENERATE_EXAM, convertAnswerKey, quizAnswerType } from "../../components/common/utils/constant";
import { genSignature, getNonce, removeNullAttributeFromObject } from "../../components/common/utils/helper";
import { documentActions } from "../../components/manage_document/redux/action";
import AnswerBoardGroupExam from "../../components/manage_exam/components/AnswerBoardGroupExam";
import CreateQuizRightSide from "../../components/manage_exam/components/CreateQuizRightSide";
import { TopicLv1Item } from "../../components/manage_exam/components/CreateQuizSelectTopics";
import CreationInfoExamViewOnly from "../../components/manage_exam/components/CreationInfoExamViewOnly ";
import CreationInfoExamWareHouse from "../../components/manage_exam/components/CreationInfoExamWareHouse";
import DialogEditExam from "../../components/manage_exam/components/DialogEditExam";
import QuestionsGroupExam from "../../components/manage_exam/components/QuestionsGroupExam";
import {
  ANSWER_TYPE_OPTIONS,
  DIALOG_TYPE,
  MAX_LENGTH_CORRECT_OPTION,
  MODE,
} from "../../components/manage_exam/utils/constant";
import {
  addQuestionToBottomOfParentBlock,
  getAnswerTypeOfQuestion,
  getBaseOption,
  getCorrectOrdinalOfListQuestion,
  getFirstOrdinalQuestionWithoutParent,
  getSortedListQuestion,
} from "../../components/manage_exam/utils/helpers";
import { eventTracking } from "../../firebase/firebaseConfig";
import SelectQuestionThousandQuestion from "../../components/manage_exam/components/SelectQuestionThousandQuestion";

const ITEMS_PER_PAGE = 15;

export default function ThousandQuestions({ isEdited = false }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const mainRef: any = useRef(null);
  const contentRef: any = useRef(null);
  const { previewExam } = useSelector((state: { document }) => state.document);
  const { creationInfoExam, createExamFromFolderId, examDetail } = useSelector((state: { common }) => state.common);
  const { topics_lv1 } = creationInfoExam;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [creationInfo, setCreationInfo] = useState<any>({
    thematics: [],
    title: "",
  });
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
  }>({
    title: "",
  });
  const [page, setPage] = useState<number>(1);
  const [questionsSelected, setQuestionsSelected] = useState<any[]>([]);
  const [ordinalQuestion, setOrdinalQuestion] = useState<number>(1);
  const [openDialogAddOrEditExam, setOpenDialogAddOrEditExam] = useState<{ type: number | null; question?: any }>({
    type: null,
  });
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [cachedExam, setCachedExam] = useState<{
    questions: any[];
    solutions: any[];
  }>({
    questions: [],
    solutions: [],
  });

  const numOfQuestionsSelected = questionsSelected
    ?.filter((e) => e?.answer_type !== quizAnswerType.NONE)
    ?.filter((question) => !question.deleted)?.length;
  const mode = searchParams.get("mode") as any;
  // đề thi được share or có phòng thi đang diễn ra
  const isViewOnly = isEdited && examDetail?.permission !== "mine";

  const listTopicLv1 = useMemo(() => {
    if (!creationInfo.grade?.id || !creationInfo.subject_types?.id || !creationInfo.learningProgram?.id) return [];
    let topicRender = [...(topics_lv1 || [])];
    if (creationInfo.grade?.id) {
      topicRender = topicRender.filter((topic) => topic.grade_id === creationInfo.grade.id);
    }
    if (creationInfo.subject_types?.id) {
      topicRender = topicRender.filter((topic) => topic.subject_type_id === creationInfo.subject_types.id);
    }
    if (creationInfo.learningProgram?.id) {
      topicRender = topicRender.filter((topic) => topic.learning_program_id === creationInfo.learningProgram.id);
    }
    if (creationInfo.semesters?.id) {
      topicRender = topicRender.filter((topic) => topic.semester_id === creationInfo.semesters.id);
    }
    if (creationInfo.thematics?.id) {
      topicRender = topicRender.filter((topic) => topic.thematic_id === creationInfo.thematics.id);
    }
    return topicRender;
  }, [creationInfo, topics_lv1]);

  const questionWithDifficultDegree = useMemo(
    () =>
      isNumber(creationInfo?.difficultDegree)
        ? previewExam?.questions?.filter((e) => e?.difficult_degree === creationInfo?.difficultDegree)
        : previewExam?.questions,
    [previewExam, creationInfo?.difficultDegree]
  );

  const previewQuestions = useMemo(() => {
    if (mode === MODE.SELECT) {
      const questions = questionWithDifficultDegree?.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
      const parentIds = questions?.filter((e) => e?.parent)?.map((e) => e.parent);
      // cộng thêm parentQuestions vào list question
      const parentQs = cachedExam?.questions?.filter((e) => parentIds?.includes(e?.id)) || [];
      return questions?.concat(parentQs);
    } else {
      return questionsSelected;
    }
  }, [mode, isEdited, cachedExam?.questions, questionsSelected, page, creationInfo?.difficultDegree]);

  const listQuestionWithoutParent = useMemo(() => {
    return (
      questionsSelected
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE) || []
    );
  }, [questionsSelected]);

  const currentQuestion = useMemo(() => {
    const cq = questionsSelected
      ?.filter((e) => e?.answer_type !== quizAnswerType.NONE)
      ?.filter((question) => !question.deleted)
      ?.find((e: any) => e.ordinal === ordinalQuestion);
    if (cq) {
      return cq;
    }
    // nếu câu hỏi có ordinalQuestion đã bị xoá thì setOrdinalQuestion là câu trước nó, nếu không có câu trước đó thì tìm câu đầu tiên.
    setOrdinalQuestion(
      ordinalQuestion - 1 >= 1 ? ordinalQuestion - 1 : getFirstOrdinalQuestionWithoutParent(questionsSelected)
    );
  }, [questionsSelected, ordinalQuestion, quizAnswerType]);

  const maxPage =
    Math.floor(
      (questionWithDifficultDegree?.filter((e) => e?.answer_type !== quizAnswerType.NONE)?.length - 1) / ITEMS_PER_PAGE
    ) + 1;

  const isShowRightSide = useMemo<boolean>(
    () =>
      questionsSelected
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
        ?.filter((question) => question.isSelfInput).length > 0 && mode !== MODE.SELECT,
    [questionsSelected, mode]
  );

  const getTopicInfoFromQuestions = () => {
    // + Lớp: lớp cao nhất của các Topic mà user đã chọn
    // + Môn: user đã chọn xuất dữ liệu
    // + Chương trình học: sách thuộc lớp ghi nhận phía trên mà có nhiều câu được thêm tạo đề nhất
    // + Học kỳ: nếu chỉ thêm toàn câu học kỳ 1/ học kỳ 2 của lớp ghi nhận phía trên thì ghi nhận là Học kỳ 1/ Học kỳ 2 nhưng nếu lẫn lộn thì ghi nhận là Học kỳ 2.
    const questionsSelectedWithoutSelfInputQuestions = questionsSelected?.filter((question) => !question.isSelfInput);
    const findLearningProgramId = () => {
      const lpIds = questionsSelectedWithoutSelfInputQuestions
        ?.filter((e) => e?.grade_id === gradeId)
        .map((e) => e?.learning_program_id);
      const counts = {};
      lpIds.forEach((e) => {
        counts[e] = (counts[e] || 0) + 1;
      });
      return Number(Object.keys(counts).find((e) => counts[e] === Math.max(...(Object.values(counts) as number[]))));
    };

    const gradeId = Math.min(...questionsSelectedWithoutSelfInputQuestions?.map((e) => e?.grade_id));
    const gradeInfo = creationInfoExam?.grades?.find((e) => e?.id === gradeId);
    const subjectType = gradeInfo?.subject_types[0];
    const learningProgram = subjectType?.learning_programs?.find((e) => e?.id === findLearningProgramId());
    const semesterId = questionsSelectedWithoutSelfInputQuestions
      ?.filter((e) => e?.grade_id === gradeId)
      .some((e) => e?.semester_id === 2)
      ? 2
      : 1;

    const topicInfo: any = {};
    if (gradeInfo) topicInfo.grade = gradeInfo;
    if (subjectType) topicInfo.subject_types = subjectType;
    if (learningProgram) topicInfo.learningProgram = learningProgram;
    const semesters = learningProgram?.semesters?.find((e) => e?.id === semesterId);
    if (semesters) topicInfo.semesters = semesters;

    return topicInfo;
  };

  const checkEditCreationInfo = useMemo<boolean>(() => {
    return !lodash.isEqual(
      [
        examDetail?.title,
        examDetail?.time,
        // examDetail?.count,
        examDetail?.grade?.id,
        examDetail?.subject_type?.id,
        examDetail?.learning_program?.id,
        examDetail?.semester?.id,
        // examDetail?.thematics,
      ],
      [
        creationInfo?.title?.trim(),
        creationInfo?.time,
        // creationInfo?.numOfQuestions,
        creationInfo?.grade?.id,
        creationInfo?.subject_types?.id,
        creationInfo?.learningProgram?.id,
        creationInfo?.semesters?.id,
        // examInfo?.thematics || [],
      ]
    );
  }, [examDetail, creationInfo]);

  const { checkIsEditQuestion, checkIsEditSolution } = useMemo<{
    checkIsEditQuestion: boolean;
    checkIsEditSolution: boolean;
  }>(() => {
    if (!examDetail) return { checkIsEditQuestion: false, checkIsEditSolution: false };

    const originExamQuestionData = examDetail?.questions
      ?.filter((question) => !question.original_id)
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
      ?.map((question) => {
        // format self-input question from server to client
        return {
          ...question,
          contents: question.questions,
          options: question.options.map((option) => ({
            ...option,
            contents: [...option.items],
            answer_key: convertAnswerKey(option.ordinal),
          })),
          solutions:
            examDetail.solutions?.find((solution) => solution.quiz_question_id === question.id)?.items?.detail || [],
          isSelfInput: true,
        };
      });

    const currentQuestionData = questionsSelected
      ?.filter((question) => !question.original_id)
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE);

    const checkIsEditQuestion =
      !isEqual(originExamQuestionData?.length, currentQuestionData?.filter((e) => !e?.deleted)?.length) ||
      !isEqual(
        originExamQuestionData?.map((e) => e?.options?.map((op) => [op?.correct, op?.items?.[0]?.content])).flat(),
        currentQuestionData?.map((e) => e?.options?.map((op) => [op?.correct, op?.contents?.[0]?.content])).flat()
      ) ||
      !isEqual(
        originExamQuestionData?.map((e) => e?.questions?.map((c) => c?.content)).flat(),
        currentQuestionData?.map((e) => e?.contents?.map((c) => c?.content)).flat()
      ) ||
      !isEqual(
        originExamQuestionData?.map((e) => ({
          description_answer_before: e?.description_answer_before,
          description_answer_after: e?.description_answer_after,
        })),
        currentQuestionData?.map((e) => ({
          description_answer_before: e?.description_answer_before,
          description_answer_after: e?.description_answer_after,
        }))
      );

    const checkIsEditSolution = !isEqual(
      originExamQuestionData
        ?.filter((question) => question.isSelfInput)
        ?.map((e) => e.solutions?.map((e) => e?.content))
        .flat(),
      currentQuestionData
        ?.filter((question) => question.isSelfInput)
        ?.filter((e) => !isEmpty(e?.solutions))
        ?.map((e) => e?.solutions?.map((e) => e?.content))
        ?.flat()
    );

    return { checkIsEditQuestion, checkIsEditSolution };
  }, [examDetail, questionsSelected]);

  const checkEnableButtonCreate = useMemo<boolean>(() => {
    const listParentQuestion = questionsSelected
      ?.filter((question) => question.isSelfInput)
      ?.filter((question) => !question.deleted)
      ?.filter((question) => question.answer_type === quizAnswerType.NONE);

    const isAllParentHasChild = listParentQuestion?.every((parentQuestionItem) => {
      return listQuestionWithoutParent?.some((question) => question.parent === parentQuestionItem.id);
    });

    return (
      creationInfo.title &&
      !errorMessage.title &&
      creationInfo.time &&
      questionsSelected.length !== 0 &&
      questionsSelected?.filter((question) => question.answer_type !== quizAnswerType.NONE)?.length > 0 &&
      questionsSelected
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.isSelfInput)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
        ?.every(
          (e: any) =>
            !!e?.options?.some(
              (op) =>
                op.correct === 1 &&
                op.contents?.[0]?.content?.trim() !== "" &&
                (e.answer_type === quizAnswerType.CONSTRUCTED_RESPONSE
                  ? op.contents?.[0]?.content.length <= MAX_LENGTH_CORRECT_OPTION
                  : true)
            )
        ) &&
      isAllParentHasChild
    );
  }, [creationInfo, errorMessage, questionsSelected]);

  const checkEnableButtonEdit = useMemo<boolean>(() => {
    const listParentQuestion = questionsSelected
      ?.filter((question) => question.isSelfInput)
      ?.filter((question) => !question.deleted)
      ?.filter((question) => question.answer_type === quizAnswerType.NONE);

    const isAllParentHasChild = listParentQuestion?.every((parentQuestionItem) => {
      return listQuestionWithoutParent?.some((question) => question.parent === parentQuestionItem.id);
    });

    const checkFillData = [
      creationInfo?.title,
      creationInfo?.grade?.id,
      creationInfo?.subject_types?.id,
      creationInfo?.learningProgram?.id,
      creationInfo?.semesters?.id,
      questionsSelected?.filter((question) => question.answer_type !== quizAnswerType.NONE)?.length > 0,
      questionsSelected
        ?.filter((question) => !question.deleted)
        ?.filter((question) => question.isSelfInput)
        ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
        ?.every(
          (e: any) =>
            !!e?.options?.some(
              (op) =>
                op.correct === 1 &&
                op.contents?.[0]?.content?.trim() !== "" &&
                (e.answer_type === quizAnswerType.CONSTRUCTED_RESPONSE
                  ? op.contents?.[0]?.content.length <= MAX_LENGTH_CORRECT_OPTION
                  : true)
            )
        ),
      isAllParentHasChild,
    ].every((e) => !!e);
    const checkValidData = !errorMessage.title;
    return (
      checkFillData &&
      checkValidData &&
      (checkEditCreationInfo ||
        !lodash.isEqual(
          lodash.sortBy(examDetail?.questions, "ordinal")?.map((e) => e?.id),
          lodash.sortBy(questionsSelected, "ordinal")?.map((e) => e?.id)
        ) ||
        checkIsEditQuestion ||
        checkIsEditSolution)
    );
  }, [examDetail, creationInfo, questionsSelected, checkEditCreationInfo, checkIsEditQuestion, errorMessage]);

  const handleCreateOrEditExam = () => {
    if (!isEdited) {
      eventTracking("select_question_input_type_done");
    }

    const folderId = createExamFromFolderId;

    const _mapQuestion = (qs) => {
      if (qs.isSelfInput) return qs;
      return {
        ...qs,
        solutions: qs?.solutions?.items?.detail
          ? [{ ...qs?.solutions?.items?.detail[0], id: qs?.solutions?.id }]
          : [{ ...qs?.solutions?.items?.suggestion[0], id: qs?.solutions?.id }],
        contents: qs?.questions || qs?.contents,
        options: qs?.options?.map((e) => ({
          ...e,
          contents: [quizAnswerType?.ONE_ANSWER, quizAnswerType.SORT_POSITION].includes(qs?.answer_type)
            ? e?.items
            : [{ ...e?.items[0], content: qs?.solutions?.correct_options?.join(" ") }],
        })),
      };
    };
    let dataPost: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade_id: creationInfo?.grade?.id,
      subject_type_id: creationInfo?.subject_types?.id,
      learning_program_id: creationInfo?.learningProgram?.id,
      folder_id: folderId,
      semester_ids: creationInfo?.semesters?.id ? [creationInfo?.semesters?.id] : undefined,
      thematic_ids: creationInfo?.thematics,
    };
    if (!isEdited || (isEdited && creationInfo.title?.trim() !== examDetail.title)) {
      dataPost.title = creationInfo.title?.trim();
    }

    if (!isEdited) {
      dataPost = {
        ...dataPost,
        type_generate: TYPE_GENERATE_EXAM.THOUSAND_EXAM,
        json_data: {
          time: creationInfo?.time,
          num_of_questions: numOfQuestionsSelected,
          data: JSON.stringify(questionsSelected?.filter((question) => !question.deleted)?.map((e) => _mapQuestion(e))),
        },
      };
    } else {
      const removeQuestions =
        examDetail?.questions
          ?.filter(
            (e) => questionsSelected?.filter((question) => !question.deleted)?.findIndex((qs) => qs?.id === e?.id) < 0
          )
          ?.map((e) => ({ ...e, deleted: true })) || [];

      const listQuestionEdit = questionsSelected
        ?.filter((question) => !question.deleted)
        ?.map((e, idx) => {
          const isExistingQuestion = examDetail?.questions?.findIndex((qs) => qs?.id === e?.id) >= 0;
          if (!isExistingQuestion) {
            return {
              ..._mapQuestion(e),
              added: true,
              ordinal: idx + 1,
            };
          } else {
            const mappedQuestion = _mapQuestion(e);

            const getRemoveOptions = (qs) => {
              const oldQuestion = examDetail?.questions?.find((e) => e?.id === qs.id);
              if (!oldQuestion) return [];
              return (
                oldQuestion.options
                  ?.filter((e) => {
                    return qs?.options?.findIndex((o) => o?.id === e?.id) < 0;
                  })
                  ?.map((e) => ({ ...e, deleted: true })) || []
              );
            };
            const oldAnswerType = examDetail?.questions?.find((question) => question.id === e.id)?.answer_type;
            const isEditQuestion =
              e?.answer_type !== oldAnswerType ||
              !isEqual(
                [
                  examDetail?.questions?.find((qs) => qs.id === e.id)?.description_answer_before,
                  examDetail?.questions?.find((qs) => qs.id === e.id)?.description_answer_after,
                ],
                [e.description_answer_before, e.description_answer_after]
              ) ||
              e?.parent !== examDetail?.questions?.find((questionItem) => questionItem.id === e.id)?.parent;

            return {
              ...mappedQuestion,
              ordinal: idx + 1,
              options: mappedQuestion.isSelfInput
                ? mappedQuestion.options
                    ?.map((op, i) =>
                      !op?.id
                        ? {
                            ...op,
                            added: true,
                            ordinal: i,
                          }
                        : { ...op, ordinal: i, edited: true }
                    )
                    ?.concat(getRemoveOptions(e))
                : mappedQuestion.options,
              edited: isEditQuestion,
            };
          }
        })
        // ?.concat(
        //   parentQuestions?.map((e) =>
        //     examDetail?.questions?.findIndex((qs) => qs?.id === e?.id) < 0
        //       ? { ..._mapQuestion(e), added: true }
        //       : _mapQuestion(e)
        //   )
        // )
        ?.concat(removeQuestions);

      dataPost = {
        ...dataPost,
        topic_slug: examDetail?.slug,
        is_edit_question: checkIsEditQuestion ? 1 : 0,
        json_data: {
          time: creationInfo?.time,
          num_of_questions: numOfQuestionsSelected,
          data: JSON.stringify(listQuestionEdit),
        },
      };
    }
    dataPost = {
      ...removeNullAttributeFromObject(dataPost),
      signature: genSignature(removeNullAttributeFromObject(dataPost), undefined, undefined, [
        "semester_ids",
        "thematic_ids",
        "json_data",
      ]),
    };
    if (isEdited) {
      dispatch(
        commonActions.handleEditExam({ data: dataPost, topicSlug: examDetail?.slug }, (tpSlug) => {
          if (tpSlug === examDetail?.slug) {
            navigate(0);
          } else {
            navigate(`/quan-ly-tai-lieu/${tpSlug}?mode=edit`, { replace: true });
            window.location.reload();
          }
        })
      );
    } else {
      dispatch(
        commonActions.handleCreateExam(dataPost, (topicSlug: string) => {
          navigate(`/quan-ly-tai-lieu/${topicSlug}`);
        })
      );
    }
  };

  const handleGetQuestion = (topicLv1Id, topicLv2Id) => {
    setIsLoading(true);
    // set page ve default
    setPage(1);
    setCreationInfo({ ...creationInfo, difficultDegree: null });
    const topicInfo = topics_lv1?.find((e) => e?.id === topicLv1Id);
    let dataPost: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
    };
    dataPost = {
      ...dataPost,
      signature: genSignature(dataPost),
      topic_id: topicLv2Id,
      gradeId: topicInfo?.grade_id,
      subjectTypeId: topicInfo?.subject_type_id,
      learningProgramId: topicInfo?.learning_program_id,
      semesterId: topicInfo?.semester_id,
    };
    dispatch(
      documentActions.previewExam(dataPost, (exam) => {
        setIsLoading(false);
        const uniqQuestions = uniqBy([...cachedExam.questions, ...(exam?.questions || [])], "id") as any[];
        const uniqSolutions = uniqBy([...cachedExam.solutions, ...(exam?.solutions || [])], "id") as any[];
        setCachedExam({
          questions: uniqQuestions,
          solutions: uniqSolutions,
        });
      })
    );
  };

  const handleChooseQuestion = (question: any, actionType: "add" | "remove") => {
    const hasParentQuestion = Boolean(question.parent);

    if (actionType === "remove") {
      setOrdinalQuestion(question.ordinal);

      if (!hasParentQuestion) {
        setQuestionsSelected((prev) =>
          getCorrectOrdinalOfListQuestion(
            prev.filter(
              (questionItem) => questionItem?.original_id !== question?.id && questionItem?.id !== question?.id
            )
          )
        );
      } else {
        const listChild = questionsSelected.filter((questionItem) => questionItem.parent === question.parent);
        const isParentHasOnlyOneChild = listChild.length === 1;
        if (isParentHasOnlyOneChild) {
          setQuestionsSelected((prev) =>
            getCorrectOrdinalOfListQuestion(
              prev.filter(
                (questionItem) =>
                  questionItem?.original_id !== question?.id &&
                  questionItem?.id !== question?.id &&
                  questionItem?.id !== question?.parent
              )
            )
          );
        } else {
          setQuestionsSelected((prev) =>
            getCorrectOrdinalOfListQuestion(
              prev.filter(
                (questionItem) => questionItem?.original_id !== question?.id && questionItem?.id !== question?.id
              )
            )
          );
        }
      }
    } else if (actionType === "add") {
      const newQuestion = {
        ...question,
        solutions: previewExam?.solutions?.find((e) => e?.quiz_question_id === question.id),
      };

      if (!hasParentQuestion) {
        setQuestionsSelected((prev) => getCorrectOrdinalOfListQuestion(prev.concat([newQuestion])));
      } else {
        const parentQuestion = questionsSelected.find(
          (questionItem) => questionItem.id === question.parent || questionItem.original_id === question.parent
        );
        const isAddedParent = !!parentQuestion;
        if (!isAddedParent) {
          const parentQuestion = cachedExam?.questions?.find((questionItem) => questionItem.id === question.parent);
          setQuestionsSelected((prev) =>
            getCorrectOrdinalOfListQuestion(prev.concat([{ ...parentQuestion, isSelfInput: false }, newQuestion]))
          );
        } else {
          newQuestion.parent = parentQuestion.id;
          const newListQuestion = addQuestionToBottomOfParentBlock(questionsSelected, newQuestion, newQuestion.parent);
          setQuestionsSelected(getCorrectOrdinalOfListQuestion(newListQuestion));
        }
      }
      /**
       * - When user scroll to bottom without change ordinalQuestion (ordinalQuestion = 1), then add question and preview. It isn't scroll to top because ordinal do not change
       * => Reset ordinal to scroll top when add question.
       */
      setOrdinalQuestion(-1);
    }
  };

  const scrollToTopContent = () => {
    setOrdinalQuestion(1);
    document.querySelector("#listQuestion")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleCreateNewQuestion = (
    data: any,
    isParentQuestion: boolean,
    answerTypeChosen: keyof typeof ANSWER_TYPE_OPTIONS,
    parentQuestionId: number
  ) => {
    let qs: any;
    if (isParentQuestion) {
      qs = {
        id: -((questionsSelected?.length + 1) as number),
        ordinal: questionsSelected?.filter((question) => !question.deleted)?.length + 1,
        contents: [{ content: data?.find((e) => e?.ordinal === -1)?.content, content_type: "html", ordinal: 1 }],
        answer_type: quizAnswerType.NONE,
        isSelfInput: true,
      };
    } else {
      const answerType =
        answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER
          ? getAnswerTypeOfQuestion(data)
          : quizAnswerType.CONSTRUCTED_RESPONSE;

      let options;
      if (answerTypeChosen === ANSWER_TYPE_OPTIONS.SELECT_ANSWER) {
        options = data
          ?.filter((e) => e?.ordinal >= 0)
          ?.map((op) => ({
            contents: [{ content: op?.content, content_type: "html", ordinal: 1 }],
            answer_key: op?.answer_key,
            correct: op?.correct || 0,
            ordinal: op?.ordinal,
          }));
      } else {
        const correctOption = data?.find((e) => e.ordinal === 1);
        options = [
          {
            contents: [{ content: correctOption.content, content_type: "html", ordinal: 1 }],
            correct: correctOption.correct,
            ordinal: correctOption.ordinal,
          },
        ];
      }

      let ordinal: number;
      if (!Boolean(parentQuestionId)) {
        ordinal = questionsSelected?.filter((question) => !question.deleted)?.length + 1;
      } else {
        const previousOrdinal = questionsSelected
          ?.filter((question) => !question.deleted)
          ?.reduce((accumulate, question) => {
            if (question.parent === parentQuestionId || question.id === parentQuestionId) {
              return question.ordinal;
            } else {
              return accumulate;
            }
          }, -1);
        ordinal = previousOrdinal + 1;
      }

      qs = {
        ordinal,
        contents: [{ content: data?.find((e) => e?.ordinal === -1)?.content, content_type: "html", ordinal: 1 }],
        ...(answerTypeChosen === ANSWER_TYPE_OPTIONS.ENTER_ANSWER && {
          description_answer_before: data?.find((e) => e?.ordinal === 0).content,
          description_answer_after: data?.find((e) => e?.ordinal === 2).content,
        }),
        options,
        solutions: [],
        answer_type: answerType,
        isSelfInput: true,
        parent: parentQuestionId,
      };
      setOrdinalQuestion(ordinal);
    }

    if (parentQuestionId) {
      const previousQuestionIndex = questionsSelected.findIndex((question) => question.ordinal === qs.ordinal - 1);
      const clonedListQuestion = [...questionsSelected];
      clonedListQuestion.splice(previousQuestionIndex + 1, 0, qs);
      setQuestionsSelected(getCorrectOrdinalOfListQuestion(clonedListQuestion));
    } else {
      setQuestionsSelected([...questionsSelected].concat([qs]));
    }
  };

  const handleUpdateListQuestion = (questions) => {
    setQuestionsSelected(questions);
    const newListQuestionLength = questions
      ?.filter((question) => question.answer_type !== quizAnswerType.NONE)
      ?.filter((e) => !e?.deleted)?.length;
    setOrdinalQuestion((prev) => {
      if (prev > newListQuestionLength) {
        return newListQuestionLength;
      }
      return prev;
    });
  };

  const handleChooseAnswerKey = (key: any, isChecked: boolean) => {
    const newListQuestionWithoutParentQuestions = questionsSelected?.map((e: any) => {
      if (e?.ordinal === ordinalQuestion) {
        const newOptions = e?.options?.map((op) =>
          key === op?.answer_key
            ? !!op?.id
              ? { ...getBaseOption(op), correct: Number(isChecked), edited: true }
              : { ...getBaseOption(op), correct: Number(isChecked) }
            : getBaseOption(op)
        );
        const answerType = getAnswerTypeOfQuestion(newOptions);
        return {
          ...e,
          options: newOptions,
          answer_type: answerType,
        };
      } else {
        return e;
      }
    });
    setQuestionsSelected(newListQuestionWithoutParentQuestions);
  };

  const handleChangeCorrectAnswer = (content: string) => {
    const newListQuestionWithoutParentQuestions = questionsSelected.map((e: any) => {
      if (e?.ordinal === ordinalQuestion) {
        const newOptions = e?.options?.map((op) =>
          !!op?.id
            ? {
                ...getBaseOption(op),
                contents: [
                  {
                    id: op?.items ? op?.items[0]?.id : null,
                    content: content,
                    content_type: "html",
                    ordinal: 1,
                    edited: true,
                  },
                ],
                edited: true,
              }
            : {
                ...getBaseOption(op),
                contents: [
                  {
                    content: content,
                    content_type: "html",
                    ordinal: 1,
                  },
                ],
              }
        );
        return {
          ...e,
          options: newOptions,
          answer_type: quizAnswerType.CONSTRUCTED_RESPONSE,
        };
      } else {
        return e;
      }
    });
    setQuestionsSelected(newListQuestionWithoutParentQuestions);
  };

  const handleFillSolution = (solution: any) => {
    const oldContent = currentQuestion?.solutions.find((e: any) => e.content_type === "html")?.content;
    if ((!oldContent || oldContent?.length === 0) && solution?.length === 0) return;

    const newListQuestionWithoutParentQuestions = questionsSelected.map((e: any) => {
      const newSolution = {
        ordinal: 1,
        content: solution,
        content_type: "html",
      };
      return e?.ordinal === ordinalQuestion
        ? {
            ...e,
            solutions: [
              !!e?.id
                ? !solution || solution?.length === 0
                  ? { ...newSolution, id: e?.solutions[0]?.id, deleted: true }
                  : !oldContent || oldContent?.length === 0
                  ? {
                      ...newSolution,
                      id: e?.solutions[0]?.id,
                      added: true,
                    }
                  : {
                      ...newSolution,
                      id: e?.solutions[0]?.id,
                      edited: true,
                    }
                : newSolution,
            ],
          }
        : e;
    });
    setQuestionsSelected(newListQuestionWithoutParentQuestions);
  };

  useEffect(() => {
    document.getElementById(`question_${ordinalQuestion}`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [ordinalQuestion]);

  useEffect(() => {
    if (isEdited && examDetail) {
      // set default info
      setCreationInfo((prev) => ({
        ...prev,
        title: examDetail.title,
        grade: examDetail.grade,
        subject_types: examDetail.subject_type,
        learningProgram: examDetail.learning_program,
        semesters: examDetail.semester,
        thematics: examDetail.thematics,
        time: examDetail.time,
        numOfQuestions: examDetail?.questions?.length,
      }));
      const qsSelected = getSortedListQuestion(
        examDetail?.questions?.map((question) => {
          if (question.original_id) {
            return {
              ...question,
              solutions: examDetail?.solutions?.find((e) => e?.quiz_question_id === question.id),
            };
          } else {
            // format self-input question from server to client
            const solution = examDetail.solutions?.find((solution) => solution.quiz_question_id === question.id);
            const solutionId = solution?.id;
            return {
              ...question,
              contents: question.questions,
              options: question.options.map((option) => ({
                ...option,
                contents: [...option.items],
                ...(question.answer_type !== quizAnswerType.CONSTRUCTED_RESPONSE && {
                  answer_key: convertAnswerKey(option.ordinal),
                }),
              })),
              solutions: solution?.items?.detail?.map((item) => ({ ...item, id: solutionId })) || [],
              isSelfInput: true,
            };
          }
        })
      );
      setQuestionsSelected(qsSelected);

      const firstOrdinalQuestionWithoutParent = qsSelected
        ?.filter((question) => !question.deleted)
        ?.find((question) => question.answer_type !== quizAnswerType.NONE).ordinal;
      setOrdinalQuestion(firstOrdinalQuestionWithoutParent);
    }
  }, [isEdited, examDetail]);

  useEffect(() => {
    scrollToTopContent();
  }, [page]);

  useEffect(() => {
    if (mode === MODE.SELECT) {
      eventTracking("screen_question_input_type_view");
    } else if (mode === MODE.PREVIEW) {
      if (!isEdited) {
        eventTracking("screen_question_preview_view");
      }
    }
  }, [mode]);

  useEffect(() => {
    if (page !== 1) {
      // set page to default
      setPage(1);
    }
    scrollToTopContent();
  }, [creationInfo?.difficultDegree]);

  useEffect(() => {
    return () => {
      // clean quiz khi ra khoi page
      dispatch(documentActions.handleCleanPreviewExam());
    };
  }, []);

  useEffect(() => {
    if (isEdited) {
      setSearchParams({ mode: MODE.EDIT }, { replace: true });
    } else {
      setSearchParams({ mode: MODE.SELECT }, { replace: true });
    }
  }, [isEdited]);

  useEffect(() => {
    if (!isEdited) {
      dispatch(
        commonActions.handleGetTeacherInfo((teacherInfoResponse) => {
          const formattedTime = moment().format("DDMMyyyy hhmm");
          const subjectTitle = teacherInfoResponse?.subject_register?.title;
          const initialTitle = `Đề ${subjectTitle} ${formattedTime}`;
          setCreationInfo((prev) => ({ ...prev, title: initialTitle }));
        })
      );
    }
  }, [isEdited]);

  return (
    <div className="main-content overflow-hidden" ref={mainRef}>
      <div>
        {isViewOnly ? (
          <CreationInfoExamViewOnly examInfo={creationInfo} numOfQuestions={numOfQuestionsSelected as any} />
        ) : (
          <CreationInfoExamWareHouse
            examInfo={creationInfo}
            setExamInfo={setCreationInfo}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            disableCreateExamButton={!checkEnableButtonCreate}
            disablePreviewButton={questionsSelected.length === 0}
            totalQuestions={numOfQuestionsSelected}
            mode={mode}
            onClickPreviewButton={() => {
              setSearchParams({ mode: isEdited ? MODE.EDIT : MODE.PREVIEW });
              scrollToTopContent();
              setCreationInfo({ ...creationInfo, ...getTopicInfoFromQuestions() });
            }}
            onClickCreateExam={handleCreateOrEditExam}
            onClickBackButton={() => {
              setSearchParams({ mode: MODE.SELECT });
              if (!isEdited) {
                setCreationInfo({ ...creationInfo, semesters: [] });
              }
            }}
            disableSaveExamButton={!checkEnableButtonEdit}
            onClickSaveExam={handleCreateOrEditExam}
            isThousandQuestions
          />
        )}
      </div>
      <div className="flex flex-row w-full">
        <div
          className={`${
            ![MODE.PREVIEW, MODE.EDIT].includes(mode) && "pl-[28px]"
          } w-[312px] h-[calc(100vh-232px)] overflow-x-hidden overflow-y-auto scrollbar-thin`}
        >
          {[MODE.PREVIEW, MODE.EDIT].includes(mode) ? (
            <div className="min-h-full">
              <AnswerBoardGroupExam
                questions={questionsSelected?.filter((e) => e?.answer_type !== quizAnswerType.NONE)}
                solutions={questionsSelected
                  ?.filter((e) => e?.answer_type !== quizAnswerType.NONE)
                  ?.map((e) => e?.solutions)}
                setOrdinalQuestion={setOrdinalQuestion}
              />
            </div>
          ) : listTopicLv1.length === 0 ? (
            <div className="h-full flex items-center">
              <span className="text-gray-900">
                {lodash.isEmpty(creationInfo?.grade) && lodash.isEmpty(creationInfo?.subject_types) ? (
                  <FormattedMessage id="thousandQuestions.chooseInfo" />
                ) : (
                  <FormattedMessage id="common.nodata" />
                )}
              </span>
            </div>
          ) : (
            <div className="flex flex-col gap-4 p-3">
              {listTopicLv1?.map((topic_lv1) => (
                <TopicLv1Item
                  key={topic_lv1.id}
                  topicLv1={topic_lv1}
                  onClickTopicLv1={() => {}}
                  onClickTopicLv2={handleGetQuestion}
                />
              ))}
            </div>
          )}
        </div>
        <div
          id="listQuestion"
          className="flex-1 border-l border-r border-gray-200 h-[calc(100vh-232px)] overflow-auto scrollbar-thin"
          ref={contentRef}
        >
          {isLoading ? (
            <div className="flex justify-center mt-[20%]">
              <ProgressSpinner
                style={{ width: "80px", height: "80px" }}
                pt={{
                  circle: {
                    className: "stroke-primary",
                  },
                }}
              />
            </div>
          ) : (!lodash.isEmpty(previewExam) && previewQuestions?.length > 0) || mode === MODE.EDIT ? (
            <div className="w-full">
              {mode === MODE.SELECT ? (
                <>
                  <SelectQuestionThousandQuestion
                    questions={previewQuestions}
                    solutions={previewExam?.solutions}
                    isShowBorder={isViewOnly}
                    isShowRightSide={isShowRightSide}
                    questionsSelected={questionsSelected?.filter((e) => e?.answer_type !== quizAnswerType.NONE)}
                    onChooseQuestion={handleChooseQuestion}
                    onClickQuestionContent={(ordinal) => setOrdinalQuestion(ordinal)}
                  />
                  {maxPage > 1 && (
                    <div className="flex justify-end h-[76px] items-center border-t px-8">
                      <div className="w-[282px]">
                        <Paging page={page} setPage={setPage} maxPage={maxPage} />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <QuestionsGroupExam
                  questions={previewQuestions}
                  solutions={
                    [MODE.PREVIEW, MODE.EDIT].includes(mode)
                      ? questionsSelected
                          ?.filter((e) => e?.answer_type !== quizAnswerType.NONE)
                          ?.map((e) => e?.solutions)
                      : previewExam?.solutions
                  }
                  isThousandQuestions={!isViewOnly}
                  questionsSelected={questionsSelected?.filter((e) => e?.answer_type !== quizAnswerType.NONE)}
                  onChooseQuestion={handleChooseQuestion}
                  isPreview={mode === MODE.PREVIEW}
                  isSelect={mode === MODE.SELECT}
                  isEdited={isEdited}
                  isShowBorder={isViewOnly}
                  isShowCreateQuestionButton={mode !== MODE.SELECT}
                  isShowRightSide={isShowRightSide}
                  onClickQuestionContent={(ordinal) => setOrdinalQuestion(ordinal)}
                  onClickCreateSelfInputQuestion={() => setOpenDialogAddOrEditExam({ type: DIALOG_TYPE.CREATE })}
                  onClickOpenSolutionSelfInputQuestionDialog={(ordinal) => {
                    setOpenEditor(true);
                    setOrdinalQuestion(ordinal);
                  }}
                  updateListQuestion={handleUpdateListQuestion}
                />
              )}
            </div>
          ) : (
            <div className="flex justify-center mt-[10%]">
              <div>
                <NoDataIcon />
                <span className="flex justify-center text-[16px] text-gray-700">
                  <FormattedMessage id="common.nodata" />
                </span>
              </div>
            </div>
          )}
        </div>
        {isShowRightSide && (
          <CreateQuizRightSide
            disabled={false}
            isViewOnly={isViewOnly || !currentQuestion?.isSelfInput}
            currentQuestion={currentQuestion}
            solution={currentQuestion?.solutions}
            ordinalQuestionWithoutParent={
              listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) + 1
            }
            handleChooseAnswerKey={handleChooseAnswerKey}
            onChangeCorrectAnswer={handleChangeCorrectAnswer}
            setOpenEditor={setOpenEditor}
            mainRef={mainRef}
            disablePreviousQuestionButton={
              listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) <= 0
            }
            onClickPreviousQuestionButton={() => {
              const currentChildQuestionIndex = listQuestionWithoutParent.findIndex(
                (question) => question.ordinal === ordinalQuestion
              );
              const previousChildQuestion = listQuestionWithoutParent[currentChildQuestionIndex - 1];
              setOrdinalQuestion(previousChildQuestion.ordinal);
            }}
            disableNextQuestionButton={
              listQuestionWithoutParent.findIndex((question) => question.ordinal === ordinalQuestion) >=
              listQuestionWithoutParent.length - 1
            }
            onClickNextQuestionButton={() => {
              const currentChildQuestionIndex = listQuestionWithoutParent.findIndex(
                (question) => question.ordinal === ordinalQuestion
              );
              const nextChildQuestion = listQuestionWithoutParent[currentChildQuestionIndex + 1];
              setOrdinalQuestion(nextChildQuestion.ordinal);
            }}
          />
        )}
      </div>

      {isNumber(openDialogAddOrEditExam.type) && (
        <DialogEditExam
          question={openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE ? undefined : currentQuestion}
          listParentQuestion={questionsSelected
            ?.filter((e) => !e?.deleted)
            ?.filter((e) => e?.isSelfInput)
            ?.filter((question) => question.answer_type === quizAnswerType.NONE)
            ?.filter((question) => question.id !== openDialogAddOrEditExam.question?.id)
            ?.filter((question) => (isEdited ? !question.original_id || question.id < 0 : question.id < 0))}
          open={isNumber(openDialogAddOrEditExam.type)}
          onClose={() => {
            setOpenDialogAddOrEditExam({ type: null });
          }}
          onSubmit={(val, isParentQuestion, answerTypeChosen, parentQuestionId) => {
            if (openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE) {
              handleCreateNewQuestion(val, isParentQuestion, answerTypeChosen, parentQuestionId);
            }
          }}
          title={
            openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE
              ? intl.formatMessage({ id: "selfInput.addQuestion.title" })
              : intl.formatMessage({ id: "selfInput.editQuestion.title" })
          }
          isAdded={openDialogAddOrEditExam.type === DIALOG_TYPE.CREATE}
          isSelfInput
          ordinalQuestion={
            ((questionsSelected
              ?.filter((e) => e?.answer_type !== quizAnswerType.NONE)
              ?.filter((question) => !question?.deleted)?.length as number) + 1) as any
          }
        />
      )}

      {openEditor && (
        <DialogTextEditor
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          onSubmit={(val: any) => {
            handleFillSolution(val);
          }}
          content={currentQuestion?.solutions.find((e: any) => e.content_type === "html")?.content}
          title="Nhập hướng dẫn giải"
        />
      )}
    </div>
  );
}
