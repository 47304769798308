import React, { useState } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "../../../assets";
import { Button } from "primereact/button";

// topic_lv1_item start
type TTopicLv2ItemProps = {
  topicLv2: any;
  onClick: () => void;
};

export function TopicLv2Item({ topicLv2, onClick }: TTopicLv2ItemProps) {
  return (
    <span className="line-clamp-2 hover:cursor-pointer" onClick={onClick}>
      {topicLv2.title}
    </span>
  );
}
// topic_lv1_item end

// topic_lv1_item start
type TTopicLv1ItemProps = {
  topicLv1: any;
  onClickTopicLv1: (topicLv1Id: number) => void;
  onClickTopicLv2: (topicLv1Id: number, topicLv2Id: number) => void;
};

export function TopicLv1Item({ topicLv1, onClickTopicLv1, onClickTopicLv2 }: TTopicLv1ItemProps) {
  const [isShowTopicsLv2, setIsShowTopicsLv2] = useState<boolean>(false);
  return (
    <div>
      <div className="flex justify-between items-center line-clamp-2">
        <div className="flex-1 hover:cursor-pointer" onClick={() => onClickTopicLv1(topicLv1.id)}>
          <span className="font-semibold text-gray-900">{topicLv1.title}</span>
        </div>
        <Button
          icon={isShowTopicsLv2 ? <ArrowUpIcon stroke="#475467" /> : <ArrowDownIcon stroke="#475467" />}
          rounded
          text
          onClick={() => {
            setIsShowTopicsLv2((prev) => !prev);
          }}
          className="focus:shadow-none"
        />
      </div>
      <div className="ml-4 flex flex-col gap-4">
        {isShowTopicsLv2 &&
          topicLv1.topics_lv2?.map((topic_lv2) => {
            return (
              <TopicLv2Item
                key={topic_lv2.id}
                topicLv2={topic_lv2}
                onClick={() => onClickTopicLv2(topicLv1.id, topic_lv2.id)}
              />
            );
          })}
      </div>
    </div>
  );
}
// topic_lv1_item end

// ListTopicsLv1 start
type TCreateQuizSelectTopicsProps = {
  listTopicLv1: any[];
  onClickTopicLv1: (topicLv1Id: number) => void;
  onClickTopicLv2: (topicLv1Id: number, topicLv2Id: number) => void;
};

export default function CreateQuizSelectTopics({
  listTopicLv1,
  onClickTopicLv1,
  onClickTopicLv2,
}: TCreateQuizSelectTopicsProps) {
  return (
    <div className="flex flex-col gap-4 p-3">
      {listTopicLv1?.map((topic_lv1) => (
        <TopicLv1Item
          key={topic_lv1.id}
          topicLv1={topic_lv1}
          onClickTopicLv1={onClickTopicLv1}
          onClickTopicLv2={onClickTopicLv2}
        />
      ))}
    </div>
  );
}
// ListTopicsLv1 end
