import React, { useMemo } from "react";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { TrashIcon } from "../../../assets";

type TCreateQuizInputScoreTableProps = {
  chosenTopics: any;
  onUpdateNumsOfQuestion: (topicLv1Id: number, topicLv2Id: number, difficultDegreeField: number, value: number) => void;
  onClickDeleteTopic: (topicLv1Id: number, topicLv2Id: number) => void;
};

export default function CreateQuizInputScoreTable({
  chosenTopics,
  onUpdateNumsOfQuestion,
  onClickDeleteTopic,
}: TCreateQuizInputScoreTableProps) {
  const { isShowDefault, tableValue } = useMemo(() => {
    const defaultValue = [
      {
        key: "topicLv1Id-topicLv2Id-1",
        topicLv1Title: "Chọn chương cần tạo đề",
        topicLv2Title: "Chọn bài cần tạo đề",
        difficultDegree1: "-",
        difficultDegree2: "-",
        difficultDegree3: "-",
        difficultDegree4: "-",
      },
      {
        topicLv2Id: "topicLv1Id-topicLv2Id-2",
        topicLv1Title: "Chọn chương cần tạo đề",
        topicLv2Title: "Chọn bài cần tạo đề",
        difficultDegree1: "-",
        difficultDegree2: "-",
        difficultDegree3: "-",
        difficultDegree4: "-",
      },
    ];

    const tableValueRender = chosenTopics
      .map((topicLv1) => {
        if (!topicLv1?.topicLv2?.length) {
          return {
            key: `${topicLv1.id}-${undefined}`,
            topicLv1Id: topicLv1.id,
            topicLv2Id: undefined,
            topicLv1Title: topicLv1.title,
            topicLv2Title: "",
            difficultDegree1: topicLv1.questionsInfo.find((qi) => qi.difficultDegree === 1)?.count || 0,
            difficultDegree1Max: topicLv1.questionsInfo.find((qi) => qi.difficultDegree === 1)?.maxCount || 0,
            difficultDegree2: topicLv1.questionsInfo.find((qi) => qi.difficultDegree === 2)?.count || 0,
            difficultDegree2Max: topicLv1.questionsInfo.find((qi) => qi.difficultDegree === 2)?.maxCount || 0,
            difficultDegree3: topicLv1.questionsInfo.find((qi) => qi.difficultDegree === 3)?.count || 0,
            difficultDegree3Max: topicLv1.questionsInfo.find((qi) => qi.difficultDegree === 3)?.maxCount || 0,
            difficultDegree4: topicLv1.questionsInfo.find((qi) => qi.difficultDegree === 4)?.count || 0,
            difficultDegree4Max: topicLv1.questionsInfo.find((qi) => qi.difficultDegree === 4)?.maxCount || 0,
          };
        }
        return topicLv1?.topicLv2.map((topicLv2) => {
          return {
            key: `${topicLv1.id}-${topicLv2.id}`,
            topicLv1Id: topicLv1.id,
            topicLv2Id: topicLv2.id,
            topicLv1Title: topicLv1.title,
            topicLv2Title: topicLv2.title,
            difficultDegree1: topicLv2.questionsInfo.find((qi) => qi.difficultDegree === 1)?.count || 0,
            difficultDegree1Max: topicLv2.questionsInfo.find((qi) => qi.difficultDegree === 1)?.maxCount || 0,
            difficultDegree2: topicLv2.questionsInfo.find((qi) => qi.difficultDegree === 2)?.count || 0,
            difficultDegree2Max: topicLv2.questionsInfo.find((qi) => qi.difficultDegree === 2)?.maxCount || 0,
            difficultDegree3: topicLv2.questionsInfo.find((qi) => qi.difficultDegree === 3)?.count || 0,
            difficultDegree3Max: topicLv2.questionsInfo.find((qi) => qi.difficultDegree === 3)?.maxCount || 0,
            difficultDegree4: topicLv2.questionsInfo.find((qi) => qi.difficultDegree === 4)?.count || 0,
            difficultDegree4Max: topicLv2.questionsInfo.find((qi) => qi.difficultDegree === 4)?.maxCount || 0,
          };
        });
      })
      .flat();

    return {
      isShowDefault: tableValueRender.length === 0,
      tableValue: tableValueRender.length === 0 ? defaultValue : tableValueRender,
    };
  }, [chosenTopics]);

  const handleCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case "difficultDegree1":
        onUpdateNumsOfQuestion(rowData.topicLv1Id, rowData.topicLv2Id, 1, newValue);
        break;
      case "difficultDegree2":
        onUpdateNumsOfQuestion(rowData.topicLv1Id, rowData.topicLv2Id, 2, newValue);
        break;
      case "difficultDegree3":
        onUpdateNumsOfQuestion(rowData.topicLv1Id, rowData.topicLv2Id, 3, newValue);
        break;
      case "difficultDegree4":
        onUpdateNumsOfQuestion(rowData.topicLv1Id, rowData.topicLv2Id, 4, newValue);
        break;
      default:
        break;
    }
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="STT"
          rowSpan={2}
          style={{ width: 0 }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
        <Column
          header="Chương"
          rowSpan={2}
          style={{ width: "30%" }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
        <Column
          header="Bài"
          rowSpan={2}
          style={{ width: "30%" }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
        <Column
          header="Mức độ nhận thức"
          rowSpan={1}
          colSpan={4}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
        <Column
          header="Tổng số câu"
          rowSpan={2}
          style={{ width: 0 }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
        <Column
          header="Tỷ trọng (%)"
          rowSpan={2}
          style={{ width: 0 }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
        <Column
          header="Xoá"
          rowSpan={2}
          style={{ width: 0 }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
      </Row>
      <Row>
        <Column
          header="Nhận biết"
          style={{ width: "10%" }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
        <Column
          header="Thông hiểu"
          style={{ width: "10%" }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
        <Column
          header="Vận dụng"
          style={{ width: "10%" }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
        <Column
          header="Nâng cao"
          style={{ width: "10%" }}
          pt={{ headerContent: { className: "flex justify-center [&>span]:text-center" } }}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <DataTable
      dataKey="key"
      value={tableValue}
      editMode="cell"
      headerColumnGroup={headerGroup}
      showGridlines
      rowClassName={() => (isShowDefault ? "p-disabled" : "")}
    >
      <Column body={(_, options) => options.rowIndex + 1} pt={{ bodyCell: { className: "text-center" } }} />
      <Column field="topicLv1Title" />
      <Column field="topicLv2Title" />
      <Column
        field="difficultDegree1"
        editor={(options) => {
          return (
            <InputNumber
              min={0}
              max={options.rowData.difficultDegree1Max}
              value={options.value}
              disabled={options.rowData.difficultDegree1Max === 0}
              onValueChange={(e) => options.editorCallback?.(e.target.value)}
              pt={{
                input: {
                  root: {
                    className: "w-full",
                  },
                },
              }}
            />
          );
        }}
        onCellEditComplete={(e) => handleCellEditComplete(e)}
        pt={{ bodyCell: { className: "text-center" } }}
      />
      <Column
        field="difficultDegree2"
        editor={(options) => (
          <InputNumber
            min={0}
            max={options.rowData.difficultDegree2Max}
            value={options.value}
            disabled={options.rowData.difficultDegree2Max === 0}
            onValueChange={(e) => options.editorCallback?.(e.target.value)}
            pt={{
              input: {
                root: {
                  className: "w-full",
                },
              },
            }}
          />
        )}
        onCellEditComplete={(e) => handleCellEditComplete(e)}
        pt={{ bodyCell: { className: "text-center" } }}
      />
      <Column
        field="difficultDegree3"
        editor={(options) => (
          <InputNumber
            min={0}
            max={options.rowData.difficultDegree3Max}
            value={options.value}
            disabled={options.rowData.difficultDegree3Max === 0}
            onValueChange={(e) => options.editorCallback?.(e.target.value)}
            pt={{
              input: {
                root: {
                  className: "w-full",
                },
              },
            }}
          />
        )}
        onCellEditComplete={(e) => handleCellEditComplete(e)}
        pt={{ bodyCell: { className: "text-center" } }}
      />
      <Column
        field="difficultDegree4"
        editor={(options) => (
          <InputNumber
            min={0}
            max={options.rowData.difficultDegree4Max}
            value={options.value}
            disabled={options.rowData.difficultDegree4Max === 0}
            onValueChange={(e) => options.editorCallback?.(e.target.value)}
            pt={{
              input: {
                root: {
                  className: "w-full",
                },
              },
            }}
          />
        )}
        onCellEditComplete={(e) => handleCellEditComplete(e)}
        pt={{ bodyCell: { className: "text-center" } }}
      />
      <Column
        body={(data, options) => {
          if (isShowDefault) return "-";
          const totalQuestions =
            data.difficultDegree1 + data.difficultDegree2 + data.difficultDegree3 + data.difficultDegree4;
          return totalQuestions;
        }}
        pt={{ bodyCell: { className: "text-center" } }}
      />
      <Column
        body={(data, options) => {
          if (isShowDefault) return "-";

          const numsOfQuestion =
            data.difficultDegree1 + data.difficultDegree2 + data.difficultDegree3 + data.difficultDegree4;

          const totalQuestions = options.props?.value?.reduce((total, current) => {
            const currentNumsofQuestion =
              current.difficultDegree1 + current.difficultDegree2 + current.difficultDegree3 + current.difficultDegree4;

            return total + currentNumsofQuestion;
          }, 0);

          return Number((numsOfQuestion / totalQuestions) * 100).toFixed(2);
        }}
        pt={{ bodyCell: { className: "text-center" } }}
      />
      <Column
        body={(data, options) => {
          if (isShowDefault) return <TrashIcon className="[&>path]:stroke-gray-400" />;
          return (
            <Button
              className="focus:shadow-none"
              rounded
              text
              icon={<TrashIcon />}
              onClick={() => onClickDeleteTopic(data.topicLv1Id, data.topicLv2Id)}
            />
          );
        }}
      />
    </DataTable>
  );
}
