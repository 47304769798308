export const ACTION_TYPES: { [x: string]: any } = {
  CREATE_FOLDER: Symbol("CREATE_FOLDER"),
  CREATE_FOLDER_SUCCESS: Symbol("CREATE_FOLDER_SUCCESS"),
  CREATE_FOLDER_FAILED: Symbol("CREATE_FOLDER_FAILED"),
  GET_LIST_FOLDER_FILE: Symbol("GET_LIST_FOLDER_FILE"),
  GET_LIST_FOLDER_FILE_SUCCESS: Symbol("GET_LIST_FOLDER_FILE_SUCCESS"),
  GET_LIST_FOLDER_FILE_FAILED: Symbol("GET_LIST_FOLDER_FILE_FAILED"),
  UPDATE_FOLDER: Symbol("UPDATE_FOLDER"),
  UPDATE_FOLDER_SUCCESS: Symbol("UPDATE_FOLDER_SUCCESS"),
  UPDATE_FOLDER_FAILED: Symbol("UPDATE_FOLDER_FAILED"),
  DELETE_FOLDER: Symbol("DELETE_FOLDER"),
  DELETE_FOLDER_SUCCESS: Symbol("DELETE_FOLDER_SUCCESS"),
  DELETE_FOLDER_FAILED: Symbol("DELETE_FOLDER_FAILED"),
  SHARE_DOCUMENT: Symbol("SHARE_DOCUMENT"),
  SHARE_DOCUMENT_SUCCESS: Symbol("SHARE_DOCUMENT_SUCCESS"),
  SHARE_DOCUMENT_FAILED: Symbol("SHARE_DOCUMENT_FAILED"),
  DELETE_EXAMS: Symbol("DELETE_EXAMS"),
  DELETE_EXAMS_SUCCESS: Symbol("DELETE_EXAMS_SUCCESS"),
  DELETE_EXAMS_FAILED: Symbol("DELETE_EXAMS_FAILED"),
  PREVIEW_EXAM: Symbol("PREVIEW_EXAM"),
  PREVIEW_EXAM_SUCCESS: Symbol("PREVIEW_EXAM_SUCCESS"),
  PREVIEW_EXAM_FAILED: Symbol("PREVIEW_EXAM_FAILED"),
  CREATE_GROUP_EXAM: Symbol("CREATE_GROUP_EXAM"),
  CREATE_GROUP_EXAM_SUCCESS: Symbol("CREATE_GROUP_EXAM_SUCCESS"),
  CREATE_GROUP_EXAM_FAILED: Symbol("CREATE_GROUP_EXAM_FAILED"),
  GET_EXAM_DETAIL: Symbol("GET_EXAM_DETAIL"),
  GET_EXAM_DETAIL_SUCCESS: Symbol("GET_EXAM_DETAIL_SUCCESS"),
  GET_EXAM_DETAIL_FAILED: Symbol("GET_EXAM_DETAIL_FAILED"),
  CLEAN_PREVIEW_EXAM: Symbol("CLEAN_PREVIEW_EXAM"),
  CLEAR_LIST_FOLDER_FILE: Symbol("CLEAR_LIST_FOLDER_FILE"),
};
