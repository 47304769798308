import React from "react";
import { isMobileOrTabletDevice } from "../components/common/utils/helper";
import { Navigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const UseAppInDeskTop = () => {
  if (!isMobileOrTabletDevice()) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex flex-col justify-center gap-4 px-4 py-28">
      <h3 className="text-[24px] font-semibold text-center">
        <FormattedMessage id="UseAppInDeskTop.title" />
      </h3>
      <p className="text-[16px]">
        <FormattedMessage id="UseAppInDeskTop.description" />
      </p>
    </div>
  );
};

export default UseAppInDeskTop;
