import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TCommonState } from "../components/common/utils/type";
import { commonActions } from "../components/common/redux/action";
import { FormattedMessage, useIntl } from "react-intl";
import { ArrowDownIcon, ChangePasswordIcon, SettingIcon } from "../assets";
import { Avatar } from "primereact/avatar";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { distinctArrayObject, genSignature, getNonce } from "../components/common/utils/helper";
import { emailRegex, phoneRegex } from "../components/common/utils/constant";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../components/common/components/button/ButtonPrimary";

const TAB = {
  SETTINGS: 1,
  CHANGE_PASSWORD: 2,
};

export default function AccountInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const [tab, setTab] = useState(TAB.SETTINGS);
  const { teacherInfo, userInfos, gradeSubjects } = useSelector((state: { common: TCommonState }) => state?.common);
  const [accountInfo, setAccountInfo] = useState<any>();
  const [errorText, setErrorText] = useState<any>({});

  const handleGetTeacherInfo = () => {
    dispatch(commonActions.handleGetTeacherInfo());
    // get gradeAndSubject
    dispatch(commonActions.handleGetGradeAndSubjects());
  };

  const handleUpdateInfo = () => {
    let params: any = {
      nonce: getNonce(),
      timestamp: moment().unix(),
      full_name: accountInfo?.name || "",
      school: accountInfo?.school || "",
    };
    if (accountInfo?.phone !== teacherInfo?.phone) {
      params.phone = accountInfo?.phone;
    }
    if (accountInfo?.email !== teacherInfo?.email) {
      params.email = accountInfo?.email || "";
    }
    params = {
      ...params,
      signature: genSignature(params),
      grades_subjects_id: `[${accountInfo?.grades?.map((e) => `[${e},${accountInfo?.subject}]`).toString()}]`,
    };

    dispatch(commonActions.handleUpdateAccountInfo(params, () => navigate(0)));
  };

  const gradeChoices = useMemo(
    () => gradeSubjects.filter((e) => !e?.parent_id)?.map((e) => ({ value: e?.id, label: e?.title })),
    [gradeSubjects]
  );

  const subjectFromGradeIds = useMemo(() => {
    let countOccurrences: any = {};
    let subjectIds: number[] = [];

    const allSubjectsOfGrades = gradeSubjects.filter((e) => accountInfo?.grades?.includes(e?.parent_id));
    allSubjectsOfGrades.forEach((e) => {
      if (countOccurrences[e?.id]) {
        countOccurrences[e?.id] += 1;
      } else {
        countOccurrences[e?.id] = 1;
      }
    });
    Object.keys(countOccurrences).forEach((e) => {
      if (countOccurrences[e] === accountInfo?.grades?.length) {
        subjectIds.push(Number(e));
      }
    });
    return distinctArrayObject(
      allSubjectsOfGrades.filter((e) => subjectIds?.includes(e?.id)),
      "id"
    );
  }, [accountInfo?.grades, teacherInfo, gradeSubjects]);

  const checkDisableButtonEdit = useMemo(() => {
    if (
      isEmpty(accountInfo?.phone) ||
      !phoneRegex.test(accountInfo?.phone) ||
      !accountInfo?.grades ||
      accountInfo?.grades?.length === 0 ||
      !accountInfo?.subject
    )
      return true;
    const oldInfo = [
      teacherInfo?.name || "",
      teacherInfo?.email || "",
      teacherInfo?.phone,
      teacherInfo?.school || "",
      teacherInfo?.grade_register?.map((e) => e?.id),
      teacherInfo?.subject_register?.id,
    ];

    const updateInfo = [
      accountInfo?.name || "",
      accountInfo?.email || "",
      accountInfo?.phone,
      accountInfo?.school || "",
      accountInfo?.grades,
      accountInfo?.subject,
    ];
    return isEqual(oldInfo, updateInfo);
  }, [accountInfo, teacherInfo]);

  useEffect(() => {
    if (!!teacherInfo?.status) {
      setAccountInfo({
        name: teacherInfo?.name,
        email: teacherInfo?.email,
        phone: teacherInfo?.phone,
        school: teacherInfo?.school,
        grades: teacherInfo?.grade_register?.map((e) => e?.id),
        subject: teacherInfo?.subject_register?.id,
      });
    }
  }, [teacherInfo]);

  useEffect(() => {
    handleGetTeacherInfo();
  }, []);

  return (
    <div className="main-content flex">
      <div className="w-[330px] h-full flex flex-col py-8 px-4">
        <span className="flex font-bold text-[24px] leading-[36px] pl-6">
          <FormattedMessage id="accountInfo.title" />
        </span>
        <div className="flex flex-col pt-4">
          <button
            onClick={() => setTab(TAB.SETTINGS)}
            className={`h-[56px] rounded-[12px] ${tab === TAB.SETTINGS && "bg-primary-50"}`}
          >
            <span className="pl-6 flex items-center gap-2">
              <SettingIcon /> <FormattedMessage id="accountInfo.settings" />
            </span>
          </button>
          <button
            onClick={() => dispatch(commonActions.handleOpenDialogFeatureDeveloping(true))}
            className={`h-[56px] rounded-[12px] ${tab === TAB.CHANGE_PASSWORD && "bg-primary-50"}`}
          >
            <span className="pl-6 flex items-center gap-2">
              <ChangePasswordIcon /> <FormattedMessage id="accountInfo.changePassword" />{" "}
            </span>
          </button>
        </div>
      </div>

      <div className="py-8 px-10 w-[calc(100%-330px)] border-l h-max">
        <div className="flex w-full items-center">
          <Avatar
            label={userInfos?.member?.display_name || ""}
            image={userInfos?.member?.avatar}
            className="mr-4 w-[88px] h-[88px]"
            shape="circle"
          />
          <div className="flex flex-col w-[calc(100%-104px)]">
            <span className="line-clamp-2 text-left text-24">{userInfos?.member?.display_name}</span>
            <span className="font-[400] w-[calc(100%-15px)] truncate">{userInfos?.member?.email}</span>
          </div>
        </div>
        <div className="flex mt-[32px] w-full justify-between">
          <div className="flex flex-col gap-2 w-[calc(50%-22px)]">
            <label htmlFor="username" className="font-[600]">
              <FormattedMessage id="accountInfo.name" />
            </label>
            <InputText
              id="username"
              aria-describedby="name-error"
              value={accountInfo?.name}
              onChange={(e) => {
                if (e?.target?.value.length <= 64) {
                  setAccountInfo({ ...accountInfo, name: e?.target?.value });
                } else {
                  setErrorText({
                    ...errorText,
                    name: intl.formatMessage({ id: "account.fields.name.errorMessage" }),
                  });
                }
              }}
              onBlur={(event) => {
                setErrorText({
                  ...errorText,
                  name:
                    event?.target?.value.length <= 64
                      ? undefined
                      : intl.formatMessage({ id: "account.fields.name.errorMessage" }),
                });
              }}
            />
            {errorText?.name && (
              <small id="name-error" className="text-red-500 mt-[-5px]">
                {errorText?.name}
              </small>
            )}
          </div>
          <div className="flex flex-col gap-2 w-[calc(50%-22px)]">
            <label htmlFor="phone" className="font-[600]">
              <FormattedMessage id="accountInfo.phone" />
            </label>
            <InputText
              id="phone"
              aria-describedby="phone-error"
              value={accountInfo?.phone}
              onChange={(e) => {
                setAccountInfo({ ...accountInfo, phone: e?.target?.value });
              }}
              onBlur={(event) => {
                setErrorText({
                  ...errorText,
                  phone: phoneRegex.test(event.target.value)
                    ? undefined
                    : event.target.value?.length === 0
                    ? intl.formatMessage({ id: "account.fields.phone.emptyMessage" })
                    : intl.formatMessage({ id: "account.fields.phone.errorMessage" }),
                });
              }}
            />
            {errorText?.phone && (
              <small id="phone-error" className="text-red-500 mt-[-5px]">
                {errorText?.phone}
              </small>
            )}
          </div>
        </div>
        <div className="flex mt-[32px] w-full justify-between">
          <div className="flex flex-col gap-2 w-[calc(50%-22px)]">
            <label htmlFor="email" className="font-[600]">
              <FormattedMessage id="accountInfo.email" />
            </label>
            <InputText
              id="email"
              aria-describedby="email-error"
              value={accountInfo?.email}
              onChange={(e) => {
                setAccountInfo({ ...accountInfo, email: e?.target?.value });
              }}
              onBlur={(event) => {
                setErrorText({
                  ...errorText,
                  email:
                    event.target.value?.length === 0 || emailRegex.test(event.target.value)
                      ? undefined
                      : intl.formatMessage({ id: "account.fields.email.errorMessage" }),
                });
              }}
            />
            {errorText?.email && (
              <small id="email-error" className="text-red-500 mt-[-5px]">
                {errorText?.email}
              </small>
            )}
          </div>
        </div>
        <div className="flex mt-[32px] w-full flex-col">
          <label htmlFor="school" className="font-[600] pb-2">
            <FormattedMessage id="accountInfo.school" />
          </label>
          <InputText
            id="school"
            aria-describedby="school-error"
            value={accountInfo?.school}
            onChange={(e) => {
              if (e?.target?.value.length <= 250) {
                setAccountInfo({ ...accountInfo, school: e?.target?.value });
              } else {
                setErrorText({
                  ...errorText,
                  school: intl.formatMessage({ id: "account.fields.school.errorMessage" }),
                });
              }
            }}
            onBlur={(event) => {
              setErrorText({
                ...errorText,
                school:
                  event?.target?.value.length <= 250
                    ? undefined
                    : intl.formatMessage({ id: "account.fields.school.errorMessage" }),
              });
            }}
          />
          {errorText?.school && (
            <small id="school-error" className="text-red-500 mt-1">
              {errorText?.school}
            </small>
          )}
        </div>
        <div className="flex mt-[32px] w-full flex-col">
          <label htmlFor="school" className="font-[600] pb-2">
            <FormattedMessage id="accountInfo.grade" />
          </label>
          <MultiSelect
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            aria-describedby="grade-error"
            value={accountInfo?.grades}
            onChange={(e) => {
              if (e?.value?.length <= 3) {
                setAccountInfo({ ...accountInfo, grades: e?.value });
              }
              // else {
              //   setErrorText({ ...errorText, grade: intl.formatMessage({ id: "account.fields.grade.errorMessage" }) });
              // }
            }}
            showSelectAll={false}
            // panelHeaderTemplate={null}
            options={gradeChoices}
            className="text-ellipsis"
            pt={{
              input: {
                className: "pr-0",
              },
              header: {
                className: "hidden",
              },
            }}
          />
          {/* {errorText?.grade && (
            <small id="grade-error" className="text-red-500 mt-1">
              {errorText?.grade}
            </small>
          )} */}
        </div>
        <div className="flex mt-[32px] w-full flex-col">
          <label htmlFor="school" className="font-[600] pb-2">
            <FormattedMessage id="accountInfo.subject" />
          </label>
          <Dropdown
            dropdownIcon={<ArrowDownIcon stroke="#475467" />}
            value={accountInfo?.subject}
            onChange={(e) => setAccountInfo({ ...accountInfo, subject: e?.value })}
            options={subjectFromGradeIds?.map((e: any) => ({
              label: e?.title,
              value: e?.id,
            }))}
            pt={{
              input: {
                className: "pr-0",
              },
            }}
          />
        </div>
        <div className="flex w-full justify-end pt-8">
          <ButtonPrimary
            disabled={checkDisableButtonEdit}
            rounded
            className="w-[145px] h-[44px] flex justify-center font-bold"
            onClick={handleUpdateInfo}
          >
            <FormattedMessage id="accountInfo.edit" />
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
}
