import React from "react";
import { NoDataIcon } from "../../../assets";

type TNoDataProps = {
  message?: string;
};

export default function NoData({ message }: TNoDataProps) {
  return (
    <div className="flex flex-col items-center w-[289px]">
      <NoDataIcon />
      <span className="text-center">{message}</span>
    </div>
  );
}
