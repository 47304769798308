import { ACTION_TYPES } from "./actionTypes";

const createGroup = (params, successCallback = (groupHid: string) => {}) => {
  return {
    type: ACTION_TYPES.CREATE_GROUP,
    params,
    successCallback,
  };
};

const getListGroup = (callback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_LIST_GROUP,
    callback,
  };
};

const deleteGroup = (params, successCallback = () => {}, errorCallback = (error) => {}) => {
  return {
    type: ACTION_TYPES.DELETE_GROUP,
    params,
    successCallback,
    errorCallback,
  };
};

const updateGroup = (params, successCallback = () => {}, errorCallback = (error) => {}) => {
  return {
    type: ACTION_TYPES.UPDATE_GROUP,
    params,
    successCallback,
    errorCallback,
  };
};

const getGroupDetail = (
  params,
  successCallback = (groupDetail) => {},
  errorCallback = (error) => {},
  finallyCallback = () => {}
) => {
  return {
    type: ACTION_TYPES.GET_GROUP_DETAIL,
    params,
    successCallback,
    errorCallback,
    finallyCallback,
  };
};

const addMembersToGroup = (params, successCallback = () => {}, errorCallback = (error) => {}) => {
  return {
    type: ACTION_TYPES.ADD_MEMBERS_TO_GROUP,
    params,
    successCallback,
    errorCallback,
  };
};

const removeMemberOfGroup = (params, successCallback = () => {}, errorCallback = (error) => {}) => {
  return {
    type: ACTION_TYPES.REMOVE_MEMBER_OF_GROUP,
    params,
    successCallback,
    errorCallback,
  };
};

const getGroupActionHistory = (params, successCallback = () => {}, errorCallback = (error) => {}) => {
  return {
    type: ACTION_TYPES.GET_GROUP_ACTION_HISTORY,
    params,
    successCallback,
    errorCallback,
  };
};

const addMembersOfGroupToRoom = (params, finallyCallback = () => {}) => {
  return {
    type: ACTION_TYPES.ADD_MEMBERS_OF_GROUP_TO_ROOM,
    params,
    finallyCallback,
  };
};

const getMemberResultInGroup = (params, finallyCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_MEMBER_RESULT_IN_GROUP,
    params,
    finallyCallback,
  };
};

const getAllMembers = (finallyCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_ALL_MEMBERS,
    finallyCallback,
  };
};

const getMemberResult = (params, finallyCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_MEMBER_RESULT,
    params,
    finallyCallback,
  };
};

export const manageStudentActions = {
  createGroup,
  getListGroup,
  deleteGroup,
  updateGroup,
  getGroupDetail,
  addMembersToGroup,
  removeMemberOfGroup,
  getGroupActionHistory,
  addMembersOfGroupToRoom,
  getMemberResultInGroup,
  getAllMembers,
  getMemberResult,
};
