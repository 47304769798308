import React, { useMemo } from "react";
import { Button as PrimeButton, ButtonProps } from "primereact/button";

type TButtonSize = "medium" | "large";
type TButtonIconPos = "left" | "right";

export type TButtonBaseProps = ButtonProps & {
  buttonSize?: TButtonSize;
  buttonIconPos?: TButtonIconPos;
};

export default function ButtonBase({
  buttonSize = "medium",
  buttonIconPos = "left",
  className,
  pt,
  ...props
}: TButtonBaseProps) {
  // overwrite classNameForButtonSize by pass className `min-w` at props.
  const classNameForButtonSize = useMemo(() => {
    switch (buttonSize) {
      case "medium": {
        return "min-w-[145px]";
      }
      case "large": {
        return "min-w-[343px]";
      }
    }
  }, [buttonSize]);

  // if use external icon, iconPos will unavailable, so this style will set icon position
  const classNameForButtonIconPos = useMemo(() => {
    if (buttonIconPos === "left") {
      return "flex-row";
    } else {
      return "flex-row-reverse";
    }
  }, [buttonIconPos]);

  return (
    <PrimeButton
      rounded
      iconPos={buttonIconPos}
      className={`justify-center gap-2 h-[44px] mr-0 transition-colors focus:shadow-none ${classNameForButtonSize} ${classNameForButtonIconPos} ${className}`}
      {...props}
      pt={{
        label: {
          className: "grow-0 text-[16px] font-semibold leading-6",
        },
        ...pt,
      }}
    />
  );
}
