import { classNames } from "primereact/utils";
import React from "react";
import { quizAnswerType } from "../../common/utils/constant";

export default function CreateQuizLeftSide({ listQuestion, checkEnableEditExam, setOrdinalQuestion }) {
  return (
    <div className="w-full h-full">
      <div className="pl-10 pr-4 pt-6 space-y-6">
        <span className="text-gray-900 font-[600]">Đáp án</span>
        {checkEnableEditExam && (
          <div className="space-y-1">
            {listQuestion
              ?.filter((question) => !question?.deleted)
              ?.filter((question) => question?.answer_type !== quizAnswerType.NONE)
              ?.map((e: any, index: number) => (
                <div
                  key={e.ordinal}
                  className="p-2"
                  role="button"
                  onClick={() => {
                    setOrdinalQuestion(e?.ordinal);
                  }}
                >
                  <div className="flex gap-x-4">
                    <p
                      className={classNames("text-gray-900 text-[12px]", {
                        "mt-3": [quizAnswerType.ONE_ANSWER, quizAnswerType.MANY_ANSWER, undefined].includes(
                          e.answer_type
                        ),
                      })}
                    >
                      {index + 1}
                    </p>
                    {(() => {
                      switch (e.answer_type) {
                        case quizAnswerType.ONE_ANSWER:
                        case quizAnswerType.MANY_ANSWER:
                          return (
                            <div className="flex-1 grid grid-cols-4 gap-y-2">
                              {e?.options?.map((option, index) => (
                                <p
                                  key={index}
                                  className={classNames(
                                    "flex justify-center items-center",
                                    "w-[40px] h-[40px] rounded-full border-[1px] bg-gray-100 text-[12px] text-gray-900",
                                    {
                                      "bg-success text-white": option.correct,
                                    }
                                  )}
                                >
                                  {option?.answer_key}
                                </p>
                              ))}
                            </div>
                          );
                        case quizAnswerType.CONSTRUCTED_RESPONSE:
                          return (
                            e?.options[0].contents[0] && (
                              <span className="font-normal text-gray-900 text-[12px]">
                                {e?.options[0].contents[0].content}
                              </span>
                            )
                          );
                      }
                    })()}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
