import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { AddExamIcon } from "../../../assets";
import { eventTracking } from "../../../firebase/firebaseConfig";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";

type TManageGroupHeaderProps = {
  onClickCreateGroupButton: () => void;
  onSearch: (keyword: string) => void;
};

export default function ManageGroupHeader({ onClickCreateGroupButton, onSearch }: TManageGroupHeaderProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const intl = useIntl();

  const handleSearch = () => {
    onSearch(inputValue);
  };

  return (
    <>
      <div className="flex justify-between px-10 py-6 border-t border-b border-gray200">
        <ButtonPrimary
          icon={<AddExamIcon />}
          label={intl.formatMessage({ id: "createGroup.button" })}
          onClick={() => eventTracking("select_create_group_button", undefined, () => onClickCreateGroupButton())}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search hover:cursor-pointer" onClick={handleSearch} />
          <InputText
            placeholder={intl.formatMessage({ id: "common.search.placeholder" })}
            className="w-[270px] h-[44px] border border-gray200 rounded-lg"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              if (e.target.value.length === 0) onSearch("");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
        </span>
      </div>
    </>
  );
}
