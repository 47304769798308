import moment from "moment";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { CopyIcon } from "../../../assets";
import ButtonSecondary from "../../common/components/button/ButtonSecondary";
import { QUIZ_LEVEL } from "../../common/utils/constant";
import { eventTracking } from "../../../firebase/firebaseConfig";

export default function RoomInfomation({ roomInfo }) {
  const intl = useIntl();
  return (
    <div className="flex-1 flex flex-col gap-10 px-10 py-8">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.subject" />
          </div>
          <div>{roomInfo?.subject_title}</div>
        </div>
        <hr className="border-gray-200" />

        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.slots" />
          </div>
          <div>{roomInfo?.max_slot}</div>
        </div>
        <hr className="border-gray-200" />

        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.class" />
          </div>
          <div>{roomInfo?.grade?.title}</div>
        </div>
        <hr className="border-gray-200" />

        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.roomType" />
          </div>
          <div>{roomInfo?.room_type.title}</div>
        </div>
        <hr className="border-gray-200" />

        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.quizLevel" />
          </div>
          <div>
            {roomInfo &&
              (roomInfo?.quiz_level_title || (
                <FormattedMessage id={QUIZ_LEVEL.find((e) => e.level === roomInfo?.quiz_level)?.title} />
              ))}
          </div>
        </div>
        <hr className="border-gray-200" />

        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.numOfQuestion" />
          </div>
          <div>{roomInfo?.num_of_questions}</div>
        </div>
        <hr className="border-gray-200" />

        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.timeQuiz" />
          </div>
          <div>
            <FormattedMessage id="common.minutesWithValue" values={{ nums: roomInfo?.time }} />
          </div>
        </div>
        <hr className="border-gray-200" />

        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.startTime" />
          </div>
          <div>{moment.unix(roomInfo?.start_time).format("HH:mm DD/MM/YYYY")}</div>
        </div>
        <hr className="border-gray-200" />

        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.endTime" />
          </div>
          <div>{moment.unix(roomInfo?.end_time).format("HH:mm DD/MM/YYYY")}</div>
        </div>
        <hr className="border-gray-200" />

        <div className="flex justify-between">
          <div>
            <FormattedMessage id="roomDetail.delaySubmit" />
          </div>
          <div>
            <FormattedMessage id="common.minutesWithValue" values={{ nums: roomInfo?.delay_submit_time }} />
          </div>
        </div>
        <hr className="border-gray-200" />

        {/* <div className="flex justify-between">
          <div>Phí tham gia</div>
          <div>{roomInfo.fee}</div>
        </div>
        <hr className="border border-gray-200" /> */}

        <div className="flex justify-between">
          <div className="w-1/3">
            <FormattedMessage id="roomDetail.linkJoin" />
          </div>
          <div className="flex items-center justify-end w-2/3">
            <div className="flex items-center gap-2">
              <span className="flex-1 break-all">{roomInfo?.direct_link_auto}</span>
              <CopyToClipboard
                text={roomInfo?.direct_link_auto}
                onCopy={() => {
                  eventTracking("select_dr_copy_invited_link");
                  toast.success(intl.formatMessage({ id: "common.copiedSuccessfully" }));
                }}
              >
                <CopyIcon className="hover:cursor-pointer" />
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>

      <hr className="border-2 border-gray-300" />

      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="w-1/3">
            <FormattedMessage id="roomDetail.roomCode" />
          </div>
          <div className="flex items-center justify-end w-2/3">
            <div className="flex items-center gap-2">
              {roomInfo?.room_hid}
              <CopyToClipboard
                text={roomInfo?.room_hid}
                onCopy={() => {
                  eventTracking("select_dr_copy_room_code");
                  toast.success(intl.formatMessage({ id: "common.copiedSuccessfully" }));
                }}
              >
                <CopyIcon className="hover:cursor-pointer" />
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <hr className="border-gray-200" />

        {roomInfo?.password && (
          <>
            <div className="flex justify-between">
              <div className="w-1/3">
                <FormattedMessage id="roomDetail.password" />
              </div>
              <div className="flex items-center justify-end w-2/3">
                <div className="flex items-center gap-2">
                  <span className="flex-1 break-all">{roomInfo?.password}</span>
                  <CopyToClipboard
                    text={roomInfo?.password}
                    onCopy={() => {
                      eventTracking("select_dr_copy_room_pass");
                      toast.success(intl.formatMessage({ id: "common.copiedSuccessfully" }));
                    }}
                  >
                    <CopyIcon className="hover:cursor-pointer" />
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <hr className="border-gray-200" />
          </>
        )}

        <div className="flex justify-between">
          <div className="w-1/3">
            <FormattedMessage id="roomDetail.linkRoom" />
          </div>
          <div className="flex items-center justify-end w-2/3">
            <div className="flex items-center gap-2">
              <span className="flex-1 break-all">{roomInfo?.direct_link_slug}</span>
              <CopyToClipboard
                text={roomInfo?.direct_link_slug}
                onCopy={() => {
                  eventTracking("select_dr_copy_room_link");
                  toast.success(intl.formatMessage({ id: "common.copiedSuccessfully" }));
                }}
              >
                <CopyIcon className="hover:cursor-pointer" />
              </CopyToClipboard>
            </div>
          </div>
        </div>

        {roomInfo?.note && (
          <>
            <hr className="border-gray-200" />

            <div className="flex justify-between">
              <div className="w-1/3">
                <FormattedMessage id="roomDetail.note" />
              </div>
              <div className="flex items-center justify-end w-2/3">
                <div>{roomInfo?.note}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <hr className="border-2 border-gray-300" />
      <div className="flex flex-col gap-4">
        <div>
          {/* <div>
          <FormattedMessage id="detailRoom.displayStatusLabel" />
        </div> */}
          <span>
            {roomInfo?.display_status === "open"
              ? intl.formatMessage({ id: "detailRoom.displayStatusOpen" })
              : intl.formatMessage({ id: "detailRoom.displayStatusClosed" })}
          </span>
        </div>
        <hr className="border-gray-200" />
        <div className="">
          {/* <div>
          <FormattedMessage id="detailRoom.teacherInviteLabel" />
        </div> */}
          <span>
            {Boolean(roomInfo?.teacher_invitee_only)
              ? intl.formatMessage({ id: "detailRoom.teacherInviteOnly" })
              : intl.formatMessage({ id: "detailRoom.teacherAcceptInvite" })}
          </span>
        </div>
        <hr className="border-gray-200" />
        <div className="flex justify-between">
          <div>
            <FormattedMessage id="detailRoom.notify" />
          </div>
          <span>
            {Boolean(roomInfo?.is_notification)
              ? intl.formatMessage({ id: "detailRoom.notify.on" })
              : intl.formatMessage({ id: "detailRoom.notify.off" })}
          </span>
        </div>
      </div>
      {roomInfo?.exam_codes?.length > 0 && (
        <div className="space-y-3">
          <p className="text-gray-900">
            <FormattedMessage id="roomDetail.exam_codes" />
          </p>
          <div className="grid grid-cols-4 gap-x-5 gap-y-3">
            {roomInfo?.exam_codes?.map((title) => (
              <div>
                <a target="_blank" href={`${window.location.href}/${title}`}>
                  <ButtonSecondary onClick={() => eventTracking("select_dr_shuffled_exam")}>{title}</ButtonSecondary>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
