import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import React, { ReactNode, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { DownloadIcon, MoveExamIcon, ShareIcon, TrashIcon } from "../../../assets";
import { EExamActions, TExam } from "../../../types/exam";
import NoData from "../../common/components/NoData";
import { commonActions } from "../../common/redux/action";
import ExamItem from "./ExamItem";

type TExamTableProps = {
  examList: TExam[];
  hiddenActionKeys: EExamActions[];
  onClickShareExams: (examIds: number[]) => void;
  onClickDeleteExams: (examIds: number[]) => void;
  onClickCreateRoom: (examId: number) => void;
};

export default function ExamTable({
  examList,
  hiddenActionKeys,
  onClickShareExams,
  onClickDeleteExams,
  onClickCreateRoom,
}: TExamTableProps) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isCheckedMultiple, setIsCheckedMultiple] = useState<boolean>(false);
  const [checkedExamIds, setCheckedExamIds] = useState<number[]>([]);

  const handleClickCheckboxSingleExam = (isChecked: boolean, examId: number) => {
    if (isChecked) setCheckedExamIds((prev) => [...prev, examId]);
    else {
      setCheckedExamIds((prev) => prev.filter((item) => item !== examId));
    }
  };

  const handleClickCheckboxMultipleExam = (checked: boolean) => {
    if (checked) setCheckedExamIds(examList.map((exam) => exam.id));
    else setCheckedExamIds([]);
  };

  useEffect(() => {
    if (checkedExamIds.length === 0) {
      setIsCheckedMultiple(false);
    } else {
      setIsCheckedMultiple(true);
    }
  }, [checkedExamIds]);

  useEffect(() => {
    setCheckedExamIds([]);
  }, [examList]);

  const handleOpenDialogFeatureDeveloping = () => {
    dispatch(commonActions.handleOpenDialogFeatureDeveloping(true));
  };
  const examActions: {
    key: EExamActions;
    icon: ReactNode;
    onClick?: () => void;
  }[] = [
    {
      key: EExamActions.SHARE,
      icon: <ShareIcon />,
      onClick: () => onClickShareExams(checkedExamIds),
    },
    {
      key: EExamActions.MOVE,
      icon: <MoveExamIcon />,
      onClick: () => handleOpenDialogFeatureDeveloping(),
    },
    {
      key: EExamActions.DOWNLOAD,
      icon: <DownloadIcon />,
      onClick: () => handleOpenDialogFeatureDeveloping(),
    },
    {
      key: EExamActions.DELETE,
      icon: <TrashIcon />,
      onClick: () => onClickDeleteExams(checkedExamIds),
    },
  ];

  return (
    <div className="px-10 py-3">
      <table className="table-fixed w-full text-left text-gray-500 dark:text-gray-400">
        <thead className="text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th colSpan={2} className="px-4 py-3">
              <div className="flex justify-center">
                <Checkbox
                  onChange={(e) => handleClickCheckboxMultipleExam(e.checked as boolean)}
                  checked={isCheckedMultiple}
                  pt={{
                    input: {
                      className: "[&]:shadow-none",
                    },
                  }}
                />
              </div>
            </th>
            {isCheckedMultiple ? (
              <th colSpan={41} className="px-4 py-3">
                <div className="flex gap-8 items-center justify-start">
                  {examActions.map((action) => {
                    if (hiddenActionKeys.includes(action.key)) return null;
                    return (
                      <Button
                        key={action.key}
                        icon={action.icon}
                        className="flex items-center w-[24px] h-[24px] bg-transparent border-none focus:shadow-none"
                        onClick={action.onClick}
                      />
                    );
                  })}
                </div>
              </th>
            ) : (
              <>
                {/* TODO: Make column and items synchronized */}
                <th colSpan={15} className="px-4 py-3">
                  <FormattedMessage id="examList.col.name" />
                </th>
                <th colSpan={5} className="px-4 py-3">
                  <FormattedMessage id="examList.col.status" />
                </th>
                <th colSpan={6} className="px-4 py-3">
                  <FormattedMessage id="examList.col.updateAt" />
                </th>
                <th colSpan={10} className="px-4 py-3" />
                <th colSpan={5} className="px-4 py-3 text-center">
                  <FormattedMessage id="examList.col.createRoom" />
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {examList?.map((exam) => (
            <ExamItem
              key={exam.id}
              exam={exam}
              isChecked={checkedExamIds.includes(exam.id)}
              hiddenActionKeys={hiddenActionKeys}
              onClickDelete={(id: number) => onClickDeleteExams([id])}
              onClickShare={(id: number) => onClickShareExams([id])}
              onClickCheckbox={handleClickCheckboxSingleExam}
              onClickCreateRoom={onClickCreateRoom}
            />
          ))}
        </tbody>
      </table>
      {examList?.length === 0 && (
        <div className="w-full flex justify-center mt-8">
          <NoData message={intl.formatMessage({ id: "noExamData" })} />
        </div>
      )}
    </div>
  );
}
