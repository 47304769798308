import { InputText } from "primereact/inputtext";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { eventTracking } from "../../../firebase/firebaseConfig";
import ButtonGhost from "../../common/components/button/ButtonGhost";
import ButtonPrimary from "../../common/components/button/ButtonPrimary";
import Dialog from "../../common/components/dialog/Dialog";

type TAddMemberDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formValue: { memberId: string }) => void;
};

export default function AddMemberDialog({ isOpen, onClose, onSubmit }: TAddMemberDialogProps) {
  const intl = useIntl();

  const [formValue, setFormValue] = useState<{
    memberId: string;
  }>({
    memberId: "",
  });

  const validateMemberId = (memberId: string) => {
    if (memberId.trim().length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    onSubmit(formValue);
  };

  const isDisableButtonSubmit = useMemo(() => !validateMemberId(formValue.memberId), [formValue]);

  const actions = (
    <div className="flex justify-end gap-4">
      <ButtonGhost label={intl.formatMessage({ id: "common.cancel" })} onClick={onClose} />
      <ButtonPrimary
        label={intl.formatMessage({ id: "common.add" })}
        onClick={handleSubmit}
        disabled={isDisableButtonSubmit}
      />
    </div>
  );

  useEffect(() => {
    eventTracking("dl_add_member_view");
  }, []);

  return (
    <Dialog
      title={intl.formatMessage({ id: "addMemberDialog.title" })}
      isOpen={isOpen}
      onClose={onClose}
      actions={actions}
    >
      <InputText
        className="w-full shadow-none focus:border-secondary hover:border-secondary"
        placeholder={intl.formatMessage({
          id: "addMemberDialog.textField.placeholder",
        })}
        value={formValue.memberId}
        onChange={(e) => {
          validateMemberId(e.target.value);
          setFormValue((prev) => ({ ...prev, memberId: e.target.value }));
        }}
      />
    </Dialog>
  );
}
