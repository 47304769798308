import React from "react";

import { FileUploader } from "react-drag-drop-files";
import { UploadFile } from "../../../assets";
import { FormattedMessage } from "react-intl";

type UploadFileType = {
  fileTypes: string[];
  handleChange: (v: any) => void;
};

export const UploadFileComponent = ({
  fileTypes,
  handleChange,
}: UploadFileType) => {
  const [typeError, setTypeError] = React.useState(false);
  return (
    <FileUploader
      handleChange={(file: File) => {
        handleChange(file);
        setTypeError(false);
      }}
      name="file"
      types={fileTypes}
      onTypeError={() => setTypeError(true)}
    >
      <div className="flex justify-center items-center flex-col gap-2 border-[1px] 2xl:w-[512px] sm:w-[400px] h-[108px] rounded-[12px]">
        <button
          style={{ height: "46px", width: "46px" }}
          className={`${typeError && "border border-red"} hover:bg-white`}
        >
          <UploadFile />
        </button>
        {typeError && (
          <span className="text-red text-[10px] my-[-5px]">
            <FormattedMessage id="common.fileTypeError" />
          </span>
        )}
        <span className="text-[14px] text-center">
          <span className="font-[600] text-secondary">
            <FormattedMessage id="common.inputFile.action" />{" "}
          </span>
          <span className="text-gray-900">
            <FormattedMessage id="common.inputFile.description" /> (
            {fileTypes.map((type: string) => `${type}`).join(", ")})
          </span>
        </span>
      </div>
    </FileUploader>
  );
};
