import React from "react";
import ButtonBase, { TButtonBaseProps } from "./ButtonBase";

type TButtonSecondaryProps = TButtonBaseProps;

export default function ButtonSecondary({ className, ...props }: TButtonSecondaryProps) {
  return (
    <ButtonBase
      className={`
      text-secondary hover:text-secondary-700 disabled:text-secondary-50
        bg-transparent hover:bg-secondary-50 disabled:bg-transparent
        border 
      border-secondary disabled:border-secondary-50
        ${className}
      `}
      {...props}
    ></ButtonBase>
  );
}
