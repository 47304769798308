export type TFolder = {
  id: number;
  title: string;
  slug: string;
};

export enum EFolderActions {
  ADD = "ADD",
  EDIT = "EDIT",
  SHARE = "SHARE",
  DOWNLOAD = "DOWNLOAD",
  DELETE = "DELETE",
}
