import React from "react";
import ShowBasicDataContent from "./ShowBasicDataContent";
import ButtonBase from "../../common/components/button/ButtonBase";
import { AddQuestionIcon, FlagIcon, DeleteQuestionIcon, TrashIcon } from "../../../assets";
import { ANSWER_KEY, quizAnswerType } from "../../common/utils/constant";
import SelectionsCard from "./QuizComponents/SelectionsCard";
import SortPositionCard from "./QuizComponents/SortPositionCard";
import OneAnswerCard from "./QuizComponents/OneAnswerCard";
import { sortBy, isEqual } from "lodash";

type TProps = {
  qs: any;
  solution: any;
  isShowSolution: boolean;
  parentQs?: any;
  questionNumber: number;
  isThousandQuestions?: boolean;
  questionsSelected?: any;
  isShowSmallCancelButton?: boolean;
  onOpenReportDialog: (questionId: number) => void;
  onClickShowSolution: (questionId: number) => void;
  onChooseQuestion?: (question: any, actionType: "add" | "remove") => void;
  onClickQuestionContent?: () => void;
};

function QuestionRow({
  qs,
  solution,
  isShowSolution,
  parentQs,
  questionNumber,
  isThousandQuestions = false,
  questionsSelected,
  isShowSmallCancelButton = false,
  onOpenReportDialog,
  onClickShowSolution,
  onChooseQuestion = (question, actionType) => {},
  onClickQuestionContent = () => {},
}: TProps) {
  // original_id là id của question ở database_1 từ phía BE
  const isSelected = questionsSelected?.findIndex((e) => e?.id === qs?.id || e?.original_id === qs?.id) > -1;
  return (
    <div id={`question_${qs?.ordinal}`} className="flex flex-col gap-6">
      <div className="flex justify-between">
        <div className="flex flex-col flex-1">
          <div className="mt-2 inline-block font-semibold">Câu {questionNumber}:</div>
          {sortBy(parentQs?.questions, "ordinal")?.map((e, ix) => (
            <div key={ix} className="flex items-baseline w-full gap-2">
              <div className="flex-1 text-start">
                <ShowBasicDataContent ele={e} />
              </div>
            </div>
          ))}
          <span className="font-semibold inline-block" onClick={onClickQuestionContent}>
            {qs?.questions?.map((ele: any) => <ShowBasicDataContent key={ele.id} ele={ele} />)}
          </span>
        </div>
        <ButtonBase
          label="Báo lỗi"
          icon={<FlagIcon />}
          buttonIconPos="right"
          className="bg-transparent border-gray-200 rounded-[8px] text-gray-900 text-[14px] font-semibold min-w-min px-2 py-1 h-[32px] tracking-tighter"
          onClick={() => onOpenReportDialog(qs.id)}
        />
      </div>
      <div className={`${isThousandQuestions && "flex items-center gap-4"}`}>
        {(() => {
          switch (qs?.answer_type) {
            case quizAnswerType.SORT_POSITION: {
              return <SortPositionCard options={qs?.options} solution={solution} />;
            }
            case quizAnswerType.ONE_ANSWER: {
              return <OneAnswerCard options={qs?.options} />;
            }
            case quizAnswerType.CONCAT_LETTER_EN:
            case quizAnswerType.CONCAT_LETTER_VI:
            case quizAnswerType.CONCAT_NUMBER:
            case quizAnswerType.CONCAT_WORD:
            case quizAnswerType.CONCAT_LETTER_MIX:
              return <SelectionsCard options={qs.options} solution={solution} />;
          }
        })()}
        {isThousandQuestions && (
          <>
            {isSelected && !isShowSmallCancelButton && (
              <button
                className="bg-error border-error w-[69px] h-[92px] min-w-[69px] rounded-[16px] flex items-center flex-col justify-center gap-3"
                onClick={() => onChooseQuestion(qs, "remove")}
              >
                <DeleteQuestionIcon />
                <span className="text-white font-[600]">Huỷ</span>
              </button>
            )}
            {!isSelected && (
              <button
                className="bg-primary border-primary w-[69px] h-[92px] min-w-[69px] rounded-[16px] flex items-center flex-col justify-center gap-3"
                onClick={() => onChooseQuestion(qs, "add")}
              >
                <AddQuestionIcon />
                <span className="text-white font-[600]">Thêm</span>
              </button>
            )}
          </>
        )}
      </div>
      {!!isShowSolution && (
        <div className="flex justify-between items-center w-full font-semibold">
          <button
            className="text-secondary"
            onClick={() => {
              onClickShowSolution(qs.id);
            }}
          >
            Hướng dẫn giải
          </button>
          {isShowSmallCancelButton && (
            <button className="flex gap-2 text-error" onClick={() => onChooseQuestion(qs, "remove")}>
              <TrashIcon />
              Hủy
            </button>
          )}
        </div>
      )}
    </div>
  );
}
const checkEqualProps = (preProps, nextProps) => {
  return (
    isEqual(preProps?.qs, nextProps?.qs) &&
    isEqual(preProps?.solution, nextProps?.solution) &&
    isEqual(preProps?.parentQs, nextProps?.parentQs) &&
    isEqual(preProps?.questionNumber, nextProps?.questionNumber) &&
    isEqual(preProps?.questionsSelected, nextProps?.questionsSelected) &&
    isEqual(preProps?.onChooseQuestion, nextProps?.onChooseQuestion)
  );
};
export default React.memo(QuestionRow, checkEqualProps);
