import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { DiamondRoomCard, GiftItem, IconBorderGrade, RoomUnLock } from "../../../assets";
import { Avatar } from "primereact/avatar";
import { Image } from "primereact/image";
import { QUIZ_LEVEL } from "../../common/utils/constant";
import { Divider } from "primereact/divider";
import { getRoomStatus } from "../utils/helper";
import LoadingSvg from "../../images/LoadingSvg";

const roomStatus = [
  {
    messageId: "Arena.room_status.waiting",
    color: "#38B000",
  },
  {
    messageId: "Arena.room_status.playing",
    color: "#B00020",
  },
  {
    messageId: "Arena.room_status.finished",
    color: "#9E9E9E",
  },
];

type TRoomCard = {
  room: any;
  subject: any;
  handleDirectDetail?: any;
  index: string | number;
  formatDate?: string;
  isPreview?: boolean;
  type?: string;
  hideStatus?: boolean;
  grades: any;
};

export default function RoomCard({
  room,
  subject,
  grades,
  handleDirectDetail,
  isPreview = false,
  type = "",
  hideStatus = false,
}: TRoomCard) {
  return (
    <div
      id="roomCard"
      style={{
        borderRadius: 15,
        color: "black",
        display: "flex",
        flexDirection: "row",
        // margin: "8px",
        cursor: "pointer",
        width: isPreview ? "98%" : "100%",
      }}
      onClick={() => handleDirectDetail(room)}
      className={`sm:px-0 sm:h-auto lg:h-max max-h-[214px] py-2 relative ${
        room?.is_pinned && type === "listRoom" ? "bg-lightYellow border-gray border" : "border-gray border"
      }`}
    >
      <div className="flex flex-row justify-between sm:w-1/2 w-max shrink" id="roomCardHeader">
        <div className="grid grid-cols-1 grid-rows-2 items-center justify-items-center mx-auto min-w-[100px]">
          <div className="mt-2">
            <div style={{ position: "relative" }}>
              <LoadingSvg fileName={subject?.slug?.replace("-", "_")} />
              <Avatar
                image={room?.owner?.photo_url}
                imageAlt={room?.owner?.name}
                shape="circle"
                className="absolute w-[24px] h-[24px] right-0 bottom-0"
              />
            </div>
          </div>
          <div className="text-center mt-[-10px]">
            <span className="text-primary font-semibold text-[14px] line-clamp-2 break-words px-2">{room?.title}</span>
            <div className="text-[12px] text-gray-700 text-center">
              <span
                id="roomSubjectTitle"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                className="mt-1"
              >
                <FormattedMessage
                  id="Arena.room_subject"
                  values={{
                    title: subject?.title,
                  }}
                />
              </span>
              <span className="flex justify-center mt-1">{grades?.find((e) => e?.id === room?.subject_id)?.title}</span>
              {room?.fee_join > 0 && (
                <span className="flex items-center text-[12px] gap-1 text-green justify-center whitespace-nowrap">
                  <FormattedMessage id="arena.fee.join.room" values={{ fee: room?.fee_join }} />
                  <DiamondRoomCard />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="py-2">
          <Divider layout="vertical" />
        </div>
      </div>
      <div
        className="flex flex-row justify-between p-2"
        style={{
          display: "flex",
          width: "100%",
          // gap: matches1500 ? 20 : 10
        }}
      >
        <div className="flex flex-col gap-y-1 justify-start text-left ">
          <span className="text-[14px] text-gray-900">
            <FormattedMessage id="Arena.room_num_of_questions" values={{ num: room?.num_of_questions }} />
          </span>
          <span className="text-[14px] text-gray-900">
            <FormattedMessage id="Arena.room_member" values={{ num: room?.num_of_members, max: room?.max_slot }} />
          </span>
          <span className="text-[14px] text-gray-900">
            <FormattedMessage id="Arena.room_type" values={{ type: room?.room_type?.title }} />
          </span>

          <span className="flex items-center flex-row flex-nowrap text-[14px] text-gray-900">
            <FormattedMessage id="room.exam.type" />:{" "}
            {room?.quiz_level_title || (
              <FormattedMessage id={QUIZ_LEVEL.find((e) => e.level === room?.quiz_level)?.title} />
            )}
          </span>
          <span className="text-[14px] text-gray-900">
            <FormattedMessage id="Arena.room_quiz_time" values={{ time: room?.time }} />
          </span>
          <span className="text-[14px] text-gray-900 whitespace-nowrap" style={{ wordWrap: "normal" }}>
            <FormattedMessage
              id="Arena.room_start_time"
              values={{
                time: moment.unix(room?.start_time).format("HH:mm DD/MM/YYYY"),
              }}
            />
          </span>
          <span className="text-[14px] text-gray-900 whitespace-nowrap">
            <FormattedMessage
              id="Arena.room_end_time"
              values={{
                time: moment.unix(room.end_time).format("HH:mm DD/MM/YYYY"),
              }}
            />
          </span>
        </div>
        <div id="roomStatus" className="flex absolute right-2 top-2 flex-col items-center w-[50px]">
          <div className="flex flex-row items-center gap-x-1.5 flex-nowrap">
            {(room?.star || room?.has_gift_item) && <GiftItem />}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <RoomUnLock />
            </div>
          </div>
          {!hideStatus && (
            <span
              style={{ color: roomStatus.find((e) => e.messageId === getRoomStatus(room))?.color }}
              className="text-[12px] whitespace-nowrap"
            >
              <FormattedMessage id={getRoomStatus(room)} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
