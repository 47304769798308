import orderBy from "lodash/orderBy";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ProfileAddIcon } from "../../../assets";
import Paging from "../../common/components/Paging";
import { stringToSlug } from "../../common/utils/helper";
import { TCommonState } from "../../common/utils/type";
import MemberItem from "./MemberItem";

const ITEMS_PER_PAGE = 10;

type TMemberAndRankingProps = {
  mode: "ranking" | "member";
  members?: any[];
  roomInfo: any;
  highScores?: any[];
  isShowAddMemberButton: boolean;
  onClickAddMember: () => void;
  onDeleteMember: (memberId) => void;
};

export default function MemberAndRanking({
  mode,
  members,
  roomInfo,
  highScores,
  isShowAddMemberButton,
  onClickAddMember,
  onDeleteMember,
}: TMemberAndRankingProps) {
  const intl = useIntl();

  const userInfos = useSelector((state: { common: TCommonState }) => state.common.userInfos);
  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");

  const listMember = useMemo(() => {
    let listMemberRender: any[] = [];
    listMemberRender = (members as any[])?.filter(
      (member: any) => stringToSlug(member.display_name)?.includes(stringToSlug(searchText))
    );

    if (highScores) {
      // add ranking, highscore and exam_code
      listMemberRender = listMemberRender.map((member: any) => {
        const memberHighScore = highScores.find((memberHighScore) => memberHighScore.member_hid === member.member_hid);

        if (memberHighScore) {
          return {
            ...member,
            ranking: memberHighScore.ranking,
            score: memberHighScore.score,
            exam_code: memberHighScore.exam_code,
          };
        }
        return member;
      });

      // sorting
      listMemberRender = orderBy(listMemberRender, (item) => {
        return item.ranking || Number.MAX_VALUE;
      });
    }

    return listMemberRender;
  }, [members, highScores, searchText]);

  const maxPage = Math.floor((listMember?.length - 1) / ITEMS_PER_PAGE) + 1;

  useEffect(() => {
    if (searchText.length > 0 && page > 1) {
      setPage(1);
    }
  }, [searchText]);

  useEffect(() => {
    document.querySelector(".main-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [page]);

  return (
    <div className="flex flex-col gap-6 w-[312px] pl-4 pr-10 py-8 border-l border-gray-200">
      <div className="flex justify-between items-center">
        <span className="text-gray-900 font-semibold">
          {mode === "member" ? (
            <span>
              <FormattedMessage
                id="memberAndRanking.memberMode.title"
                values={{
                  num: roomInfo.num_of_members,
                  max: roomInfo.max_slot,
                }}
              />
            </span>
          ) : (
            <span>
              <FormattedMessage id="memberAndRanking.rankingMode.title" />
            </span>
          )}
        </span>
        {isShowAddMemberButton && (
          <Button
            icon={<ProfileAddIcon />}
            onClick={onClickAddMember}
            className="w-[60px] h-[44px] rounded-[100px] bg-secondary border-none"
          />
        )}
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search hover:cursor-pointer" />
        <InputText
          placeholder={intl.formatMessage({
            id: "memberAndRanking.addMember.placeholder",
          })}
          className="border border-gray-200 rounded-[20px] w-full"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </span>
      <div className="h-full relative">
        <div className="overflow-auto">
          {listMember
            ?.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
            .map((member: any, idx) => (
              <MemberItem
                key={member?.member_hid}
                member={member}
                mode={mode}
                deletable={member.member_hid !== userInfos.member?.pub_id}
                onDeleteMember={onDeleteMember}
                isLast={idx === ITEMS_PER_PAGE - 1}
              />
            ))}
        </div>
        {maxPage > 1 && (
          <div className="w-[256px] mt-[32px]">
            <Paging page={page} setPage={setPage} maxPage={maxPage} />
          </div>
        )}
      </div>
    </div>
  );
}
