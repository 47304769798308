import { MathJax } from "better-react-mathjax";
import { isEmpty } from "lodash";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useScroll, useWindowSize } from "react-use";
import { NoteText, PencilEdit } from "../../../assets";
import { convertAnswerKey, quizAnswerType } from "../../common/utils/constant";
import ShowBasicDataContent from "./ShowBasicDataContent";
import { MAX_LENGTH_CORRECT_OPTION } from "../utils/constant";

const WIDTH_LEFT_RIGHT_SIDE = 312;

type CreateQuizRightSideProps = {
  disabled: any;
  currentQuestion: any;
  ordinalQuestionWithoutParent: number;
  handleChooseAnswerKey: any;
  onChangeCorrectAnswer: any;
  setOpenEditor: any;
  mainRef: any;
  isViewOnly: any;
  solution?: any;
  disablePreviousQuestionButton: boolean;
  onClickPreviousQuestionButton: () => void;
  disableNextQuestionButton: boolean;
  onClickNextQuestionButton: () => void;
};

function CreateQuizRightSide({
  disabled,
  currentQuestion,
  ordinalQuestionWithoutParent,
  handleChooseAnswerKey,
  onChangeCorrectAnswer,
  setOpenEditor,
  mainRef,
  isViewOnly,
  solution,
  disablePreviousQuestionButton,
  onClickPreviousQuestionButton,
  disableNextQuestionButton,
  onClickNextQuestionButton,
}: CreateQuizRightSideProps) {
  const intl = useIntl();
  const scroll = useScroll(mainRef);
  const windowSize = useWindowSize();
  const fixedRightSide = scroll?.y - 104 > 0;
  const checkEmptySolution = currentQuestion?.isSelfInput
    ? !currentQuestion || currentQuestion?.solutions?.length === 0 || currentQuestion?.solutions?.[0]?.deleted
    : !solution;

  return (
    <div
      className={`w-[${WIDTH_LEFT_RIGHT_SIDE}px] h-[calc(100vh-232px)] scrollbar-thin overflow-auto overflow-x-hidden ${
        disabled && "opacity-40 pointer-events-none"
      } pl-4 pr-10 py-6 flex flex-col gap-y-8 text-gray-900 font-[600]`}
      style={{
        position: fixedRightSide ? "fixed" : "unset",
        top: "90px",
        right: mainRef?.current?.clientHeight > windowSize?.height ? "16px" : "0px",
        // transition: "all 500ms",
      }}
    >
      <div className="flex justify-between items-center">
        <button
          className="disabled:text-gray-400"
          onClick={onClickPreviousQuestionButton}
          disabled={disablePreviousQuestionButton}
        >
          <i className="pi pi-chevron-left" />
        </button>
        Câu {ordinalQuestionWithoutParent > 0 ? ordinalQuestionWithoutParent : ""}
        <button
          onClick={onClickNextQuestionButton}
          className="disabled:text-gray-400"
          disabled={disableNextQuestionButton}
        >
          <i className="pi pi-chevron-right" />
        </button>
      </div>
      <div
        className={`${
          currentQuestion?.answer_type === quizAnswerType.ONE_ANSWER && currentQuestion?.original_id
            ? "flex justify-between items-center"
            : currentQuestion?.isSelfInput
            ? "space-y-3"
            : "inline-block"
        }`}
      >
        <span>Đáp án đúng: </span>
        {currentQuestion?.isSelfInput
          ? (() => {
              switch (currentQuestion?.answer_type) {
                case quizAnswerType.ONE_ANSWER:
                case quizAnswerType.MANY_ANSWER:
                  return (
                    <div className="grid grid-cols-4 gap-y-2">
                      {currentQuestion.options.map((option) => (
                        <div className="flex items-center gap-2">
                          <Checkbox
                            onChange={(e) => handleChooseAnswerKey(option.answer_key, e.checked as boolean)}
                            checked={Boolean(option.correct)}
                            pt={{
                              input: {
                                className: "[&]:shadow-none",
                              },
                            }}
                            disabled={isViewOnly}
                          />
                          {option.answer_key}
                        </div>
                      ))}
                    </div>
                  );
                case quizAnswerType.CONSTRUCTED_RESPONSE:
                  const correctOptionContent = currentQuestion.options.find((option) => option.correct === 1)
                    ?.contents[0].content;
                  const isError = correctOptionContent?.length > MAX_LENGTH_CORRECT_OPTION;
                  return (
                    <div>
                      <InputTextarea
                        className={`w-full h-[104px] resize-none ${
                          isError &&
                          "[&.p-inputtextarea]:!border-error [&.p-inputtextarea:focus]:!border-error [&.p-inputtextarea:hover]:!border-error"
                        }`}
                        value={correctOptionContent}
                        onChange={(e) => {
                          onChangeCorrectAnswer(e.target.value);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                          }
                        }}
                      />
                      {isError && (
                        <span className="text-error text-[13px] font-normal">
                          <FormattedMessage id="createQuestion.errorMessage.characterLimitExceeded" />
                        </span>
                      )}
                    </div>
                  );
              }
            })()
          : (() => {
              switch (currentQuestion?.answer_type) {
                case quizAnswerType.SORT_POSITION: {
                  const checkContentImage = currentQuestion?.options?.some(
                    (e) => e?.items[0]?.content?.includes("<img")
                  );
                  const correctAnswer = solution?.correct_options?.map(
                    (ele) => currentQuestion?.options?.findIndex((e) => e.id === ele) + 1
                  );
                  return (
                    <div className="font-normal">
                      {checkContentImage
                        ? correctAnswer?.join(" - ")
                        : solution?.correct_options
                            ?.map((correctId) => {
                              return currentQuestion?.options.find((option) => option.id === correctId).items[0]
                                .content;
                            })
                            .join(" ")}
                    </div>
                  );
                }
                case quizAnswerType.ONE_ANSWER: {
                  return (
                    <span className="w-[90px] h-[40px] border flex justify-center items-center rounded-[16px] font-normal text-[#495057]">
                      {convertAnswerKey(currentQuestion?.options?.find((e) => e?.correct === 1)?.ordinal - 1)}
                    </span>
                  );
                }
                case quizAnswerType.CONCAT_LETTER_EN:
                case quizAnswerType.CONCAT_LETTER_VI:
                case quizAnswerType.CONCAT_NUMBER:
                case quizAnswerType.CONCAT_WORD:
                case quizAnswerType.CONCAT_LETTER_MIX:
                  return (
                    solution?.correct_options && (
                      <span className="font-normal">{solution?.correct_options?.join(" ")}</span>
                    )
                  );
              }
            })()}
      </div>
      <div className="flex flex-col break-words">
        <span className="mb-2 flex justify-between items-center pb-4">
          Hướng dẫn giải:&nbsp;
          {!checkEmptySolution && !isViewOnly && (
            <button onClick={() => setOpenEditor(true)}>
              <PencilEdit />
            </button>
          )}
        </span>
        {!checkEmptySolution ? (
          <MathJax dynamic>
            {currentQuestion?.isSelfInput ? (
              currentQuestion?.solutions?.map((so: any) => (
                <div key={so.ordinal} className="font-[500] text-[14px]">
                  <ShowBasicDataContent ele={so} />
                </div>
              ))
            ) : (
              <div className="break-words">
                {isEmpty(solution?.items?.suggestion) && isEmpty(solution?.items?.detail) ? (
                  <span className="font-normal">{intl.formatMessage({ id: "manageExam.noSolution" })}</span>
                ) : (
                  <>
                    {solution?.items?.suggestion?.map((solution, idx) => (
                      <div key={idx} className="flex flex-col">
                        <ShowBasicDataContent ele={solution} />
                      </div>
                    ))}
                    {solution?.items?.detail?.map((solution, idx) => (
                      <div key={idx} className="flex flex-col">
                        <ShowBasicDataContent ele={solution} />
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </MathJax>
        ) : !isViewOnly ? (
          <button
            className="border-[1px] font-[400] text-[12px] rounded-[16px] w-[256px] h-[40px] flex items-center justify-center gap-1"
            onClick={() => setOpenEditor(true)}
          >
            <NoteText />
            Thêm hướng dẫn giải
          </button>
        ) : null}
      </div>
    </div>
  );
}
export default React.memo(CreateQuizRightSide);
